import { HttpMenuApi } from 'plugins/MenuPlugin/MenuApi';
import { MyCheckApp } from '../core';
import { HttpLocationApi } from 'plugins/LocationPlugin/LocationApi';
import { ApiClient } from '../ApiClient';
import { GaService } from '../service/GaService';
import { HttpAuthApi } from 'plugins/AuthPlugin/AuthApi';
import { HttpCheckoutApi } from 'plugins/CheckoutPlugin/CheckoutApi';

declare const window: {
  injector: Injector;
};

type InjectorSetterValueType =
  | HttpMenuApi
  | HttpAuthApi
  | HttpCheckoutApi
  | MyCheckApp
  | HttpLocationApi
  | ApiClient
  | GaService;

const idMultiplier = 1000;

export class Injector {
  public id: number = Math.ceil(Math.random() * idMultiplier);

  private map: Map<string, any> = new Map();

  public get<T>(key: string): T {
    return this.map.get(key);
  }

  public set(key: string, value: InjectorSetterValueType): void {
    this.map.set(key, value);
  }
}

if (!window.injector) {
  window.injector = new Injector();
}

export const injector = window.injector;
