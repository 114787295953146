import axios from 'axios';
import get from 'lodash/get';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment-timezone';

import {
  FLOW_TYPES,
  isPast,
  MyCheckApp,
  ParamErrorCodes,
  PaymentConstants,
  setDateTime,
  toLocationFormat,
  UseNavigationProps,
} from 'mycheck-core';
import { IConfigTimeSettings } from 'types/GenericTypes';
import {
  IAllergenData,
  ICurrency,
  IExperienceItem,
  IGenericLocationItem,
  IHotelItem,
  ILocationExperience,
  ILocationItem,
  ITimeSettings,
  IUICheckoutSettings,
  IUIMenuSettings,
  LocationApi,
} from './types/LocationTypes';

export class LocationStore {
  @observable @persist('list') hotelsList: Array<IHotelItem> = [];
  @observable @persist('list') restaurantList: Array<ILocationItem> = [];
  @observable selectedExperience: IExperienceItem = null;
  @observable isExperienceFetching = false;
  @observable isHotelListFetching = false;

  @observable refreshGuestToken = '';
  @observable flowType: FLOW_TYPES = FLOW_TYPES.RESTAURANT;
  @observable viewMode = false;
  @observable fetchingList: Array<Date> = [];
  @observable interval = 1;

  @observable isSelectedRestaurantIdFromUrl = false;
  @observable isAutoExperienceId = false;

  @observable isMenuSelectFirstSlot = false;

  @observable selectedDate = moment.utc().format();
  @observable selectedDateTemp: string = undefined;
  @observable selectedExperienceType = '';
  @observable selectedExperienceTypeTemp = '';
  @observable selectedRestaurantId: number = null;
  @observable selectedHotel: number = null;
  @observable locationGroupId: number = null;
  @observable selectedExperienceId: number = null;
  @observable @persist('list') allergensList: Array<IAllergenData> = [];
  @observable @persist('list') currenciesList: Array<ICurrency> = [];
  @observable timeSettings: ITimeSettings = null;
  @observable currentLocationID: number = undefined;

  @observable isNative = false;
  @observable isIframe = false;
  @observable moreInfo = null;
  @observable timeSlots = null;
  @observable navigation: UseNavigationProps = null;

  private LocationApi: LocationApi;

  constructor(
    locationApi: LocationApi,
    options: { timeSettings: IConfigTimeSettings },
    flowType: FLOW_TYPES,
    isViewMode: boolean,
  ) {
    this.LocationApi = locationApi;
    this.interval = options.timeSettings.interval;
    this.flowType = flowType;
    this.viewMode = isViewMode;

    makeAutoObservable(this);
  }

  @computed
  get isFetching(): boolean {
    return this.fetchingList.length !== 0;
  }

  @computed
  get homeList(): Array<IGenericLocationItem<any>> {
    return this.flowType === FLOW_TYPES.RESTAURANT
      ? this.restaurantList
      : this.hotelsList;
  }

  @computed
  get selectedRestaurant(): ILocationItem {
    return (
      this.restaurantList.find((e) => e.id === this.selectedRestaurantId) ||
      ({} as ILocationItem)
    );
  }

  @computed
  get selectedHotelObj(): IHotelItem {
    return (
      this.hotelsList.find((e) => e.id === this.selectedHotel) ||
      ({} as IHotelItem)
    );
  }

  @computed
  get selectedExperienceFromList(): ILocationExperience {
    return (
      this.selectedRestaurant.experiences?.find(
        (e) => e.id === this.selectedExperienceId,
      ) || ({} as ILocationExperience)
    );
  }

  @computed
  get menuFiles() {
    return this.selectedExperience?.settings.menus?.files || [];
  }

  @computed
  get menuTranslations() {
    return this.selectedExperience?.settings.menus?.translations?.files || [];
  }

  @computed
  get chargeOptions() {
    return (
      (this.selectedExperience?.settings.payments.allowed || [])
        .map((elem) => elem.type)
        .filter((elem) =>
          PaymentConstants.SupportedPayments.includes(
            elem as unknown as (typeof PaymentConstants.SupportedPayments)[number],
          ),
        )
        .map((key) => ({
          value: PaymentConstants.PaymentMethodsValue[key],
          label: PaymentConstants.PaymentMethodsLabel[key],
        })) || []
    );
  }

  @computed
  get deliveryOption() {
    return this.selectedExperience?.settings.deliveries
      .map((elem) => elem.type)
      .map((key) => ({
        value: PaymentConstants.DeliveryOptionsValue[key],
        label: PaymentConstants.DeliveryOptionsLabel[key],
      }))[0];
  }

  @computed
  get uiCheckoutSettings(): IUICheckoutSettings {
    return (
      this.selectedExperience?.settings?.ui?.checkout ||
      ({} as IUICheckoutSettings)
    );
  }

  @computed
  get uiMenuSettings(): IUIMenuSettings {
    return (
      this.selectedExperience?.settings?.ui?.menu || ({} as IUIMenuSettings)
    );
  }

  @computed
  get experiencesTypes(): Array<string> {
    const config = MyCheckApp.instance.hotelConfig;
    const default_type = get(config, 'experience.default_type', null);

    const types = [
      ...new Set(
        this.restaurantList
          .map((location) => location.experiences.map((exp) => exp.type))
          .reduce((a, b) => [...a, ...b], []),
      ),
    ];

    if (!types.includes(default_type)) return types;

    return [default_type, ...types.filter((item) => item !== default_type)];
  }

  @computed
  get locationTimezoneName(): string {
    const selectedExperience = this.selectedExperience?.timezone?.name;

    if (selectedExperience) {
      return selectedExperience;
    }

    const timezoneHotel = this.selectedHotelObj?.timezone?.name;
    const timezoneRestaurant = this.selectedRestaurant?.timezone?.name;
    return typeof timezoneHotel !== 'undefined'
      ? timezoneHotel
      : timezoneRestaurant;
  }

  @computed
  get isSelectedExperienceActive(): boolean {
    return this.selectedExperience?.settings?.checkout?.is_active ?? true;
  }

  @computed
  get isAsap(): boolean {
    return this.selectedExperience?.settings?.is_asap_only ?? false;
  }

  get productId(): number {
    return get(MyCheckApp.instance.getGlobalConfig(), 'settings.product', 0);
  }

  @computed
  get localDateFormat(): string {
    return (
      this.selectedRestaurant?.localization?.date.default_format ??
      this.selectedHotelObj?.localization?.date.default_format
    );
  }

  @computed
  get localTimeFormat(): string {
    return (
      this.selectedRestaurant?.localization?.time.default_format ??
      this.selectedHotelObj?.localization?.time.default_format
    );
  }

  @computed
  get localHotelDateFormat(): string {
    return this.selectedHotelObj?.localization?.date.default_format;
  }

  @computed
  get localHotelTimeFormat(): string {
    return this.selectedHotelObj?.localization?.time.default_format
      .replace('A', 'a')
      .replace(' ', '');
  }

  setIsMenuSelectFirstSlot = (isFirst: boolean): void => {
    this.isMenuSelectFirstSlot = isFirst;
  };

  @action
  // TODO type time argument properly throughout the app
  setSelectedTime = (time: string): void => {
    if (time) {
      this.selectedDate = setDateTime(
        this.selectedDate,
        isPast(time) ? moment.utc().format() : time,
      );
    }
  };

  @action
  // TODO type time argument properly throughout the app
  setSelectedDate = (date: string): void => {
    if (date) {
      this.selectedDate = setDateTime(
        isPast(date) ? moment.utc().format() : date,
      );
    }
  };

  @action
  resetSelectedDate = (): void => {
    this.selectedDate = '';
  };

  @action
  // TODO type time argument properly throughout the app
  setSelectedTimeTemp = (time: string): void => {
    if (time) {
      this.selectedDateTemp = setDateTime(
        this.selectedDateTemp,
        isPast(time) ? moment.utc().format() : time,
      );
    }
  };

  @action
  // TODO type time argument properly throughout the app
  setSelectedDateTemp = (date: string): void => {
    if (date) {
      this.selectedDateTemp = setDateTime(
        isPast(date) ? moment.utc().format() : date,
      );
    }
  };

  @action
  resetSelectedDateTemp = (): void => {
    this.selectedDateTemp = '';
  };

  @action
  resetTimeSettings = (): void => {
    this.timeSettings = null;
  };

  @action
  setSelectedExperienceType = (
    experience: string,
    getExperienceId = false,
  ): void => {
    this.selectedExperienceType = experience;
    this.isAutoExperienceId = getExperienceId;
  };

  @action
  setSelectedExperienceTypeTemp = (experience: string): void => {
    this.selectedExperienceTypeTemp = experience;
  };

  @action
  setSelectedRestaurantId = (restaurantId: number, fromUrl = false): void => {
    this.selectedRestaurantId = Number(restaurantId);
    this.isSelectedRestaurantIdFromUrl = fromUrl;
  };

  @action
  setSelectedHotel = (hotel: number): void => {
    this.selectedHotel = hotel;
    this.selectedExperience = null;
  };

  @action
  setLocationGroupId = (locationGroupId: number): void => {
    this.locationGroupId = locationGroupId;
  };

  @action
  setIsNative = (isNative: boolean): void => {
    this.isNative = isNative;
  };

  @action
  setIsIframe = (isIframe: boolean): void => {
    this.isIframe = isIframe;

    if (isIframe) {
      document.body.className = 'iframe';
    }
  };

  @action
  setMoreInfo = (restaurantId: number): void => {
    this.moreInfo = restaurantId;
  };

  @action
  setTimeSlots = (restaurantId: number): void => {
    this.timeSlots = restaurantId;
  };

  @action
  setSelectedExperienceId = (id: number): void => {
    this.selectedExperienceId = id;
  };

  @action
  setCurrentLocationId = (id: number) => {
    this.currentLocationID = id;
  };

  @action
  setNavigation = (navigation: UseNavigationProps) => {
    this.navigation = navigation;
  };

  @action
  clearRestaurant = (): void => {
    this.isSelectedRestaurantIdFromUrl = false;
    this.isAutoExperienceId = false;
  };

  @action
  clearRestaurantList = (): void => {
    this.restaurantList = [];
  };

  @action
  fetchHomeList = async (businessId: number): Promise<void> => {
    if (this.flowType === FLOW_TYPES.RESTAURANT) {
      await this.fetchRestaurants(businessId);
    } else {
      await this.fetchHotels(businessId);
    }
  };

  @action
  fetchHotels = async (businessId: number): Promise<void> => {
    this.isHotelListFetching = true;

    try {
      const response = await this.LocationApi.fetchHotels(businessId);
      this.hotelsList = response.data;

      if (
        this.selectedHotel &&
        !this.hotelsList.map((e) => e.id).includes(this.selectedHotel)
      ) {
        this.navigation.push('../not-found', true, ParamErrorCodes.HOTEL_ERROR);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.navigation.push('../not-found', true, ParamErrorCodes.HOTEL_ERROR);
      }
    } finally {
      this.isHotelListFetching = false;
    }
  };

  @action
  startFetching = (): void => {
    this.fetchingList.push(new Date());
  };

  @action
  stopFetching = (): void => {
    this.fetchingList.pop();
  };

  @action
  fetchRestaurants = async (
    businessId: number,
    backgroundFetching = false,
  ): Promise<void> => {
    try {
      if (!this.fetchingList.length && !backgroundFetching) {
        this.startFetching();
      }

      const response = await this.LocationApi.fetchLocations({
        businessId,
        date: this.selectedDate ? toLocationFormat(this.selectedDate) : '',
        locationGroupId: this.locationGroupId,
      });

      const restaurant = response.data.find(
        (v) => v.id === this.selectedRestaurantId,
      );

      if (this.isSelectedRestaurantIdFromUrl && !restaurant) {
        this.navigation.push(
          '../not-found',
          true,
          ParamErrorCodes.RESTAURANT_ERROR,
        );
      }

      const currentExperience = restaurant?.experiences.find(
        (v) => v.type === this.selectedExperienceType,
      );

      if (this.isAutoExperienceId && !currentExperience) {
        this.navigation.push(
          '../not-found',
          true,
          ParamErrorCodes.EXPERIENCE_ERROR,
        );
      }

      runInAction(() => {
        this.restaurantList = response.data;
        if (this.isAutoExperienceId) {
          this.setSelectedExperienceId(currentExperience.id);
        }
      });

      if (this.experiencesTypes.length === 1) {
        this.setSelectedExperienceType(this.experiencesTypes[0]);
        this.setSelectedExperienceTypeTemp(this.experiencesTypes[0]);
      }
    } catch (err) {
      if (!axios.isCancel(err)) {
        this.navigation.push('../not-found', true, ParamErrorCodes.HOTEL_ERROR);
      }
    } finally {
      if (this.timeSettings) {
        this.stopFetching();
      }
    }
  };

  @action
  fetchExperience = async (selectedExperienceId: number): Promise<void> => {
    try {
      this.isExperienceFetching = true;
      const response =
        await this.LocationApi.fetchExperience(selectedExperienceId);
      runInAction(() => {
        if (selectedExperienceId !== this.selectedExperienceId) {
          this.setSelectedExperienceId(selectedExperienceId);
        }
        this.selectedExperience = response.data;
      });
    } finally {
      this.isExperienceFetching = false;
    }
  };

  @action
  fetchAllergens = async (): Promise<void> => {
    if (this.selectedExperience.settings.menus?.allergens?.url) {
      const response = await this.LocationApi.fetchAllergens(
        this.selectedExperience.settings.menus.allergens.url,
      );
      this.allergensList = response.allergens;
    }
  };

  @action
  confirmExperienceSlot = async (
    checkoutTime: string,
    selectedExperienceId: number = this.selectedExperience.id,
    updateTimeSlots?: boolean,
  ) => {
    return await this.LocationApi.confirmExperienceSlot(
      selectedExperienceId,
      toLocationFormat(checkoutTime || new Date()),
    ).then((data) => {
      if (data && (!this.timeSlots || updateTimeSlots))
        this.timeSlots = data.data.time_slots;

      return data.data;
    });
  };

  @action
  fetchCurrenciesList = async (): Promise<void> => {
    const { data } = await this.LocationApi.fetchCurrenciesList();
    this.currenciesList = data;
  };

  @action
  fetchTimeSettings = async (businessId: number): Promise<void> => {
    const { data } = await this.LocationApi.fetchTimeSettings({
      businessId,
      experience: {
        type: this.selectedExperienceType,
      },
    });
    this.timeSettings = { ...data, interval: this.interval };
  };

  @computed
  isTipAvailable(paymentMethod: string): boolean {
    const hideTip =
      this.selectedExperience?.settings?.ui.checkout?.tip?.hide_tip
        ?.payment_types || [];
    const isTipHiddenDueToPaymentMethod = hideTip.includes(paymentMethod);
    const noTipsAvailable = !!this.uiCheckoutSettings.tip?.options.length;
    return !isTipHiddenDueToPaymentMethod && noTipsAvailable;
  }

  @computed
  fetchAll = async (
    page: 'restaurant' | 'menu',
    blockHomePage?: boolean,
  ): Promise<void> => {
    try {
      if (!this.isFetching) {
        this.startFetching();

        const flowCalls = [
          this.fetchTimeSettings(this.selectedHotel),
          this.fetchRestaurants(this.selectedHotel),
        ];

        if (page === 'menu' || (page === 'restaurant' && blockHomePage)) {
          flowCalls.push(this.fetchHotels(this.selectedHotel));
        }

        await Promise.all(flowCalls);
      }
    } catch (err) {
    } finally {
      this.stopFetching();
    }
  };
}
