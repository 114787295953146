import React, { useEffect, useState } from 'react';

export const CookiePolicy: React.FC = () => {
  const [show, _setShow] = useState(false);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.removeAttribute('style');
    };
  }, [show]);

  return null;
};
