import {
  isPast,
  isWithinSixtyMinutes,
  useCheckoutStore,
  useIsLocationOpen,
  useLocationStore,
  useNearestTime,
} from 'mycheck-core';

export function useCanContinueToCheckout() {
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const { nearestTime } = useNearestTime();
  const { isOpen } = useIsLocationOpen();

  if (
    LocationStore.viewMode ||
    !Object.keys(CheckoutStore.currentOrder).length
  ) {
    return false;
  }

  const selectedExp = LocationStore.selectedRestaurant.experiences?.find(
    (e) => e.id === LocationStore.selectedExperienceId,
  );
  if (!selectedExp) {
    return false;
  }

  if (!selectedExp.settings.slots.next_available) {
    return false;
  }

  if (selectedExp.settings.is_asap_only) {
    if (
      isOpen(LocationStore.selectedExperience) &&
      isWithinSixtyMinutes(CheckoutStore.checkoutTime)
    ) {
      if (
        isPast(LocationStore.selectedDate, nearestTime) ||
        isPast(LocationStore.selectedDate)
      ) {
        return true;
      }
    }

    if (CheckoutStore.isGrace && CheckoutStore.isOnGraceTime) {
      return true;
    }
    return false;
  }

  return true;
}
