import React, {
  useEffect,
  useMemo,
  useState,
  type ChangeEvent,
  type KeyboardEvent,
} from 'react';

import { FormikProps, FormikErrors } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import {
  capitalizeFirstLetter,
  GaService,
  MyCheckApp,
  PaymentConstants,
  removeEmojies,
  Trans,
  useAuthStore,
  useCanContinueToCheckout,
  useCheckoutStore,
  useLanguageStore,
  useLocationStore,
  useTranslation,
  useWalletService,
  useWindowSize,
} from 'mycheck-core';

import {
  CustomInput,
  CustomPhoneInput,
  ExternalLink,
  FieldLimitLabel,
  SelectBox,
} from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { currencyFormatter } from '../../../../core/core/helpers/currencyFormatter';
import { NumberOfGuestOptions, TipStringValues } from '../../Constants';
import { IFormikValues } from '../../types/IFormikValues';
import { Coupon } from '../Coupon/Coupon';
import { CustomCheckbox } from '../CustomCheckbox/CustomCheckbox';
import { OrderSummary } from '../OrderSummary/OrderSummary';
import { PaymentsMethodBox } from '../PaymentsMethod/PaymentsMethodBox';

import styles from './CheckoutForm.module.scss';
import { IUIFieldSettings } from 'plugins/LocationPlugin/types/LocationTypes';
import { CardSummary, Tax } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

interface Props {
  formikProps: FormikProps<IFormikValues>;
  isPendingCalculations: boolean;
  isCheckoutFetching: boolean;
  setEnableValidation: (status: boolean) => void;
  validationSchema: yup.ObjectSchema<Record<string, unknown>>;
  summary: Partial<CardSummary>;
  taxes: Array<Tax>;
  onCouponVerify: (summary: CardSummary, taxes: Array<Tax>) => void;
}

export const CheckoutForm: React.FC<Props> = observer(
  ({
    formikProps,
    summary,
    taxes,
    isPendingCalculations,
    setEnableValidation,
    validationSchema,
    isCheckoutFetching,
    onCouponVerify,
  }) => {
    const { t } = useTranslation();
    const AuthStore = useAuthStore();
    const LocationStore = useLocationStore();
    const CheckoutStore = useCheckoutStore();
    const LanguageStore = useLanguageStore();
    const canContinueToCheckout = useCanContinueToCheckout();
    const walletService = useWalletService();
    const location = useLocation();
    const currentCurrency = LocationStore.currenciesList.find(
      (item) => item.code === LocationStore.selectedRestaurant.currency,
    );
    const { isLg } = useWindowSize();

    const getIsFieldVisible = (name: string): boolean => {
      const fieldSettings: IUIFieldSettings =
        LocationStore.uiCheckoutSettings[name];
      if (!fieldSettings) {
        return false;
      } else {
        return fieldSettings.show_with_payment_type &&
          fieldSettings.show_with_payment_type.length
          ? fieldSettings.show_with_payment_type.some(
              (paymentType) => paymentType === formikProps.values.paymentMethod,
            )
          : true;
      }
    };

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const termsAndConditions = get(
        _config,
        'home.settings.termsAndConditions',
        null,
      );
      const privacyPolicy = get(_config, 'home.settings.privacyPolicy', null);
      const linkStyle = get(_config, 'checkout.link', {});

      const textStyle = get(_config, 'checkout.terms', {});
      const dividerStyle = {
        borderBottom: get(_config, 'general.divider', {}),
      };
      const paymentDisclaimerStyle = get(
        _config,
        'checkout.paymentDisclaimer',
        {},
      );
      const paymentMethodDisclaimerStyle = {
        ..._config.paymentDisclaimerStyle,
        textAlign: 'center',
        marginTop: 13,
      };

      return {
        termsAndConditions,
        privacyPolicy,
        linkStyle,
        textStyle,
        dividerStyle,
        paymentDisclaimerStyle,
        paymentMethodDisclaimerStyle,
      };
    }, [location.search]);
    const [isCardLoading, setIsCardLoading] = useState(false);

    const isRequiredField = (name: string) => {
      try {
        const yupTests = yup.reach(validationSchema, name).describe();
        return (
          yupTests as unknown as { tests: [{ name: string }] }
        ).tests.some((test) => test.name === 'required');
      } catch (error) {
        return false;
      }
    };

    const tipValues =
      LocationStore.uiCheckoutSettings.tip?.options.map((v) => {
        if (v === TipStringValues.NO_TIP) {
          return {
            id: TipStringValues.NO_TIP,
            value: 0,
            label: t('checkout.noTipLabel'),
          };
        }

        if (v === TipStringValues.CUSTOM) {
          return { id: TipStringValues.CUSTOM, value: 0 };
        }

        return { id: v, value: v / 100, label: `${v}%` } as unknown as {
          id: TipStringValues;
          value: number;
          label: string;
        };
      }) || [];

    const tipPrice = () => {
      const customTip = formikProps.values.customTip;
      if (!summary || !formikProps.values.tip) {
        CheckoutStore.setTipPrice(0);
        return 0;
      }

      if (formikProps.values.tip === '0') {
        CheckoutStore.setTipPrice(0);
        return 0;
      }

      if (!!customTip) {
        CheckoutStore.setTipPrice(customTip || 0);
        return customTip || 0;
      }

      const result =
        tipValues?.find((e) => e.id === formikProps.values.tip)?.value *
          (summary.subtotal as number) || 0;
      CheckoutStore.setTipPrice(result);
      return result;
    };

    const onPaymentMethodChange = async () => {
      if (LocationStore.isTipAvailable(formikProps.values.paymentMethod)) {
        await formikProps.setFieldValue(
          'tip',
          LocationStore.uiCheckoutSettings?.tip?.selected,
        );
      } else {
        await formikProps.setFieldValue('customTip', '');
        await formikProps.setFieldValue('tip', 0);
      }

      if (CheckoutStore.coupon) {
        const accessToken = AuthStore.accessToken;
        const businessId = LocationStore.selectedRestaurantId;
        const experienceId = LocationStore.selectedExperience.id;
        setIsCardLoading(true);
        const response = await CheckoutStore.updateOrCreateCheckout({
          accessToken,
          businessId,
          experienceId,
          totalCalculation: true,
          couponCode: getIsFieldVisible('coupons')
            ? CheckoutStore.coupon.code
            : undefined,
        });
        setIsCardLoading(response.isPendingCalculations);
        onCouponVerify(response.summary, response.taxes);
      }
    };

    useEffect(() => {
      (async () => {
        await onPaymentMethodChange();
      })();
    }, [formikProps.values.paymentMethod, AuthStore.refreshToken]);

    useEffect(() => {
      walletService.setPayment(summary.total as number, tipPrice());
    }, [summary.total, tipPrice()]);

    const selectBoxStyle = { marginTop: 24 };

    const sendGaOnValidation = (errors: { [key: string]: string }) => {
      Object.values(errors).forEach((error) =>
        GaService.instance.sendEvent({
          category: 'Ordering - Checkout',
          action: 'Error',
          label: error,
        }),
      );
    };

    const isRoomNumberFieldVisible = getIsFieldVisible('room_number');

    const getLabelTranslation = (name: string) =>
      LanguageStore.getExperienceTranslation(
        LocationStore.selectedExperience.id,
        `settings.ui.checkout.${name}.label`,
        LocationStore.uiCheckoutSettings[name].label,
      );

    const handleChangeComment = (event: ChangeEvent<HTMLTextAreaElement>) =>
      formikProps.setFieldValue(
        'additionalComment',
        removeEmojies(event.currentTarget.value),
      );

    const isCashDisclaimerVisible =
      LocationStore.uiCheckoutSettings.show_payment_methods &&
      formikProps.values.paymentMethod ===
        PaymentConstants.PaymentMethodsValue.PAY_IN_PERSON;

    const handleEnterPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    const selectedPaymentMethod = LocationStore.chargeOptions.filter((pm) => {
      return pm.value === CheckoutStore.paymentMethod;
    })[0];

    return (
      <form
        onSubmit={async (event: any) => {
          setEnableValidation(true);
          event.preventDefault();
          const phone = formikProps.values.phone;
          const dialCode = formikProps.values.dialCode;

          if (phone === dialCode) {
            await formikProps.setFieldValue('phone', '');
          }
          const errors = await formikProps.validateForm();
          sendGaOnValidation(errors as FormikErrors<unknown>);
          formikProps.handleSubmit();
        }}
        onKeyDown={handleEnterPress}
        className={styles.checkoutPageForm}
      >
        <CustomButton
          text={`${capitalizeFirstLetter(
            CheckoutStore.paymentMethod ===
              PaymentConstants.PaymentMethodsValue.WALLET
              ? t('checkout.pay')
              : t('checkout.submitButton').toLowerCase(),
          )} ${currencyFormatter(
            summary.total && parseFloat(summary.total.toString()) + tipPrice(),
            currentCurrency,
          )}`}
          type="submit"
          className={styles.checkoutPageFormCustomButton}
          testId="checkout-form"
          disabled={
            isPendingCalculations ||
            isCheckoutFetching ||
            !canContinueToCheckout
          }
        />
        {LocationStore.uiCheckoutSettings.show_payment_methods && (
          <PaymentsMethodBox
            testId={'payment-method-checkout'}
            text={
              t(`checkout.paymentMethods.${selectedPaymentMethod.value}`) ||
              selectedPaymentMethod.label
            }
            onPaymentMethodChange={() =>
              formikProps.setFieldValue(
                'paymentMethod',
                CheckoutStore.paymentMethod,
              )
            }
          />
        )}
        <div className={styles.checkoutPageFormUserInfo}>
          <div className={styles.checkoutPageFormUserInfoTitle}>
            {t('checkout.yourDetails')}
          </div>
          {getIsFieldVisible('first_name') && (
            <CustomInput
              onChange={formikProps.handleChange}
              value={formikProps.values.firstName}
              name={'firstName'}
              error={!!formikProps.errors.firstName}
              placeholder={getLabelTranslation('first_name')}
              type={LocationStore.uiCheckoutSettings.first_name.type}
              disabled={!LocationStore.uiCheckoutSettings.first_name.editable}
              showOptional={!isRequiredField('firstName')}
              maxLength={LocationStore.uiCheckoutSettings.first_name.max}
              testId="first-name-checkout"
            />
          )}
          {getIsFieldVisible('last_name') && (
            <CustomInput
              onChange={formikProps.handleChange}
              value={formikProps.values.lastName}
              error={!!formikProps.errors.lastName}
              name={'lastName'}
              placeholder={getLabelTranslation('last_name')}
              type={LocationStore.uiCheckoutSettings.last_name.type}
              disabled={!LocationStore.uiCheckoutSettings.last_name.editable}
              showOptional={!isRequiredField('lastName')}
              maxLength={LocationStore.uiCheckoutSettings.last_name.max}
              testId="last-name-checkout"
            />
          )}
          {getIsFieldVisible('phone') && (
            <CustomPhoneInput
              formikProps={formikProps}
              placeholder={getLabelTranslation('phone')}
              disabled={!LocationStore.uiCheckoutSettings.phone.editable}
              showOptional={!isRequiredField('phone')}
              testId="phone-checkout"
            />
          )}
          {getIsFieldVisible('email') && (
            <CustomInput
              onChange={formikProps.handleChange}
              value={formikProps.values.email}
              error={!!formikProps.errors.email}
              name={'email'}
              placeholder={getLabelTranslation('email')}
              type="text"
              disabled={!LocationStore.uiCheckoutSettings.email.editable}
              showOptional={!isRequiredField('email')}
              maxLength={LocationStore.uiCheckoutSettings.email.max}
              testId="email-checkout"
            />
          )}
          {isCashDisclaimerVisible && (
            <div className={styles.checkoutPageFormUserInfoDisclaimer}>
              {t('checkout.cashDisclaimer')}
            </div>
          )}
          {isRoomNumberFieldVisible && (
            <>
              <CustomInput
                onChange={formikProps.handleChange}
                value={formikProps.values.roomNumber}
                name={'roomNumber'}
                id={'roomNumber'}
                error={!!formikProps.errors.roomNumber}
                placeholder={getLabelTranslation('room_number')}
                type={LocationStore.uiCheckoutSettings.room_number.type}
                disabled={
                  !LocationStore.uiCheckoutSettings.room_number.editable
                }
                showOptional={!isRequiredField('roomNumber')}
                maxLength={LocationStore.uiCheckoutSettings.room_number.max}
                testId="room-number-checkout"
              />
              {!!formikProps.errors.roomNumber && (
                <div
                  className={styles.checkoutPageFormUserInfoDisclaimer}
                  style={{ color: '#bb0e2d' }}
                >
                  {t('checkout.roomNumberDisclaimer')}
                </div>
              )}
            </>
          )}

          {getIsFieldVisible('table_number') && (
            <CustomInput
              onChange={formikProps.handleChange}
              value={formikProps.values.tableNumber}
              name={'tableNumber'}
              error={!!formikProps.errors.tableNumber}
              placeholder={getLabelTranslation('table_number')}
              type={LocationStore.uiCheckoutSettings.table_number.type}
              disabled={!LocationStore.uiCheckoutSettings.table_number.editable}
              showOptional={!isRequiredField('tableNumber')}
              maxLength={LocationStore.uiCheckoutSettings.table_number.max}
              testId="table-number-checkout"
            />
          )}
          {getIsFieldVisible('guest_count') && (
            <SelectBox
              data={NumberOfGuestOptions}
              formikProps={formikProps}
              value={formikProps.values.numberOfGuest}
              property={'numberOfGuest'}
              error={!!formikProps.errors.numberOfGuest}
              placeholder={getLabelTranslation('guest_count')}
              disabled={!LocationStore.uiCheckoutSettings.guest_count.editable}
              customStyle={selectBoxStyle}
              withLabel={!!formikProps.values.numberOfGuest}
              limit={4}
              showOptional={!isRequiredField('numberOfGuest')}
              testId="guest-checkout"
            />
          )}
          {getIsFieldVisible('comments') && (
            <>
              <CustomInput
                textarea
                className={styles.checkoutPageFormUserInfoAdditionalComment}
                onChange={handleChangeComment}
                name={'additionalComment'}
                value={formikProps.values.additionalComment}
                error={!!formikProps.errors.additionalComment}
                placeholder={getLabelTranslation('comments')}
                type={LocationStore.uiCheckoutSettings.comments.type}
                disabled={!LocationStore.uiCheckoutSettings.comments.editable}
                showOptional={!isRequiredField('additionalComment')}
                maxLength={LocationStore.uiCheckoutSettings.comments.max}
                testId="comment-checkout"
              />
              <FieldLimitLabel
                fieldName="additionalComment"
                maxLength={LocationStore.uiCheckoutSettings.comments.max}
                style={
                  isLg
                    ? {
                        width: 'auto',
                        marginRight: 0,
                      }
                    : { marginRight: 0 }
                }
              />
            </>
          )}
        </div>
        {getIsFieldVisible('coupons') && (
          <Coupon onCouponVerify={onCouponVerify} />
        )}
        <OrderSummary
          formikProps={formikProps}
          tipValues={tipValues}
          tipPrice={tipPrice()}
          summary={summary}
          taxes={taxes}
          isPendingCalculations={isPendingCalculations || isCardLoading}
        />

        <div className={styles.checkoutPageFormTermsAndConditions}>
          {(config.termsAndConditions || config.privacyPolicy) && (
            <CustomCheckbox
              name={'termsAndConditions'}
              value={formikProps.values.termsAndConditions}
              testId="terms-and-conditions"
            >
              <div className={styles.checkoutPageFormTermsAndConditionsText}>
                {!!config.termsAndConditions && (
                  <Trans
                    i18nKey="checkout.termsAndConditions"
                    components={{
                      termsLink: (
                        <ExternalLink href={config.termsAndConditions}>
                          text
                        </ExternalLink>
                      ),
                    }}
                  />
                )}
                {!!config.privacyPolicy && (
                  <Trans
                    i18nKey="checkout.privacyPolicy"
                    components={{
                      privacyLink: (
                        <ExternalLink href={config.privacyPolicy}>
                          text
                        </ExternalLink>
                      ),
                    }}
                  />
                )}
                .
              </div>
            </CustomCheckbox>
          )}
        </div>
      </form>
    );
  },
);

CheckoutForm.displayName = 'CheckoutForm';
