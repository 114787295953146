import React, { type PropsWithChildren } from 'react';

import { DateTimeTracker } from './DateTimeTracker/DateTimeTracker';
import { ErrorModalTracker } from './ErrorModalTracker/ErrorModalTracker';
import { MenuTracker } from './MenuTracker/MenuTracker';
import { NotAcceptingOrdersModal } from './NotAcceptingOrdersModal/NotAcceptingOrdersModal';
import { TimezoneModal } from './TimezoneModal/TimezoneModal';

export const ModalTrackersAnchor: React.FC<PropsWithChildren> = ({
  children,
}) => (
  <ErrorModalTracker>
    <MenuTracker>
      <TimezoneModal>
        <NotAcceptingOrdersModal>
          <DateTimeTracker>{children}</DateTimeTracker>
        </NotAcceptingOrdersModal>
      </TimezoneModal>
    </MenuTracker>
  </ErrorModalTracker>
);
