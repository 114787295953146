import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import classnames from 'classnames';
import { FormikProps } from 'formik';

import { Paper } from '@components';

import styles from './AddonsGroup.module.scss';
import { AddonsGroupLabel } from './AddonsGroupLabel';
import { AddonsGroupRow } from './AddonsGroupRow';
import {
  DynamicValueArray,
  GenericRefProps,
  MenuModifier,
  MenuModifierGroup,
} from 'types/GenericTypes';

interface Props {
  formikProps: FormikProps<DynamicValueArray>;
  itemData: MenuModifierGroup;
}

export const AddonsGroup = forwardRef<GenericRefProps, Props>(
  ({ itemData, formikProps }, ref) => {
    const hasError = () => formikProps.errors?.modifiers?.[itemData.id];
    const labelRef = useRef<HTMLDivElement>();

    useImperativeHandle(ref, () => ({
      getId: () => itemData.id,
      scrollIntoView: () => labelRef.current.scrollIntoView(),
    }));

    return (
      <>
        <div className={styles.addonsGroupTitleWrap} ref={labelRef}>
          <AddonsGroupLabel group={itemData} />
        </div>
        <Paper
          testId="addons-group"
          className={classnames(styles.addonsGroup, hasError() && styles.error)}
        >
          <div className={styles.addonsGroupCheckboxWrap}>
            {itemData.modifiers.map((group: MenuModifier) => (
              <AddonsGroupRow
                key={group.pos_unique_id}
                isRadioButton={
                  itemData.maxSelect === 1 && itemData.minSelect === 1
                }
                groupId={itemData.id}
                formikProps={formikProps}
                group={group}
                maxSelect={itemData.maxSelect}
                labelRef={labelRef}
              />
            ))}
          </div>
        </Paper>
      </>
    );
  },
);

AddonsGroup.displayName = 'AddonsGroup';
