import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  capitalizeFirstLetter,
  GaService,
  HistoryContext,
  MyCheckApp,
  useAuthStore,
  useCanContinueToCheckout,
  useCheckoutStore,
  useDateTimeTrackerHandler,
  useLanguageStore,
  useLocationStore,
  useMenuTrackerHandler,
  useModalNavigator,
  useNavigation,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Loader } from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { currencyFormatter } from '../../../../core/core/helpers/currencyFormatter';
import { PaymentsMethod } from '../../../CheckoutPlugin/components/PaymentsMethod/PaymentsMethod';
import { OrderPageItem } from '../../components/OrderPageItem/OrderPageItem';

import styles from './OrderPage.module.scss';
import MobileFixedHeaderWrapper from 'components/MobileFixedHeaderWrapper/MobileFixedHeaderWrapper';

export const OrderPage: React.FC = observer(() => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { openRegisterPage } = useModalNavigator();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const menuTracker = useMenuTrackerHandler();
  const { confirmSlot } = useDateTimeTrackerHandler();
  const canContinueToCheckout = useCanContinueToCheckout();
  const historyContext = useContext(HistoryContext);
  const currentCurrency = LocationStore.currenciesList.find(
    (item) => item.code === LocationStore.selectedRestaurant.currency,
  );

  const [extraPadding, setExtraPadding] = useState(null);

  const disclaimerRef = useRef<HTMLDivElement>();
  const location = useLocation();
  const { isLg } = useWindowSize();
  const hasCartItems = Object.keys(CheckoutStore.currentOrder).length > 0;

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembersValue = MyCheckApp.instance.getSupportMembersValue();

    const paletteBackgroundStyle = get(
      _config,
      'palette.primaryBackground',
      '',
    );
    const primary = get(_config, 'palette.primary', {});

    return {
      supportMembersValue,
      paletteBackgroundStyle,
      primary,
    };
  }, [location.search]);

  const [paymentsMethodOpen, setPaymentsMethodOpen] = useState(false);

  if (!isLg && !Object.keys(CheckoutStore.currentOrder).length) {
    navigation.replace('/menu');
  }

  const handleBackClick = () => navigation.replace('/menu');
  const handleCheckoutClick = () => {
    if (config.supportMembersValue && !AuthStore.isLoggedIn) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      historyContext.setCallback(onSubmit);
      return openRegisterPage();
    }

    if (!config.supportMembersValue && AuthStore.isGuest) {
      AuthStore.logout();
    }

    CheckoutStore.setPaymentMethod(
      LocationStore.chargeOptions.length === 1
        ? LocationStore.chargeOptions[0].value
        : LocationStore.selectedExperience.settings.payments?.default,
    );

    if (isLg || LocationStore.chargeOptions.length === 1) {
      navigation.push('/menu/checkout');
    }

    if (!isLg && LocationStore.chargeOptions.length > 1) {
      setPaymentsMethodOpen(true);
    }
  };

  const onSubmit = async () => {
    await menuTracker.checkIsSameMenu({ callback: handleCheckoutClick });
    await confirmSlot({
      isUpdateModalAvailable: true,
      isFutureModalAvailable: true,
      isClosedModalAvailable: false,
    });

    GaService.instance.sendEvent({
      category: 'Ordering - My Order',
      action: 'Proceed to checkout',
      label: '',
    });
  };

  const handleExtraPadding = () => {
    const headerSize = 60;
    const buttonSize = 48;
    let extraPaddingVal = headerSize + buttonSize;

    if (disclaimerRef.current) {
      extraPaddingVal +=
        disclaimerRef?.current.getBoundingClientRect().height + 28;
    }

    setExtraPadding(extraPaddingVal);
  };
  const finalPrice = () =>
    Object.values(CheckoutStore.currentOrder)
      .map((order) => {
        const reducedModifiers = Object.values(order.modifiers).reduce(
          (a, b) => [...(a as []), ...(b as [])],
          [],
        ) as unknown as string[];
        return {
          ...order,
          selectedModifiersPrice: order.modifierGroups
            .map((mg) =>
              mg.modifiers.filter((mod) =>
                reducedModifiers.includes(mod.pos_unique_id),
              ),
            )
            .reduce((a, b) => [...a, ...b], [])
            .reduce((a, b) => a + b.price, 0),
        };
      })
      .reduce(
        (a, b) => a + (b.price + b.selectedModifiersPrice) * b.quantity,
        0,
      );

  useEffect(() => {
    handleExtraPadding();
  }, [disclaimerRef]);

  useEffect(() => {
    if (
      !LocationStore.selectedExperience &&
      LocationStore.selectedExperienceId
    ) {
      LocationStore.fetchExperience(LocationStore.selectedExperienceId);
    }
    if (!isLg) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div
      className={styles.root}
      style={{ background: config.paletteBackgroundStyle }}
    >
      {!LocationStore.selectedExperience && <Loader />}
      {!isLg && (
        <MobileFixedHeaderWrapper
          headerTitle={t('menu.menuTitle')}
          handleBackClick={handleBackClick}
        />
      )}

      {isLg && (
        <div
          className={classnames(
            styles.orderPageHeader,
            !hasCartItems ? styles.orderPageHeaderNoItems : '',
          )}
        >
          {t('menu.menuTitle')}
        </div>
      )}

      <div
        className={styles.orderPage}
        style={{
          paddingBottom: LocationStore.isSelectedExperienceActive
            ? extraPadding
            : 0,
        }}
      >
        {!hasCartItems && (
          <div className={styles.orderPageNoItems}> {t('menu.noItems')}</div>
        )}
        {hasCartItems && (
          <>
            {LocationStore.selectedExperience?.settings.ui.my_order
              ?.disclaimer && (
              <div
                className={styles.orderPageDisclaimer}
                style={{
                  ...(!LocationStore.isSelectedExperienceActive && {
                    bottom: 0,
                  }),
                }}
                ref={disclaimerRef}
              >
                {LanguageStore.getExperienceTranslation(
                  LocationStore.selectedExperience.id,
                  'settings.ui.my_order.disclaimer',
                  LocationStore.selectedExperience.settings.ui.my_order
                    .disclaimer,
                )}
              </div>
            )}

            <div
              style={
                LocationStore.selectedExperience?.settings.ui.my_order
                  ?.disclaimer
                  ? { borderTop: '1px dashed #E3E3E3' }
                  : {}
              }
            >
              {Object.keys(CheckoutStore.currentOrder)
                .map((key) => CheckoutStore.currentOrder[key])
                .map((item) => (
                  <OrderPageItem key={item.editId} item={item} />
                ))}
            </div>

            {canContinueToCheckout &&
              LocationStore.isSelectedExperienceActive && (
                <CustomButton
                  text={`${capitalizeFirstLetter(
                    t('menu.proceedToCheckoutLabel').toLowerCase(),
                  )} ${currencyFormatter(finalPrice(), currentCurrency)}`}
                  handleButtonClick={onSubmit}
                  className={styles.orderPageCustomButton}
                  testId="proceed-checkout"
                />
              )}

            {paymentsMethodOpen && (
              <PaymentsMethod
                handleOpen={() => {
                  setPaymentsMethodOpen(!paymentsMethodOpen);
                }}
                onChoose={() => {
                  navigation.push('/menu/checkout');
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
});

OrderPage.displayName = 'OrderPage';
