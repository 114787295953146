import React, { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import {
  capitalizeFirstLetter,
  GaService,
  MyCheckApp,
  PaymentConstants,
  startOrderListening,
  toUTCDateFormat,
  useAuthStore,
  useCheckoutStore,
  useDateTimeTrackerHandler,
  useErrorTrackerHandler,
  useLocationStore,
  useNavigation,
  useTranslation,
  useWalletService,
  useWindowSize,
} from 'mycheck-core';

import { CustomButton, Loader } from '@components';

import styles from './Wallet.module.scss';
import { IUIFieldSettings } from 'plugins/LocationPlugin/types/LocationTypes';
import {
  CardSummary,
  TransactionVerification,
} from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { currencyFormatter } from 'core/core/helpers/currencyFormatter';
import { observer } from 'mobx-react';

interface Props {
  initCheckout?: () => void;
  summary: Partial<CardSummary>;
}
export const Wallet: React.FC<Props> = observer(({ initCheckout, summary }) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const location = useLocation();
  const navigation = useNavigation();
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const walletService = useWalletService();
  const { confirmSlot } = useDateTimeTrackerHandler();
  const errorHandler = useErrorTrackerHandler();
  const [isManageOpen, setIsManageOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isSubmitOrderButtonEnabled, setIsSubmitOrderButtonEnabled] =
    useState(false);
  const [isWalletMounted, setIsWalletMounted] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const currentCurrency = LocationStore.currenciesList.find(
    (item) => item.code === LocationStore.selectedRestaurant.currency,
  );
  const hasSavedCards = !!AuthStore.paymentMethods.length;
  const supportMembers = MyCheckApp.instance.getSupportMembersValue();
  const isMemberFlowEnabled = supportMembers && AuthStore.isLoggedIn;
  const isMemberFlowOrPaymentFailed = isMemberFlowEnabled || isPaymentFailed;

  const config = useMemo(() => {
    const walletVersion =
      MyCheckApp.instance.getGlobalConfig().settings.walletDetails?.version;

    return {
      walletVersion,
    };
  }, [location.search]);

  const onReady = () => {
    walletService.setAcceptedCards();
    setIsWalletMounted(true);
  };

  const goToConfirm = () => {
    if (!isLg) {
      document.getElementById('checkoutPageId').style.height =
        'calc(100vh - 100px)';
    }

    navigation.push('/menu/confirm');
  };

  const onTransactionVerification =
    (event: 'ALTERNATIVE' | '3DS') =>
    async (object: TransactionVerification) => {
      setSubmitting(true);
      setIsPaymentFailed(false);

      if (object.status === 'FAILURE') {
        if (event === '3DS') {
          errorHandler.onError({
            message: 'after3DsComplete',
            name: '3DS_failure',
          });
        }
        if (event === 'ALTERNATIVE') {
          errorHandler.onError({
            message: 'afterAlternativePaymentComplete',
            name: 'afterAlternativePayment_failure',
          });
        }

        setSubmitting(false);
        setIsPaymentFailed(true);
      } else {
        try {
          //  await CheckoutStore.confirm3DSecure(object.transaction_id);

          goToConfirm();
        } catch (err) {
          errorHandler.onError(err);
        } finally {
          setSubmitting(false);
          setIsPaymentFailed(true);
        }
      }
    };

  const getIsFieldVisible = (fieldSettings: IUIFieldSettings): boolean => {
    if (!fieldSettings) {
      return false;
    }

    return fieldSettings.show_with_payment_type &&
      fieldSettings.show_with_payment_type.length
      ? fieldSettings.show_with_payment_type.some(
          (paymentType) => paymentType === CheckoutStore.paymentMethod,
        )
      : true;
  };

  const submitOrder = async () => {
    try {
      setIsPaymentFailed(false);
      setSubmitting(true);

      const { is3DSecure } = await CheckoutStore.cartCheckout({
        accessToken: AuthStore.accessToken,
        businessId: LocationStore.selectedRestaurantId,
        checkoutAt: toUTCDateFormat(CheckoutStore.checkoutTime),
        experienceId: LocationStore.selectedExperience.id,
        isTipAvailable: LocationStore.isTipAvailable(
          PaymentConstants.PaymentMethodsValue.WALLET,
        ),
        isCoupon:
          CheckoutStore.coupon &&
          getIsFieldVisible(LocationStore.uiCheckoutSettings['coupons']),
      });
      if (is3DSecure) {
        try {
          await startOrderListening(+CheckoutStore.orderNumber);
        } catch (err) {
          CheckoutStore.uuid = '';
          setSubmitting(false);
          setIsPaymentFailed(true);
          try {
            await initCheckout();
          } finally {
            throw err;
          }
        }
      }

      goToConfirm();
    } catch (err) {
      if (err instanceof Error) {
        if (err.message !== 'CHALLENGE_DECLINED_BY_USER') {
          errorHandler.onError(err);
        }
        GaService.instance.sendEvent({
          category: 'Ordering - Checkout',
          action: 'Error',
          label: err.message,
        });
      }
    } finally {
      setSubmitting(false);
      setIsPaymentFailed(true);
    }
  };

  const paymentCompleted = async (paymentDetails: { status: string }) => {
    if (paymentDetails.status === 'FAILURE') {
      setIsPaymentFailed(true);
      setSubmitting(false);
    }
  };

  const checkoutReady = async (status) => {
    await AuthStore.fetchPaymentMethods();
    setIsPaymentFailed(false);

    if (status) {
      try {
        walletService.checkIsCheckoutReady();
        const responsePayment = await walletService.getCard();
        CheckoutStore.setPaymentMethodId(responsePayment.id);
        CheckoutStore.setPaymentMethodCreditTypeFull(
          responsePayment.creditTypeFull,
        );

        if (responsePayment.fingerprint && responsePayment.encrypted_cvv) {
          CheckoutStore.setFingerprint(responsePayment.fingerprint);
          CheckoutStore.setEncryptedCvv(responsePayment.encrypted_cvv);
        }

        const { isAvailable } = await confirmSlot({
          isUpdateModalAvailable: true,
          isFutureModalAvailable: true,
          isClosedModalAvailable: true,
        });

        if (isAvailable) {
          if (!isMemberFlowEnabled) {
            await submitOrder();
          }
          setIsSubmitOrderButtonEnabled(true);
        } else {
          setIsSubmitOrderButtonEnabled(false);
        }

        //  await menuTracker.checkIsSameMenu({callback: () => submitOrder()});
      } catch (err) {
        document.getElementById('mycheck-wallet-v2').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        setIsSubmitOrderButtonEnabled(false);
        throw err;
      }
    } else if (!CheckoutStore.paymentId) {
      setIsPaymentFailed(true);
      setSubmitting(false);
    }
  };

  const paymentMethodRemoved = async () => {
    await AuthStore.fetchPaymentMethods();
  };

  const encryptedCvvReceived = (cvv: string) => {
    if (!cvv) {
      setIsSubmitOrderButtonEnabled(false);
    }
  };

  useEffect(() => {
    (async () => {
      await walletService.destroy();
      await walletService.init({
        onReady,
        onManageOpen: () => setIsManageOpen(true),
        onManageClose: () => setIsManageOpen(false),
        onTransactionVerification,
        checkoutReady,
        paymentCompleted,
        paymentMethodRemoved,
        encryptedCvvReceived,
        confirmButtonText: t('checkout.submitButton').toLowerCase(),
      });
    })();
  }, []);

  const handleSubmitButtonClick = async () => {
    await submitOrder();
  };

  return (
    <div>
      {submitting && (
        <Loader
          label={t('checkout.submittingOrder')}
          labelStyle={{
            color: '#ffffff',
            fontSize: 16,
          }}
        />
      )}
      {config.walletVersion === 'v2' && (
        <div
          data-test-id="wallet-v2-checkout"
          id="mycheck-wallet-v2"
          className={styles.wallet}
          style={isManageOpen ? { zIndex: 4 } : {}}
        />
      )}

      {config.walletVersion === 'v3' && (
        <>
          <div
            data-test-id="mycheck-wallet-v3"
            id="mycheck-wallet-v3"
            className={styles.wallet}
          />
        </>
      )}
      {isWalletMounted && hasSavedCards && isMemberFlowOrPaymentFailed && (
        <CustomButton
          text={`${capitalizeFirstLetter(t('checkout.pay'))} ${currencyFormatter(
            summary.total &&
              parseFloat(summary.total.toString()) + CheckoutStore.tipPrice,
            currentCurrency,
          )}`}
          disabled={!isSubmitOrderButtonEnabled || submitting}
          className={styles.submitOrderButton}
          handleButtonClick={handleSubmitButtonClick}
        />
      )}
    </div>
  );
});
