import { useLanguageStore, useMenuStore } from 'mycheck-core';
import {
  MenuCategoryType,
  MenuItem as MenuItemGenericType,
  MenuModifier,
  MenuModifierGroup,
  MenuSubcategory,
} from 'types/GenericTypes';

export function useGetTranslationForMenuItem() {
  const LanguageStore = useLanguageStore();
  const MenuStore = useMenuStore();

  const getCurrentTranslation = (
    key: string,
    defaultValue:
      | MenuItemGenericType
      | MenuModifier
      | MenuCategoryType
      | MenuSubcategory
      | MenuModifierGroup,
  ) => {
    return {
      ...defaultValue,
      ...(MenuStore.translations[LanguageStore.selectedValue]?.[key] || {}),
    };
  };

  return getCurrentTranslation;
}
