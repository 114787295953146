import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';

import dayjs from 'dayjs';
import { observer } from 'mobx-react';

import {
  useErrorTrackerHandler,
  useLocationStore,
  useCheckoutStore,
  useLocation,
  useInterval,
  isPast,
  isSameDate,
  useInactiveUserTracker,
  FLOW_TYPES,
  GaService,
  useNearestTime,
  hoursDifference,
  useIsLocationOpen,
  getMillisecondsToNextMinute,
  useNotAcceptingOrdersHandler,
  getInitialTime,
} from 'mycheck-core';

import { DateTimeTrackerClosedUpdateModal } from './DateTimeTrackerClosedUpdateModal';
import { DateTimeTrackerFutureUpdateModal } from './DateTimeTrackerFutureUpdateModal';
import { DateTimeTrackerGraceModal } from './DateTimeTrackerGraceModal';
import { DateTimeTrackerUpdateModal } from './DateTimeTrackerUpdateModal';

type ConfirmSlotProps = {
  isClosedModalAvailable: boolean;
  isUpdateModalAvailable: boolean;
  isFutureModalAvailable: boolean;
};

const dateTimeTrackerContext = {
  confirmSlot: async (_props: ConfirmSlotProps) => ({
    isAvailable: true,
    didTimeChange: false,
  }),
  preventUpdate: () => () => null,
};

const DateTimeTrackerContext = createContext(dateTimeTrackerContext);

export function useDateTimeTrackerHandler(preventUpdate = false) {
  const context = useContext(DateTimeTrackerContext);

  useEffect(() => {
    if (preventUpdate) {
      return context.preventUpdate();
    }
  }, []);

  return {
    confirmSlot: context.confirmSlot,
  };
}

export const DateTimeTracker: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const LocationStore = useLocationStore();
    const CheckoutStore = useCheckoutStore();
    const errorHandler = useErrorTrackerHandler();
    const location = useLocation();
    const NotAcceptingOrdersHandler = useNotAcceptingOrdersHandler();
    const { isClosingIn } = useIsLocationOpen();
    const { nearestTime } = useNearestTime();

    const isGraceTime = useRef<boolean>(false);
    const wasGraceShown = useRef<boolean>(false);
    const timeForGrace = useRef<NodeJS.Timeout>(null);
    const [intervalTime, setIntervalTime] = useState(
      getMillisecondsToNextMinute(),
    );
    const [isOpen, setIsOpen] = useState(false);
    const [isFuture, setIsFuture] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [isGraceOpen, setIsGraceOpen] = useState(false);

    const handleModalClose = useCallback(() => {
      setIsFuture(false);
      setIsClosed(false);
      setIsOpen(false);
      setIsGraceOpen(false);
    }, []);

    const setNewCheckoutTime = async () => {
      const response = await LocationStore.confirmExperienceSlot(
        LocationStore.selectedDate,
      );
      CheckoutStore.setSelectedCheckoutTime(
        CheckoutStore.isGrace
          ? response.next_available_with_grace
          : response.next_available,
      );

      if (
        CheckoutStore.isGrace &&
        response.next_available &&
        response.next_available_with_grace &&
        !isSameDate(response.next_available, response.next_available_with_grace)
      ) {
        setIsOpen(true);
        setIsGraceOpen(true);
        isGraceTime.current = true;
        wasGraceShown.current = true;
      }
    };

    const handleClosedTime = (preventNullTime: boolean) => {
      if (preventNullTime) {
        setIsClosed(true);
        setIsOpen(true);
      }

      return { isAvailable: false, didTimeChange: true };
    };

    const handleUpdateTime = (
      nextAvailable,
      isUpdateModalAvailable: boolean,
    ) => {
      GaService.instance.sendEvent({
        category: 'Ordering - Locations',
        action: 'Pickup time updated to',
        label: dayjs(nextAvailable).format('hh:mm A'),
      });

      if (LocationStore.isAsap) {
        return;
      }

      if (isUpdateModalAvailable) {
        setIsOpen(true);
      }
    };

    const handleGraceTime = (nextAvailableTime, previousCheckoutTime) => {
      setIsOpen(true);
      setIsGraceOpen(true);
      wasGraceShown.current = true;

      return {
        isAvailable: true,
        didTimeChange: !isSameDate(nextAvailableTime, previousCheckoutTime),
      };
    };

    const handleFutureTime = (nextAvailableTime, previousCheckoutTime) => {
      if (hoursDifference(nextAvailableTime, previousCheckoutTime) >= 1) {
        if (LocationStore.isAsap && !CheckoutStore.isOnGraceTime) {
          NotAcceptingOrdersHandler.open();
          return { isAvailable: false, didTimeChange: true };
        } else {
          setIsFuture(true);
        }
      }

      return {
        isAvailable: true,
        didTimeChange: !isSameDate(nextAvailableTime, previousCheckoutTime),
      };
    };

    const confirmSlot = async (props: ConfirmSlotProps) => {
      try {
        //View menu
        if (!LocationStore.selectedExperience.settings.checkout.is_active) {
          return { isAvailable: true, didTimeChange: false };
        }

        if (CheckoutStore.checkoutTime === null) {
          return handleClosedTime(props.isClosedModalAvailable);
        }

        const response = await LocationStore.confirmExperienceSlot(
          CheckoutStore.checkoutTime,
        );
        const previousCheckoutTime = CheckoutStore.checkoutTime;

        let nextAvailableTime = response.next_available;

        if (
          CheckoutStore.isGrace &&
          !isSameDate(previousCheckoutTime, nextAvailableTime) &&
          !isSameDate(nextAvailableTime, response.next_available_with_grace)
        ) {
          nextAvailableTime = response.next_available_with_grace;
          isGraceTime.current = true;
          CheckoutStore.setIsOnGraceTime(true);
        } else {
          CheckoutStore.setIsOnGraceTime(false);
        }

        if (!isSameDate(nextAvailableTime, previousCheckoutTime)) {
          CheckoutStore.setSelectedCheckoutTime(nextAvailableTime);
        }

        if (nextAvailableTime === null) {
          return handleClosedTime(props.isClosedModalAvailable);
        }

        if (!isSameDate(previousCheckoutTime, nextAvailableTime)) {
          handleUpdateTime(nextAvailableTime, props.isUpdateModalAvailable);
        }

        if (isGraceTime.current && !wasGraceShown.current) {
          return handleGraceTime(nextAvailableTime, previousCheckoutTime);
        }

        if (props.isFutureModalAvailable) {
          return handleFutureTime(nextAvailableTime, previousCheckoutTime);
        }

        return {
          isAvailable: true,
          didTimeChange: !isPast(LocationStore.selectedDate, nearestTime),
        };
      } catch (err) {
        errorHandler.onError(err);
        return { isAvailable: undefined, didTimeChange: undefined };
      }
    };

    const setDateAndTime = () => {
      if (nearestTime) {
        setIntervalTime(getMillisecondsToNextMinute());
        const initialTime = getInitialTime();
        LocationStore.setSelectedDate(initialTime);
        LocationStore.setSelectedTime(initialTime);
        LocationStore.setSelectedDateTemp(initialTime);
        LocationStore.setSelectedTimeTemp(initialTime);
      }
    };

    const preventUpdate = () => {
      setIntervalTime(999999);
      return () => setIntervalTime(getMillisecondsToNextMinute());
    };

    const updateLocalTime = () => {
      if (
        !CheckoutStore.orderNumber &&
        LocationStore.timeSettings &&
        (!LocationStore.selectedDate || isPast(LocationStore.selectedDate))
      ) {
        setDateAndTime();
      }
    };

    const onUserInactive = () => {
      LocationStore.resetSelectedDate();
      setDateAndTime();
      if (
        LocationStore.flowType === FLOW_TYPES.HOTEL &&
        LocationStore.selectedHotel
      ) {
        LocationStore.fetchRestaurants(Number(LocationStore.selectedHotel));
      }
    };

    const renderBody = () => {
      if (isOpen) {
        if (isGraceOpen) {
          return (
            <DateTimeTrackerGraceModal handleModalClose={handleModalClose} />
          );
        }

        if (isFuture) {
          return (
            <DateTimeTrackerFutureUpdateModal
              handleModalClose={handleModalClose}
            />
          );
        }

        if (isClosed) {
          return (
            <DateTimeTrackerClosedUpdateModal
              handleModalClose={handleModalClose}
            />
          );
        }

        return (
          <DateTimeTrackerUpdateModal handleModalClose={handleModalClose} />
        );
      }

      return null;
    };

    const showGraceModal = () =>
      confirmSlot({
        isUpdateModalAvailable: true,
        isFutureModalAvailable: true,
        isClosedModalAvailable: false,
      });

    useEffect(() => {
      if (location.pathname === '/' || location.pathname === '/location') {
        clearTimeout(timeForGrace.current);
      }

      if (isOpen) {
        setIsFuture(false);
        setIsClosed(false);
        setIsOpen(false);
        setIsGraceOpen(false);
      }
    }, [location.pathname]);

    useEffect(() => {
      if (nearestTime) {
        updateLocalTime();
      }
    }, [
      LocationStore.selectedHotel,
      LocationStore.locationGroupId,
      nearestTime,
    ]);

    useEffect(() => {
      wasGraceShown.current = false;
      isGraceTime.current = false;
    }, [LocationStore.selectedExperienceId]);

    useEffect(() => {
      if (LocationStore.selectedExperience) {
        clearTimeout(timeForGrace.current);
        const timeout = isClosingIn(LocationStore.selectedExperience);
        if (timeout > 0) {
          timeForGrace.current = setTimeout(showGraceModal, timeout);
        }
      }
    }, [LocationStore.selectedExperience]);

    useEffect(() => {
      if (
        LocationStore.selectedExperience &&
        !CheckoutStore.checkoutTime &&
        location.pathname.includes('/menu')
      ) {
        setNewCheckoutTime();
      }
    }, [LocationStore.selectedExperience, location.pathname]);

    useInterval(updateLocalTime, intervalTime);
    useInactiveUserTracker(onUserInactive);

    return (
      <DateTimeTrackerContext.Provider value={{ confirmSlot, preventUpdate }}>
        {children}
        {renderBody()}
      </DateTimeTrackerContext.Provider>
    );
  },
);

DateTimeTracker.displayName = 'DateTimeTracker';
