import React, {
  useMemo,
  useCallback,
  type CSSProperties,
  ReactElement,
} from 'react';

import { RadioButton } from '@components';

type Item = {
  value: string;
  label: string;
  content?: ReactElement;
};

interface Props {
  data: Array<Item>;
  containerClassName?: string;
  formikProps?: any;
  value?: string;
  property: string;
  testId?: string;
  radioButtonStyle?: CSSProperties;
  itemKeyForCheck?: keyof Item;
}

export const RadioButtonGroup: React.FC<Props> = ({
  data,
  containerClassName,
  formikProps,
  value,
  property,
  testId,
  radioButtonStyle,
  itemKeyForCheck = 'label',
}) => {
  const checkedHandler = useCallback(
    (id: string) => formikProps.setFieldValue(property, id),
    [formikProps, property],
  );
  const radioButtons = useMemo(
    () =>
      data.map((item) => (
        <RadioButton
          key={item.label}
          isChecked={item[itemKeyForCheck] === value}
          onClick={checkedHandler}
          content={item.content}
          text={item.label}
          id={item[itemKeyForCheck] as Item['value']}
          style={radioButtonStyle}
        />
      )),
    [data, value],
  );

  return (
    <div className={containerClassName} data-test-id={testId}>
      {radioButtons}
    </div>
  );
};
