import React, { useMemo, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useAuthStore,
  useLanguageLoader,
  useLocationStore,
  useModalNavigator,
} from 'mycheck-core';

import { HeaderProfile, MenuSideBar } from '@components';

import { LangPicker } from '../../../LanguagePlugin/components/LanguagePicker';

import styles from './OptionsHeader.module.scss';

export const OptionsHeader: React.FC = observer(() => {
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const { openRegisterPage } = useModalNavigator();

  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => setIsMenuOpen(false);
  const handleMenuOpen = () => {
    if (AuthStore.isGuest) {
      openRegisterPage();
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const logo = get(_config, 'home.style.logo', null);
    const primary = get(_config, 'palette.primary', {});

    return {
      logo,
      primary,
      supportMembers,
    };
  }, [location.search]);

  const { availableLanguages } = useLanguageLoader();

  return (
    <>
      <div
        className={classnames(
          styles.optionsHeader,
          LocationStore.isNative ? styles.optionsHeaderIsNative : {},
        )}
      >
        <div className={styles.optionsHeaderLogoWrapper}>
          <img className={styles.optionsHeaderLogo} src={config.logo} alt="" />
        </div>

        {availableLanguages.length > 1 && (
          <div className={styles.optionsHeaderLanguagePicker}>
            <LangPicker
              primaryColor
              pickerRight={0}
              overlayStyle={{
                left: 'auto',
                width: window.innerWidth,
                height: window.innerHeight,
              }}
            />
          </div>
        )}
        {config.supportMembers && (
          <div className={styles.optionsHeaderHeaderProfile}>
            <HeaderProfile
              iconColor={config.primary}
              onPress={handleMenuOpen}
            />
          </div>
        )}
      </div>
      {config.supportMembers && (
        <MenuSideBar
          isMenuOpen={isMenuOpen}
          handleMenuOpen={handleMenuOpen}
          closeMenu={closeMenu}
          fullScreen
        />
      )}
    </>
  );
});

OptionsHeader.displayName = 'OptionsHeader';
