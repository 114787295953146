type Params = {
  hotelId: number;
  restaurantId?: number;
  experienceId: number;
};

export class CartStoreService {
  private static hotelId = null;
  private static restaurantId = null;
  private static experienceId = null;

  private static getKey = () => {
    const KEY = `${CartStoreService.restaurantId}_${CartStoreService.experienceId}`;
    if (CartStoreService.hotelId) {
      return `${CartStoreService.hotelId}_${KEY}`;
    }

    return KEY;
  };

  public static setCurrentStore = ({
    hotelId,
    restaurantId,
    experienceId,
  }: Params) => {
    CartStoreService.hotelId = hotelId;
    CartStoreService.restaurantId = restaurantId;
    CartStoreService.experienceId = experienceId;

    return CartStoreService.restoreFromStore();
  };

  public static saveToStore = (value: Record<string, unknown>) => {
    localStorage.setItem(
      CartStoreService.getKey(),
      JSON.stringify({ timestamp: Date.now(), value }),
    );
  };

  public static clearStore = () => {
    localStorage.removeItem(CartStoreService.getKey());
  };

  public static restoreFromStore = () => {
    try {
      const store = JSON.parse(localStorage.getItem(CartStoreService.getKey()));
      const timestamp = store.timestamp;
      if (timestamp) {
        const hour = Math.abs(Date.now() - timestamp) / 36e5;
        if (hour >= 1) {
          localStorage.removeItem(CartStoreService.getKey());

          return {};
        }
      }

      return store?.value || {};
    } catch (err) {
      return {};
    }
  };
}
