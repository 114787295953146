import { useLocation } from 'react-router-dom';

import { useCheckoutStore, useNavigation, useWindowSize } from 'mycheck-core';

export function useGoBackToMenu(goBack = false) {
  const CheckoutStore = useCheckoutStore();
  const { isLg } = useWindowSize();
  const location = useLocation();
  const navigation = useNavigation();
  const goBackToMenu = () => {
    if (!CheckoutStore.numberOfCurrentOrderItems || goBack) {
      const [partOne, partTwo] = location.pathname.split('/').filter(Boolean);
      if (partTwo === 'checkout') {
        navigation.goBack();

        if (!isLg) {
          setTimeout(() => navigation.goBack(), 50);
        }
      }

      if (partOne === 'order') {
        navigation.goBack();
      }
    }
  };

  return { goBackToMenu };
}
