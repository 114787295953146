import React, { useMemo } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

import {
  displayDateTime,
  getDateDays,
  isBetween,
  isPast,
  isWithinSixtyMinutes,
  MyCheckApp,
  useLocationStore,
  useNearestTime,
  useTranslation,
} from 'mycheck-core';

import { Text } from '@components';

import { success } from '../../../../core/styles/variables.module.scss';

import styles from './LocationContentExperienceTime.module.scss';
import { ILocationItem } from 'plugins/LocationPlugin/types/LocationTypes';
const BASIC_PATH = 'restaurants.restaurantItem';

type Props = {
  restaurant: ILocationItem;
};

export const LocationContentExperienceTime: React.FC<Props> = observer(
  ({ restaurant }) => {
    const LocationStore = useLocationStore();
    const { t } = useTranslation();
    const { nearestTime } = useNearestTime();
    const location = useLocation();

    const selectedExperience = restaurant.experiences.find(
      (experience) => experience.type === LocationStore.selectedExperienceType,
    );

    const nextAvailable = selectedExperience.settings.slots.next_available;
    const nextAvailableDiffFromNow = LocationStore.locationTimezoneName
      ? moment(nextAvailable)
          .tz(LocationStore.locationTimezoneName)
          .diff(moment().tz(LocationStore.locationTimezoneName), 'minutes')
      : 1;
    const nextAvailableDiffFromSelectedDate = LocationStore.locationTimezoneName
      ? moment(nextAvailable)
          .tz(LocationStore.locationTimezoneName)
          .diff(
            moment(LocationStore.selectedDate).tz(
              LocationStore.locationTimezoneName,
            ),
            'minutes',
          )
      : 1;
    const hasAround =
      nextAvailableDiffFromSelectedDate - nextAvailableDiffFromNow <= 1 &&
      nextAvailableDiffFromSelectedDate - nextAvailableDiffFromNow >= 0;
    const minimumPickupTime =
      nextAvailableDiffFromNow === 0 ? 1 : nextAvailableDiffFromNow;
    const minimumPickupTimeUnderHour = minimumPickupTime <= 60;
    const openHours = selectedExperience.settings.open_hours;
    const noNextAvailable = nextAvailable === null;
    const isAsapOnly = selectedExperience.settings.is_asap_only;
    const isViewMenuOnly =
      !selectedExperience.settings.checkout?.is_active ?? true;
    const displayOrderTime = selectedExperience.settings.display_order_time;
    const isWithinOneHourSelectedDate = isWithinSixtyMinutes(
      nextAvailable,
      LocationStore.selectedDate,
    );
    const nearestIsWithinSixtyMinutes = isWithinSixtyMinutes(
      LocationStore.selectedDate,
      nextAvailable,
    );
    const labelBackGroundColor = {
      green: success,
      grey: '#777777',
    };

    const openDays =
      typeof LocationStore.locationTimezoneName !== 'undefined'
        ? getDateDays(
            Object.keys(openHours),
            LocationStore.locationTimezoneName,
          )
        : null;

    const isOpen = openHours[openDays?.today.format('ddd').toLowerCase()]?.some(
      (element) =>
        isBetween(element.from, element.to, LocationStore.locationTimezoneName),
    );

    const isSelectDateInOpenTime = () => {
      return LocationStore.locationTimezoneName
        ? openHours[
            moment(LocationStore.selectedDate)
              .tz(LocationStore.locationTimezoneName)
              .format('ddd')
              .toLowerCase()
          ]?.some((element) => {
            return isBetween(
              element.from,
              element.to,
              LocationStore.locationTimezoneName,
              LocationStore.selectedDate,
            );
          })
        : true;
    };

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const availabilityLabelStyle = () => {
        const { color, ...text } = get(
          _config,
          `${BASIC_PATH}.availability.textValue`,
          { color: {}, text: {} },
        );
        return { color, text };
      };

      return {
        availabilityLabelStyle,
      };
    }, [location.search]);

    const renderValue = () => {
      if (
        isAsapOnly ||
        noNextAvailable ||
        isViewMenuOnly ||
        (isOpen &&
          nearestIsWithinSixtyMinutes &&
          minimumPickupTimeUnderHour &&
          hasAround)
      ) {
        return null;
      }

      const timezone = LocationStore.locationTimezoneName;
      const value = displayDateTime(
        nextAvailable,
        timezone,
        LocationStore.localHotelDateFormat,
        LocationStore.localHotelTimeFormat,
      );
      if (!value) return null;

      const splitValue = value.split(',');
      return {
        day: splitValue[0].trim(),
        time: splitValue[1].trim(),
      };
    };

    const labelRenderComponent = (
      translation: string,
      background: string,
      color: string,
      translationVariable: any = {},
    ) => (
      <span
        style={{ ...config.availabilityLabelStyle().text, background, color }}
      >
        {t(translation, translationVariable)}
      </span>
    );

    const renderLabel = () => {
      if (isViewMenuOnly) {
        return labelRenderComponent(
          'location.viewMenu',
          labelBackGroundColor.grey,
          '#ffffff',
        );
      }

      if (!openHours || (Array.isArray(openHours) && !openHours.length)) {
        return labelRenderComponent(
          'location.notAvailable',
          labelBackGroundColor.grey,
          '#ffffff',
        );
      }

      if (isAsapOnly) {
        if (
          LocationStore.selectedDate === nearestTime ||
          isPast(LocationStore.selectedDate)
        ) {
          if (isOpen && isWithinOneHourSelectedDate) {
            if (displayOrderTime) {
              return labelRenderComponent(
                'location.around',
                labelBackGroundColor.green,
                '#ffffff',
                { prep: minimumPickupTime },
              );
            }
            return labelRenderComponent(
              'location.orderNow',
              labelBackGroundColor.green,
              '#ffffff',
            );
          }
        }

        return labelRenderComponent(
          'location.notAvailable',
          labelBackGroundColor.grey,
          '#ffffff',
        );
      }

      if (noNextAvailable) {
        return labelRenderComponent(
          'location.notAvailable',
          labelBackGroundColor.grey,
          '#ffffff',
        );
      }

      if (
        isOpen &&
        nearestIsWithinSixtyMinutes &&
        minimumPickupTimeUnderHour &&
        hasAround
      ) {
        return labelRenderComponent(
          'location.around',
          labelBackGroundColor.green,
          '#ffffff',
          { prep: minimumPickupTime },
        );
      }

      if (isSelectDateInOpenTime() || isWithinOneHourSelectedDate) {
        return labelRenderComponent(
          'location.nextAvailableLabel',
          minimumPickupTimeUnderHour || nextAvailableDiffFromSelectedDate <= 60
            ? labelBackGroundColor.green
            : labelBackGroundColor.grey,
          '#ffffff',
          renderValue(),
        );
      }

      return labelRenderComponent(
        'location.nextAvailableLabel',
        labelBackGroundColor.grey,
        '#ffffff',
        renderValue(),
      );
    };

    return (
      <div
        className={classnames(styles.time, restaurant.image && styles.image)}
      >
        {!restaurant.experiences.length ? (
          <Text value="location.closedLabel" />
        ) : (
          renderLabel()
        )}
      </div>
    );
  },
);

LocationContentExperienceTime.displayName = 'LocationContentExperienceTime';
