import { ApiClient, MyCheckApp } from 'mycheck-core';
import { LanguageApi } from './types/LanguageTypes';

const LanguageUrls = {
  getSpecificTranslationsUrl: (accountId: number, resourceName: string) =>
    `${process.env.REACT_APP_SPECIFIC_TRANSLATION_URI}/account/${accountId}/${resourceName}/${accountId}_${resourceName}.json`,
};

export class HttpLanguageApi implements LanguageApi {
  fetchSpecificTranslations = async (resourceName: string) => {
    try {
      const { data } = await ApiClient.instance.get<any>(
        LanguageUrls.getSpecificTranslationsUrl(
          MyCheckApp.instance.getBusinessID(),
          resourceName,
        ),
      );
      return data;
    } catch {
      return null;
    }
  };
}
