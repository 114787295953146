import React, { useState, type CSSProperties } from 'react';

import { observer } from 'mobx-react';

import { useLocationStore, useTranslation } from 'mycheck-core';

import { OptionSelect } from '@components';

import { ExperienceModal } from './ExperienceModal/ExperienceModal';

interface Props {
  fixedToTop: boolean;
}

export const ExperienceDropdown: React.FC<Props> = observer(
  ({ fixedToTop = false }) => {
    const LocationStore = useLocationStore();
    const { t } = useTranslation();
    const [isExperienceModalOpen, setIsExperienceModalOpen] = useState(false);

    const handleModalOpen = () =>
      setIsExperienceModalOpen(!isExperienceModalOpen);

    const scrolledStyles: CSSProperties = {
      borderRadius: 0,
      position: 'fixed',
      top: 0,
      width: '100%',
      marginLeft: -16,
      zIndex: 2,
    };

    return (
      <>
        <OptionSelect
          style={
            fixedToTop ? { ...scrolledStyles, height: 48 } : { height: 48 }
          }
          onClick={handleModalOpen}
        >
          {t(`experience.${LocationStore.selectedExperienceType}`)}
        </OptionSelect>
        {isExperienceModalOpen && (
          <ExperienceModal handleModalOpen={() => handleModalOpen()} />
        )}
      </>
    );
  },
);

ExperienceDropdown.displayName = 'ExperienceDropdown';
