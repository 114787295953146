import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';

import { useTranslation } from 'mycheck-core';

import styles from './MyOrderSummary.module.scss';
import MyOrderSummaryItem from './MyOrderSummaryItem';
import { HttpAuthApiResponse } from 'plugins/AuthPlugin/types/AuthTypes';

interface MyOrderSummaryProps extends PropsWithChildren {
  selectedOrder: HttpAuthApiResponse['order'];
}

const MyOrderSummary: React.FC<MyOrderSummaryProps> = ({
  children,
  selectedOrder,
}) => {
  const { t } = useTranslation();
  const {
    tip,
    discount,
    subtotal,
    currency,
    taxes,
    service_charges,
    total_paid,
    service_charge,
  } = selectedOrder;
  const displayTipInfo = tip && Number(tip) > 0;
  const displayDiscountInfo = discount && Number(discount) > 0;

  return (
    <div className={styles.myOrderSummary}>
      <div className={styles.myOrderSummaryWrapper}>
        <div className={styles.myOrderSummaryWrapperChargesInfo}>
          <MyOrderSummaryItem
            label={t('history.subtotal')}
            value={subtotal}
            currency={currency}
          />
          {taxes.map(({ id, name, amount, is_inclusive }) => (
            <MyOrderSummaryItem
              key={id}
              label={name}
              value={amount}
              currency={currency}
              className={classnames({
                [styles.myOrderSummaryInclusiveTaxes]: is_inclusive,
              })}
            />
          ))}
          {service_charges.length ? (
            service_charges.map(({ id, name, amount }) => (
              <MyOrderSummaryItem
                key={id}
                label={name}
                value={amount}
                currency={currency}
              />
            ))
          ) : (
            <MyOrderSummaryItem
              label={t('history.serviceCharge')}
              value={service_charge}
              currency={currency}
            />
          )}
          {displayTipInfo && (
            <MyOrderSummaryItem
              label={t('history.tip')}
              value={tip}
              currency={currency}
            />
          )}
          {displayDiscountInfo && (
            <MyOrderSummaryItem
              label={t('history.discount')}
              value={discount}
              currency={currency}
            />
          )}
        </div>
        <MyOrderSummaryItem
          label={t('history.iPaid')}
          value={total_paid}
          currency={currency}
          className={styles.myOrderSummaryTotal}
        />
      </div>
      {children}
    </div>
  );
};

export default MyOrderSummary;
