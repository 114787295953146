import { MyCheckPlugin, MyCheckApp, injector } from 'mycheck-core';

import { HTTP_CHECKOUT_API, HttpCheckoutApi } from './CheckoutApi';
import { CHECKOUT_STORE, CheckoutStore } from './stores/CheckoutStore';

const DINE_CHECKOUT_PLUGIN = 'DINE_CHECKOUT_PLUGIN';

export { CheckoutStore };

export class CheckoutPlugin implements MyCheckPlugin {
  readonly name = DINE_CHECKOUT_PLUGIN;
  private publishableKey: string;

  async init(): Promise<void> {
    injector.set(HTTP_CHECKOUT_API, new HttpCheckoutApi());
    this.publishableKey = MyCheckApp.instance.getPublishableKey();
  }

  getStores = () => {
    const checkoutStore = new CheckoutStore(new HttpCheckoutApi());
    return [{ [CHECKOUT_STORE]: checkoutStore }];
  };

  afterHydrate = (): void => {
    return null;
  };
}
