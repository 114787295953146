import { useEffect, useState } from 'react';

export const useScrollPosition = (): number => {
  const [scrollPosition, setScrollPosition] = useState(
    window.scrollY || window.pageYOffset,
  );

  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.scrollY || window.pageYOffset);
    };

    window.addEventListener('scroll', updateScrollPosition);
    window.addEventListener('wheel', updateScrollPosition);

    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
      window.removeEventListener('wheel', updateScrollPosition);
    };
  }, [scrollPosition]);

  return scrollPosition;
};
