export const paramHandler = (
  params: URLSearchParams,
  paramName: string,
  functionCall: (value: string) => void,
) => {
  const paramValue = params.get ? params.get(paramName) : params[paramName];
  if (paramValue) {
    functionCall(paramValue);
  }

  return paramValue;
};
