import React, { useCallback, useState, type MutableRefObject } from 'react';

import { FormikProps } from 'formik';

import { useTranslation } from 'mycheck-core';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';
import { AllergensGroup } from '../AllergensGroup/AllergensGroup';
import { AllergensModal } from '../AllergensModal/AllergensModal';
import { CheckboxField } from '../ModifiersGroup/CheckboxField';
import { RadioField } from '../ModifiersGroup/RadioField';

import styles from './AddonsGroup.module.scss';
import { DynamicValueArray, MenuModifier } from 'types/GenericTypes';

type Props = {
  isRadioButton: boolean;
  formikProps: FormikProps<DynamicValueArray>;
  group: MenuModifier;
  groupId: number;
  maxSelect: number;
  labelRef: MutableRefObject<HTMLDivElement>;
};

export const AddonsGroupRow: React.FC<Props> = ({
  group,
  groupId,
  formikProps,
  isRadioButton,
  maxSelect,
  labelRef,
}) => {
  const { values, errors, setFieldValue, setFieldError } = formikProps;
  const getTranslationForItem = useGetTranslationForMenuItem();
  const [allergensModalOpen, setAllergensModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleAllergensClick = useCallback(
    () => setAllergensModalOpen((value) => !value),
    [],
  );

  if (isRadioButton) {
    return (
      <div className={styles.row}>
        <RadioField
          name={getTranslationForItem(group.pos_unique_id, group).name}
          id={group.pos_unique_id}
          price={group.price}
          values={values}
          modifierGroup={groupId}
          setFieldValue={setFieldValue}
        />
        {group.kcal > 0 && (
          <div className={styles.kcal}>
            {group.kcal}
            {t('menu.calories.name')}
          </div>
        )}
        {!!group.allergens?.length && (
          <div className={styles.rowAllergens}>
            <AllergensGroup
              allergens={group.allergens}
              handleAllergensClick={handleAllergensClick}
            />
          </div>
        )}
        {!!allergensModalOpen && (
          <AllergensModal
            handleCloseAllergens={handleAllergensClick}
            allergens={group.allergens}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles.row}>
      <CheckboxField
        name={getTranslationForItem(group.pos_unique_id, group).name}
        id={group.pos_unique_id}
        price={group.price}
        values={values}
        modifierGroup={groupId}
        setFieldValue={setFieldValue}
        maxSelect={maxSelect}
        errors={errors}
        labelRef={labelRef}
        setFieldError={setFieldError}
      />
      {group.kcal > 0 && (
        <div className={styles.kcal}>
          {group.kcal}
          {t('menu.calories.name')}
        </div>
      )}
      {!!group.allergens?.length && (
        <div className={styles.rowAllergens}>
          <AllergensGroup
            allergens={group.allergens}
            handleAllergensClick={handleAllergensClick}
          />
        </div>
      )}
      {!!allergensModalOpen && (
        <AllergensModal
          handleCloseAllergens={handleAllergensClick}
          allergens={group.allergens}
        />
      )}
    </div>
  );
};
