import moment from 'moment-timezone';

import {
  displayDateTime,
  isPast,
  isSameDay,
  useCheckoutStore,
  useLocationStore,
  useNearestTime,
  useTranslation,
} from 'mycheck-core';

export const useDisplayDateTime = ({
  useLocation,
  isHotelDateTimeFormat,
  toTimeRange,
}: {
  useLocation?: boolean;
  isHotelDateTimeFormat?: boolean;
  toTimeRange?: boolean;
}): string => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const { nearestTime } = useNearestTime();
  const { t } = useTranslation();

  if (useLocation) {
    if (!LocationStore.selectedDate) {
      return t('timeSelection.today') + ', ' + t('general.now');
    }

    if (
      isSameDay(
        LocationStore.selectedDate,
        LocationStore.locationTimezoneName,
      ) &&
      (LocationStore.selectedDate === nearestTime ||
        isPast(LocationStore.selectedDate))
    ) {
      return t('timeSelection.today') + ', ' + t('general.now');
    }

    const now = moment().tz(LocationStore.locationTimezoneName);
    const diff = moment(LocationStore.selectedDate).diff(now, 'minute');

    if (
      LocationStore.selectedExperience &&
      diff <= LocationStore.selectedExperience.settings.frame_time
    ) {
      return t('timeSelection.today') + ', ' + t('general.now');
    }

    /* if(LocationStore.isMenuSelectFirstSlot){
       return t('timeSelection.today') + ', ' +  t('general.now')
     }*/

    return displayDateTime(
      CheckoutStore.checkoutTime,
      LocationStore.locationTimezoneName,
      isHotelDateTimeFormat
        ? LocationStore.localHotelDateFormat
        : LocationStore.localDateFormat,
      isHotelDateTimeFormat
        ? LocationStore.localHotelTimeFormat
        : LocationStore.localTimeFormat,
      false,
      toTimeRange,
    );
  }

  if (!LocationStore.selectedDateTemp) {
    return t('timeSelection.today') + ', ' + t('general.now');
  }

  if (
    isSameDay(
      LocationStore.selectedDateTemp,
      LocationStore.locationTimezoneName,
    ) &&
    (LocationStore.selectedDateTemp === nearestTime ||
      isPast(LocationStore.selectedDateTemp))
  ) {
    return t('timeSelection.today') + ', ' + t('general.now');
  }

  return displayDateTime(
    LocationStore.selectedDateTemp,
    LocationStore.locationTimezoneName,
    isHotelDateTimeFormat
      ? LocationStore.localHotelDateFormat
      : LocationStore.localDateFormat,
    isHotelDateTimeFormat
      ? LocationStore.localHotelTimeFormat
      : LocationStore.localTimeFormat,
    false,
    toTimeRange,
  );
};
