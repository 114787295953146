import React, { useEffect } from 'react';

import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import {
  useAuthStore,
  useLocationStore,
  useModalNavigator,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Header, Overlay, PriceFormatter } from '@components';

import DataGrid from '../../../../components/DataGrid/DataGrid';

import styles from './HistoryPage.module.scss';

export const HistoryPage: React.FC = observer(() => {
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const authStore = useAuthStore();
  const LocationStore = useLocationStore();
  const { closeHistoryPage, openMyOrderPage } = useModalNavigator();
  const isOrderListEmpty =
    authStore.history !== null && !toJS(authStore.history).length;

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    if (authStore.history == null) {
      authStore.fetchHistory(LocationStore.selectedRestaurantId);
    }

    return () => {
      document.body.removeAttribute('style');
    };
  }, []);

  const handleBackClick = () => {
    closeHistoryPage();
    authStore.clearHistory();
  };

  const handleOrderClick = (orderId: number) => () => {
    authStore.setSelectedOrderId(orderId);
    openMyOrderPage();
    closeHistoryPage();
  };

  const renderBody = () => (
    <div className={styles.historyPage}>
      <DataGrid>
        <DataGrid.Header>
          <DataGrid.Column>{t('history.date')}</DataGrid.Column>
          <DataGrid.Column>{t('history.location')}</DataGrid.Column>
          <DataGrid.Column>{t('history.payment')}</DataGrid.Column>
        </DataGrid.Header>
        <DataGrid.Container>
          {authStore.history !== null &&
            authStore.history.map((data, index) => (
              <DataGrid.Row onRowClick={handleOrderClick(data.id)} key={index}>
                <DataGrid.Column>
                  {dayjs(data.created_time)
                    .add(data.business_timezone.offset.hours, 'hour')
                    .format('DD.MM.YY')}
                </DataGrid.Column>
                <DataGrid.Column>{data.restaurant_name}</DataGrid.Column>
                <DataGrid.Column>
                  <PriceFormatter
                    value={Number(data.total_paid)}
                    currency={data.currency}
                  />
                </DataGrid.Column>
              </DataGrid.Row>
            ))}
          {isOrderListEmpty && (
            <div className={styles.historyPageNoOrders}>
              <p>{t('history.noOrdersYet')}</p>
            </div>
          )}
          <DataGrid.Loader isLoading={authStore.isHistoryFetching} />
        </DataGrid.Container>
      </DataGrid>
      <Header
        headerTitle={t('history.title')}
        handleBackClick={handleBackClick}
        enforceSmall={isLg}
        rootStyle={{ position: 'absolute', width: '100%', top: 0 }}
        hideBackButton={isLg}
      />
    </div>
  );

  if (isLg) {
    return <Overlay>{renderBody()}</Overlay>;
  }

  return renderBody();
});

HistoryPage.displayName = 'HistoryPage';
