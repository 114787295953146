import React, { PropsWithChildren } from 'react';

import styles from './DataGrid.module.scss';

export interface DataGridHeaderProps extends PropsWithChildren {}

const DataGridHeader: React.FC<DataGridHeaderProps> = ({ children }) => {
  return <div className={styles.dataGridHeader}>{children}</div>;
};

export default DataGridHeader;
