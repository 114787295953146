import get from 'lodash/get';

import {
  injector,
  MyCheckApp,
  FLOW_TYPES,
  MyCheckPlugin,
  StoresConstants,
} from 'mycheck-core';

import { HttpLocationApi, HTTP_LOCATION_API } from './LocationApi';
import { LocationStore } from './LocationStore';

export { LocationStore };

const DINE_LOCATION_PLUGIN_KEY = 'DINE_LOCATION_PLUGIN_KEY';

export class LocationPlugin implements MyCheckPlugin {
  readonly name = DINE_LOCATION_PLUGIN_KEY;

  async init(): Promise<void> {
    injector.set(HTTP_LOCATION_API, new HttpLocationApi());
  }

  getStores = () => {
    const config = MyCheckApp.instance.getGlobalConfig();
    const timeSettings = get(config, 'home.settings.timeSettings', undefined);
    const flowType = get(config, 'settings.flow', FLOW_TYPES.RESTAURANT);
    const isViewMode = get(config, 'settings.viewMode', false);

    const locationStore = new LocationStore(
      new HttpLocationApi(),
      { timeSettings },
      flowType,
      isViewMode,
    );
    return [{ [StoresConstants.LocationStore]: locationStore }];
  };

  afterHydrate = (): void => {
    return null;
  };
}
