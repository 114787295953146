import jwtDecode from 'jwt-decode';

import {
  attachWalletV2,
  attachWalletV3,
  MyCheckApp,
  PaymentConstants,
  useAuthStore,
  useLanguageStore,
  useLocationStore,
} from 'mycheck-core';
import { TransactionVerification } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

import { useTranslation } from '../index';

type InitProps = {
  onReady: () => void;
  onManageOpen: () => void;
  onManageClose: () => void;
  paymentMethodSelected?: () => void;
  paymentMethodRemoved?: () => void;
  encryptedCvvReceived?: (cvv: string) => void;
  addCardFormReady?: (isFormReady: boolean) => void;
  cardTokenReceived?: (cardToken: string) => void;
  onTransactionVerification: (
    type: '3DS' | 'ALTERNATIVE',
  ) => (value: TransactionVerification) => void;
  checkoutReady?: (status: boolean) => void;
  paymentCompleted?: (paymentDetails: any) => void;
  merchantId: number;
  acceptedCards: Array<string>;
  acceptedAlternativeWallets: Array<string>;
  local: string;
  publishableKey: string;
  refreshToken: string;
  currency: string;
  confirmButtonText: string;
};

const WalletV2 = {
  attach: async () => await attachWalletV2(),
  init: (props: InitProps) => {
    const {
      onManageOpen,
      onManageClose,
      paymentMethodSelected,
      onTransactionVerification,
      merchantId,
      acceptedCards,
      acceptedAlternativeWallets,
      local,
      publishableKey,
      refreshToken,
    } = props;
    window.mycheckWallet.init('mycheck-wallet-v2', {
      publishableKey,
      refreshToken,
      autoFocus: false,
      local,
      events: {
        openManagePaymentMethod: () => onManageOpen(),
        exitManagePaymentMethod: () =>
          setTimeout(() => {
            onManageClose();
            if (paymentMethodSelected) {
              paymentMethodSelected();
            }
          }, 400),
        after3DsComplete: onTransactionVerification('3DS'),
        afterAlternativePaymentComplete:
          onTransactionVerification('ALTERNATIVE'),
      },
      merchantId,
      acceptedCards: acceptedCards.length ? acceptedCards : undefined,
      acceptedAlternativeWallets,
    });
  },
  setAcceptedCards: (cards) => {
    console.log('setAcceptedCards', cards);
  },
  setPayment: (currency: string, summary: number, tip: number) => {
    if (window.mycheckWallet) {
      window.mycheckWallet.setPayment({ currency, amount: summary + tip });
    }
  },
  getCard: () => {
    if (window.mycheckWallet) {
      return window.mycheckWallet.getCard();
    }

    throw new Error();
  },

  destroy: () => {
    return null;
  },
};

let isCheckoutReadyValue = false;

const WalletV3 = {
  settings: {},
  attach: async () => await attachWalletV3(),
  init: (props: InitProps) => {
    window.mycheck.auth.connect({
      publishableKey: props.publishableKey,
      refreshToken: props.refreshToken,
    });

    window.mycheck.wallet.init({
      'wallet-configuration-id': 1,
      settings: {
        confirm_button: {
          text: props.confirmButtonText,
        },
        locale: props.local,
        merchant_id: props.merchantId,
        view: 'CHECKOUT',
        acceptedPaymentMethods: props.acceptedAlternativeWallets,
      },
      payment_details: {
        currency: props.currency,
        amount: 100,
      },
    });
    window.mycheck.wallet.on('ready', props.onReady);
    window.mycheck.wallet.on(
      'checkoutReady',
      (status: boolean, _paymentDetails) => {
        isCheckoutReadyValue = status;
        if (props.checkoutReady) {
          props.checkoutReady(status);
        }
      },
    );
    window.mycheck.wallet.on('paymentMethodRemoved', () => {
      if (props.paymentMethodRemoved) {
        props.paymentMethodRemoved();
      }
    });
    window.mycheck.wallet.on('paymentMethodSelected', () => {
      if (props.paymentMethodSelected) {
        props.paymentMethodSelected();
      }
    });
    window.mycheck.wallet.on('encryptedCvvReceived', (cvv: string) => {
      if (props.encryptedCvvReceived) {
        props.encryptedCvvReceived(cvv);
      }
    });
    window.mycheck.wallet.on('addCardFormReady', (isFormReady: boolean) => {
      if (props.addCardFormReady) {
        props.addCardFormReady(isFormReady);
      }
    });
    window.mycheck.wallet.on('paymentCompleted', (paymentDetails) => {
      if (props.paymentCompleted) {
        props.paymentCompleted(paymentDetails);
      }
    });
    window.mycheck.wallet.on('cardTokenReceived', (cardToken: string) => {
      if (props.cardTokenReceived) {
        props.cardTokenReceived(cardToken);
      }
    });
  },
  setAcceptedCards: (cards) => {
    window.mycheck.wallet.setAcceptedCreditCards(cards);
  },
  setPayment: (currency: string, summary: number, tip: number) => {
    if (window.mycheck?.wallet) {
      window.mycheck.wallet.setPaymentDetails({
        currency,
        amount: summary + tip,
      });
    }
  },
  getCard: async () => {
    if (window.mycheck?.wallet) {
      const response = await window.mycheck.wallet.getPaymentMethod();
      return {
        ...response,
        id: jwtDecode<{ sub: { id: string } }>(response.cc_token)?.sub.id,
      };
    }

    throw new Error();
  },
  destroy: () => {
    window.mycheck?.wallet.destroy();
  },
};

export const useWalletService = () => {
  const { t } = useTranslation();
  const AuthStore = useAuthStore();
  const LanguageStore = useLanguageStore();
  const LocationStore = useLocationStore();

  const { publishableKey, refreshToken } = AuthStore;
  const { selectedValue } = LanguageStore;

  const isV3WalletVersion = () =>
    MyCheckApp.instance.getGlobalConfig().settings.walletDetails?.version ===
    'v3';
  const WalletVersion = isV3WalletVersion() ? WalletV3 : WalletV2;
  const checkIsCheckoutReady = () => {
    if (!isV3WalletVersion() || isCheckoutReadyValue) {
      return;
    }
    const err = new Error();
    err.message = 'MISSING_CREDIT_CARD_DETAILS';
    throw err;
  };

  const getPaymentTypeObject = () =>
    LocationStore.selectedExperience.settings.payments.allowed.find(
      (item) => item.type === PaymentConstants.PaymentMethodsValue.WALLET,
    );

  const getAcceptedAlternativeWallets = () => {
    const paymentTypeObject = getPaymentTypeObject();
    const paymentsAvailable = [
      paymentTypeObject?.google_pay_active && 'googlepay',
      paymentTypeObject?.apple_pay_active && 'applepay',
      paymentTypeObject?.alipay_active && 'alipay',
    ].filter(Boolean);

    return paymentsAvailable.length === 0 ? [] : paymentsAvailable;
  };

  const getAcceptedCards = () => {
    const paymentTypeObject = getPaymentTypeObject();
    return paymentTypeObject?.accepted_cards?.map((e) => e) || [];
  };

  const getMerchantId = () => {
    const paymentTypeObject = getPaymentTypeObject();
    return paymentTypeObject?.merchant_id;
  };

  const init = async ({
    onReady,
    onManageOpen,
    onManageClose,
    paymentMethodSelected,
    onTransactionVerification,
    checkoutReady,
    paymentCompleted,
    paymentMethodRemoved,
    encryptedCvvReceived,
    addCardFormReady,
    cardTokenReceived,
  }: Pick<
    InitProps,
    | 'onReady'
    | 'onManageOpen'
    | 'onManageClose'
    | 'paymentMethodSelected'
    | 'onTransactionVerification'
    | 'checkoutReady'
    | 'paymentCompleted'
    | 'confirmButtonText'
    | 'paymentMethodRemoved'
    | 'encryptedCvvReceived'
    | 'addCardFormReady'
    | 'cardTokenReceived'
  >) => {
    const storeInitObject: Pick<
      InitProps,
      | 'publishableKey'
      | 'refreshToken'
      | 'local'
      | 'merchantId'
      | 'acceptedAlternativeWallets'
      | 'acceptedCards'
      | 'currency'
      | 'confirmButtonText'
    > = {
      publishableKey,
      refreshToken,
      local: selectedValue,
      merchantId: getMerchantId(),
      acceptedCards: getAcceptedCards(),
      acceptedAlternativeWallets: getAcceptedAlternativeWallets(),
      currency: LocationStore.selectedRestaurant.currency,
      confirmButtonText: t('checkout.submitButton').toLowerCase(),
    };

    await WalletVersion.attach();
    WalletVersion.init({
      ...storeInitObject,
      onReady,
      onManageClose,
      onManageOpen,
      paymentMethodSelected,
      onTransactionVerification,
      checkoutReady,
      paymentCompleted,
      paymentMethodRemoved,
      encryptedCvvReceived,
      addCardFormReady,
      cardTokenReceived,
    });
  };

  const setPayment = (summary = 0, tip: number) =>
    WalletVersion.setPayment(
      LocationStore.selectedRestaurant.currency,
      typeof summary === 'number' ? summary : parseFloat(summary),
      tip,
    );

  const getCard = async () => await WalletVersion.getCard();
  const destroy = async () => await WalletVersion.destroy();
  const setAcceptedCards = () => {
    const acceptedCards = getAcceptedCards();
    if (acceptedCards.length) {
      WalletVersion.setAcceptedCards(acceptedCards);
    }
  };

  return {
    init,
    setPayment,
    getCard,
    destroy,
    setAcceptedCards,
    checkIsCheckoutReady,
  };
};
