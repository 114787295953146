import React, { useMemo } from 'react';

import classnames from 'classnames';
import { FormikProps } from 'formik';
import { LocationStore as LocationStoreClass } from 'location-plugin';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useCheckoutStore,
  useLanguageStore,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { Loader, PriceFormatter } from '@components';

import { TipStringValues } from '../../Constants';
import { IFormikValues } from '../../types/IFormikValues';
import { CustomTip } from '../CustomTip/CustomTip';
import { TipItem } from '../TipItem/TipItem';

import styles from './OrderSummary.module.scss';
import { Tax } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

interface Props {
  formikProps: FormikProps<IFormikValues>;
  tipPrice: number;
  tipValues:
    | {
        id: TipStringValues;
        value: number;
        label?: string;
      }[]
    | [];
  isPendingCalculations: boolean;
  summary: {
    subtotal?: string | number;
    total?: string | number;
    tax?: string;
    service_charges?: {
      name: string;
      amount: number;
      isVisible: boolean;
    }[];
    discount?: string;
  };
  taxes: Array<Tax>;
  LOCATION_STORE?: LocationStoreClass;
}

export const OrderSummary: React.FC<Props> = ({
  formikProps,
  tipPrice,
  tipValues,
  isPendingCalculations,
  summary,
  taxes,
}) => {
  const { t } = useTranslation();
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const LanguageStore = useLanguageStore();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const disclaimerStyle = get(
      _config,
      'checkout.orderSummary.disclaimer',
      {},
    );
    const priceStyle = get(_config, 'checkout.orderSummary.price', {});
    const textStyle = get(_config, 'checkout.orderSummary.text', {});
    const titleStyle = get(_config, 'checkout.orderSummary.title', {});
    const primaryColor = get(_config, 'palette.primary', {});
    const orderSummarySettings = get(_config, 'settings.orderSummary', {});
    const coupon = CheckoutStore.getCoupon();

    const orderSummaryData = [
      {
        title: t('checkout.orderSubtotal'),
        value: summary.subtotal,
        isVisible: orderSummarySettings.showSubtotal,
        isDiscount: false,
        isInclusive: false,
      },
      ...(summary.service_charges
        ? summary.service_charges.map((e) => ({
            title: e.name,
            value: e.amount,
            isVisible:
              orderSummarySettings.showServiceCharge &&
              !!summary.service_charges.length,
            isDiscount: false,
            isInclusive: false,
          }))
        : []),
      summary.discount && Number(summary.discount) > 0
        ? {
            title: coupon.name || 'discount',
            value: summary.discount,
            isVisible: true,
            isDiscount: true,
            isInclusive: false,
          }
        : {},
      ...taxes
        .map((e) => ({
          title: e.name,
          value: e.amount,
          isVisible: !!taxes.length,
          isDiscount: false,
          isInclusive: e.is_inclusive,
        }))
        .filter((e) => e.value !== '0.00'),
      {
        title: t('checkout.orderTax'),
        value: summary.tax,
        isVisible:
          orderSummarySettings.showTax &&
          summary.tax &&
          !taxes?.length &&
          summary.tax !== '0.00',
        isDiscount: false,
        isInclusive: false,
      },
      {
        title: t('checkout.orderTip'),
        value: tipPrice,
        isVisible: LocationStore.isTipAvailable(
          formikProps.values.paymentMethod,
        ),
        isDiscount: false,
        isInclusive: false,
      },
    ].filter(Boolean);

    return {
      disclaimerStyle,
      priceStyle,
      textStyle,
      titleStyle,
      primaryColor,
      orderSummarySettings,
      orderSummaryData,
    };
  }, [
    location.search,
    summary,
    taxes,
    formikProps.values.customTip,
    formikProps.values.tip,
  ]);

  const handleTipButtonClick = (value: string) => {
    if (value !== TipStringValues.CUSTOM) {
      formikProps.setFieldValue('customTip', '');
    }

    formikProps.setFieldValue('tip', value);
  };

  return (
    <div className={styles.root}>
      {isPendingCalculations && (
        <Loader
          withOverlay={false}
          label={t('checkout.pendingCalculationLabel')}
        />
      )}
      <div
        className={styles.orderSummary}
        style={isPendingCalculations ? { opacity: 0 } : {}}
      >
        <div className={styles.orderSummaryHeader}>
          <div className={styles.orderSummaryHeaderTitle}>
            {t('checkout.orderSummaryTitle')}
          </div>
        </div>

        <div className={styles.orderSummaryDisclaimer}>
          {LanguageStore.getExperienceTranslation(
            LocationStore.selectedExperience?.id,
            'settings.ui.checkout.disclaimer',
            LocationStore.uiCheckoutSettings.disclaimer,
          )}
        </div>
        <div className={styles.orderSummaryContainer}>
          {config.orderSummaryData.map(
            (dataItem, index) =>
              !!dataItem.isVisible && (
                <div
                  className={classnames(
                    styles.orderSummaryContainerItem,
                    dataItem.isInclusive
                      ? styles.orderSummaryContainerItemIsInclusive
                      : '',
                  )}
                  key={`${dataItem.title}-${dataItem.value}-${index}`}
                >
                  <div
                    className={classnames(
                      styles.orderSummaryContainerItemName,
                      dataItem.isInclusive
                        ? styles.orderSummaryContainerItemNameIsInclusive
                        : '',
                    )}
                  >
                    {dataItem.title}
                  </div>
                  <div
                    className={classnames(
                      styles.orderSummaryContainerItemValue,
                      dataItem.isInclusive
                        ? styles.orderSummaryContainerItemNameIsInclusive
                        : '',
                    )}
                  >
                    {dataItem.isDiscount && '-'}{' '}
                    <PriceFormatter value={Number(dataItem.value)} />
                  </div>
                </div>
              ),
          )}
        </div>
        {LocationStore.isTipAvailable(formikProps.values.paymentMethod) && (
          <div className={styles.orderSummaryButtonGroup}>
            {tipValues.map((button: { id: string; label?: string }) =>
              button.id === TipStringValues.CUSTOM ? (
                <CustomTip
                  id={button.id}
                  key={button.id}
                  onClick={handleTipButtonClick}
                  currentValue={formikProps.values.tip}
                  formikProps={formikProps}
                />
              ) : (
                <TipItem
                  key={button.id}
                  className={
                    button.id === TipStringValues.NO_TIP
                      ? styles.orderSummaryButtonGroupNoTip
                      : ''
                  }
                  id={button.id}
                  label={button.label}
                  currentValue={formikProps.values.tip}
                  onClick={handleTipButtonClick}
                  testId={`tip-item-${button.label}`}
                />
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
