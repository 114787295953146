import React, {
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  type CSSProperties,
  type RefObject,
  type SyntheticEvent,
} from 'react';

import classnames from 'classnames';
import { css } from 'glamor';
import get from 'lodash/get';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useClickOutside, useWindowSize } from 'mycheck-core';

import { CustomIcon } from '../Icon/CustomIcon';

import styles from './DropDown.module.scss';
import { DropDownLabel } from './DropDownLabel';

type DropDownType = {
  componentStyles?: {
    wrapperStyle?: CSSProperties;
    wrapperContentStyle?: CSSProperties;
    rootStyle?: CSSProperties;
    customDropdownStyle?: CSSProperties;
  };
  label: JSX.Element | string;
  children: ({
    onClose,
    style,
  }: {
    onClose: () => void;
    style: CSSProperties;
  }) => JSX.Element;
  disableDropdown?: boolean;
  showArrow?: boolean;
  withPortal?: boolean;
  portalTargetRef?: RefObject<HTMLDivElement>;
  onHomePage?: boolean;
  usePortalTargetOutsideClick?: boolean;
  openToTheRight?: boolean;
  withoutAngle?: boolean;
  menuTabScrollingTracker?: boolean;
  isMenuTab?: boolean;
  isSelected?: boolean;
  onDropdownClick?: () => void;
  testId?: string;
  withSiblingCheck?: boolean;
  newStyles?: boolean;
};

export const DropDown: React.FC<DropDownType> = forwardRef(
  (
    {
      label,
      children,
      componentStyles = {},
      disableDropdown,
      showArrow = true,
      withPortal,
      portalTargetRef,
      onHomePage,
      usePortalTargetOutsideClick,
      openToTheRight,
      withoutAngle,
      isSelected,
      isMenuTab,
      onDropdownClick,
      withSiblingCheck = false,
      testId = '',
      newStyles = false,
    },
    ref,
  ) => {
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [isLeftSide, setIsLeftSide] = useState(false);
    const [leftSideOffset, setLeftSideOffset] = useState(0);
    const [rightSideOffset, setRightSideOffset] = useState(0);
    const [topSideOffset, setTopSideOffset] = useState(0);
    const [rootWidth, setRootWidth] = useState(0);

    const [wrapperLeftSideOffset, setWrapperLeftSideOffset] = useState(190);

    const rootRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const dropdownStyle = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const unselectedTabStyle = get(_config, 'general.tabs.unSelected', {});
      const selectedTabStyle = get(_config, 'general.tabs.selected', {});
      const unselectedArrowStyle = get(
        _config,
        'general.tabs.unSelected.icons.arrowDown',
        {},
      );
      const selectedArrowStyle = get(
        _config,
        'general.tabs.selected.icons.arrowDown',
        {},
      );

      return {
        notSelected: {
          ...unselectedTabStyle.Text,
          ...unselectedTabStyle.Background,
        },
        selected: {
          ...selectedTabStyle.Text,
          ...selectedTabStyle.Background,
        },
        arrow: {
          notSelected: unselectedArrowStyle,
          selected: selectedArrowStyle,
        },
      };
    }, [location.search]);

    const handleDropdownClick = (e: SyntheticEvent) => {
      e.stopPropagation();
      if (!disableDropdown) {
        onDropdownClick && onDropdownClick();
        setShowDropdown(!showDropdown);
      }
    };

    const onClose = () => setShowDropdown(false);

    const measurePosition = () => {
      const dropdownBounding = rootRef.current?.getBoundingClientRect();

      if (dropdownBounding) {
        setIsLeftSide(dropdownBounding.right < 210);
        setLeftSideOffset(dropdownBounding.right - 26);
        setRightSideOffset(window.innerWidth - dropdownBounding.right - 4);
        setTopSideOffset(dropdownBounding.top + dropdownBounding.height);
        setRootWidth(dropdownBounding.width - 28);

        setWrapperLeftSideOffset(wrapperRef.current?.clientWidth - 14);
      }
    };

    const blockPosition = useCallback(() => {
      if (showDropdown) {
        document.documentElement.style.overflow = 'hidden';
      } else {
        document.documentElement.removeAttribute('style');
      }
    }, [showDropdown]);

    useImperativeHandle(ref, () => ({
      rootRef,
      wrapperRef,
      contentRef,
      onClose,
    }));

    useClickOutside(
      withPortal && usePortalTargetOutsideClick ? portalTargetRef : rootRef,
      onClose,
      { withSibling: withSiblingCheck },
    );

    const { isLg } = useWindowSize(measurePosition);

    useLayoutEffect(() => {
      if (showDropdown) {
        measurePosition();
      }

      if (!isLg) {
        blockPosition();
      }
    }, [showDropdown]);

    const renderDropdown = () => {
      const withPortalWrapperStyle: CSSProperties =
        componentStyles.customDropdownStyle || {
          ...(isLg
            ? {}
            : ({ position: 'fixed !important' } as unknown as {
                position: 'fixed';
              })),
          left: isLeftSide && leftSideOffset,
          right: !isLeftSide && rightSideOffset,
          top: `${topSideOffset}px !important`,
        };

      const wrapperStyle = componentStyles.wrapperStyle || {};
      const wrapperContentStyle = componentStyles.wrapperContentStyle || {};

      const wrapperClassname = css({
        ...(withPortal
          ? withPortalWrapperStyle
          : isLeftSide
            ? { left: rootWidth }
            : { right: -2 }),
        ...wrapperStyle,
      });
      const wrapperContentClassname = css({
        ...wrapperContentStyle,
        ...(openToTheRight ? { padding: '0 !important' } : {}),
      });

      return (
        <>
          <div
            className={classnames(styles.wrapper, {
              [wrapperClassname.toString()]: true,
              [styles.leftWrapper]: isLeftSide,
            })}
            ref={wrapperRef}
          >
            <div
              ref={contentRef}
              className={classnames(styles.wrapperContent, {
                [wrapperContentClassname.toString()]: true,
              })}
            >
              {children({
                onClose,
                style: { top: 0, left: wrapperLeftSideOffset },
              })}
            </div>
            {!withoutAngle && (
              <div
                className={classnames(
                  styles.angle,
                  isLeftSide && styles.leftAngle,
                )}
              >
                <div className={styles.angleIcon} />
              </div>
            )}
          </div>
          <div style={{ position: 'fixed', inset: 0 }} />
        </>
      );
    };

    const renderRootDropdown = () => {
      if (!showDropdown) {
        return null;
      }

      if (
        withPortal &&
        portalTargetRef &&
        portalTargetRef.current &&
        !isMenuTab
      ) {
        return ReactDOM.createPortal(renderDropdown(), portalTargetRef.current);
      }

      return renderDropdown();
    };

    const arrowDownCustomStylePath =
      !onHomePage && 'restaurants.icons.arrowDown';

    if (newStyles) {
      return (
        <div
          data-test-id={`dropdown-${testId}`}
          onClick={handleDropdownClick}
          style={{ position: 'relative' }}
        >
          <>
            {label}
            {renderRootDropdown()}
          </>
        </div>
      );
    }

    return (
      <div
        data-test-id={`dropdown-${testId}`}
        ref={rootRef}
        className={styles.root}
        onClick={handleDropdownClick}
        style={componentStyles.rootStyle}
      >
        <>
          {typeof label === 'string' ? <DropDownLabel name={label} /> : label}
          {renderRootDropdown()}
          {showArrow && (
            <CustomIcon
              customStylePath={arrowDownCustomStylePath}
              name="arrowDown"
              style={
                typeof isSelected === 'boolean'
                  ? isSelected
                    ? dropdownStyle.arrow.selected
                    : dropdownStyle.arrow.notSelected
                  : {}
              }
            />
          )}
        </>
      </div>
    );
  },
);

DropDown.displayName = 'DropDown';
