import { useEffect, useState } from 'react';

const LG_BREAKPOINT = 992;
const isLandscape = (width, height) => width > height;

const getSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
  isLg:
    window.innerWidth >= LG_BREAKPOINT &&
    isLandscape(window.innerWidth, window.innerHeight),
});

export function useWindowSize(callback?: () => void) {
  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const timeoutList = [];
    function handleResize() {
      const timeout = setTimeout(() => {
        setWindowSize(getSize());

        if (callback) {
          callback();
        }
        timeoutList.splice(-1);
      }, 5);
      timeoutList.push(timeout);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      timeoutList.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  return windowSize;
}
