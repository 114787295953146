import React, { useMemo, type CSSProperties } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { IconSvg, stylePlace } from './helpers';

type Props = {
  name: IconSvg;
  className?: string;
  style?: CSSProperties;
  alt?: string;
};

export const Icon: React.FC<Props> = ({ name, className, alt = '', style }) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const iconStyle = get(_config, stylePlace(name), {});
    const iconSrc = get(_config, `general.icons.${name}`, null);

    return {
      iconStyle,
      iconSrc,
    };
  }, [location.search]);

  if (!config.iconSrc) {
    return null;
  }

  return (
    <img
      src={config.iconSrc}
      style={{ ...config.iconStyle, ...style }}
      className={className}
      alt={alt}
    />
  );
};
