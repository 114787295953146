import React, { useMemo } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './NextButton.module.scss';

type NextButtonType = {
  onDisabledClick?: () => void;
  disabled?: boolean;
  onClick?: () => void;
};

export const NextButton: React.FC<NextButtonType> = ({
  onDisabledClick,
  disabled = false,
  onClick,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const forwardSquareStyle = get(_config, 'register.icons.forwardSquare', {});
    const pencilSquareStyle = get(_config, 'register.icons.pencilSquare', {});

    return {
      forwardSquareStyle,
      pencilSquareStyle,
    };
  }, [location.search]);

  if (disabled) {
    return (
      <button
        type="button"
        onClick={onDisabledClick}
        className={classnames(styles.nextButton, styles.nextButtonDisabled)}
        style={config.pencilSquareStyle}
      >
        <CustomIcon name="pencil" customStylePath="register.icons.pencil" />
      </button>
    );
  }

  return (
    <button
      type={!onClick ? 'submit' : 'button'}
      className={styles.nextButton}
      style={config.forwardSquareStyle}
      {...(onClick && { onClick: onClick })}
    >
      <CustomIcon name="forward" customStylePath="register.icons.forward" />
    </button>
  );
};
