import React, { useCallback, useEffect, useMemo, useState } from 'react';

import get from 'lodash/get';

import { MyCheckApp } from 'mycheck-core';

import { Background, ClosePage, Overlay, Text } from '@components';

import styles from './InternetTracker.module.scss';
import { ReactComponent as NoConnection } from './no_connection.svg';

export const InternetTracker: React.FC = () => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  const handleOnline = useCallback(() => {
    window.location.reload();
    setIsOnline(true);
  }, [isOnline]);

  const handleOffline = useCallback(() => setIsOnline(false), [isOnline]);
  const color = useMemo(
    () => get(MyCheckApp.instance.getGlobalConfig(), 'palette.primary', ''),
    [window.location.search],
  );

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if (isOnline) {
    return null;
  }

  return (
    <Overlay className={styles.modal} testId="internet-tracker">
      <Background className={styles.root}>
        <ClosePage onClose={handleOnline} testId="internet-tracker" />
        <NoConnection className={styles.icon} style={{ color }} />
        <div className={styles.text}>
          <Text value="general.noInternetFirstLine" />
        </div>
        <div className={styles.text}>
          <Text value="general.noInternetSecondLine" />
        </div>
      </Background>
    </Overlay>
  );
};
