import React, {
  useCallback,
  useMemo,
  type CSSProperties,
  type MouseEvent,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useLocationStore,
  useNativeBridge,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon, HeaderProfile } from '@components';

import { LangPicker } from '../../../../LanguagePlugin/components/LanguagePicker';

import styles from './Header.module.scss';

interface Props {
  onPress: () => void;
  title: string;
  style?: CSSProperties;
  onHomePage?: boolean;
}

export const Header: React.FC<Props> = ({
  title,
  onPress,
  style,
  onHomePage,
}) => {
  const { isLg } = useWindowSize();
  const nativeBridge = useNativeBridge();
  const locationStore = useLocationStore();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const header = get(_config, 'general.header', {});
    const headerTitle = get(_config, 'general.title', {});
    const mobileHeaderTitle = get(_config, 'home.style.mobileHeaderText', {});
    const langPicker = get(_config, 'home.settings.languagePicker', {});
    const backButton = get(_config, 'home.icons.backButton', {});

    const componentStyle = {
      defaultLanguage: langPicker.Default,
      header,
      showLangPicker: !!langPicker,
    };

    return {
      headerTitle,
      mobileHeaderTitle,
      backButton,
      componentStyle,
      supportMembers,
    };
  }, [location.search]);

  const onHandleClickBack = useCallback(
    (_event?: MouseEvent<HTMLDivElement>) => {
      nativeBridge.postMessageClose({ action: 'CLOSE', source: 'home' });
    },
    [],
  );

  if (locationStore.isIframe === true) {
    return null;
  }

  return (
    <div
      data-test-id="header-location"
      className={styles.header}
      style={{ ...style }}
    >
      <h1
        className={styles.headerTitle}
        style={isLg ? config.headerTitle : config.mobileHeaderTitle}
      >
        {title}
      </h1>
      {locationStore.isNative && (
        <div className={styles.BackButton} onClick={onHandleClickBack}>
          <CustomIcon name={'back'} style={config.backButton} />
        </div>
      )}
      <div className={classnames(styles.headerProfile)}>
        {config.supportMembers && !locationStore.isNative && (
          <HeaderProfile onHomePage={onHomePage} onPress={onPress} />
        )}
      </div>
      <div className={styles.headerLang}>
        <LangPicker onHomePage={onHomePage} />
      </div>
    </div>
  );
};
