import get from 'lodash/get';
import {
  observable,
  makeAutoObservable,
  action,
  computed,
  runInAction,
} from 'mobx';
import { persist } from 'mobx-persist';
import { LanguageApi, LanguageOption } from './types/LanguageTypes';

export class LanguageStore {
  private LanguageApi: LanguageApi;

  @observable options: LanguageOption[] = [];
  @observable @persist selectedValue: string;

  @observable @persist('object') experienceTranslations = null;
  @observable @persist('object') businessTranslations = null;
  @observable @persist('object') productTranslations = null;

  constructor(languageApi: LanguageApi) {
    this.LanguageApi = languageApi;

    makeAutoObservable(this);
  }

  @action
  loadTranslation = (selectedLanguage: LanguageOption) => {
    this.selectedValue = selectedLanguage.value;
  };

  @action
  fetchSpecificTranslations = async () => {
    const [
      businessTranslationsResponse,
      experienceTranslationsResponse,
      productTranslationsResponse,
    ] = await Promise.all([
      this.LanguageApi.fetchSpecificTranslations('business'),
      this.LanguageApi.fetchSpecificTranslations('experience'),
      this.LanguageApi.fetchSpecificTranslations('restaurant-product'),
    ]);

    runInAction(() => {
      this.businessTranslations = businessTranslationsResponse?.business;
      this.experienceTranslations = experienceTranslationsResponse?.experience;
      this.productTranslations =
        productTranslationsResponse?.['restaurant-product'];
    });
  };

  @computed
  get selectedOption() {
    return this.options.find((e) => e.value === this.selectedValue);
  }

  private getExperienceTranslationObject = (experienceId: number) => {
    return (
      this.experienceTranslations?.[this.selectedValue]?.find(
        (t) => Number(t.resource_id) === experienceId,
      ) || {}
    );
  };

  @computed
  private getBusinessTranslationObject = (resourceId: number) => {
    return (
      this.businessTranslations?.[this.selectedValue]?.find(
        (t) => Number(t.resource_id) === resourceId,
      ) || {}
    );
  };

  private getProductTranslationObject = (
    resourceId: number,
    productId: number,
  ) => {
    return (
      this.productTranslations?.[this.selectedValue]?.find(
        (t) => t.resource_id === `${resourceId}-${productId}`,
      ) || {}
    );
  };

  getExperienceTranslation = (
    experienceId: number,
    path: string,
    defaultTranslation: string,
  ) => {
    return get(
      this.getExperienceTranslationObject(experienceId),
      `translation.${path}`,
      defaultTranslation,
    );
  };

  @computed
  getBusinessTranslation = (
    resourceId: number,
    path: string,
    defaultTranslation: string,
  ) => {
    const value = get(
      this.getBusinessTranslationObject(resourceId),
      `translation.${path}`,
      defaultTranslation,
    );
    return value || defaultTranslation;
  };

  getProductTranslation = (
    resourceId: number,
    productId: number,
    path: string,
    defaultTranslation: string,
  ) => {
    return get(
      this.getProductTranslationObject(resourceId, productId),
      `translation.${path}`,
      defaultTranslation,
    );
  };
}
