import React from 'react';

import { Trans, useLanguageLoader } from 'mycheck-core';

type Props = {
  tKey: string;
  values: Record<string, unknown>;
};
export const TranslateText: React.FC<Props> = ({ tKey, values }) => {
  const { isRtl } = useLanguageLoader();
  return (
    <Trans
      i18nKey={tKey}
      values={values}
      defaults="{{at}}"
      components={{
        text: <span />,
        ltr: <span className={isRtl() ? 'override-bidi-ltr' : ''} />,
        rtl: <span className={isRtl() ? 'override-bidi-rtl' : ''} />,
        isolate: <span className="override-bidi-isolate" />,
      }}
    />
  );
};
