export enum ErrorCodes {
  VERIFICATION_ERROR_HIDDEN = 9999,

  FIRST_EMAIL_REDIRECT_CODE = 1,
  SECOND_EMAIL_REDIRECT_CODE = 1009,

  INVALID_VERIFICATION_CODE = 1021,
  VERIFICATION_CODE_EXPIRED = 1020,
}

export enum ParamErrorCodes {
  EXPERIENCE_ERROR = 'EXPERIENCE_ERROR',
  RESTAURANT_ERROR = 'RESTAURANT_ERROR',
  HOTEL_ERROR = 'HOTEL_ERROR',
}
