import { useEffect, useState } from 'react';

export const useActiveScroll = (): boolean => {
  const [activeScroll, setActiveScroll] = useState(true);

  useEffect(() => {
    const locationContentElem = document.getElementById('locationContent');
    const screenHeight = window.screen.height;

    setActiveScroll(
      locationContentElem && locationContentElem.scrollHeight >= screenHeight,
    );
  }, []);

  return activeScroll;
};
