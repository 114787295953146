import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import localeDate from 'dayjs/plugin/localeData';
import timezonePlugin from 'dayjs/plugin/timezone';
import utcPlugin from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import {
  IOpenHoursDay,
  ITimeSettings,
  OpenHoursFormat,
} from 'plugins/LocationPlugin/types/LocationTypes';
import { GenericSelectValueItem } from 'types/GenericTypes';

import { t } from '../service/i18n';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(customParseFormat);
dayjs.extend(utcPlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(localeDate);

const DISPLAY_FORMAT_DAY = 'ddd';
const MINUTES_IN_HOUR = 60;
const DATE_TIME_FORMATS = 'YYYY-MM-DDTHH:mm:ssZ';
const DATE_YEARS_MONTHS_DAYS_FORMATS = 'YYYY-MM-DD';

const transformToTimeRange = (date: moment.Moment, timeFormat: string) =>
  `${moment(date).format(timeFormat)}-${moment(date)
    .add(30, 'minute')
    .format(timeFormat)}`;

export const translateDateMonth = (
  date: moment.Moment,
  dateFormat: string,
): string => {
  if (!dateFormat.includes('MMMM')) {
    return date.format(dateFormat);
  }
  const monthKey = date.format('MMM').toLowerCase();
  const monthValue = date.format('MMMM');
  const monthTranslation = t(`location.month.${monthKey}.long`);
  return date.format(dateFormat).replace(monthValue, monthTranslation);
};

const getCorrectTranslation = (
  parseJsDate: moment.Moment,
  timezoneName: string,
  dateFormat: string,
  timeFormat: string,
  withMixedDirections = false,
  toTimeRange = false,
) => {
  const isRtl = document.dir === 'rtl';
  const mixedTag = withMixedDirections
    ? document.dir === 'rtl'
      ? 'rtl'
      : 'ltr'
    : '';
  const startingTag = mixedTag ? `<${mixedTag}>` : '';
  const endingTag = mixedTag ? `</${mixedTag}>` : '';
  const startingTextTag = mixedTag ? '<text>' : '';
  const endingTextTag = mixedTag ? '</text>' : '';
  const startingIsolateTag = mixedTag ? '<isolate>' : '';
  const endingIsolateTag = mixedTag ? '</isolate>' : '';

  if (isRtl) {
    if (parseJsDate.isSame(moment.utc().tz(timezoneName), 'day')) {
      return `${startingTextTag}${parseJsDate.format(
        timeFormat,
      )}, ${endingTextTag}${startingIsolateTag}${t(
        'timeSelection.today',
      )} ${endingIsolateTag}`;
    }

    if (
      parseJsDate.isSame(moment.utc().tz(timezoneName).add(1, 'day'), 'day')
    ) {
      return `${startingTextTag}${parseJsDate.format(
        timeFormat,
      )}, ${endingTextTag}${startingIsolateTag}${t(
        'timeSelection.tomorrow',
      )} ${endingIsolateTag}`;
    }

    return `${startingTextTag}${parseJsDate.format(
      `${timeFormat}, ${dateFormat}`,
    )} ${endingTextTag}`;
  }

  const formattedTime = toTimeRange
    ? transformToTimeRange(parseJsDate, timeFormat)
    : parseJsDate.format(timeFormat);

  if (parseJsDate.isSame(moment.utc().tz(timezoneName), 'day')) {
    return `${startingTag}${t(
      'timeSelection.today',
    )}${endingTag}${startingTextTag}, ${formattedTime}${endingTextTag}`;
  }

  if (parseJsDate.isSame(moment.utc().tz(timezoneName).add(1, 'day'), 'day')) {
    return `${startingTag}${t(
      'timeSelection.tomorrow',
    )}${endingTag}${startingTextTag}, ${formattedTime}${endingTextTag}`;
  }

  const dayOfWeek = parseJsDate.format('ddd').toLowerCase();
  const translatedDayOfWeek = t(`location.${dayOfWeek}`);
  return `${startingTextTag}${
    translatedDayOfWeek || dayOfWeek
  }, ${formattedTime}${endingTextTag}`;
};

export const displayTimeFromHourMinute = (
  time: string,
  timeFormat: string,
): string => dayjs.utc(time, 'HH:mm').format(timeFormat);
export const displayTime = (
  time: string,
  timezone: string,
  timeFormat: string,
): string => dayjs.utc(time).tz(timezone).format(timeFormat);

export const getDateLabel = (
  day: moment.Moment | string,
  timezone: string,
  dateFormat: string,
  withDate = false,
): string => {
  day = moment(day).tz(timezone);

  if (day.isSame(moment().tz(timezone), 'day')) {
    return t('timeSelection.today');
  }

  if (day.isSame(moment().tz(timezone).add(1, 'day'), 'day')) {
    return t('timeSelection.tomorrow');
  }
  if (dateFormat)
    dateFormat = dateFormat.replace(', YYYY', '').replace('/YYYY', '');

  const dayOfWeek = day.format('ddd').toLowerCase();
  const translatedDayOfWeek = t(`location.${dayOfWeek}`);

  const formattedDate = translateDateMonth(day, dateFormat);

  return withDate
    ? `${translatedDayOfWeek || day.format('ddd')}, ${formattedDate}`
    : `${translatedDayOfWeek || day.format('ddd')}`;
};

export const displayDateTime = (
  date: string,
  timezoneName: string,
  dateFormat: string,
  timeFormat: string,
  displayWithMixed = false,
  toTimeRange = false,
): string => {
  if (!date || !timezoneName || !dateFormat || !timeFormat) {
    return null;
  }

  return getCorrectTranslation(
    moment(date).tz(timezoneName),
    timezoneName,
    dateFormat,
    timeFormat,
    displayWithMixed,
    toTimeRange,
  );
};

export const setDateTime = (
  date: string,
  time?: string,
  timezone = 'UTC',
): string => {
  if (date) {
    return date;
  }

  const dateDayJs = dayjs.utc(date);
  const timeDayJs = dayjs.utc(time);

  if (dateDayJs.toJSON()) {
    if (time) {
      return dateDayJs
        .set('hour', timeDayJs.hour())
        .set('minute', timeDayJs.minute())
        .set('second', 0)
        .tz(timezone)
        .format();
    }

    return dateDayJs.set('second', 0).format();
  }

  return timeDayJs.set('second', 0).format();
};

// TODO - One of those 2 need to be removed in future
export const toLocationFormat = (date: string | Date): string =>
  dayjs.utc(date).format(DATE_TIME_FORMATS);
export const toUTCDateFormat = (date?: string): string =>
  dayjs(date).utc().format(DATE_TIME_FORMATS);
export const toYearMonthDayFormat = (date: Date | string): string =>
  dayjs(date).format(DATE_YEARS_MONTHS_DAYS_FORMATS);

export const getTimezone = (): number => dayjs().utcOffset() / MINUTES_IN_HOUR;

export const transformDate = (
  timezone: string,
  dateFormat: string,
  numberOfDays = 7,
  withDate = true,
  openHours: OpenHoursFormat,
): {
  label: string;
  value: string;
  valueDate?: string;
}[] => {
  const now = moment().tz(timezone);
  const momentDay = moment()
    .tz(timezone || 'UTC')
    .startOf('day');
  const days = [momentDay.clone()];

  while (days.length < numberOfDays) {
    momentDay.add(1, 'day');
    days.push(momentDay.clone());
  }

  return days
    .filter((day) => {
      const dayName = day.format('ddd').toLowerCase();
      const hours = openHours[dayName];

      if (!hours) return false;

      if (day.isSame(now, 'day')) {
        const toHour = openHours[dayName][0].to.split(':')[0];
        const toMin = openHours[dayName][0].to.split(':')[1];

        const closeTime = moment()
          .tz(timezone)
          .startOf('day')
          .set('hour', toHour)
          .set('minute', toMin);
        if (now.isAfter(closeTime)) return false;
      }

      return openHours[dayName];
    })
    .map((day) => ({
      label: getDateLabel(day, timezone, dateFormat, withDate),
      value: day.utc().format(),
    }));
};

export const transformTimeArrayToDateTimeArray = (
  array: Array<{ from: string }>,
  date: string,
  experienceTimezone: string,
  timeFormat: string,
  frameTime: number,
): { label: string; value: string }[] =>
  array.map((e, index) => {
    const now = moment().tz(experienceTimezone);
    const time = moment.utc(e.from, 'HH:mm');
    const newDate = moment
      .utc(date)
      .tz(experienceTimezone)
      .set('hour', time.hour())
      .set('minute', time.minute())
      .set('second', 0);

    const diff = newDate.diff(now, 'minute');

    return {
      value: newDate.clone().utc().format(),
      label:
        index === 0 && diff <= frameTime
          ? t('general.now')
          : newDate.format(timeFormat),
    };
  });

const getSpecificDayValues = (data: ITimeSettings, selectedDay: string) => {
  const specificDay = data.open_hours[selectedDay.toLowerCase()];
  if (specificDay && specificDay.length > 0) {
    return specificDay[0];
  }

  return null;
};

export function getNearestDateTime(
  data: ITimeSettings,
  timezone: string,
): string | null {
  const now = moment.utc().tz(timezone);

  let counter = 0;
  let currentDate = now.clone();
  let currentDaySettings = null;

  if (!Object.values(data.open_hours).length) {
    return null;
  }

  while (
    currentDaySettings === null &&
    counter < data.available_days &&
    counter < 30
  ) {
    counter++;

    const value = getSpecificDayValues(
      data,
      currentDate.format(DISPLAY_FORMAT_DAY),
    );
    if (value === null) {
      currentDate = currentDate.add(1, 'day').startOf('day');
    } else {
      const endValue = moment.tz(value.to, 'HH:mm', timezone).endOf('hour');
      const startValue = moment
        .tz(value.from, 'HH:mm', timezone)
        .startOf('hour');
      endValue
        .set('date', currentDate.date())
        .set('month', currentDate.month())
        .set('year', currentDate.year());
      startValue
        .set('date', currentDate.date())
        .set('month', currentDate.month())
        .set('year', currentDate.year());

      if (currentDate.isBefore(startValue)) {
        currentDaySettings = currentDate;
      } else if (currentDate.isBetween(startValue, endValue)) {
        currentDaySettings = currentDate;
      } else {
        currentDate.add('day', 1).startOf('day');
      }
    }
  }

  if (!currentDaySettings) {
    return moment.utc().format();
  }

  return currentDaySettings.utc().format();
}

export const getInitialTime = (): string => moment.utc().format();

export const transformTime = (
  data: ITimeSettings,
  selectedDate: string,
  timezone: string,
  timeFormat: string,
  toRange = true,
): Array<GenericSelectValueItem> => {
  const getStartDate = (date: moment.Moment, tz: string) => {
    const currentTime = moment.utc().tz(tz);
    const selectedDateStart = date.clone().startOf('day');

    if (selectedDateStart.isBefore(currentTime)) {
      return currentTime;
    }

    return selectedDateStart;
  };

  const minutesInterval = 15;
  const timeData = [];
  const now = moment.tz(timezone);
  const selectedDateMoment = moment.utc(selectedDate).tz(timezone);

  const selectedDateEnd = selectedDateMoment.clone().endOf('day');
  const selectedDateStart = getStartDate(selectedDateMoment, timezone);

  const day: Array<IOpenHoursDay> =
    data.open_hours?.[selectedDateMoment.format('ddd').toLowerCase()] || [];
  day.some((range) => {
    const fromHour = range.from.split(':')[0];
    const fromMin = range.from.split(':')[1];
    const toHour = range.to.split(':')[0];
    const toMin = range.to.split(':')[1];

    const startingTimeMoment = selectedDateMoment
      .clone()
      .set('hour', Number(fromHour))
      .set('minute', Number(fromMin))
      .startOf('minute');

    selectedDateEnd.set('hour', Number(toHour)).set('minute', Number(toMin));

    if (selectedDateStart.isAfter(selectedDateEnd)) {
      return false;
    }

    selectedDateStart.set('hour', startingTimeMoment.hour()).startOf('hour');
    while (selectedDateStart.isBefore(startingTimeMoment, 'minute')) {
      selectedDateStart.add(minutesInterval, 'minute');
    }

    if (selectedDateMoment.isSame(now, 'day')) {
      while (selectedDateStart.isSameOrBefore(now, 'minute')) {
        selectedDateStart.add(minutesInterval, 'minute');
      }
    }

    return true;
  });

  while (selectedDateStart.isBefore(selectedDateEnd)) {
    if (now.clone().add(minutesInterval, 'minute').isAfter(selectedDateStart)) {
      timeData.push({
        value: now.clone().utc().format(),
        label: toRange
          ? t('timeSelection.asapTimeSelection')
          : t('general.now'),
      });
    } else {
      timeData.push({
        value: selectedDateStart.clone().utc().format(),
        label: toRange
          ? transformToTimeRange(selectedDateStart, timeFormat)
          : `${moment(selectedDateStart).format(timeFormat)}`,
      });
    }

    selectedDateStart.add(minutesInterval, 'minute');
  }

  return timeData;
};

export function getDateDays(days: Array<string>, timezone: string) {
  const LIMIT_DAY_COUNT = 7;
  const today = moment.utc().tz(timezone).startOf('day');

  if (!days.length) {
    return null;
  }

  let dayCount = 0;
  let currentIterationDay = today.clone();

  const daysFormatted: Array<moment.Moment> = [];
  while (dayCount < LIMIT_DAY_COUNT) {
    currentIterationDay = currentIterationDay.add(1, 'day');
    const newDay = currentIterationDay.format('ddd').toLowerCase();
    if (days.includes(newDay)) {
      daysFormatted.push(currentIterationDay.clone());
    }

    dayCount = dayCount + 1;
  }

  return { today, days: daysFormatted };
}

export const isBefore = (time: string, timezoneName: string): boolean => {
  const [hour, minute] = time.split(':');
  const parsedTime = (
    timezoneName === 'UTC' ? dayjs.utc() : dayjs.utc().tz(timezoneName)
  )
    .set('hour', parseInt(hour))
    .set('minute', parseInt(minute));
  return dayjs.utc().isBefore(parsedTime);
};

export const isBetween = (
  timeFrom: string,
  timeTo: string,
  timezoneName: string,
  date?: string,
): boolean => {
  const [hourFrom, minuteFrom] = timeFrom.split(':');
  const [hourTo, minuteTo] = timeTo.split(':');
  const parsedTimeFrom = (
    timezoneName === 'UTC' ? moment().utc() : moment().tz(timezoneName)
  )
    .set('hour', parseInt(hourFrom))
    .set('minute', parseInt(minuteFrom))
    .set('second', 0);
  const parsedTimeTo = (
    timezoneName === 'UTC' ? moment().utc() : moment().tz(timezoneName)
  )
    .set('hour', parseInt(hourTo))
    .set('minute', parseInt(minuteTo))
    .set('second', 0);
  const currentTime = date
    ? moment(date)
        .tz(timezoneName)
        .set('day', parsedTimeFrom.day())
        .set('date', parsedTimeFrom.date())
        .set('year', parsedTimeFrom.year())
    : moment().utc();

  return (
    currentTime.format() === parsedTimeFrom.format() ||
    currentTime.isBetween(parsedTimeFrom, parsedTimeTo, null, '[]')
  );
};
export const millisecondsDifferenceTime = (
  timeTo: string,
  timezoneName: string,
): number => {
  const [hourTo, minuteTo] = timeTo.split(':');
  const parsedTimeTo = (
    timezoneName === 'UTC' ? dayjs.utc() : dayjs.utc().tz(timezoneName)
  )
    .set('hour', parseInt(hourTo))
    .set('minute', parseInt(minuteTo))
    .set('second', 0);
  return parsedTimeTo.diff(dayjs.utc(), 'millisecond');
};

export const toHourFormatDisplay = (
  date: string,
  timezoneName: string,
  timeFormat: string,
): string => dayjs.utc(date).tz(timezoneName).format(timeFormat);

export const isSameDate = (dateOne: string, dateTwo: string): boolean =>
  dayjs(dateOne).isSame(dateTwo);
export const isSameDay = (dayOne, timezoneName: string) =>
  dayjs(dayOne).tz(timezoneName).isSame(dayjs().tz(timezoneName), 'day');
export const isWithinSixtyMinutes = (
  date: string,
  dateCompare = undefined,
): boolean => dayjs(date).diff(dayjs.utc(dateCompare), 'minute') <= 60;
export const isPast = (compareWith: string, date?: string): boolean => {
  return (
    moment.utc(compareWith).isBefore(moment.utc(date), 'minute') ||
    moment.utc(compareWith).isSame(moment.utc(date), 'minute')
  );
};
export const hoursDifference = (dateOne: string, dateTwo: string): number =>
  dayjs.utc(dateOne).diff(dayjs.utc(dateTwo), 'hour');

export const isNextDay = (date: string | Dayjs): boolean => {
  if (!date) {
    return false;
  }

  if (typeof date === 'string') {
    return dayjs.utc(date).isSame(dayjs.utc().add(1, 'day'), 'day');
  }

  return date.isSame(dayjs.utc().add(1, 'day'), 'day');
};

export const isNextDays = (date: string, timezone: string): boolean => {
  if (!date) {
    return false;
  }

  const parsedTime = dayjs.utc(date).tz(timezone);
  return parsedTime.date() !== dayjs.utc().tz(timezone).date();
};

export const getMonths = (): Array<string> => {
  return dayjs
    .monthsShort()
    .map((month) => `${t(`location.month.${month.toLowerCase()}.long`)}`);
};
export const getMonth = (date: Date): number => dayjs.utc(date).get('month');
export const getYear = (date: Date): number => dayjs.utc(date).get('year');

export const isDayToday = (date: string): boolean => dayjs(date).isToday();

export const getDate = (date?: string): string =>
  dayjs(date).isToday()
    ? dayjs(date).format()
    : dayjs.utc(date).hour(0).minute(0).second(0).millisecond(0).format();
export const getMillisecondsToNextMinute = (): number =>
  Math.abs(
    dayjs().diff(
      dayjs().add(1, 'minute').set('second', 5).set('millisecond', 0),
      'millisecond',
    ),
  );
