import { ApiCacheConstants, ApiClient } from 'mycheck-core';

export interface MenuApi {
  fetchMenuWithUrl: (url: string) => Promise<any>;
  fetchOutOfStockItems: (bid: number) => Promise<any>;
}

export const HTTP_MENU_API = 'HTTP_MENU_API';

export class HttpMenuApi implements MenuApi {
  fetchMenuWithUrl = async (url: string) => {
    const { data } = await ApiClient.instance.get<any>(
      url,
      ApiCacheConstants.FETCH_MENU,
    );
    return data;
  };

  fetchOutOfStockItems = async (bid: number) => {
    const { data } = await ApiClient.instance.get(
      '/menus/api/v1/getOutOfStockItems',
      ApiCacheConstants.FETCH_STOCK_ITEMS,
      { params: { bid } },
    );
    return data;
  };
}
