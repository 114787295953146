import React, { useCallback, useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  displayDateTime,
  MyCheckApp,
  useCheckoutStore,
  useFlowType,
  useLocationStore,
  useNavigation,
  useTranslation,
} from 'mycheck-core';

import { PopUp, TranslateText } from '@components';

import styles from './DateTimeTracker.module.scss';

type Props = { handleModalClose: () => void };
export const DateTimeTrackerFutureUpdateModal: React.FC<Props> = ({
  handleModalClose,
}) => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { isRestaurantFlow } = useFlowType();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});

    return {
      titleStyle,
    };
  }, [location.search]);

  const onClose = useCallback(() => {
    handleModalClose();
    navigation.push(isRestaurantFlow ? '/' : '/location');
  }, [window.location.search]);

  const buttonText = (
    <TranslateText
      tKey="general.closedModalPrimaryButton"
      values={{
        at: displayDateTime(
          CheckoutStore.checkoutTime,
          LocationStore.locationTimezoneName,
          LocationStore.localDateFormat,
          LocationStore.localTimeFormat,
          true,
        ),
      }}
    />
  );

  return (
    <PopUp
      onClose={onClose}
      onCancelClick={onClose}
      onClick={handleModalClose}
      testId="date-time-tracker"
      buttonText={buttonText}
      withConfirmButton
    >
      <div className={styles.wrapper}>
        <div className={styles.title} style={config.titleStyle}>
          {t('general.closedModal')}
        </div>
      </div>
    </PopUp>
  );
};
