import React, { FC, PropsWithChildren } from 'react';

import styles from './MobileFixedHeaderWrapper.module.scss';
import { Header, HeaderProps } from 'components/Header/Header';

interface MobileFixedHeaderWrapperProps
  extends HeaderProps,
    PropsWithChildren {}

const MobileFixedHeaderWrapper: FC<MobileFixedHeaderWrapperProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.mobileFixedHeaderWrapper}>
      {
        <>
          <Header {...props}></Header>
          {children}
        </>
      }
    </div>
  );
};

export default MobileFixedHeaderWrapper;
