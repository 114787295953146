import React, { useCallback, useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useFlowType,
  useNavigation,
  useTranslation,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './DateTimeTracker.module.scss';

type Props = {
  handleModalClose: () => void;
};
export const DateTimeTrackerClosedUpdateModal: React.FC<Props> = ({
  handleModalClose,
}) => {
  const { t } = useTranslation();
  const { isRestaurantFlow } = useFlowType();
  const navigation = useNavigation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});

    return {
      titleStyle,
    };
  }, [location.search]);

  const onClose = useCallback(() => {
    handleModalClose();
    navigation.push(isRestaurantFlow ? '/' : '/location');
  }, [window.location.search]);

  return (
    <PopUp
      onClose={onClose}
      onCancelClick={onClose}
      onClick={handleModalClose}
      testId="date-time-tracker"
      withConfirmButton={false}
    >
      <div className={styles.wrapper}>
        <div className={styles.titleClosed} style={config.titleStyle}>
          {t('general.closedModal')}
        </div>
        <div className={styles.body} />
      </div>
    </PopUp>
  );
};
