import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import classnames from 'classnames';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import {
  ErrorCodes,
  HistoryContext,
  MyCheckApp,
  useAuthStore,
  useErrorTrackerHandler,
  useLanguageStore,
  useModalNavigator,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { ClosePage, CustomButton, Overlay } from '@components';

import styles from './RegisterPage.module.scss';
import { RegisterChain } from './screens/RegisterChain/RegisterChain';

let initialValues = {
  country: 'us',
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  privacyCheck: false,
  optinMarketing: '',
  verificationCode: '',
};

export enum RegisterSteps {
  stepOne = 1,
  stepTwo = 2,
  stepThree = 3,
  stepFour = 4,
}

export const RegisterPage: React.FC = () => {
  const [step, setStep] = useState(RegisterSteps.stepOne);
  const [enableValidation, setEnableValidation] = useState(false);
  const AuthStore = useAuthStore();
  const LanguageStore = useLanguageStore();
  const errorHandler = useErrorTrackerHandler();
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const historyContext = useContext(HistoryContext);
  const { closeRegisterPage } = useModalNavigator();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const logo = get(_config, 'home.style.logo', null);

    return {
      logo,
    };
  }, [location.search]);

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';

    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
    };
  }, []);

  const handleStepBack = useCallback(() => setStep(step - 1), [step]);
  const handleBackClick = useCallback(() => {
    AuthStore.logout();
    setStep(RegisterSteps.stepOne);
    closeRegisterPage();
  }, []);

  const handleResendCodeClick = async () => {
    try {
      await AuthStore.sendPhoneNumber(AuthStore.phone);
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err);
      }
    }
  };

  const getValidateSchema = () => {
    switch (step) {
      case RegisterSteps.stepOne:
        return yup.object().shape({
          phone: yup.string().min(10).required(),
        });
      case RegisterSteps.stepTwo:
        return yup.object().shape({
          verificationCode: yup
            .string()
            .trim()
            .length(6, ErrorCodes.VERIFICATION_ERROR_HIDDEN.toString())
            .required(ErrorCodes.VERIFICATION_ERROR_HIDDEN.toString()),
        });
      case RegisterSteps.stepThree:
        return yup.object().shape({
          email: yup
            .string()
            .email(t('profile.emailValidationError.invalidEmail'))
            .required(),
        });
      case RegisterSteps.stepFour:
        return yup.object().shape({
          email: yup
            .string()
            .email(t('profile.emailValidationError.invalidEmail'))
            .required(t('register.emailValidation')),
          firstName: yup
            .string()
            .strict(true)
            .trim()
            .matches(/^[a-zA-Z ]*$/, t('register.firstNameError.invalidName'))
            .required(t('register.firstNameError.required')),
          lastName: yup
            .string()
            .strict(true)
            .trim()
            .matches(/^[a-zA-Z ]*$/, t('register.lastNameError.invalidName'))
            .required(t('register.lastNameError.required')),
          privacyCheck: yup.boolean().oneOf([true]).required(),
          optinMarketing: yup.string().required(),
        });
      default:
        return;
    }
  };

  const onSubmit = async (
    { phone, email, verificationCode, firstName, lastName, optinMarketing },
    { setFieldError, resetForm },
  ) => {
    switch (step) {
      case RegisterSteps.stepOne:
        try {
          await AuthStore.sendPhoneNumber(phone);
          AuthStore.setPhone(phone);
          setEnableValidation(false);
          setStep(RegisterSteps.stepTwo);
        } catch (error) {
          errorHandler.onError(error);
        }
        break;

      case RegisterSteps.stepTwo:
        try {
          await AuthStore.sendVerificationCode(verificationCode);
          const status = await AuthStore.sendVerifyUser(true, {
            firstName,
            lastName,
            language: LanguageStore.selectedValue,
          });
          if (status === 'OK') {
            await AuthStore.getMetadata();
            await AuthStore.setMetadata({
              language: LanguageStore.selectedValue,
              dateOfBirth: AuthStore.dateOfBirth,
            });
            setEnableValidation(false);
            setStep(RegisterSteps.stepOne);
            closeRegisterPage();
            if (historyContext.callback) {
              historyContext.callback();
            }
          }
        } catch (error) {
          const err = error as Error & { code: ErrorCodes };
          if (
            err.code === ErrorCodes.FIRST_EMAIL_REDIRECT_CODE ||
            err.code === ErrorCodes.SECOND_EMAIL_REDIRECT_CODE
          ) {
            setEnableValidation(false);
            setStep(RegisterSteps.stepThree);
          } else {
            setFieldError('verificationCode', err.message);
          }
        }
        break;

      case RegisterSteps.stepThree:
        const response = await AuthStore.sendVerifyEmail(email);
        if (!response.state.exists) {
          try {
            AuthStore.setEmail(email);
            resetForm({ email: 'test' });
            initialValues = { ...initialValues, email: AuthStore.email };
            setStep(RegisterSteps.stepFour);
          } catch (error) {
            errorHandler.onError(error);
          }
        }
        break;

      case RegisterSteps.stepFour:
        try {
          await AuthStore.sendVerifyUser(false, {
            firstName,
            lastName,
            language: LanguageStore.selectedValue,
          });
          AuthStore.setUserFirstName(firstName);
          AuthStore.setUserLastName(lastName);
          await AuthStore.setMetadata({
            language: LanguageStore.selectedValue,
            optinMarketing,
          });
          await AuthStore.getMetadata();
          setEnableValidation(false);
          setStep(RegisterSteps.stepOne);
          closeRegisterPage();
          if (historyContext.callback) {
            historyContext.callback();
          }
        } catch (error) {
          errorHandler.onError(error);
        }
        break;
      default:
        break;
    }
  };

  const checkIsSubmitButtonDisabled = ({ dirty, isValid }) =>
    !dirty || !isValid;

  const renderBody = (formikProps) => (
    <div className={styles.root}>
      <ClosePage onClose={handleBackClick} testId="register-page" />
      <div className={styles.registerPage}>
        <img src={config.logo} alt={'No img'} width={config.logo.width} />
        <form
          onSubmit={(event: any) => {
            setEnableValidation(true);
            event.preventDefault();
            formikProps.handleSubmit();
          }}
          className={classnames(
            styles.registerPageForm,
            step === RegisterSteps.stepFour && styles.registerPageFormExtended,
          )}
          noValidate
        >
          <RegisterChain
            step={step}
            formikProps={formikProps}
            handleStepBack={handleStepBack}
            handleResendCodeClick={handleResendCodeClick}
          />
        </form>
      </div>
      {step === RegisterSteps.stepFour && (
        <CustomButton
          text={t('register.agreeButton')}
          type="submit"
          className={styles.rootCustomButton}
          testId="agree-button"
          handleButtonClick={formikProps.submitForm}
          disabled={checkIsSubmitButtonDisabled(formikProps)}
        />
      )}
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidateSchema()}
      onSubmit={onSubmit}
      validateOnChange={enableValidation}
      validateOnBlur={enableValidation}
      enableReinitialize
    >
      {(formikProps) =>
        isLg ? (
          <Overlay
            height={712}
            onClickOutside={handleBackClick}
            testId="register-page"
          >
            {renderBody(formikProps)}
          </Overlay>
        ) : (
          renderBody(formikProps)
        )
      }
    </Formik>
  );
};
