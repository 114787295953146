import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useLocationStore, useTranslation } from 'mycheck-core';

import { PrimaryButton } from '@components';

import { IFormikValues } from '../../types/FormikValues';
import { SelectLocation } from '../SelectLocation/SelectLocation';

import styles from './HomePageForm.module.scss';
import { useGetHomeList } from './useHomePageFormData';

export const HotelFlow: React.FC = observer(() => {
  const LocationStore = useLocationStore();
  const homeList = useGetHomeList();
  const formikProps = useFormikContext<IFormikValues>();
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const locationLimit = get(_config, 'home.settings.locationLimit', 5);

    return {
      locationLimit,
    };
  }, [location.search]);

  return (
    <>
      <div className={styles.homePageFormWrapper}>
        <div className={styles.homePageFormWrapperHotelSelect}>
          <SelectLocation
            data={homeList}
            formikProps={formikProps}
            limit={config.locationLimit}
          />
        </div>
      </div>
      <PrimaryButton
        className={styles.homePageButtonOrder}
        testId="home-submit"
      >
        {t(`home.${LocationStore.viewMode ? 'buttonViewMode' : 'button'}`)}
      </PrimaryButton>
    </>
  );
});

HotelFlow.displayName = 'HotelFlow';
