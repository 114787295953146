import React, { type CSSProperties, type PropsWithChildren } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { CustomIcon } from '@components';

import { IconSvg } from '../Icon/helpers';

import styles from './OptionSelect.module.scss';

interface OptionSelectProps extends PropsWithChildren {
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
  leftIconName?: IconSvg;
}

export const OptionSelect: React.FC<OptionSelectProps> = observer(
  ({ className, onClick, style, children, leftIconName = null }) => {
    return (
      <div className={classnames(styles.root, className)}>
        <div className={styles.contentBox} onClick={onClick} style={style}>
          <div className={styles.contentBoxType}>
            {leftIconName && (
              <CustomIcon
                name={leftIconName}
                className={styles.contentBoxIconCustom}
              />
            )}
            {children}
          </div>
          <div className={styles.contentBoxIcon}>
            <CustomIcon
              name="arrowDown"
              customStylePath="general.arrowDown"
              className={styles.contentBoxIconArrow}
            />
          </div>
        </div>
      </div>
    );
  },
);

OptionSelect.displayName = 'OptionSelect';
