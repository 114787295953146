import React from 'react';

import { CustomIcon } from '../../components/Icon/CustomIcon';

import styles from './DataGrid.module.scss';

export interface DataGridLoaderProps {
  isLoading: boolean;
}

const DataGridLoader: React.FC<DataGridLoaderProps> = ({ isLoading }) => {
  return isLoading ? (
    <div className={styles.dataGridContainerLoader}>
      <CustomIcon name="loader" />
    </div>
  ) : null;
};

export default DataGridLoader;
