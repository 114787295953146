import React, { forwardRef, useMemo } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useLocationStore } from 'mycheck-core';

import styles from './MenuCategory.module.scss';
import { transformCategoryHashUrl } from 'core/core/helpers/transformCategoryHashUrl';

type MenuCategoryHeaderType = {
  name: string;
  subcategory?: boolean;
  id?: string;
};

export const MenuCategoryHeader = forwardRef<
  HTMLDivElement,
  MenuCategoryHeaderType
>(({ name, subcategory, id = '' }, ref) => {
  const location = useLocation();
  const locationStore = useLocationStore();
  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const { primary } = get(_config, 'palette', {});

    return {
      primary,
    };
  }, [location.search]);

  return (
    <div
      ref={ref}
      id={id ? transformCategoryHashUrl(id, 'id') : ''}
      data-test-id="menu-category-header"
      className={classnames(styles.category, {
        [styles.categorySubcategory]: subcategory,
        [styles.categoryIsNative]: locationStore.isNative,
      })}
      style={!subcategory ? { color: config.primary } : {}}
    >
      {name}
    </div>
  );
});

MenuCategoryHeader.displayName = 'MenuCategoryHeader';
