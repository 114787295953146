import React, { PropsWithChildren } from 'react';

import styles from './DataGrid.module.scss';

export interface DataGridRowProps extends PropsWithChildren {
  onRowClick: () => void;
}

const DataGridRow: React.FC<DataGridRowProps> = ({ children, onRowClick }) => {
  return (
    <div
      data-test-id="data-grid-row"
      className={styles.dataGridContainerRow}
      onClick={onRowClick}
    >
      {children}
    </div>
  );
};

export default DataGridRow;
