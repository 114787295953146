import React, { useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  displayDateTime,
  MyCheckApp,
  useCheckoutStore,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { PopUp, TranslateText } from '@components';

import styles from './DateTimeTracker.module.scss';

type Props = { handleModalClose: () => void };
export const DateTimeTrackerUpdateModal: React.FC<Props> = ({
  handleModalClose,
}) => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});
    const bodyStyle = get(_config, 'general.popup.mainText', {});

    return {
      titleStyle,
      bodyStyle,
    };
  }, [location.search]);

  return (
    <PopUp onClose={handleModalClose} testId="date-time-tracker">
      <div className={styles.wrapper}>
        <div className={styles.title} style={config.titleStyle}>
          {t('general.dateTimeModalTitle')}
        </div>
        <div style={config.bodyStyle} className={styles.body}>
          <TranslateText
            tKey=""
            values={{
              at: displayDateTime(
                CheckoutStore.checkoutTime,
                LocationStore.locationTimezoneName,
                LocationStore.localDateFormat,
                LocationStore.localTimeFormat,
                true,
              ),
            }}
          />
        </div>
      </div>
    </PopUp>
  );
};
