import {
  MyCheckPlugin,
  MyCheckApp,
  StoresConstants,
  ApiClient,
} from 'mycheck-core';

import i18n, { defaultTranslation } from '../../core/core/service/i18n';

import { HttpLanguageApi } from './LanguageApi';
import { LanguageStore } from './LanguageStore';

export { LanguageStore };

export const LANGUAGE_PLUGIN_KEY = 'LANGUAGE_PLUGIN_KEY';
export const LOCAL_STORAGE_KEY = 'CURRENT_TRANSLATION';

export interface FontsObject {
  [key: string]: string;
}

interface TranslationPlugin {
  fetchTranslation: (url: string) => Promise<Record<string, unknown>>;
}

export class LanguagePlugin implements MyCheckPlugin, TranslationPlugin {
  public static get instance() {
    return MyCheckApp.instance.getPlugin<LanguagePlugin>(LANGUAGE_PLUGIN_KEY);
  }

  public readonly name = LANGUAGE_PLUGIN_KEY;
  public readonly version = '0.0.1';

  public async fetchTranslation(url: string): Promise<Record<string, unknown>> {
    try {
      const { data } = await ApiClient.instance.get<any>(url, null, {
        transformRequest: [
          (ReqData, headers) => {
            delete (headers.common as unknown as { Authorization: unknown })
              .Authorization;
            return ReqData;
          },
        ],
      });

      this.setCurrentTranslation(data);
      return data;
    } catch (err) {
      this.setCurrentTranslation(defaultTranslation);
      return defaultTranslation;
    }
  }

  public async init(): Promise<void> {
    i18n.addResourceBundle(
      'default',
      'translation',
      this.getCurrentTranslation(),
      true,
      true,
    );
    await i18n.changeLanguage('default');
  }

  private setCurrentTranslation(value): void {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
  }

  public getCurrentTranslation(): string {
    try {
      return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    } catch (err) {
      return null;
    }
  }

  getStores = () => {
    const locationStore = new LanguageStore(new HttpLanguageApi());
    return [{ [StoresConstants.LanguageStore]: locationStore }];
  };

  afterHydrate = (): void => {
    return null;
  };
}
