import React, { forwardRef } from 'react';

import { observer } from 'mobx-react';

import { useCheckoutStore } from 'mycheck-core';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';
import { MenuItemType } from '../../types/MenuItemType';
import styles from '../MenuCategory/MenuCategory.module.scss';
import { MenuCategoryHeader } from '../MenuCategory/MenuCategoryHeader';
import { MenuItem } from '../MenuItem/MenuItem';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

interface MenuItemProps {
  category: MenuCategoryType & MenuSubcategory;
}
export const MenuItemContent = observer(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, MenuItemProps>(({ category }, ref) => {
    const getTranslationForItem = useGetTranslationForMenuItem();
    const CheckoutStore = useCheckoutStore();

    return (
      <div>
        <MenuCategoryHeader
          ref={ref}
          id={category.name}
          name={getTranslationForItem(category.pos_unique_id, category).name}
          subcategory={category.type === MenuItemType.SUBCATEGORY}
        />
        {!!category.items?.length && (
          <div className={styles.categoryWrapper}>
            <div className={styles.categoryProducts}>
              {category.items?.map((item) => (
                <MenuItem
                  key={item.id}
                  item={item}
                  category={category}
                  counter={CheckoutStore.getOrderItemNumber(item.id)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }),
);

MenuItemContent.displayName = 'MenuItemContent';
