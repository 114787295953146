import React, { useCallback, useContext } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import {
  GaService,
  useMenuStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { PriceFormatter } from '@components';

import { Badge } from '../../../../components/Badge/Badge';
import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';
import { AllergensGroup } from '../AllergensGroup/AllergensGroup';
import styles from '../MenuCategory/MenuCategory.module.scss';
import { MenuPageContext } from 'plugins/MenuPlugin/pages/MenuPage/common/MenuPageContext';
import {
  MenuCategoryType,
  MenuSubcategory,
  MenuItem as MenuItemGenericType,
} from 'types/GenericTypes';

type MenuItemType = {
  item: MenuItemGenericType;
  category: MenuCategoryType & MenuSubcategory;
  counter?: number;
};

export const MenuItem: React.FC<MenuItemType> = observer(
  ({ item, counter }) => {
    const { handleProductClick, handleAllergensClick } =
      useContext(MenuPageContext);
    const menuStore = useMenuStore();
    const itemFromMenu =
      menuStore.items.find((e) => e.id === item.id) ||
      ({} as { stock: unknown });
    const { isLg } = useWindowSize();

    const onClick = useCallback(() => {
      if (!!itemFromMenu.stock) {
        GaService.instance.sendEvent({
          category: 'Ordering - Menu',
          action: 'Click on item',
          label: item.name,
        });
        handleProductClick(item);
      }
    }, []);

    const getTranslationForItem = useGetTranslationForMenuItem();
    const { t } = useTranslation();

    if (item.is_hidden || !itemFromMenu) {
      return null;
    }

    let name = getTranslationForItem(item.pos_unique_id, item).name;
    let description = !!item.description
      ? getTranslationForItem(item.pos_unique_id, item).description
      : '';

    if (item.image && name.length > 80) {
      name = name.substring(0, isLg ? 90 : 78) + '...';
      description = '';
    }
    if (!item.image && name.length > 120) {
      name = name.substring(0, isLg ? 140 : 130) + '...';
      description = '';
    }

    return (
      <div
        data-test-id="menu-item"
        onClick={onClick}
        className={classnames(
          styles.categoryProductsItem,
          !itemFromMenu.stock && styles.unclickable,
        )}
      >
        {!!counter && (
          <div
            className={classnames(
              styles.badge,
              item.image ? styles.badgeWithImage : styles.badgeNoImage,
            )}
          >
            <Badge>{counter}</Badge>
          </div>
        )}
        {item.image && (
          <div
            className={classnames(
              styles.categoryProductsItemImage,
              !itemFromMenu.stock && styles.outOfStock,
            )}
            style={{ backgroundImage: `url(${item.image})` }}
          />
        )}
        <div
          className={classnames(
            styles.categoryProductsItemInfo,
            !itemFromMenu.stock && styles.outOfStock,
          )}
          style={!item.image && !isLg ? { minHeight: 'auto' } : {}}
        >
          <div
            className={styles.categoryProductsItemInfoName}
            style={!item.image ? { width: '100%' } : {}}
          >
            {name}
            {item.kcal > 0 && (
              <span className={styles.categoryProductsItemInfoCalories}>
                {item.kcal}
                {t('menu.calories.name')}
              </span>
            )}
            <div
              className={styles.categoryProductsItemInfoDesc}
              style={!item.image ? { width: '100%' } : {}}
            >
              {description}
            </div>
          </div>
        </div>
        <div className={styles.categoryProductsItemFooter}>
          {item.allergens?.length > 0 && (
            <AllergensGroup
              handleAllergensClick={handleAllergensClick}
              allergens={item.allergens}
              numberOfVisibleItems={5}
            />
          )}
          {itemFromMenu.stock && (
            <div
              className={styles.categoryProductsItemFooterPrice}
              style={item.allergens?.length === 0 ? { width: '100%' } : {}}
            >
              <PriceFormatter value={item.price} />
            </div>
          )}
          {!itemFromMenu.stock && (
            <div
              className={classnames(
                styles.categoryProductsItemFooterOutOfStock,
              )}
              style={item.allergens?.length === 0 ? { width: '100%' } : {}}
            >
              {t('menu.outOfStock')}
            </div>
          )}
        </div>
      </div>
    );
  },
);

MenuItem.displayName = 'MenuItem';
