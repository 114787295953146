import React, { useMemo, useState, type PropsWithChildren } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { PopUp } from '@components';

import { NotAcceptingOrdersContext } from './NotAcceptingOrdersContext';
import styles from './NotAcceptingOrdersModal.module.scss';

export const NotAcceptingOrdersModal: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});
    const bodyStyle = get(_config, 'general.popup.mainText', {});

    return {
      titleStyle,
      bodyStyle,
    };
  }, [location.search]);

  const open = () => {
    if (
      location.pathname.indexOf('/menu') > -1 ||
      location.pathname === '/menu/checkout'
    ) {
      setIsOpen(true);
    }
  };
  const handleModalClose = () => setIsOpen(false);

  return (
    <NotAcceptingOrdersContext.Provider value={{ isOpen, open }}>
      {children}
      {isOpen && (
        <PopUp onClose={handleModalClose} testId="not-accepting-orders">
          <div className={styles.wrapper}>
            <div className={styles.title} style={config.titleStyle}>
              {t('menu.locationCloseMessage1')}
            </div>
            <div style={config.bodyStyle}>
              {t('menu.locationCloseMessage2')}
            </div>
          </div>
        </PopUp>
      )}
    </NotAcceptingOrdersContext.Provider>
  );
};
