import React from 'react';

import { useNavigation, useParams, useWindowSize } from 'mycheck-core';

import { MoreInfo } from '../MoreInfo/MoreInfo';

export const MoreInfoModal: React.FC = () => {
  const navigation = useNavigation();
  const { id } = useParams<{ id: string }>();
  const { isLg } = useWindowSize();

  if (isLg) {
    navigation.push('/location');
  }

  return <MoreInfo restaurantId={+id} />;
};
