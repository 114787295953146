import React, { useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useWindowSize } from 'mycheck-core';

import { CustomIcon, Text } from '@components';

import styles from './FooterDesktop.module.scss';

type Props = {
  isHomeScreen?: boolean;
};

export const FooterDesktop: React.FC<Props> = ({ isHomeScreen = false }) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const homeStyle = get(_config, 'home.style.footer', {});
    const generalStyle = get(_config, 'general.footer', {});
    const restaurantsFooterTextStyle = get(_config, 'restaurants.footer', {});

    return {
      homeStyle,
      generalStyle,
      restaurantsFooterTextStyle,
    };
  }, [location.search]);

  const homeStylePath = 'home.icons';

  const { isLg } = useWindowSize();

  if (!isLg) {
    return null;
  }

  return (
    <div
      data-test-id="page-footer"
      className={styles.root}
      style={isHomeScreen ? config.homeStyle : config.generalStyle}
    >
      <div className={styles.item}>
        <CustomIcon
          customStylePath={isHomeScreen && `${homeStylePath}.step1`}
          name="step1"
          className={styles.icon}
        />
        <div style={!isHomeScreen ? config.restaurantsFooterTextStyle : {}}>
          <Text value="home.footerFirstElement" />
        </div>
        <CustomIcon
          customStylePath={isHomeScreen && `${homeStylePath}.arrow`}
          name="arrow"
          className={styles.arrow}
        />
      </div>
      <div className={styles.item}>
        <CustomIcon
          customStylePath={isHomeScreen && `${homeStylePath}.step2`}
          name="step2"
          className={styles.icon}
        />
        <div style={!isHomeScreen ? config.restaurantsFooterTextStyle : {}}>
          <Text value="home.footerSecondElement" />
        </div>
        <CustomIcon
          customStylePath={isHomeScreen && `${homeStylePath}.arrow`}
          name="arrow"
          className={styles.arrow}
        />
      </div>
      <div className={styles.item}>
        <CustomIcon
          customStylePath={isHomeScreen && `${homeStylePath}.step3`}
          name="step3"
          className={styles.icon}
        />
        <div style={!isHomeScreen ? config.restaurantsFooterTextStyle : {}}>
          <Text value="home.footerThirdElement" />
        </div>
      </div>
    </div>
  );
};
