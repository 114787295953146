import React, { useEffect } from 'react';

import classnames from 'classnames';

import {
  useLocationStore,
  useTranslation,
  useWalletService,
  useWindowSize,
} from 'mycheck-core';

import { Icon, Overlay } from '@components';

import { Choose } from './Choose/Choose';
import styles from './PaymentsMethod.module.scss';
import { Wallet } from './Wallet/Wallet';
import { CardSummary } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

interface Props {
  handleOpen: () => void;
  onChoose?: () => void;
  type?: 'CHOOSE' | 'WALLET';
  initCheckout?: () => void;
  summary?: Partial<CardSummary>;
}

export const PaymentsMethod: React.FC<Props> = ({
  handleOpen,
  onChoose,
  type = 'CHOOSE',
  initCheckout,
  summary,
}) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const LocationStore = useLocationStore();
  const walletService = useWalletService();

  const onClose = async () => {
    document.body.style.removeProperty('overscroll-behavior-y');
    document.body.style.removeProperty('touch-action');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');

    if (type === 'WALLET') {
      await walletService.destroy();
    }
    handleOpen();
  };

  const onClickOutside = () => {
    setTimeout(() => {
      onClose();
    }, 500);
  };

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
    };
  }, []);

  const getTitle = () => {
    switch (type) {
      case 'CHOOSE':
        return (
          <>
            <div className={styles.paymentsMethodContainerHeaderChooseTitle}>
              {t('checkout.paymentMethodLabel')}
            </div>
            <div
              className={classnames(
                styles.paymentsMethodContainerHeaderChooseTitle,
                styles.paymentsMethodContainerHeaderChooseTitleSubtitle,
              )}
            >
              {t('checkout.paymentMethod.subtitle')}
            </div>
          </>
        );
      case 'WALLET':
        return (
          <div className={styles.paymentsMethodContainerHeaderTitle}>
            {t('checkout.paymentMethod.cardPayment')}
          </div>
        );
    }
  };

  const getHeight = (): number => {
    switch (type) {
      case 'CHOOSE':
        return LocationStore.chargeOptions.length < 3 ? 267 : 331;
      case 'WALLET':
        return 515;
    }
  };

  const getBody = () => {
    switch (type) {
      case 'CHOOSE':
        return <Choose onClose={onClose} onChoose={onChoose} />;
      case 'WALLET':
        return <Wallet summary={summary} initCheckout={initCheckout} />;
    }
  };

  const getHeader = () => {
    return (
      <div className={styles.paymentsMethodContainerHeader}>
        {getTitle()}
        <div
          className={classnames(
            styles.paymentsMethodContainerHeaderExit,
            type === 'WALLET'
              ? styles.paymentsMethodContainerHeaderExitWallet
              : '',
          )}
          onClick={onClose}
        >
          <Icon name="x" />
        </div>
      </div>
    );
  };

  const renderBody = () => (
    <>
      {getHeader()}
      {getBody()}
    </>
  );

  if (isLg) {
    return (
      <Overlay
        width={type === 'CHOOSE' ? 358 : 436}
        height={
          type === 'CHOOSE'
            ? isLg && LocationStore.chargeOptions.length < 3
              ? 267
              : 312
            : 550
        }
        className={classnames(
          styles.paymentsMethodContainer,
          type === 'WALLET' ? styles.paymentsMethodContainerWallet : '',
        )}
        onClickOutside={onClickOutside}
        testId={type === 'CHOOSE' ? 'choose' : 'wallet'}
      >
        {renderBody()}
      </Overlay>
    );
  }

  return (
    <Overlay
      width={'100%'}
      className={styles.paymentsMethodContainer}
      onClickOutside={onClickOutside}
      height={getHeight()}
      testId={type === 'CHOOSE' ? 'choose' : 'wallet'}
    >
      {renderBody()}
    </Overlay>
  );
};
