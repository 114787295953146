import React from 'react';

import classnames from 'classnames';

import { useLocationStore, useMenuStore, useTranslation } from 'mycheck-core';

import { CustomIcon } from '../../../../components/Icon/CustomIcon';

import styles from './KcalLAdultLabel.module.scss';

interface Props {
  className?: string;
}

export const KcalLAdultLabel: React.FC<Props> = ({ className }) => {
  const { t } = useTranslation();
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();
  const showCalories =
    LocationStore.selectedExperience?.settings?.ui?.menu?.show_calories ??
    false;

  return (
    <>
      {!(MenuStore.isFetching || LocationStore.isFetching) && showCalories && (
        <div className={classnames(styles.kcalAdultLabelContainer, className)}>
          <div className={styles.icon}>
            <CustomIcon name="info" style={{ color: '#333333' }} />
          </div>
          <span className={styles.text}>{t('menu.calories.statement')}</span>
        </div>
      )}
    </>
  );
};
