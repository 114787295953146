import React, { useEffect, useMemo } from 'react';

import { FormikProps } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useAuthStore, useTranslation } from 'mycheck-core';

import { CustomPhoneInput, Text } from '@components';

import { NextButton } from '../../../../components/NextButton/NextButton';

import styles from './PhoneNumberScreen.module.scss';
import { RegisterFormikValues } from 'plugins/AuthPlugin/types/FormikValuesType';

interface Props {
  formikProps: FormikProps<RegisterFormikValues>;
  handleReCaptchav3Verify: () => void;
}

export const PhoneNumberScreen: React.FC<Props> = observer(
  ({ formikProps, handleReCaptchav3Verify }) => {
    const AuthStore = useAuthStore();
    const { t } = useTranslation();
    const location = useLocation();

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();
      const defaultCountryCode = get(
        _config,
        'settings.countryCode',
        '',
      ).toLowerCase();

      return {
        defaultCountryCode,
      };
    }, [location.search]);

    const fetchCountryCodeFromIp = () => {
      AuthStore.getCountryCodeFromIp(config.defaultCountryCode);
    };

    useEffect(() => {
      fetchCountryCodeFromIp();
    }, [fetchCountryCodeFromIp]);

    useEffect(() => {
      formikProps.setFieldValue(
        'country',
        AuthStore.countryCodeFromLatLng || config.defaultCountryCode,
      );
    }, [AuthStore.countryCodeFromLatLng]);

    const componentStyle = () => {
      const description = get(config, 'register.text1', {});
      return { description };
    };

    const handleNextClick = async () => {
      await handleReCaptchav3Verify();
      formikProps.submitForm();
    };

    return (
      <div className={styles.phoneScreen} data-test-id="phone-screen">
        <div
          className={styles.phoneScreenDescription}
          style={componentStyle().description}
        >
          <Text value="register.phoneNumberLabel" />
        </div>
        <CustomPhoneInput
          testId="phone-number-screen"
          formikProps={formikProps}
          placeholder={t('profile.phonePlaceholder')}
        >
          <NextButton onClick={handleNextClick} />
        </CustomPhoneInput>
      </div>
    );
  },
);

PhoneNumberScreen.displayName = 'PhoneNumberScreen';
