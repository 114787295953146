import React, {
  useMemo,
  useEffect,
  useRef,
  useState,
  type CSSProperties,
} from 'react';

import dayjs from 'dayjs';
import { Formik, FormikHelpers } from 'formik';
import { isValidPhoneNumber } from 'libphonenumber-js';
import get from 'lodash/get';
import round from 'lodash/round';
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as yup from 'yup';
import { StringSchema } from 'yup';

import {
  GaService,
  getDateLabel,
  isPast,
  isSameDay,
  MyCheckApp,
  PaymentConstants,
  startCartListening,
  startCartListeningRejected,
  startOrderListening,
  toHourFormatDisplay,
  toUTCDateFormat,
  useAuthStore,
  useCheckoutStore,
  useDateTimeTrackerHandler,
  useErrorTrackerHandler,
  useGoBackToMenu,
  useLanguageStore,
  useLocationStore,
  useMenuTrackerHandler,
  useModalNavigator,
  useNavigation,
  useNearestTime,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import {
  Background,
  CustomIcon,
  Header,
  Loader,
  Overlay,
  TranslateText,
} from '@components';

import { CheckoutForm } from '../../components/CheckoutForm/CheckoutForm';
import { GuestSelectionPopup } from '../../components/GuestSelection/GuestSelectionPopup';
import { PaymentsMethod } from '../../components/PaymentsMethod/PaymentsMethod';
import { TipStringValues, TipType } from '../../Constants';

import styles from './CheckoutPage.module.scss';
import {
  IUICheckoutSettings,
  IUIFieldSettings,
} from 'plugins/LocationPlugin/types/LocationTypes';
import {
  CardSummary,
  Coupon,
  Tax,
} from 'plugins/CheckoutPlugin/types/CheckoutTypes';
import { IFormikValues } from 'plugins/CheckoutPlugin/types/IFormikValues';
import { MODAL_HEADER_OFFSET } from 'plugins/MenuPlugin/pages/MenuPage/common/constants';

export const CheckoutPage: React.FC = () => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const navigation = useNavigation();
  const menuTracker = useMenuTrackerHandler();
  const errorHandler = useErrorTrackerHandler();
  const { confirmSlot } = useDateTimeTrackerHandler();
  const { nearestTime } = useNearestTime();
  const { openRegisterPage } = useModalNavigator();
  const { goBackToMenu } = useGoBackToMenu(true);
  const navigationType = useNavigationType();

  const [localPendingCalculations, setLocalPendingCalculations] =
    useState(true);
  const [isCheckoutFetching, setIsCheckoutFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summaryObject, setSummaryObject] = useState<Partial<CardSummary>>({
    subtotal: 0,
    total: 0,
  });
  const [taxesArray, setTaxesArray] = useState([]);
  const [guestOptions, setGuestOptions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [enableValidation, setEnableValidation] = useState(false);
  const isMounted = useRef(true);
  const refFirebase = useRef<() => Promise<void>>();
  const refFirebaseReject = useRef<() => Promise<void>>();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const registerTextStyle = get(_config, 'general.text', {});
    const linkStyle = get(_config, 'general.link', {});
    const primary = get(_config, 'palette.primary', {});

    return {
      registerTextStyle,
      linkStyle,
      primary,
      supportMembers,
    };
  }, [location.search]);

  const [paymentsMethodOpen, setPaymentsMethodOpen] = useState(false);

  const handleSignInClick = () => openRegisterPage();
  const handleErrorGoBack = () => goBackToMenu();
  const goToConfirm = () => {
    if (!isLg) {
      document.getElementById('checkoutPageId').style.height =
        'calc(100vh - 100px)';
    }

    navigation.push('/menu/confirm');
  };

  const onFirebaseCartUpdate = (value: any) => {
    if (value) {
      setTaxesArray(value.taxes || []);
      setSummaryObject({
        ...value.summery,
        service_charges: value.service_charges,
      });
      setLocalPendingCalculations(false);
    }
  };

  const getIsFieldVisible = (fieldSettings: IUIFieldSettings): boolean => {
    if (!fieldSettings) {
      return false;
    }

    return fieldSettings.show_with_payment_type &&
      fieldSettings.show_with_payment_type.length
      ? fieldSettings.show_with_payment_type.some(
          (paymentType) => paymentType === CheckoutStore.paymentMethod,
        )
      : true;
  };

  const onFirebaseCartRejected = (value: any) => {
    errorHandler.onError(
      { message: value.reason, items_not_in_stock: value.out_of_stock_items },
      () => {
        CheckoutStore.deleteItemAfterError(value.out_of_stock_items);
        handleErrorGoBack();
      },
    );
  };

  const initCheckout = async () => {
    const accessToken = AuthStore.accessToken;
    const experienceId = LocationStore.selectedExperience.id;
    const businessId = LocationStore.selectedRestaurantId;
    const coupon: Coupon = CheckoutStore.getCoupon();

    const { cartId, summary, taxes, hash, isPendingCalculations } =
      await CheckoutStore.updateOrCreateCheckout({
        accessToken,
        businessId,
        experienceId,
        totalCalculation: true,
        couponCode:
          coupon &&
          getIsFieldVisible(LocationStore.uiCheckoutSettings['coupons'])
            ? coupon.code
            : undefined,
      });

    if (isMounted.current) {
      setTaxesArray(taxes || []);
      setSummaryObject(summary);
      setLocalPendingCalculations(isPendingCalculations);
      if (isPendingCalculations) {
        if (refFirebase.current) {
          refFirebase.current();
        }
        if (refFirebaseReject.current) {
          refFirebaseReject.current();
        }
        refFirebase.current = await startCartListening(
          cartId,
          hash,
          onFirebaseCartUpdate,
        );
        refFirebaseReject.current = await startCartListeningRejected(
          cartId,
          hash,
          onFirebaseCartRejected,
        );
      }
    }
  };

  const onMount = async () => {
    if (!config.supportMembers && AuthStore.isGuest && !isLg) {
      AuthStore.logInGuest(LanguageStore.selectedValue, errorHandler.onError);
    }

    try {
      await initCheckout();
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err, handleErrorGoBack);
      }
    }
  };

  const getValidationSettingsForField = (
    property: keyof IUICheckoutSettings,
  ) => {
    const fieldSettings: IUIFieldSettings | null = LocationStore
      .uiCheckoutSettings[property] as IUIFieldSettings;
    if (!fieldSettings) {
      return null;
    }

    let validationSchema =
      fieldSettings.type === 'number' ? yup.number() : yup.string().trim();

    if (property === 'guest_count') {
      validationSchema = yup.string();
    }

    if (fieldSettings.required && getIsFieldVisible(fieldSettings)) {
      validationSchema = validationSchema.required();
    }

    if (fieldSettings.type === 'email') {
      validationSchema = (validationSchema as StringSchema).email(
        'profile.emailValidationError.invalidEmail',
      );
    }

    if (fieldSettings.min) {
      validationSchema = validationSchema.min(fieldSettings.min);
    }

    if (fieldSettings.max) {
      validationSchema = validationSchema.max(fieldSettings.max);
    }

    if (property === 'phone') {
      validationSchema = (validationSchema as yup.NumberSchema).test({
        name: 'phoneNumber',
        message: 'Error',
        // DO NOT CHANGE to arrow function, arrow function binds context so below code will NOT work
        test: function (value) {
          if (fieldSettings.required && getIsFieldVisible(fieldSettings)) {
            return isValidPhoneNumber('+' + value, this.parent.country);
          }

          if (this.parent.dialCode === value || !value) {
            return true;
          }

          return isValidPhoneNumber('+' + value, this.parent.country);
        },
      });

      return validationSchema;
    }

    if (property === 'room_number') {
      validationSchema = (validationSchema as StringSchema).test({
        name: 'room_number',
        message: 'Error',
        // DO NOT CHANGE to arrow function, arrow function binds context so below code will NOT work
        test: function (value: string) {
          if (
            (fieldSettings.required && getIsFieldVisible(fieldSettings)) ||
            value?.length > 0
          ) {
            return /\d/.test(value);
          }

          return true;
        },
      });

      return validationSchema;
    }
    return validationSchema;
  };

  const getValidationSchema = () =>
    yup.object().shape({
      firstName: getValidationSettingsForField('first_name'),
      lastName: getValidationSettingsForField('last_name'),
      phone: getValidationSettingsForField('phone'),
      email: getValidationSettingsForField('email'),
      roomNumber: getValidationSettingsForField('room_number'),
      tableNumber: getValidationSettingsForField('table_number'),
      numberOfGuest: getValidationSettingsForField('guest_count'),
      additionalComment: getValidationSettingsForField('comments'),
      termsAndConditions: yup.boolean().oneOf([true]).required(),
      tip: LocationStore.uiCheckoutSettings.tip
        ? yup.string().required()
        : null,
    });

  const sendGA = () => {
    const orderItems = Object.values(CheckoutStore.currentOrder);

    GaService.instance.sendEvent({
      category: 'Ordering - Checkout',
      action: 'Success',
      label: `${Date.now()}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Tip',
      label:
        CheckoutStore.tipType === TipType.PERCENTAGE
          ? CheckoutStore.tipAmount
            ? `${CheckoutStore.tipAmount}%`
            : 'No tip'
          : 'Custom',
      value: round(
        CheckoutStore.tipType === TipType.PERCENTAGE
          ? (Number(summaryObject.subtotal) / 100) * CheckoutStore.tipAmount
          : CheckoutStore.tipAmount,
        2,
      ),
    });
    orderItems.forEach((item) => {
      GaService.instance.sendEvent({
        category: 'Ordering - Complete',
        action: 'Purchased Item',
        label: item.name,
        value: item.quantity,
      });
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Payment',
      label: CheckoutStore.paymentMethod,
      value: round(
        Number(summaryObject.total) +
          (CheckoutStore.tipType === TipType.PERCENTAGE
            ? (Number(summaryObject.subtotal) / 100) * CheckoutStore.tipAmount
            : CheckoutStore.tipAmount),
        2,
      ),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'User type',
      label: AuthStore.isGuest ? 'guest' : 'member',
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'User ID',
      label: `${AuthStore.userId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Business Name',
      label: LocationStore.selectedHotelObj.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Business ID',
      label: LocationStore.selectedHotel.toString(),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Location Name',
      label: LocationStore.selectedRestaurant.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Location ID',
      label: `${LocationStore.selectedRestaurantId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Experience Name',
      label: LocationStore.selectedExperience.name,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Experience ID',
      label: `${LocationStore.selectedExperienceId}`,
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Total items',
      label: 'Number of items',
      value: orderItems.reduce((acc, item) => acc + item.quantity, 0),
    });
    orderItems.forEach(
      (item) =>
        item.comment &&
        GaService.instance.sendEvent({
          category: 'Ordering - Complete',
          action: 'Items comments',
          label: item.name,
        }),
    );
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Guest count',
      label: CheckoutStore.numberOfDiners || '0',
    });
    CheckoutStore.comment &&
      GaService.instance.sendEvent({
        category: 'Ordering - Complete',
        action: 'Comments',
        label: CheckoutStore.comment,
      });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order time type',
      label:
        isSameDay(
          LocationStore.selectedDate,
          LocationStore.locationTimezoneName,
        ) &&
        (LocationStore.selectedDate === nearestTime ||
          isPast(LocationStore.selectedDate))
          ? 'ASAP'
          : 'Future',
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order date',
      label: dayjs(LocationStore.selectedDate).format('DD/MM/YYYY'),
    });
    GaService.instance.sendEvent({
      category: 'Ordering - Complete',
      action: 'Order time',
      label: dayjs(LocationStore.selectedDate).format('hh:mm A'),
    });
  };

  const submitOrder = async (paymentMethod: string) => {
    try {
      setIsCheckoutFetching(true);

      const { is3DSecure } = await CheckoutStore.cartCheckout({
        accessToken: AuthStore.accessToken,
        businessId: LocationStore.selectedRestaurantId,
        checkoutAt: toUTCDateFormat(CheckoutStore.checkoutTime),
        experienceId: LocationStore.selectedExperience.id,
        isTipAvailable: LocationStore.isTipAvailable(paymentMethod),
        isCoupon:
          CheckoutStore.coupon &&
          getIsFieldVisible(LocationStore.uiCheckoutSettings['coupons']),
      });
      if (is3DSecure) {
        try {
          await startOrderListening(+CheckoutStore.orderNumber);
        } catch (err) {
          CheckoutStore.uuid = '';
          try {
            await initCheckout();
          } finally {
            throw err;
          }
        }
      }

      sendGA();
      goToConfirm();
    } catch (err) {
      if (err instanceof Error) {
        if (err.message !== 'CHALLENGE_DECLINED_BY_USER') {
          errorHandler.onError(err);
        }
        GaService.instance.sendEvent({
          category: 'Ordering - Checkout',
          action: 'Error',
          label: err.message,
        });
        setSelectedUser(null);
        setGuestOptions([]);
      }
    } finally {
      setIsCheckoutFetching(false);
    }
  };

  const onSubmitFulfilled = async (
    {
      additionalComment,
      email,
      firstName,
      lastName,
      phone,
      numberOfGuest,
      paymentMethod,
      roomNumber,
      tableNumber,
      tip,
      customTip,
    }: IFormikValues,
    formikHelpers: FormikHelpers<IFormikValues>,
  ) => {
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.comments)) {
      CheckoutStore.setComment(additionalComment);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.email)) {
      CheckoutStore.setEmail(email);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.first_name)) {
      CheckoutStore.setFirstName(firstName);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.last_name)) {
      CheckoutStore.setLastName(lastName);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.guest_count)) {
      CheckoutStore.setNumberOfDiners(numberOfGuest);
    }
    if (getIsFieldVisible(LocationStore.uiCheckoutSettings.phone)) {
      CheckoutStore.setPhoneNumber(
        LocationStore.uiCheckoutSettings.phone ? phone : '',
      );
    }

    CheckoutStore.setRoomNumber(
      getIsFieldVisible(LocationStore.uiCheckoutSettings.room_number)
        ? roomNumber
        : '',
    );
    CheckoutStore.setTableNumber(
      getIsFieldVisible(LocationStore.uiCheckoutSettings.table_number)
        ? tableNumber
        : '',
    );
    CheckoutStore.setTip(tip as number);

    if (tip === TipStringValues.CUSTOM) {
      CheckoutStore.setTip(customTip, TipType.FIXED);
    }

    if (tip === TipStringValues.NO_TIP) {
      CheckoutStore.setTip(0);
    }

    const selectedHotelBID =
      LocationStore.selectedHotel ?? MyCheckApp.instance.getBusinessID();

    try {
      const isRoomCharge =
        paymentMethod === PaymentConstants.PaymentMethodsValue.ROOM;
      const hasValidation =
        LocationStore.selectedExperience.settings.payments.allowed.find(
          (e) => e.type === paymentMethod,
        )?.validation;

      if (
        LocationStore.selectedExperience.settings.is_room_validation ||
        (isRoomCharge && hasValidation)
      ) {
        if (!selectedUser) {
          const response = await CheckoutStore.validateRoom(
            isRoomCharge
              ? LocationStore.selectedRestaurantId
              : selectedHotelBID,
            AuthStore.accessToken,
            isRoomCharge,
          );

          if (
            response instanceof Error ||
            response.status === 'ERROR' ||
            response.guests.length === 0
          ) {
            if (response.message === 'NO_POST_ALLOWED') {
              errorHandler.onError({
                name: response.message,
                message: response.message,
              });
              return;
            }

            formikHelpers.setFieldError(
              'roomNumber',
              t('checkout.roomNumberDisclaimer'),
            );

            const page = document.getElementById('checkoutPageScroll');
            const elementOffsetTop =
              document.getElementById('roomNumber')?.offsetTop;

            (isLg ? page : window)?.scrollTo(
              0,
              elementOffsetTop - MODAL_HEADER_OFFSET,
            );

            return;
          }

          if (hasValidation) {
            if (response.guests.length > 1) {
              return setGuestOptions(response.guests);
            }

            CheckoutStore.setRoomToken(response.guests[0].token);
          }
        }
      }

      if (paymentMethod !== PaymentConstants.PaymentMethodsValue.WALLET) {
        await submitOrder(paymentMethod);
      } else {
        setPaymentsMethodOpen(true);
      }
    } catch (err) {
      if (err && err instanceof Error) {
        errorHandler.onError(err);
      }
    } finally {
      setIsCheckoutFetching(false);
    }
  };

  const onSubmit = async (
    props: IFormikValues,
    formikHelpers: FormikHelpers<IFormikValues>,
  ) => {
    setIsCheckoutFetching(true);

    const { didTimeChange, isAvailable } = await confirmSlot({
      isUpdateModalAvailable: true,
      isFutureModalAvailable: true,
      isClosedModalAvailable: true,
    });

    if (isAvailable === undefined) {
      await onSubmit(props, formikHelpers);
    }

    if (
      !isAvailable ||
      (didTimeChange &&
        !LocationStore.selectedExperience?.settings.is_asap_only)
    ) {
      setIsCheckoutFetching(false);
      return;
    }

    await menuTracker.checkIsSameMenu({
      callback: () => onSubmitFulfilled(props, formikHelpers),
    });
  };

  const onSubmitGuestSelection = async (roomToken: string) => {
    setGuestOptions([]);
    CheckoutStore.setRoomToken(roomToken);
    await submitOrder(CheckoutStore.paymentMethod);
  };

  const onCloseGuestSelection = () => setGuestOptions([]);

  const onCouponVerify = (summary: CardSummary, taxes: Array<Tax>) => {
    setSummaryObject(summary);
    setTaxesArray(taxes || []);
  };

  useEffect(() => {
    if (!Object.keys(CheckoutStore.currentOrder).length) {
      return navigation.replace('/menu');
    }

    if (LocationStore.selectedExperience) {
      onMount();
    }

    return () => {
      isMounted.current = false;

      if (refFirebase.current) {
        refFirebase.current();
      }

      if (refFirebaseReject.current) {
        refFirebaseReject.current();
      }
    };
  }, [LocationStore.selectedExperience?.id]);

  useEffect(() => {
    if (!LocationStore.selectedExperience) {
      LocationStore.fetchExperience(LocationStore.selectedExperienceId);
    }

    if (!isLg) {
      window.scrollTo(0, 0);
    }

    return () => {
      if (refFirebase.current) {
        refFirebase.current();
      }
      if (refFirebaseReject.current) {
        refFirebaseReject.current();
      }

      //walletService.destroy();
    };
  }, []);

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      setLoading(true);
    }
  }, [history]);

  const selectedRestaurantCountryCode =
    LocationStore.selectedRestaurant.place?.data.address_components
      .find((comp) => comp.types.includes('country'))
      ?.short_name.toLowerCase();

  const headerMobileStyle: CSSProperties = !isLg
    ? {
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 3,
      }
    : {
        backgroundColor: config.primary,
        color: '#ffffff',
        width: 480,
      };

  const renderBody = () => (
    <div className={styles.checkoutPage} id="checkoutPageId">
      <Header
        headerTitle={t('checkout.checkoutTitle')}
        color="#ffffff"
        rootStyle={headerMobileStyle}
        handleBackClick={() =>
          isLg ? navigation.push('/menu') : navigation.push('/menu/order')
        }
        enforceSmall
      />

      <Background className={styles.checkoutPageScroll} id="checkoutPageScroll">
        {config.supportMembers && !AuthStore.isLoggedIn && (
          <div
            className={styles.checkoutPageRegisterWrap}
            style={config.registerTextStyle}
          >
            <div className={styles.checkoutPageRegisterWrapTitle}>
              {t('checkout.alreadyRegisteredQuestion')}
            </div>
            <div
              data-test-id="checkout-sign-in"
              onClick={handleSignInClick}
              className={styles.checkoutPageRegisterWrapRegister}
              style={config.linkStyle}
            >
              {t('checkout.signInLink')}
            </div>
          </div>
        )}

        <div className={styles.checkoutPageInfoWrap}>
          <div className={styles.checkoutPageInfoWrapExperiance}>
            {t(`experience.${LocationStore.selectedExperienceType}`)}
          </div>
          <div className={styles.checkoutPageInfoWrapRestaurant}>
            {LanguageStore.getBusinessTranslation(
              LocationStore.selectedRestaurant.id,
              'name',
              LocationStore.selectedRestaurant.name,
            )}
          </div>
          {(!LocationStore.selectedExperience?.settings.is_asap_only ||
            LocationStore.selectedExperience?.settings.display_order_time) &&
            CheckoutStore.checkoutTime && (
              <div className={styles.checkoutPageInfoWrapTime}>
                <CustomIcon
                  name="time"
                  className={styles.checkoutPageInfoWrapTimeIcon}
                />
                <TranslateText
                  tKey="checkout.deliverTo"
                  values={{
                    at: toHourFormatDisplay(
                      CheckoutStore.checkoutTime,
                      LocationStore.locationTimezoneName,
                      LocationStore.localTimeFormat,
                    ),
                    when: getDateLabel(
                      CheckoutStore.checkoutTime,
                      LocationStore.locationTimezoneName,
                      LocationStore.localDateFormat,
                    ),
                  }}
                />
              </div>
            )}
        </div>
        {!!LocationStore.selectedExperience ? (
          <Formik
            initialValues={{
              additionalComment: '',
              email: AuthStore.email,
              firstName: AuthStore.userFirstName,
              lastName: AuthStore.userLastName,
              phone: AuthStore.phone,
              numberOfGuest: '',
              paymentMethod:
                CheckoutStore.paymentMethod ||
                LocationStore.selectedExperience?.settings.payments?.default,
              deliveryOption: LocationStore.deliveryOption,
              roomNumber: CheckoutStore.roomNumber,
              tableNumber: CheckoutStore.tableNumber,
              termsAndConditions: false,
              tip: LocationStore.isTipAvailable(
                CheckoutStore.paymentMethod ||
                  LocationStore.selectedExperience?.settings.payments?.default,
              )
                ? LocationStore.uiCheckoutSettings?.tip?.selected
                : 0,
              country: selectedRestaurantCountryCode,
            }}
            validationSchema={getValidationSchema}
            onSubmit={onSubmit}
            validateOnChange={enableValidation}
            validateOnBlur={enableValidation}
          >
            {(formikProps) => (
              <CheckoutForm
                formikProps={formikProps}
                summary={summaryObject}
                taxes={taxesArray}
                isPendingCalculations={localPendingCalculations}
                setEnableValidation={setEnableValidation}
                validationSchema={getValidationSchema()}
                isCheckoutFetching={isCheckoutFetching}
                onCouponVerify={onCouponVerify}
              />
            )}
          </Formik>
        ) : (
          <Loader withOverlay={false} />
        )}
      </Background>
      {!!guestOptions.length && (
        <GuestSelectionPopup
          options={guestOptions}
          onSubmit={onSubmitGuestSelection}
          onClose={onCloseGuestSelection}
        />
      )}
    </div>
  );

  if (isLg) {
    return (
      <>
        <Overlay width={480} height={724} testId="checkout-page">
          {!loading && renderBody()}
          {paymentsMethodOpen && (
            <PaymentsMethod
              summary={summaryObject}
              handleOpen={() => {
                setPaymentsMethodOpen(!paymentsMethodOpen);
              }}
              type={
                CheckoutStore.paymentMethod ===
                PaymentConstants.PaymentMethodsValue.WALLET
                  ? 'WALLET'
                  : 'CHOOSE'
              }
              initCheckout={initCheckout}
            />
          )}
        </Overlay>
        {(isCheckoutFetching || loading) && <Loader />}
      </>
    );
  }

  return (
    <>
      {!loading && renderBody()}
      {paymentsMethodOpen && (
        <PaymentsMethod
          summary={summaryObject}
          handleOpen={() => {
            setPaymentsMethodOpen(!paymentsMethodOpen);
          }}
          type={
            CheckoutStore.paymentMethod ===
            PaymentConstants.PaymentMethodsValue.WALLET
              ? 'WALLET'
              : 'CHOOSE'
          }
        />
      )}
      {(isCheckoutFetching || loading) && (
        <Loader
          label={t('checkout.submittingOrder')}
          labelStyle={{
            color: '#ffffff',
            fontSize: 16,
          }}
        />
      )}
    </>
  );
};
