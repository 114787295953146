import React, {
  useMemo,
  type ChangeEvent,
  type CSSProperties,
  type PropsWithChildren,
} from 'react';

import classnames from 'classnames';
import { Field, FormikProps } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { capitalizeFirstLetter, ErrorCodes, MyCheckApp } from 'mycheck-core';

import styles from './Input.module.scss';

interface InputProps extends PropsWithChildren {
  formikProps?: FormikProps<any>;
  type?: 'text' | 'email' | 'number' | 'text-only';
  name?: string;
  placeholder?: string | JSX.Element;
  className?: string;
  value: string;
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  style?: CSSProperties;
  rows?: number;
  autofocus?: boolean;
  error?: boolean;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  testId?: string;
  maxLength?: number;
}

export const Input: React.FC<InputProps> = ({
  formikProps,
  name,
  rows,
  type,
  placeholder,
  value,
  style = {},
  className = '',
  onChange,
  autofocus,
  error,
  errorMessage,
  label,
  children,
  disabled,
  testId = '',
  maxLength,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const inputStyle = get(_config, 'general.input', {});
    const inputErrorStyle = get(_config, 'general.inputError', {});
    const textStyle = get(_config, 'general.text', {});
    const errorColor = get(_config, 'palette.error', '');
    const styleObject = {
      border: '1px solid',
      inputStyle: inputStyle,
      ...(error ? inputErrorStyle : {}),
      ...style,
    };

    return {
      textStyle,
      errorColor,
      styleObject,
    };
  }, [location.search]);

  const onChangeEvt = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    formikProps.handleChange(evt);
    if (type === 'text-only') {
      formikProps.setFieldValue(
        name,
        evt.currentTarget.value.replace(/[^A-Za-z ]/gi, ''),
      );
    } else {
      formikProps.setFieldValue(name, evt.currentTarget.value);
    }
    formikProps.setErrors({ [name]: '' });
  };

  if (formikProps) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.wrapperInput}>
          {!!label && (
            <div className={styles.label} style={config.textStyle}>
              {label}
            </div>
          )}
          <Field
            className={[styles.customInput, className]
              .filter(Boolean)
              .join(' ')}
            style={config.styleObject}
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChangeEvt}
            touched={formikProps.touched}
            autoFocus={autofocus}
            disabled={disabled}
            data-test-id={`${testId}-input`}
          />
          {!!error &&
            !!errorMessage &&
            !errorMessage.includes(
              ErrorCodes.VERIFICATION_ERROR_HIDDEN.toString(),
            ) && (
              <div
                className={styles.errorMessage}
                style={{ color: config.errorColor }}
              >
                {capitalizeFirstLetter(errorMessage)}
              </div>
            )}
        </div>
        {children}
      </div>
    );
  }

  if (rows) {
    return (
      <div className={styles.wrapper}>
        <textarea
          onChange={onChange}
          value={value}
          placeholder={placeholder as string}
          rows={rows}
          className={classnames(styles.customInput, {
            [className]: !!className,
          })}
          style={{ ...config.styleObject, ...style, resize: 'none' }}
          autoFocus={autofocus}
          data-test-id={testId}
          maxLength={maxLength}
        />
      </div>
    );
  }

  return (
    <input
      onChange={onChange}
      value={value}
      placeholder={placeholder as string}
      className={classnames(styles.customInput, { [className]: !!className })}
      style={config.styleObject}
      autoFocus={autofocus}
      data-test-id={testId}
    />
  );
};
