import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  getDate,
  MyCheckApp,
  transformDate,
  transformTime,
  transformTimeArrayToDateTimeArray,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { PrimaryButton, RadioButtonGroup, SelectBox } from '@components';

import { IFormikValues } from '../../types/FormikValues';
import { SelectLocation } from '../SelectLocation/SelectLocation';

import styles from './HomePageForm.module.scss';
import { useGetExperiences, useGetHomeList } from './useHomePageFormData';

export const RestaurantFlow: React.FC = observer(() => {
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);

  const LocationStore = useLocationStore();
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const formikProps = useFormikContext<IFormikValues>();
  const homeList = useGetHomeList();
  const experiences = useGetExperiences();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const locationLimit = get(_config, 'home.settings.locationLimit', 5);
    const radioButtonStyle = get(_config, 'home.style.subtitle', {});
    return {
      locationLimit,
      radioButtonStyle,
    };
  }, [location.search]);

  const currentRestaurant = LocationStore.homeList.find(
    (e) => e.id === formikProps.values.selectedLocationID,
  );
  const restaurantExperiences = currentRestaurant?.experiences || [];
  const isMultipleRestaurantExperiences = restaurantExperiences.length > 1;
  const selectedExperience = restaurantExperiences.find(
    (el) => el.type === formikProps.values.selectedExperienceType,
  );
  const isAsapOnly = selectedExperience?.settings?.is_asap_only;

  const timezone = LocationStore.locationTimezoneName;

  const setDatesAndTimes = async () => {
    if (
      LocationStore.timeSettings &&
      selectedExperience &&
      !selectedExperience?.settings.is_asap_only
    ) {
      const newDates = transformDate(
        timezone,
        LocationStore.localDateFormat,
        LocationStore.timeSettings.available_days,
        true,
        LocationStore.timeSettings.open_hours,
      );

      const newTimes = newDates
        .map((date) => {
          return {
            [dayjs(date.value).tz(timezone).date()]: transformTime(
              LocationStore.timeSettings,
              date.value,
              timezone,
              LocationStore.localTimeFormat,
            ),
          };
        })
        .reduce((a, b) => {
          if (b[Object.keys(b)[0]].length) {
            return { ...a, ...b };
          }

          return a;
        }, {});

      const setNewDates = newDates
        .filter(
          (obj) => !!newTimes[dayjs(obj.value).tz(timezone).date()]?.length,
        )
        .map((e) => {
          const value = dayjs(e.value).tz(timezone);
          return {
            ...e,
            value: value.date(),
            valueDate: value.utc().format(),
            dayName: value.format('ddd').toLowerCase(),
          };
        });

      const locationHoursAvailable = Object.keys(
        selectedExperience.settings.open_hours,
      );
      const filteredNewDates = setNewDates.filter((e) =>
        locationHoursAvailable.includes(e.dayName),
      );
      setDates(filteredNewDates);
      setTimes([]);
    }
  };

  const setDateRelatedTimes = async () => {
    if (
      formikProps.values.selectedDate &&
      formikProps.values.selectedExperienceType &&
      !selectedExperience?.settings.is_asap_only
    ) {
      const newTimes = dates
        .map((date) => ({
          [date.value]: transformTime(
            LocationStore.timeSettings,
            date.valueDate,
            timezone,
            LocationStore.localTimeFormat,
          ),
        }))
        .reduce((a, b) => {
          if (b[Object.keys(b)[0]].length) {
            return { ...a, ...b };
          }

          return a;
        }, {});

      const response = await LocationStore.confirmExperienceSlot(
        newTimes[formikProps.values.selectedDate]?.[0].value,
        selectedExperience.id,
      );
      const locationTimeValues = transformTimeArrayToDateTimeArray(
        response.time_slots[
          dayjs(newTimes[formikProps.values.selectedDate][0].value)
            .format('ddd')
            .toLowerCase()
        ] || [],
        dates.find((e) => e.value === formikProps.values.date)?.valueDate,
        timezone,
        LocationStore.localTimeFormat,
        LocationStore.selectedExperience.settings.frame_time,
      );
      setTimes(locationTimeValues);

      if (
        !locationTimeValues.some(
          (e) => e.value === formikProps.values.selectedTime,
        )
      ) {
        formikProps.setFieldValue('selectedTime', locationTimeValues[0]?.value);
      }
    }
  };

  useEffect(() => {
    if (formikProps.values.selectedLocationID) {
      formikProps.setFieldValue(
        'selectedExperienceType',
        restaurantExperiences[0]?.type,
      );
      LocationStore.fetchTimeSettings(formikProps.values.selectedLocationID);
    }
  }, [formikProps.values.selectedLocationID]);

  useEffect(() => {
    if (selectedExperience?.settings.is_asap_only) {
      const date = getDate();
      setDates([{ label: t('timeSelection.today'), value: date }]);
      setTimes([{ label: t('timeSelection.asap'), value: date }]);

      formikProps.setFieldValue('selectedDate', date);
      formikProps.setFieldValue('selectedTime', date);
    }
  }, [formikProps.values.selectedExperienceType]);

  useEffect(() => {
    setDatesAndTimes();
  }, [
    LocationStore.timeSettings?.available_days,
    formikProps.values.selectedExperienceType,
  ]);

  useEffect(() => {
    setDateRelatedTimes();
  }, [formikProps.values.selectedDate]);

  const renderExperiences = () => {
    switch (restaurantExperiences.length) {
      case 0:
      case 1:
        return null;
      case 2:
        return (
          <RadioButtonGroup
            data={experiences}
            formikProps={formikProps}
            value={formikProps.values.selectedExperienceType}
            containerClassName={styles.homePageFormWrapperExperience}
            property={'selectedExperienceType'}
            testId="home-select-experience"
            radioButtonStyle={config.radioButtonStyle}
          />
        );
      default:
        return (
          <SelectBox
            data={experiences.map((e) => ({
              ...e,
              label: t(`experience.${e.value}`),
            }))}
            placeholder={t('home.experiencePlaceholder')}
            formikProps={formikProps}
            value={formikProps.values.selectedExperienceType}
            property={'selectedExperienceType'}
            testId="home-select-experience"
          />
        );
    }
  };

  return (
    <div className={styles.homePageFormWrapper}>
      <div className={styles.homePageFormRestaurantWrapper}>
        <div className={styles.homePageFormRestaurantWrapperPart}>
          <SelectLocation
            data={homeList}
            formikProps={formikProps}
            limit={config.locationLimit}
          />
        </div>
        {isMultipleRestaurantExperiences && (
          <div className={styles.homePageFormRestaurantWrapperPart}>
            {renderExperiences()}
          </div>
        )}
        <div className={styles.homePageFormRestaurantWrapperPart}>
          <div className={styles.homePageFormRestaurantWrapperPartDateTime}>
            <SelectBox
              data={dates}
              placeholder={t('home.datePlaceholder')}
              formikProps={formikProps}
              value={formikProps.values.selectedDate}
              property={'selectedDate'}
              testId="home-select-date"
              disabled={isAsapOnly || !selectedExperience}
            />
          </div>
          <div className={styles.homePageFormRestaurantWrapperPartDateTime}>
            <SelectBox
              data={times}
              placeholder={t('home.timePlaceholder')}
              formikProps={formikProps}
              value={formikProps.values.selectedTime}
              property={'selectedTime'}
              testId="home-select-time"
              disabled={isAsapOnly || !selectedExperience || !times.length}
            />
          </div>
        </div>
        <div className={styles.homePageFormRestaurantWrapperPart}>
          <PrimaryButton
            className={styles.homePageButtonOrder}
            testId="home-submit"
            style={!isMultipleRestaurantExperiences && isLg && { width: 300 }}
          >
            {t(`home.${LocationStore.viewMode ? 'buttonViewMode' : 'button'}`)}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
});

RestaurantFlow.displayName = 'RestaurantFlow';
