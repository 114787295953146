enum STORAGE_KEYS {
  PICKER_PREFERENCE = 'PICKUP_PICKER_PREFERENCE',
  COOKIE_POLICY = 'COOKIE_POLICY',
  EXPERIENCE_PICKER = 'EXPERIENCE_PICKER',
  IS_ADULT = 'IS_ADULT',
  UI_CONFIG_ID = 'UI_CONFIG_ID',
  COUPON = 'COUPON',
}

const getValue = (key: STORAGE_KEYS): any =>
  JSON.parse(window.localStorage.getItem(key));
const setValue = (
  key: STORAGE_KEYS,
  value: Record<string, unknown> | string | number,
): void => window.localStorage.setItem(key, JSON.stringify(value));
const removeValue = (key: STORAGE_KEYS): void =>
  window.localStorage.removeItem(key);

export const StorageService = {
  STORAGE_KEYS,
  getValue,
  setValue,
  removeValue,
};
