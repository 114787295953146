import React from 'react';

import { observer } from 'mobx-react';

import { OptionSelect } from '@components';

import { IconSvg } from '../Icon/helpers';

import styles from './Action.module.scss';

interface ActionProps {
  id?: string;
  label: string;
  height: number;
  width: number | string;
  leftIconName?: IconSvg;
  onClick: () => void;
}

export const Action: React.FC<ActionProps> = observer(
  ({ id, label, height, width, leftIconName, onClick }) => {
    return (
      <div className={styles.action} id={id}>
        <OptionSelect
          className={styles.actionOptionSelection}
          style={{ height, width }}
          leftIconName={leftIconName}
          onClick={onClick}
        >
          {label}
        </OptionSelect>
      </div>
    );
  },
);

Action.displayName = 'Action';
