import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from 'react';

import dayjs from 'dayjs';
import get from 'lodash/get';
import { observer } from 'mobx-react';

import {
  MyCheckApp,
  useLocation,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './TimezoneModal.module.scss';

const timezoneTrackerContext = {
  checkTimezone: (_fn?: () => null) => null,
};

const TimezoneTrackerContext = createContext(timezoneTrackerContext);

export function useTimezoneTrackerContext() {
  const context = useContext(TimezoneTrackerContext);
  return { checkTimezone: context.checkTimezone };
}

export const TimezoneModal: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const LocationStore = useLocationStore();
    const { t } = useTranslation();

    const [currentHotel, setCurrentHotel] = useState(null);
    const [show, setShow] = useState(false);
    const handleModalClose = useCallback(() => {
      setShow(false);
    }, []);

    const location = useLocation();

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const titleStyle = get(_config, 'general.popup.title', {});
      const bodyStyle = get(_config, 'general.popup.mainText', {});

      return {
        titleStyle,
        bodyStyle,
      };
    }, [location.search]);

    const offset = dayjs().tz(LocationStore.locationTimezoneName).utcOffset();

    const isSameTimezone = () => offset === dayjs().utcOffset();

    const checkTimezone = () => {
      if (
        typeof LocationStore.locationTimezoneName !== 'undefined' &&
        currentHotel !== LocationStore.selectedHotel
      ) {
        setCurrentHotel(LocationStore.selectedHotel);

        if (!isSameTimezone()) {
          setShow(true);
        }
      }
    };

    useEffect(() => {
      if (location.pathname !== '/location') {
        handleModalClose();
      }
    }, [location.pathname]);

    return (
      <TimezoneTrackerContext.Provider value={{ checkTimezone }}>
        {children}
        {!LocationStore.isFetching && show && (
          <PopUp onClose={handleModalClose} testId="timezone-modal">
            <div className={styles.wrapper}>
              <div className={styles.title} style={config.titleStyle}>
                {t('location.timezoneModalTitle')}
              </div>
              <div style={config.bodyStyle}>
                {t('location.timezoneModalBody', {
                  timezone: offset >= 0 ? `+${offset / 60}` : offset / 60,
                  interpolation: { escapeValue: false },
                })}
              </div>
            </div>
          </PopUp>
        )}
      </TimezoneTrackerContext.Provider>
    );
  },
);

TimezoneModal.displayName = 'TimezoneModal';
