import React, { useEffect, useMemo, type PropsWithChildren } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { Background } from '../Background/Background';
import { ClosePage } from '../ClosePage/ClosePage';
import { Overlay } from '../Overlay/Overlay';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';

import styles from './PopUp.module.scss';

interface PopUpProps extends PropsWithChildren {
  onClose: () => void;
  onClick?: () => void;
  onCancelClick?: () => void;
  buttonText?: string | JSX.Element;
  cancelButtonText?: string;
  testId?: string;
  withConfirmButton?: boolean;
  triggerToRerender?: boolean | string | number;
  closeOnClickOutside?: boolean;
  showCloseIcon?: boolean;
  paperClassName?: string;
  childWrapperClassName?: string;
}

export const PopUp: React.FC<PopUpProps> = ({
  children,
  buttonText,
  cancelButtonText,
  onClose,
  onCancelClick,
  onClick,
  testId = '',
  withConfirmButton = true,
  triggerToRerender,
  closeOnClickOutside = true,
  showCloseIcon = true,
  paperClassName,
  childWrapperClassName,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const modalTitleStyle = get(_config, 'general.popup.mainText', {});

    return {
      modalTitleStyle,
    };
  }, [location.search]);

  const onEnter = (ev: KeyboardEvent) => {
    ev.stopPropagation();
    if (ev.key === 'Enter') {
      onClick();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onEnter);
    return () => window.removeEventListener('keyup', onEnter);
  }, [triggerToRerender]);

  const renderConfirmButton = () => (
    <PrimaryButton
      testId="confirm-popup"
      className={styles.confirmButton}
      onClick={onClick || onClose}
    >
      {buttonText || t('general.popUpButton')}
    </PrimaryButton>
  );

  const renderCancelButton = () => (
    <PrimaryButton
      testId="cancel-popup"
      secondary
      className={styles.cancelButton}
      onClick={onCancelClick}
    >
      {cancelButtonText || t('general.closedModalSecondaryButton')}
    </PrimaryButton>
  );

  const handleOutsideClick = () => {
    if (closeOnClickOutside) {
      onClose();
    }
  };

  return (
    <Overlay
      onClickOutside={handleOutsideClick}
      className={paperClassName}
      testId={testId}
      isPopup
    >
      <Background className={styles.root} style={config.modalTitleStyle}>
        {showCloseIcon && <ClosePage onClose={onClose} testId={testId} />}
        <div className={childWrapperClassName}>{children}</div>
        {withConfirmButton && renderConfirmButton()}
        {!!onCancelClick && renderCancelButton()}
      </Background>
    </Overlay>
  );
};
