import React, {
  ReactElement,
  useCallback,
  useMemo,
  type CSSProperties,
} from 'react';

import get from 'lodash/get';

import { MyCheckApp } from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './RadioButton.module.scss';

type RadioButtonType = {
  id: string;
  text: string;
  content?: ReactElement;
  isChecked: boolean;
  onClick: (id: string) => void;
  withRadioInput?: boolean;
  testId?: string;
  style?: CSSProperties;
};

export const RadioButton: React.FC<RadioButtonType> = ({
  id,
  text,
  content,
  isChecked,
  onClick,
  testId,
  withRadioInput = true,
  style,
}) => {
  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const textStyle =
      style ?? get(_config, 'restaurants.experienceDesktop', {});

    return {
      textStyle,
    };
  }, [style]);

  const onRadioClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <div
      data-test-id={`${testId}-radio-button`}
      className={styles.container}
      onClick={onRadioClick}
    >
      {withRadioInput && (
        <CustomIcon
          name={isChecked ? 'radioFilled' : 'radioEmpty'}
          style={{ radioBorderColor: config.textStyle?.color }}
        />
      )}
      <div className={styles.titleField} style={config.textStyle}>
        {content ? content : text}
      </div>
    </div>
  );
};
