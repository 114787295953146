import React, { type CSSProperties } from 'react';

import { CustomIcon } from '../Icon/CustomIcon';
import { Overlay } from '../Overlay/Overlay';

import styles from './Loader.module.scss';

type Props = {
  withOverlay?: boolean;
  label?: string;
  labelStyle?: CSSProperties;
};

export const Loader: React.FC<Props> = ({
  withOverlay = true,
  label,
  labelStyle,
}) => {
  if (!withOverlay) {
    return (
      <div className={styles.absolute}>
        <CustomIcon className={styles.loaderImage} name="loader" />
        {label && (
          <div className={styles.label} style={labelStyle}>
            {label}
          </div>
        )}
      </div>
    );
  }

  return (
    <Overlay withoutModal>
      <CustomIcon className={styles.loaderImage} name="loader" />
      {label && (
        <div className={styles.label} style={labelStyle}>
          {label}
        </div>
      )}
    </Overlay>
  );
};
