import { MyCheckPlugin, injector, StoresConstants } from 'mycheck-core';

import { HttpMenuApi, HTTP_MENU_API } from './MenuApi';
import { MenuStore } from './MenuStore';

const DINE_MENU_PLUGIN_KEY = 'DINE_MENU_PLUGIN_KEY';

export { MenuStore };

export class MenuPlugin implements MyCheckPlugin {
  readonly name = DINE_MENU_PLUGIN_KEY;

  async init(): Promise<void> {
    injector.set(HTTP_MENU_API, new HttpMenuApi());
  }

  getStores = () => {
    const menuStore = new MenuStore(new HttpMenuApi());

    return [{ [StoresConstants.MenuStore]: menuStore }];
  };

  afterHydrate = (): void => {
    return null;
  };
}
