import { paramHandler } from 'mycheck-core';

export const getSearchState = () => {
  const params = new URLSearchParams(window.location.search);
  const atobState = paramHandler(params, 'state', () => null);

  if (!atobState) {
    return {};
  }

  return JSON.parse(decodeURIComponent(escape(atob(atobState))));
};
