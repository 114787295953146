import React, {
  useMemo,
  type ChangeEvent,
  type PropsWithChildren,
} from 'react';

import { css } from 'glamor';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { Background, Icon, Input } from '@components';

import styles from './SelectLocation.module.scss';

interface SelectLocationModalProps extends PropsWithChildren {
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClose: () => void;
  value: string;
}

export const SelectLocationModal: React.FC<SelectLocationModalProps> = ({
  children,
  onChange,
  value,
  onClose,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const searchStyle = get(_config, 'search.input', {});
    const inputTextStyle = get(_config, 'search.inputText', {});
    const inputPlaceholderStyle = get(_config, 'search.placeholder', {});
    const backgroundColor = get(_config, 'palette.primaryBackground', '');
    const inputCss = css({
      ...inputTextStyle,
      '&::placeholder': inputPlaceholderStyle,
    });

    return {
      searchStyle,
      backgroundColor,
      inputCss,
    };
  }, [location.search]);

  return (
    <div className={styles.modal} data-test-id="search-select-location-modal">
      <div
        className={styles.modalOverflow}
        style={{ backgroundColor: config.backgroundColor }}
      >
        <Background className={styles.modalTitle}>
          <Icon name="search" className={styles.icon} />
          <Input
            testId="search-select-location"
            placeholder={t('home.searchPlaceholder')}
            className={`${styles.input} ${config.inputCss}`}
            style={config.searchStyle}
            onChange={onChange}
            value={value}
            autofocus
          />
          <div
            data-test-id="search-select-location-close"
            className={styles.modalIcon}
            onClick={onClose}
          >
            <Icon name="x" />
          </div>
        </Background>
        <div className={styles.modalContainer}>{children}</div>
      </div>
    </div>
  );
};
