import React, { PropsWithChildren } from 'react';

import styles from './DataGrid.module.scss';

export interface DataGridContainerProps extends PropsWithChildren {}

const DataGridContainer: React.FC<DataGridContainerProps> = ({ children }) => {
  return (
    <div
      data-test-id="data-grid-container"
      className={styles.dataGridContainer}
    >
      {children}
    </div>
  );
};

export default DataGridContainer;
