import { useEffect } from 'react';

import { useFlowType, useLocationStore } from 'mycheck-core';

import { useCommonFlow } from './useCommonFlow';

export const useRestaurantFlow = () => {
  const LocationStore = useLocationStore();
  const SharedLogic = useCommonFlow(LocationStore.selectedRestaurantId);
  const { isRestaurantFlow } = useFlowType();

  const setUpFlow = async () => {
    if (isRestaurantFlow) {
      SharedLogic.resetInfo();

      if (LocationStore.selectedRestaurantId) {
        if (!LocationStore.fetchingList.length) {
          LocationStore.startFetching();
        }
        await LocationStore.fetchTimeSettings(
          LocationStore.selectedRestaurantId,
        );
        LocationStore.stopFetching();
      }
    }
  };

  useEffect(() => {
    setUpFlow();
  }, [LocationStore.selectedRestaurantId]);
};
