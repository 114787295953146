import React, { useCallback, useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useCheckoutStore,
  useFlowType,
  useTranslation,
  useNavigation,
  displayDateTime,
  useLocationStore,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './DateTimeTracker.module.scss';

type Props = { handleModalClose: () => void };
export const DateTimeTrackerGraceModal: React.FC<Props> = ({
  handleModalClose,
}) => {
  const navigation = useNavigation();
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const { isRestaurantFlow } = useFlowType();
  const { t } = useTranslation();

  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});

    return {
      titleStyle,
    };
  }, [location.search]);

  const onClose = useCallback(() => {
    handleModalClose();
    navigation.push(isRestaurantFlow ? '/' : '/location');
  }, [window.location.search]);

  return (
    <PopUp
      onClose={onClose}
      onCancelClick={onClose}
      onClick={handleModalClose}
      testId="date-time-grace-tracker"
      buttonText={t('general.graceModalContinue')}
      withConfirmButton
    >
      <div className={styles.wrapper}>
        <div className={styles.title} style={config.titleStyle}>
          {t(
            'general.graceModal',
            //TODO try to bump library to get proper typing
            {
              at: displayDateTime(
                CheckoutStore.checkoutTime,
                LocationStore.locationTimezoneName,
                LocationStore.localDateFormat,
                LocationStore.localTimeFormat,
              ),
            } as unknown as string,
            { interpolation: { escapeValue: false } },
          )}
        </div>
      </div>
    </PopUp>
  );
};
