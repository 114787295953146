import React, { type MouseEvent } from 'react';

import { useLocationStore } from 'mycheck-core';

import styles from './AllergensGroup.module.scss';

interface Props {
  allergens: Array<number>;
  numberOfVisibleItems?: number;
  handleAllergensClick: (value: number[]) => void;
}

export const AllergensGroup: React.FC<Props> = ({
  allergens,
  numberOfVisibleItems,
  handleAllergensClick,
}) => {
  const locationStore = useLocationStore();

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleAllergensClick(allergens);
  };

  const visibleAllergens = numberOfVisibleItems
    ? allergens.slice(0, numberOfVisibleItems)
    : allergens;

  return (
    <div
      data-test-id="allergens-group-trigger"
      className={styles.allergensWrap}
      onClick={handleClick}
    >
      {visibleAllergens
        .map((allergenId) => {
          const currentAllergen = locationStore.allergensList.find(
            (allergen) => allergen.id === allergenId,
          );

          if (!currentAllergen) {
            return null;
          }

          return (
            <img
              key={allergenId}
              src={currentAllergen.icon}
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(5%) sepia(6%) saturate(2383%) hue-rotate(222deg) brightness(92%) contrast(87%)',
              }}
              alt=""
              className={styles.allergensWrapItem}
            />
          );
        })
        .filter(Boolean)}
    </div>
  );
};
