import React, { Suspense } from 'react';

import get from 'lodash/get';
import { create } from 'mobx-persist';
import { Provider } from 'mobx-react';
import { createRoot } from 'react-dom/client';

import { InternetTracker } from '../components/InternetTracker/InternetTracker';

import { API_CLIENT, ApiClient } from './ApiClient';
import { StoresConstants } from './constants/StoresConstants';
import { HistoryContext } from './contexts/HistoryContext';
import { RootNavigation } from './navigation/RootNavigation';
import { GA_SERVICE, GaService } from './service/GaService';
import { getSearchState } from './service/GetSearchStateService';
import { injector } from './tools/injector';
import { PluginInjector } from './tools/pluginInjector';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const MYCHECK_APP = 'MYCHECK_APP';

export interface IStore {
  [store: string]: any;
}

export interface MyCheckPlugin {
  readonly name: string;

  init(): Promise<void>;

  initStyleConfig?(): Promise<void>;

  getPluginConfig?();

  getStores(): Array<Record<string, unknown>>;

  afterHydrate(): void;
}

const ContextValues = {
  callback: null,
  setCallback: (fn: () => void) => (ContextValues.callback = fn),
};

const RECAPTCHA_V3_KEY = process.env.REACT_APP_RECAPTCHA_V3_KEY;

export class MyCheckApp {
  public static get instance() {
    return injector.get<MyCheckApp>(MYCHECK_APP);
  }

  public config;
  public hotelConfig;
  public restaurantConfig;
  private pluginInjector = new PluginInjector();
  private stores: IStore;
  private publishableKey = '';
  private businessID: number = undefined;
  private uiConfigId: number = undefined;
  private supportGuests = false;
  private supportMembers = false;
  private blockHomePage = false;

  constructor(args: { plugins: Array<MyCheckPlugin> }) {
    args.plugins.forEach((plugin) =>
      this.pluginInjector.set(plugin.name, plugin),
    );
  }

  public getPlugin<T extends MyCheckPlugin>(key: string) {
    return this.pluginInjector.get<T>(key);
  }

  public setConfig(config) {
    const supportGuests = get(config, 'config.settings.supportGuests', true);
    this.supportGuests = supportGuests;
    this.supportMembers = !supportGuests;
    this.blockHomePage = get(config, 'config.settings.blockHomePage', true);

    this.businessID = get(config, 'business_details.id', 0);
    this.config = config;
  }

  public setHotelConfig(config: any) {
    return (this.hotelConfig = config);
  }

  public setRestaurantConfig(config: any) {
    return (this.restaurantConfig = config);
  }

  public setUiConfigId(id: number) {
    return (this.uiConfigId = id);
  }

  public getPublishableKey() {
    return this.publishableKey;
  }

  public getSupportGuestsValue() {
    const params = new URLSearchParams(window.location.search);
    const searchState = getSearchState();
    const hotel = searchState?.hotel || params.get('hotel');
    const restaurant = searchState?.restaurant || params.get('restaurant');

    return !!restaurant
      ? this.restaurantConfig.settings.supportGuests
      : !!hotel
        ? this.hotelConfig.settings.supportGuests
        : this.config.config.settings.supportGuests;
  }

  public getSupportMembersValue() {
    const params = new URLSearchParams(window.location.search);
    const searchState = getSearchState();
    const hotel = searchState?.hotel || params.get('hotel');
    const restaurant = searchState?.restaurant || params.get('restaurant');

    return !!restaurant
      ? !this.restaurantConfig.settings.supportGuests
      : !!hotel
        ? !this.hotelConfig.settings.supportGuests
        : !this.config.config.settings.supportGuests;
  }

  public getBlockHomePage() {
    const params = new URLSearchParams(window.location.search);
    const searchState = getSearchState();
    const hotel = searchState?.hotel || params.get('hotel');
    const restaurant = searchState?.restaurant || params.get('restaurant');

    return !!restaurant
      ? this.restaurantConfig.settings.blockHomePage
      : !!hotel
        ? this.hotelConfig.settings.blockHomePage
        : this.config.config.settings.blockHomePage;
  }

  public getBusinessID() {
    return this.businessID;
  }

  public getBusinessDetails() {
    return this.config.business_details;
  }

  public getWalletV3Config() {
    return this.config.wallet_v3;
  }

  public getIsCaptchaV3EnterpriseEnabled() {
    return this.config.isCaptchaV3EnterpriseEnabled;
  }

  public getOrderExpirationMinutes() {
    return this.config.order_expiration_minutes;
  }

  public getGlobalConfig() {
    const params = new URLSearchParams(window.location.search);
    const searchState = getSearchState();
    const hotel = searchState?.hotel || params.get('hotel');
    const restaurant = searchState?.restaurant || params.get('restaurant');

    return !!restaurant
      ? this.restaurantConfig
      : !!hotel
        ? this.hotelConfig
        : this.config.config;
  }

  public getUiConfigId() {
    return this.uiConfigId;
  }

  public init = async (baseUrl: string, publishableKey: string) => {
    injector.set(API_CLIENT, new ApiClient(baseUrl));
    injector.set(GA_SERVICE, new GaService('UA-60502140-29'));
    this.publishableKey = publishableKey;

    try {
      for (const plugin of this.pluginInjector.getAllPlugins()) {
        await plugin.init();
      }

      const apiClient = injector.get<ApiClient>(API_CLIENT);
      apiClient.addInterceptors({
        'Mycheck-Product-Id': this.getGlobalConfig().settings.product,
      });
    } catch (error) {}

    injector.set(MYCHECK_APP, this);
  };

  public hydrateStores = async () => {
    const savedStores = Object.values(StoresConstants);
    const hydrate = create({ storage: localStorage, jsonify: true });
    await Promise.all(
      savedStores.map((storeName: string) =>
        hydrate(storeName, this.stores[storeName]),
      ),
    );
  };

  public renderApp = async () => {
    const storesArray = [];
    this.pluginInjector
      .getAllPlugins()
      .forEach((plugin) => storesArray.push(...plugin.getStores()));

    this.stores = storesArray.reduce((cur, next) => ({ ...cur, ...next }), {});
    await this.hydrateStores();

    this.pluginInjector
      .getAllPlugins()
      .forEach((plugin) => plugin.afterHydrate());

    return (
      <Suspense fallback={null}>
        <Provider {...this.stores}>
          <HistoryContext.Provider value={ContextValues}>
            <GoogleReCaptchaProvider
              reCaptchaKey={RECAPTCHA_V3_KEY}
              useEnterprise={true}
            >
              <RootNavigation />
            </GoogleReCaptchaProvider>
          </HistoryContext.Provider>
          <InternetTracker />
        </Provider>
      </Suspense>
    );
  };

  public render = async () => {
    const domNode = document.getElementById('root');
    const root = createRoot(domNode);

    root.render(await this.renderApp());
  };
}
