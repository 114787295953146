export class StorageInteraction {
  public getAccessToken() {
    const accessToken = 'accessToken';
    if (window.localStorage.getItem('AUTH_STORE') === null) {
      return null;
    }
    return JSON.parse(window.localStorage.getItem('AUTH_STORE'))[accessToken];
  }

  public isUserGuest() {
    const isGuest = 'isGuest';
    if (window.localStorage.getItem('AUTH_STORE') === null) {
      return null;
    }
    return JSON.parse(window.localStorage.getItem('AUTH_STORE'))[isGuest];
  }
}

export const storageInjector = new StorageInteraction();
