import React, { useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { limitString, MyCheckApp, useNavigation } from 'mycheck-core';

import { Icon, PriceFormatter } from '@components';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './OrderPageItem.module.scss';
import { ProductItem } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

const LIMIT_FOR_COMMENT = 80;

interface Props {
  item: ProductItem;
}

export const OrderPageItem: React.FC<Props> = ({ item }) => {
  const navigation = useNavigation();
  const location = useLocation();
  const getTranslationForItem = useGetTranslationForMenuItem();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const onClick = () => navigation.push(`/menu/${item.id}/${item.editId}`);

  const modifierKeys = Object.keys(item.modifiers)
    .map((key) => item.modifiers[key])
    .reduce((a, b) => [...a, ...b], []);

  return (
    <div
      data-test-id="order-page-item"
      className={styles.orderPageItem}
      onClick={onClick}
    >
      <div className={styles.orderPageItemGeneral}>
        <div className={styles.orderPageItemGeneralLeftSide}>
          <div
            className={styles.orderPageItemGeneralLeftSideEditCircle}
            style={{ backgroundColor: config.primary }}
          >
            <Icon name="pencil" />
          </div>
          <div className={styles.orderPageItemGeneralLeftSideTitle}>
            <span>{item.quantity}</span>
            <span className={styles.orderPageItemGeneralLeftSideTitleX}>x</span>
            <span>{getTranslationForItem(item.pos_unique_id, item).name}</span>
          </div>
        </div>
        <div className={styles.orderPageItemGeneralPrice}>
          <PriceFormatter value={item.price * item.quantity} />
        </div>
      </div>
      {item.modifierGroups
        .map((element) => element.modifiers)
        .reduce((a, b) => [...a, ...b], [])
        .filter((a) => modifierKeys.includes(a.pos_unique_id))
        .map((modifier) => (
          <div
            key={modifier.pos_unique_id}
            className={styles.orderPageItemAddons}
          >
            <div className={styles.orderPageItemAddonsTitle}>
              {getTranslationForItem(modifier.pos_unique_id, modifier).name}
            </div>
            {!!modifier.price && (
              <div className={styles.orderPageItemAddonsPrice}>
                +<PriceFormatter value={modifier.price * item.quantity} />
              </div>
            )}
          </div>
        ))}
      <div
        className={styles.orderPageItemComment}
        data-test-id="order-page-item-comment"
      >
        {limitString(item.comment, LIMIT_FOR_COMMENT)}
      </div>
    </div>
  );
};
