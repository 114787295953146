import { useEffect, useRef, useState } from 'react';

import { useMenuStore } from 'mycheck-core';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

export function useMenuElementVisibilityTracker({ onScrollChangeEvent }) {
  const MenuStore = useMenuStore();
  const list = useRef<
    Array<{ category: MenuCategoryType | MenuSubcategory; visible: boolean }>
  >([]);
  const [visibilityList, setVisibilityList] = useState(Date.now());
  const onChangeVisibility = (
    category: MenuCategoryType | MenuSubcategory,
    visible: boolean,
  ) => {
    if (list.current.length) {
      setVisibilityList(Date.now());
      const listFromItem = list.current.find(
        (e) => e.category.id === category.id,
      );
      if (listFromItem) {
        listFromItem.visible = visible;
      }
    }
  };

  useEffect(() => {
    const element = list.current.find((e) => e.visible)?.category;
    if (element) {
      onScrollChangeEvent(element);
    }
  }, [visibilityList, MenuStore.items]);

  useEffect(() => {
    list.current = MenuStore.categoriesAndSubcategoriesFlat.map(
      (e: MenuCategoryType | MenuSubcategory) => {
        const foundElement = list.current.find(
          (existing) => existing.category.id === e.id,
        );
        return foundElement ? foundElement : { category: e, visible: false };
      },
    );
  }, [MenuStore.menuStamp]);

  return { onChangeVisibility };
}
