import { sanitizeHtmlTags } from 'mycheck-core';
import React from 'react';

export const SanitizedHtml: React.FC<{ html: string }> = ({ html }) => {
  const sanitizedHtmlContent = sanitizeHtmlTags(html);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: sanitizedHtmlContent,
      }}
    />
  );
};
