import React, { PropsWithChildren } from 'react';

import { Field, useFormikContext } from 'formik';

import { CustomIcon } from '../Icon/CustomIcon';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends PropsWithChildren {
  name: string;
  value: boolean;
  id?: string;
  testId?: string;
  customStyles?: React.CSSProperties;
  customIconStyles?: React.CSSProperties;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  value,
  name,
  id,
  children,
  testId = '',
  customStyles,
  customIconStyles,
}) => {
  const formikProps = useFormikContext();
  return (
    <div className={styles.wrap} style={customStyles}>
      <div className={styles.checkbox}>
        <Field
          data-test-id={`checkbox-${testId}`}
          id={id || name}
          type="checkbox"
          name={name}
          checked={value}
          onChange={formikProps.handleChange}
        />
        <CustomIcon
          className={styles.checkboxCustom}
          style={customIconStyles}
          name={value ? 'checkboxV' : 'checkbox'}
        />
      </div>
      <label className={styles.label} htmlFor={id || name}>
        {children}
      </label>
    </div>
  );
};
