export enum ApiCacheConstants {
  USER_ORDER_BY_ID = 'USER_ORDER_BY_ID',
  CONFIRM_SLOT = 'CONFIRM_SLOT',
  FETCH_ALLERGENS = 'FETCH_ALLERGENS',
  FETCH_EXPERIENCE = 'FETCH_EXPERIENCE',
  FETCH_HOTELS = 'FETCH_HOTELS',
  FETCH_LOCATIONS = 'FETCH_LOCATIONS',
  FETCH_TIME_SETTINGS = 'FETCH_TIME_SETTINGS',
  FETCH_MENU = 'FETCH_MENU',
  FETCH_STOCK_ITEMS = 'FETCH_STOCK_ITEMS',
}
