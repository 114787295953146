import { isNumber } from 'lodash';
import moment from 'moment';
import { MyCheckApp, paramHandler, useNavigation } from 'mycheck-core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

enum OrderTimeout {
  DEFAULT_EXPIRATION_TIME_IN_MINUTES = 30,
  INTERVAL_MS = 15000,
  TABLE_KEY = 'table',
  ROOM_KEY = 'room',
  TIMEOUT = 'TIMEOUT',
}

const OrderTimeoutService = ({ children }) => {
  const [redirectTick, setRedirectTick] = useState(0);
  const [storedOrderSessionKey, setStoredOrderSessionKey] = useState('');
  const params = useMemo(() => new URLSearchParams(window.location.search), []);
  const roomParam = params.get(OrderTimeout.ROOM_KEY);
  const tableParam = params.get(OrderTimeout.TABLE_KEY);
  const navigation = useNavigation();
  const orderExpirationMinutes =
    MyCheckApp.instance.getOrderExpirationMinutes() ||
    OrderTimeout.DEFAULT_EXPIRATION_TIME_IN_MINUTES;

  const shouldNotStartInterval = useCallback(
    (storedOrderTime: OrderTimeout.TIMEOUT | string) => {
      return (
        !storedOrderTime ||
        storedOrderTime === OrderTimeout.TIMEOUT ||
        !isNumber(orderExpirationMinutes)
      );
    },
    [orderExpirationMinutes],
  );

  const getExistingOrderSessionKeys = () => {
    const existingKeys = Object.keys(sessionStorage).filter(
      (key) =>
        key.startsWith(`${OrderTimeout.TABLE_KEY}_`) ||
        key.startsWith(`${OrderTimeout.ROOM_KEY}_`),
    );

    return existingKeys;
  };

  const clearExistingOrderSessionStorage = useCallback(() => {
    const existingKeys = getExistingOrderSessionKeys();

    existingKeys.forEach((existingKey) =>
      sessionStorage.removeItem(existingKey),
    );
  }, []);

  const redirectOnTimeoutOrderSession = useCallback(() => {
    const existingKeys = getExistingOrderSessionKeys();

    if (redirectTick === 100) {
      return;
    }

    existingKeys.forEach((existingKey) => {
      const orderTimeValue = sessionStorage.getItem(existingKey);

      if (orderTimeValue === OrderTimeout.TIMEOUT) {
        navigation.push('/order-timeout', true);

        //workaround to prevent user navigating with browser back button
        setRedirectTick((prevTick) => prevTick + 1);
      }
    });
  }, [navigation]);

  const handleStoreOrderSession = useCallback(() => {
    const orderParamDestinations = [
      OrderTimeout.TABLE_KEY,
      OrderTimeout.ROOM_KEY,
    ] as string[];

    orderParamDestinations.forEach((orderParamKey) => {
      paramHandler(params, orderParamKey, (orderParamValue) => {
        const paramKey = `${orderParamKey}_${orderParamValue}`;
        const savedParam = sessionStorage.getItem(paramKey);

        if (!savedParam && orderParamValue) {
          clearExistingOrderSessionStorage();
          sessionStorage.setItem(paramKey, moment().format());
        }
      });
    });
  }, [clearExistingOrderSessionStorage, params]);

  const handleOrderTimeout = useCallback(
    (interval: NodeJS.Timeout) => {
      sessionStorage.setItem(storedOrderSessionKey, OrderTimeout.TIMEOUT);
      clearInterval(interval);
      navigation.push('/order-timeout', true);
    },
    [storedOrderSessionKey],
  );

  const handleOrderInterval = useCallback(
    (storedOrderTime, interval: NodeJS.Timeout) => {
      const minutesPast = moment().diff(
        moment(storedOrderTime).format(),
        'minutes',
      );

      if (orderExpirationMinutes <= minutesPast) {
        handleOrderTimeout(interval);
      }
    },
    [handleOrderTimeout, orderExpirationMinutes],
  );

  useEffect(() => {
    redirectOnTimeoutOrderSession();
    if (roomParam && tableParam) {
      setStoredOrderSessionKey('');
      return;
    }

    tableParam
      ? setStoredOrderSessionKey(`${OrderTimeout.TABLE_KEY}_${tableParam}`)
      : setStoredOrderSessionKey(`${OrderTimeout.ROOM_KEY}_${roomParam}`);

    handleStoreOrderSession();
  }, [
    handleStoreOrderSession,
    redirectOnTimeoutOrderSession,
    params,
    roomParam,
    tableParam,
  ]);

  useEffect(() => {
    redirectOnTimeoutOrderSession();
    const storedOrderTime = sessionStorage.getItem(storedOrderSessionKey);

    if (shouldNotStartInterval(storedOrderTime)) {
      return;
    }

    const interval = setInterval(
      () => handleOrderInterval(storedOrderTime, interval),
      OrderTimeout.INTERVAL_MS,
    );

    return () => clearInterval(interval);
  }, [
    handleOrderInterval,
    orderExpirationMinutes,
    redirectOnTimeoutOrderSession,
    roomParam,
    shouldNotStartInterval,
    storedOrderSessionKey,
    tableParam,
  ]);

  return <>{children}</>;
};

export default OrderTimeoutService;
