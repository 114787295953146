import round from 'lodash/round';
import { ICurrency } from 'plugins/LocationPlugin/types/LocationTypes';

export const currencyFormatter = (
  value: number,
  currency?: ICurrency,
): string => {
  const currentCurrency = currency || {
    code: 'USD',
    name: 'US Dollar',
    minorUnit: 2,
    symbol: '$',
    thousandsSeparator: '',
    customSymbol: null,
  };

  const roundedPrice = round(value, currentCurrency.minorUnit).toFixed(
    currentCurrency.minorUnit,
  );
  const [integerPart, decimalPart] = roundedPrice.split('.');
  const withThousandSeparator = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    currentCurrency.thousandsSeparator ?? '',
  );
  const formattedPrice = `${withThousandSeparator}${
    decimalPart !== undefined ? `.${decimalPart}` : ''
  }`;

  return `${currentCurrency.symbol}${
    currentCurrency.customSymbol !== null ? currentCurrency.customSymbol : ''
  } ${formattedPrice}`;
};
