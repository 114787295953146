import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuPageContext } from './MenuPageContext';
import {
  MyCheckApp,
  getSearchState,
  setHtmlPageTitle,
  useAuthStore,
  useErrorTrackerHandler,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useNavigation,
  useWindowSize,
} from 'mycheck-core';
import { useProductClick } from 'plugins/MenuPlugin/hooks/useProductClick';
import { get } from 'lodash';
import { observer } from 'mobx-react';
import MenuPageContainer, { MenuPageContainerProps } from './MenuPageContainer';
import { MenuPageBody, MenuPageBodyProps } from './MenuPageBody';
import { MenuItem } from 'types/GenericTypes';
import { MenuPageDesktop } from '../MenuPageDesktop';
import { MenuPageMobile } from '../MenuPageMobile';

interface MenuPageProps {
  Container: React.FC<MenuPageContainerProps>;
  Body: React.FC<MenuPageBodyProps>;
}

const MenuPage: React.FC & MenuPageProps = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState<MenuItem | null>(null);
  const [allergensModalOpen, setAllergensModalOpen] = useState(false);
  const [allergens, setAllergens] = useState<Array<number> | null>(null);
  const [viewMenuModalOpen, setViewMenuModalOpen] = useState(false);

  const AuthStore = useAuthStore();
  const MenuStore = useMenuStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();

  const isLoading = MenuStore.isFetching || LocationStore.isFetching;

  const { isLg } = useWindowSize();
  const navigation = useNavigation();
  const { onClickItem } = useProductClick();
  const errorHandler = useErrorTrackerHandler();
  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const isCookiePolicyVisible = get(_config, 'settings.cookiePolicy', true);
    const viewModeBox = get(_config, 'menu.viewModeBox', {});
    const appSettingName = get(_config, 'settings.name');

    setHtmlPageTitle(appSettingName);

    return {
      isCookiePolicyVisible,
      viewModeBox,
    };
  }, [location.search]);

  const headerTitle = useMemo(
    () =>
      LanguageStore.getBusinessTranslation(
        LocationStore.selectedRestaurant.id,
        'name',
        LocationStore.selectedRestaurant.name,
      ),
    [LocationStore.selectedRestaurant.id],
  );

  LocationStore.setNavigation(navigation);

  useEffect(() => {
    if (!AuthStore.isGuestLoggedIn) {
      AuthStore.logInGuest(LanguageStore.selectedValue, errorHandler.onError);
    }
  }, [AuthStore.isGuestLoggedIn]);

  const handleOpenProductModal = useCallback(
    (id: number) => {
      navigation.push(`/menu/${id}`);
    },
    [window.location.search],
  );

  useEffect(() => {
    (async () => {
      const values = getSearchState();

      if (
        values.restaurant ||
        (!LocationStore.selectedExperience &&
          !LocationStore.selectedExperienceId)
      ) {
        await LocationStore.fetchAll('menu');
      }
    })();
  }, []);

  const handleProductClick = useCallback(
    async (item: MenuItem) => {
      try {
        if (MenuStore.getIsAdult() || !item.under_18) {
          await onClickItem(item, handleOpenProductModal);
        } else {
          setSelectedProduct(item);
        }
      } catch (err) {
        if (err instanceof Error) {
          errorHandler.onError(err);
        }
      }
    },
    [window.location.search],
  );

  const handleAllergensClick = useCallback(
    (value: Array<number> | null) => {
      setAllergens(value);
      setAllergensModalOpen(!allergensModalOpen);
    },
    [allergensModalOpen],
  );

  const handleCloseAllergens = useCallback(
    () => setAllergensModalOpen(!allergensModalOpen),
    [allergensModalOpen],
  );

  const handleCloseAlcoholModal = () => {
    setSelectedProduct(null);
  };

  const handleSubmitAlcoholModal = async () => {
    try {
      handleCloseAlcoholModal();
      await onClickItem(selectedProduct, handleOpenProductModal);
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err);
      }
    }
  };

  return (
    <MenuPageContext.Provider
      value={{
        setSelectedTab,
        handleProductClick,
        handleAllergensClick,
        handleCloseAllergens,
        handleSubmitAlcoholModal,
        handleCloseAlcoholModal,
        setViewMenuModalOpen,
        selectedTab,
        selectedProduct,
        allergens,
        viewMenuModalOpen,
        allergensModalOpen,
        isLoading,
        config,
        headerTitle,
      }}
    >
      <MenuPage.Container>
        {isLg ? <MenuPageDesktop /> : <MenuPageMobile />}
      </MenuPage.Container>
    </MenuPageContext.Provider>
  );
};

MenuPage.Container = MenuPageContainer;
MenuPage.Body = MenuPageBody;

MenuPage.displayName = 'MenuPage';

export default observer(MenuPage);
