import React, { useContext, useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { PopUp } from '@components';

import styles from './ViewMenuModal.module.scss';
import { MenuPageContext } from 'plugins/MenuPlugin/pages/MenuPage/common/MenuPageContext';

export const ViewMenuModal: React.FC = () => {
  const { setViewMenuModalOpen } = useContext(MenuPageContext);
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});

    return {
      titleStyle,
    };
  }, [location.search]);

  const handleModalClose = () => setViewMenuModalOpen(false);

  return (
    <PopUp
      onClose={handleModalClose}
      testId="view-menus"
      buttonText={t('menu.viewMenuModal.button')}
    >
      <div className={styles.wrapper}>
        <div className={styles.title} style={config.titleStyle}>
          {t('menu.viewMenuModal.body')}
        </div>
      </div>
    </PopUp>
  );
};
