import React, { useMemo } from 'react';

import { FormikProps } from 'formik';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { CustomInput, RadioButtonGroup, SanitizedHtml } from '@components';

import { AcceptPrivacy } from '../../../../components/AcceptPrivacy/AcceptPrivacy';

import styles from './UserInfoScreen.module.scss';
import { RegisterFormikValues } from 'plugins/AuthPlugin/types/FormikValuesType';
import get from 'lodash/get';

interface Props {
  formikProps: FormikProps<RegisterFormikValues>;
}

export const UserInfoScreen: React.FC<Props> = ({ formikProps }) => {
  const { t } = useTranslation();
  const {
    values: { email, firstName, lastName, optinMarketing },
  } = formikProps;

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const marketingOptIn = get(_config, 'settings.marketingOptInStatement', {});
    const marketingOptOut = get(
      _config,
      'settings.marketingOptOutStatement',
      {},
    );
    const privacyPolicyAndTerms = get(
      _config,
      'settings.privacyPolicyAndTerms',
      {},
    );

    return {
      marketingOptIn,
      marketingOptOut,
      privacyPolicyAndTerms,
    };
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.rootContainer}>
        <CustomInput
          onChange={formikProps.handleChange}
          value={email}
          type={'email'}
          name={'email'}
          placeholder={t('register.emailPlaceholder')}
          customStyle={{ margin: '40px 0 24px' }}
          disabled
        />
        <CustomInput
          onChange={formikProps.handleChange}
          value={firstName}
          type={'text-only'}
          name={'firstName'}
          placeholder={t('register.firstNamePlaceholder')}
          error={!!formikProps.errors.firstName}
          errorMessage={formikProps.errors.firstName}
          customStyle={{ marginTop: 0 }}
          withAnimation
        />
        <CustomInput
          onChange={formikProps.handleChange}
          value={lastName}
          type={'text-only'}
          name={'lastName'}
          placeholder={t('register.lastNamePlaceholder')}
          error={!!formikProps.errors.lastName}
          errorMessage={formikProps.errors.lastName}
          customStyle={{ marginTop: 0 }}
          withAnimation
        />
        {/**
         * @todo add translations to RadioButtonGroup labels
         */}
        <RadioButtonGroup
          value={optinMarketing}
          formikProps={formikProps}
          property={'optinMarketing'}
          containerClassName={styles.buttonGroupContainer}
          itemKeyForCheck="value"
          radioButtonStyle={{
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '19px',
          }}
          data={[
            {
              label: t('register.marketingOptIn'),
              value: '1',
              content: config.marketingOptIn ? (
                <SanitizedHtml html={config.marketingOptIn} />
              ) : null,
            },
            {
              label: t('register.marketingOptOut'),
              value: '0',
              content: config.marketingOptOut ? (
                <SanitizedHtml html={config.marketingOptOut} />
              ) : null,
            },
          ]}
        />
        <AcceptPrivacy privacyPolicyAndTerms={config.privacyPolicyAndTerms} />
      </div>
    </div>
  );
};
