import React, { useMemo } from 'react';

import classnames from 'classnames';
import { css } from 'glamor';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { ClosePage, Overlay, PrimaryButton } from '@components';

import styles from '../../../../core/components/ModalTrackers/ErrorModalTracker/ErrorModalTracker.module.scss';

type Props = {
  handleClose: () => void;
};

export const QuantityLimitedModal: React.FC<Props> = ({ handleClose }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const descriptionStyle = get(_config, 'general.popup.mainText', {});
    const quantity = get(_config, 'settings.itemLimitation', 100);

    return {
      descriptionStyle,
      quantity,
    };
  }, [location.search]);

  return (
    <Overlay testId="error-modal" className={styles.overlay} isPopup>
      <div className={styles.root}>
        <ClosePage
          testId="error-modal"
          onClose={handleClose}
          customStylePath="general.popup.icons.x"
        />
        <div
          className={classnames(
            css(config.descriptionStyle).toString(),
            styles.wrapper,
          )}
        >
          {t('errors.CART_LIMIT_ERROR', { quantity: config.quantity })}
        </div>
        <PrimaryButton onClick={handleClose}>{t('general.ok')}</PrimaryButton>
      </div>
    </Overlay>
  );
};
