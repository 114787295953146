enum PaymentMethodsValue {
  PAY_IN_PERSON = 'PAY_IN_PERSON',
  ROOM = 'ROOM',
  WALLET = 'WALLET',
}

enum PaymentMethodsLabel {
  PAY_IN_PERSON = 'Pay in person',
  ROOM = 'Charge to room',
  WALLET = 'Credit Card',
}

const SupportedPayments = Object.values(PaymentMethodsValue);

enum DeliveryOptionsValue {
  ROOM = 'ROOM',
  PICK_UP = 'PICK_UP',
}

enum DeliveryOptionsLabel {
  ROOM = 'Deliver to room',
  PICK_UP = 'Pick-up',
}

export const PaymentConstants = {
  SupportedPayments,
  PaymentMethodsValue,
  PaymentMethodsLabel,
  DeliveryOptionsValue,
  DeliveryOptionsLabel,
};
