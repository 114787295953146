import React, { useMemo } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useLocationStore } from 'mycheck-core';

import { Background, Loader } from '@components';

import { Footer } from '../../../../components/Footer/Footer';
import { CookiePolicy } from '../../../LocationPlugin/components/CookiePolicy/CookiePolicy';
import { OptionsContent } from '../../components/OptionsContent/OptionsContent';
import { OptionsHeader } from '../../components/OptionsHeader/OptionsHeader';

import styles from './OptionsPageMobile.module.scss';

export const OptionsPageMobile: React.FC = observer(() => {
  const LocationStore = useLocationStore();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const image = get(_config, 'experience.options_page_image', null);
    const { primary, secondaryBackground } = get(_config, 'palette', {});
    const isCookiePolicyVisible = get(_config, 'settings.cookiePolicy', true);

    return {
      isCookiePolicyVisible,
      primary,
      secondaryBackground,
      image,
    };
  }, [location.search]);

  return (
    <Background secondary className={styles.optionsPage} id="optionsPage">
      {config.isCookiePolicyVisible && <CookiePolicy />}
      <div
        className={styles.optionsPageBackground}
        style={{
          background: `linear-gradient(0deg, ${config.secondaryBackground} 0%, ${config.primary} 100%)`,
        }}
      >
        {config.image && (
          <img
            className={styles.optionsPageBackgroundImage}
            src={config.image}
            alt=""
          />
        )}
      </div>

      <div className={styles.optionsPageContainer}>
        <OptionsHeader />

        {LocationStore.selectedHotelObj.id && (
          <OptionsContent hotel={LocationStore.selectedHotelObj} />
        )}
      </div>
      <Footer />
      {LocationStore.isFetching ||
        (LocationStore.isHotelListFetching && <Loader />)}
    </Background>
  );
});

OptionsPageMobile.displayName = 'OptionsPageMobile';
