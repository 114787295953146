export enum PaymentMethodsValue {
  PAY_IN_PERSON = 'PAY_IN_PERSON',
  CHARGE_TO_ROOM = 'ROOM',
  WALLET = 'EMV',
}

export enum PaymentMethods {
  PAY_IN_PERSON = 'Pay in person',
  CHARGE_TO_ROOM = 'Charge to room',
  WALLET = 'Credit Card',
}

export const PaymentMethodsOptions = Object.keys(PaymentMethods).map((key) => ({
  value: PaymentMethodsValue[key],
  label: PaymentMethods[key],
}));

export enum NumberOfGuest {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
}

export const NumberOfGuestOptions = Object.values(NumberOfGuest).map((key) => ({
  value: key,
  label: key,
}));

export enum TipType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export enum TipStringValues {
  NO_TIP = 'noTip',
  CUSTOM = 'custom',
}
