import React, { useMemo, useState, type CSSProperties } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useCheckoutStore,
  useLanguageStore,
  useWindowSize,
} from 'mycheck-core';

import { Background, ExternalLink, VersionsModal } from '@components';

import styles from './Footer.module.scss';

type Props = {
  style?: CSSProperties;
  className?: string;
  dataTestFooterVersion?: string;
};

export const Footer: React.FC<Props> = ({
  style,
  className,
  dataTestFooterVersion = 'footer-version',
}) => {
  const [showVersionsModal, setShowVersionsModal] = useState(false);
  const { t } = useTranslation();
  const { selectedValue } = useLanguageStore();
  const cookiesPolicyUrlLanguage = selectedValue || 'en_US';
  const location = useLocation();
  const CheckoutStore = useCheckoutStore();
  const { isLg } = useWindowSize();

  const toggleVersionModal = () => setShowVersionsModal(!showVersionsModal);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const bodyStyle = get(_config, 'general.popup.mainText', {});

    const legalDisclosure = get(_config, 'home.settings.legalDisclosure', null);
    const termsAndConditions = get(
      _config,
      'home.settings.termsAndConditions',
      null,
    );
    const privacyPolicy = get(_config, 'home.settings.privacyPolicy', null);
    const cookiesPolicyUrl = get(_config, 'settings.cookiesPolicyUrl', null);

    return {
      bodyStyle,
      legalDisclosure,
      termsAndConditions,
      privacyPolicy,
      cookiesPolicyUrl,
    };
  }, [location.search]);

  return (
    <Background
      secondary
      className={classnames(styles.footer, className, {
        [styles.mobileOrderButtonPlaceholder]:
          !!CheckoutStore.numberOfCurrentOrderItems && !isLg,
      })}
      style={style}
    >
      {config.legalDisclosure && (
        <>
          <ExternalLink
            href={config.legalDisclosure}
            className={styles.footerLink}
          >
            {t('home.legalDisclosure')}
          </ExternalLink>
          <span className={styles.footerDivider}>|</span>
        </>
      )}

      {config.termsAndConditions && (
        <>
          <ExternalLink
            href={config.termsAndConditions}
            className={styles.footerLink}
          >
            {t('home.termsAndConditions')}
          </ExternalLink>
          <span className={styles.footerDivider}>|</span>
        </>
      )}

      {config.privacyPolicy && (
        <>
          <ExternalLink
            href={config.privacyPolicy}
            className={styles.footerLink}
          >
            {t('home.privacyPolicy')}
          </ExternalLink>
          <span className={styles.footerDivider}>|</span>
        </>
      )}

      {config.cookiesPolicyUrl && (
        <>
          <ExternalLink
            href={`${config.cookiesPolicyUrl}/${cookiesPolicyUrlLanguage}.pdf`}
            className={styles.footerLink}
          >
            {t('home.cookiePolicyLink')}
          </ExternalLink>
          <span className={styles.footerDivider}>|</span>
        </>
      )}

      <span
        className={styles.footerLink}
        onClick={toggleVersionModal}
        data-test-id={dataTestFooterVersion}
      >
        {t('versions.footerElement')}
      </span>
      {showVersionsModal && (
        <VersionsModal
          bodyStyle={config.bodyStyle}
          toggleVersionModal={toggleVersionModal}
        />
      )}
    </Background>
  );
};
