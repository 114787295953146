import React, { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import {
  attachAccessibility,
  attachFavicon,
  attachFonts,
  FLOW_TYPES,
  GaService,
  getDate,
  injector,
  MyCheckApp,
  setDateTime,
  setHtmlPageTitle,
  useAuthStore,
  useCheckoutStore,
  useErrorTrackerHandler,
  useFlowType,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useModalNavigator,
  useNavigation,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Background, Loader, MenuSideBar } from '@components';

import { HTTP_AUTH_API, HttpAuthApi } from '../../../AuthPlugin/AuthApi';
import { CookiePolicy } from '../../components/CookiePolicy/CookiePolicy';
import { FooterDesktop } from '../../components/FooterDesktop/FooterDesktop';
import { HomePageForm } from '../../components/HomePageForm/HomePageForm';

import { Header } from './Header/Header';
import styles from './HomePage.module.scss';

export const HomePage: React.FC = () => {
  const navigation = useNavigation();
  const { openRegisterPage } = useModalNavigator();
  const { t } = useTranslation();
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const MenuStore = useMenuStore();
  const AuthStore = useAuthStore();
  const errorHandler = useErrorTrackerHandler();
  const { isRestaurantFlow } = useFlowType();
  const { isLg } = useWindowSize();
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRestaurantDataLoading, setIsRestaurantDataLoading] = useState(false);

  const closeMenu = () => setIsMenuOpen(false);

  LocationStore.setNavigation(navigation);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const fonts = get(_config, 'settings.fonts');
    const favicon = get(_config, 'settings.favicon');
    const accessibility = get(_config, 'settings.accessibility');
    const appSettingName = get(_config, 'settings.name');

    setHtmlPageTitle(appSettingName);
    attachFonts(fonts);
    attachFavicon(favicon);
    attachAccessibility(
      accessibility && process.env.NODE_ENV !== 'development',
    );

    const isCookiePolicyVisible = get(_config, 'settings.cookiePolicy', true);
    const { color, image } = get(_config, 'home.style.background', {});
    const backgroundConfig = {
      background: color || `url(${image})`,
      backgroundSize: 'cover',
    };

    return {
      isCookiePolicyVisible,
      backgroundConfig,
    };
  }, [location.search]);

  const headerConfig = { background: 'transparent', boxShadow: 'none' };
  const nativeConfig = LocationStore.isNative ? { paddingTop: '44px' } : {};

  const initialValues = {
    selectedDate: isRestaurantFlow
      ? setDateTime(getDate(LocationStore.selectedDate))
      : LocationStore.selectedDate,
    selectedExperienceType: LocationStore.selectedExperienceType,
    selectedLocationID: isRestaurantFlow
      ? (LocationStore.selectedRestaurantId || '').toString()
      : LocationStore.selectedHotel,
    selectedTime: isRestaurantFlow
      ? setDateTime(getDate(LocationStore.selectedDate || dayjs().format()))
      : LocationStore.selectedDate,
  };

  const handleMenuOpen = () => {
    if (AuthStore.isGuest) {
      openRegisterPage();
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const getValidateSchema = () =>
    yup.object().shape({
      selectedDate:
        LocationStore.flowType === FLOW_TYPES.RESTAURANT
          ? yup.string().required()
          : yup.string(),
      selectedExperience: yup.string(),
      selectedLocationID: yup.string().required(),
      selectedTime:
        LocationStore.flowType === FLOW_TYPES.RESTAURANT
          ? yup.string().required()
          : yup.string(),
    });

  const onSubmit = async ({
    selectedLocationID,
    selectedDate,
    selectedTime,
    selectedExperienceType,
  }) => {
    const isSameHotel = +selectedLocationID === LocationStore.selectedHotel;

    if (isSameHotel && !isLg) {
      LocationStore.setSelectedExperienceType('');
      LocationStore.setSelectedExperienceTypeTemp('');
    }

    if (isRestaurantFlow) {
      if (LocationStore.selectedRestaurantId !== selectedLocationID) {
        CheckoutStore.clearCheckoutWithTime();
      }

      LocationStore.setSelectedRestaurantId(Number(selectedLocationID));

      if (selectedDate && selectedTime) {
        LocationStore.setSelectedDate(selectedDate);
        LocationStore.setSelectedTime(selectedTime);

        LocationStore.setSelectedDateTemp(selectedDate);
        LocationStore.setSelectedTimeTemp(selectedTime);
      }
    } else {
      LocationStore.setSelectedHotel(Number(selectedLocationID));
    }

    GaService.instance.sendEvent({
      category: 'Ordering - Start',
      action: 'Location',
      label: LocationStore.homeList.find(
        (hotel) => hotel.id === Number(selectedLocationID),
      ).name,
    });

    if (selectedExperienceType && !isSameHotel) {
      LocationStore.setSelectedExperienceType(selectedExperienceType);
      LocationStore.setSelectedExperienceTypeTemp(selectedExperienceType);
    }

    if (isRestaurantFlow) {
      setIsRestaurantDataLoading(true);

      const experience = LocationStore.selectedRestaurant.experiences?.find(
        (selectedRestaurantExperience) =>
          selectedRestaurantExperience.type ===
          LocationStore.selectedExperienceType,
      );

      try {
        await LocationStore.fetchExperience(experience.id);
        await MenuStore.selectCurrentMenu({
          bid: LocationStore.selectedRestaurant.id,
          menus: LocationStore.menuFiles,
          translations: LocationStore.menuTranslations,
          time: CheckoutStore.checkoutTime,
          timezone: LocationStore.locationTimezoneName,
          language: LanguageStore.selectedValue,
        });

        if (experience.id !== LocationStore.selectedExperience.id) {
          CheckoutStore.clearCheckoutWithTime();
        }

        GaService.instance.sendEvent({
          category: 'Ordering - Start',
          action: 'Date',
          label: selectedDate,
        });
        GaService.instance.sendEvent({
          category: 'Ordering - Start',
          action: 'Time',
          label: selectedTime,
        });
        GaService.instance.sendEvent({
          category: 'Ordering - Start',
          action: 'Experience',
          label: selectedExperienceType,
        });

        navigation.push('/menu');
      } catch (err) {
        if (err instanceof Error) {
          GaService.instance.sendEvent({
            category: 'Ordering - Start',
            action: 'Error',
            label: err.message,
          });
          setIsRestaurantDataLoading(false);
          errorHandler.onError(err);
        }
      }
    } else {
      const authApi = injector.get<HttpAuthApi>(HTTP_AUTH_API);
      const uiConfig =
        await authApi.fetchUIConfigByBusinessId(selectedLocationID);
      const parsedConfig = JSON.parse(uiConfig.data.config as string);
      parsedConfig.settings.name = uiConfig.data.name;
      parsedConfig.settings.cookiesPolicyUrl = uiConfig.data?.policy_url;
      attachFonts(parsedConfig.settings.fonts);
      MyCheckApp.instance.setHotelConfig(parsedConfig);

      if (parsedConfig.settings.favicon) {
        attachFavicon(parsedConfig.settings.favicon);
      }

      attachAccessibility(
        parsedConfig.settings.accessibility &&
          process.env.NODE_ENV !== 'development',
      );

      navigation.push('/location');
    }
  };

  useEffect(() => {
    LocationStore.clearRestaurantList();
  });

  return (
    <Background style={config.backgroundConfig} className={styles.homePage}>
      {config.isCookiePolicyVisible && <CookiePolicy />}
      <Header
        onHomePage
        style={{ position: 'absolute', ...headerConfig, ...nativeConfig }}
        onPress={handleMenuOpen}
        title={t('name')}
      />
      <MenuSideBar
        isMenuOpen={isMenuOpen}
        handleMenuOpen={handleMenuOpen}
        closeMenu={closeMenu}
      />
      {isRestaurantDataLoading && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={getValidateSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <HomePageForm />
      </Formik>
      <FooterDesktop isHomeScreen />
    </Background>
  );
};
