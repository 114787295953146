import React, { useEffect, useReducer } from 'react';

import classnames from 'classnames';

import styles from './PickerColumn.module.scss';

interface PickerColumnProps {
  options: any[];
  name: string;
  value: any;
  itemHeight: number;
  columnHeight: number;
  onChange: (name: string, newValue: any) => void;
  tabIndex: number;
  naturalScroll: boolean;
  textAlign?: 'center' | 'right' | 'left';
}

const initialState = {
  isMoving: false,
  startTouchY: 0,
  startScrollerTranslate: 0,
  scrollerTranslate: 0,
  minTranslate: 0,
  maxTranslate: 0,
  lastScrollTime: 0,
};

type Action =
  | { type: 'touch-end' }
  | {
      type: 'touch-start';
      payload: {
        startTouchY: number;
      };
    }
  | {
      type: 'translate';
      payload: {
        scrollerTranslate: number;
        minTranslate: number;
        maxTranslate: number;
      };
    }
  | { type: 'scroll'; payload: { deltaY: number } }
  | { type: 'moving' }
  | { type: 'touch-move'; payload: { touchY: number } }
  | { type: 'touch-cancel' }
  | { type: 'reset' };

const reducer = (
  state: typeof initialState,
  action: Action,
): typeof initialState => {
  switch (action.type) {
    case 'moving':
      return {
        ...state,
        isMoving: true,
      };
    case 'translate':
      return {
        ...state,
        ...action.payload,
      };
    case 'touch-start':
      return {
        ...state,
        startTouchY: action.payload.startTouchY,
        startScrollerTranslate: state.scrollerTranslate,
      };
    case 'touch-end':
      return {
        ...state,
        isMoving: false,
        startTouchY: 0,
        startScrollerTranslate: 0,
      };
    case 'touch-cancel':
      return {
        ...state,
        isMoving: false,
        startTouchY: 0,
        startScrollerTranslate: 0,
        scrollerTranslate: state.startScrollerTranslate,
      };
    case 'scroll': {
      const newTranslate = Math.max(
        state.minTranslate,
        Math.min(
          state.maxTranslate,
          (state.scrollerTranslate || 0) + Math.round(action.payload.deltaY),
        ),
      );

      return {
        ...state,
        scrollerTranslate: newTranslate,
        lastScrollTime: Date.now(),
      };
    }
    case 'touch-move': {
      let nextScrollerTranslate =
        state.startScrollerTranslate +
        action.payload.touchY -
        state.startTouchY;
      if (nextScrollerTranslate < state.minTranslate) {
        nextScrollerTranslate =
          state.minTranslate -
          Math.pow(state.minTranslate - nextScrollerTranslate, 0.8);
      } else if (nextScrollerTranslate > state.maxTranslate) {
        nextScrollerTranslate =
          state.maxTranslate +
          Math.pow(nextScrollerTranslate - state.maxTranslate, 0.8);
      }
      return {
        ...state,
        scrollerTranslate: nextScrollerTranslate,
      };
    }
    case 'reset':
      return initialState;
    default:
      return state;
  }
};
export const PickerColumn = (props: PickerColumnProps) => {
  const {
    options,
    name,
    value,
    columnHeight,
    itemHeight,
    tabIndex,
    naturalScroll,
    onChange,
    textAlign = '',
  } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    isMoving,
    lastScrollTime,
    scrollerTranslate,
    minTranslate,
    maxTranslate,
  } = state;

  const onValueSelected = (newValue) => {
    onChange(name, newValue);
  };

  useEffect(() => {
    if (isMoving) return;

    let selectedIndex = options.indexOf(value);

    if (selectedIndex < 0) {
      console.warn(
        'Warning: "' +
          name +
          '" doesn\'t contain an option of "' +
          value +
          '".',
      );
      onValueSelected(options[0]);
      selectedIndex = 0;
    }

    dispatch({
      type: 'translate',
      payload: {
        scrollerTranslate:
          columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight,
        minTranslate:
          columnHeight / 2 - itemHeight * options.length + itemHeight / 2,
        maxTranslate: columnHeight / 2 - itemHeight / 2,
      },
    });
  }, [props.onChange]);

  const handleTouchStart = (event) => {
    const startTouchY = event.targetTouches[0].pageY;

    dispatch({ type: 'touch-start', payload: { startTouchY } });
  };

  const handleTouchMove = (event) => {
    const touchY = event.targetTouches[0].pageY;

    if (!isMoving) {
      dispatch({ type: 'moving' });
      return;
    }

    dispatch({
      type: 'touch-move',
      payload: {
        touchY,
      },
    });
  };

  const postMove = () => {
    let activeIndex;
    if (scrollerTranslate > maxTranslate) {
      activeIndex = 0;
    } else if (scrollerTranslate < minTranslate) {
      activeIndex = options.length - 1;
    } else {
      activeIndex = Math.abs(
        Math.round((scrollerTranslate - maxTranslate) / itemHeight),
      );
    }

    onValueSelected(options[activeIndex]);
  };

  const handleTouchEnd = () => {
    if (!isMoving) return;

    dispatch({ type: 'touch-end' });

    setTimeout(() => {
      postMove();
    }, 100);
  };

  const handleTouchCancel = () => {
    if (!isMoving) return;

    dispatch({ type: 'touch-cancel' });
  };

  const handleItemClick = (option) => {
    if (option !== value) {
      onValueSelected(option);
    }
  };

  useEffect(() => {
    if (!lastScrollTime) return;

    const timer = setTimeout(() => {
      postMove();

      dispatch({
        type: 'reset',
      });
    }, 0);

    return () => clearTimeout(timer);
  }, [lastScrollTime]);

  const handleScroll = (event) => {
    // Support for keyboard up/down
    let deltaY = 0;

    if (!!event.keyCode && (event.keyCode === 38 || event.keyCode === 40)) {
      deltaY = event.keyCode === 38 ? 53 : -53;
    } else if (!!event.deltaY) {
      deltaY = event.deltaY;
    }

    dispatch({
      type: 'scroll',
      payload: { deltaY: naturalScroll ? -deltaY : deltaY },
    });
  };

  const renderItems = () => {
    return options.map((option, index) => {
      const style = {
        height: itemHeight + 'px',
        lineHeight: itemHeight + 'px',
      };

      return (
        <div
          key={index}
          className={classnames(styles.pickerColumnPickerItem, {
            [styles.pickerColumnPickerItemSelected]: option === value,
          })}
          style={style}
          onClick={() => handleItemClick(option)}
        >
          {option}
        </div>
      );
    });
  };

  const translateString = `translate3d(0, ${scrollerTranslate}px, 0)`;
  const style = {
    MsTransform: translateString,
    MozTransform: translateString,
    OTransform: translateString,
    WebkitTransform: translateString,
    transform: translateString,
    ...(isMoving && { transitionDuration: '0ms' }),
    ...(lastScrollTime && { transition: 'unset' }),
  };

  return (
    <div
      className={styles.pickerColumn}
      style={textAlign ? { textAlign: textAlign } : {}}
    >
      <div
        tabIndex={tabIndex}
        className={styles.pickerColumnPickerScroller}
        style={style}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onWheel={handleScroll}
        onKeyDown={handleScroll}
      >
        {renderItems()}
      </div>
    </div>
  );
};
