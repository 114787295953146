import React, { useMemo, useState } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { PopUp } from '@components';

import styles from './GuestSelectionPopup.module.scss';

type Props = {
  options: Array<{ firstName: string; lastName: string; token: string }>;
  onSubmit: (value: string) => void;
  onClose: () => void;
};

export const GuestSelectionPopup: React.FC<Props> = ({
  onSubmit,
  onClose,
  options,
}) => {
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState('');
  const { t } = useTranslation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});
    const bodyStyle = get(_config, 'general.popup.mainText', {});
    const selectedColor = get(
      _config,
      'general.popup.selectedBackgroundColor',
      '',
    );
    const selectedStyle = get(_config, 'general.popup.selectedText', '');
    const unselectedStyle = get(_config, 'general.popup.unSelectedText', '');

    return {
      titleStyle,
      bodyStyle,
      selectedColor,
      selectedStyle,
      unselectedStyle,
    };
  }, [location.search]);

  const onConfirm = () => onSubmit(selectedOption);
  const onSelectOption = (token: string) => () => setSelectedOption(token);

  return (
    <PopUp
      onClose={onClose}
      onClick={onConfirm}
      buttonText={t('checkout.guestSelectionButton')}
      testId="guest-selection"
    >
      <div className={styles.text} style={config.titleStyle}>
        {t('checkout.guestSelectionTitle')}
      </div>
      <div className={styles.text} style={config.bodyStyle}>
        {t('checkout.guestSelectionBody')}
      </div>
      <div className={styles.options}>
        {options.map((e) => (
          <div
            key={e.token}
            data-test-id="customer-guest-selection"
            onClick={onSelectOption(e.token)}
            className={styles.option}
            style={
              selectedOption === e.token
                ? {
                    ...config.selectedStyle,
                    backgroundColor: config.selectedColor,
                  }
                : config.unselectedStyle
            }
          >
            {e.firstName}
          </div>
        ))}
      </div>
    </PopUp>
  );
};
