import React from 'react';

import { useTranslation } from 'react-i18next';

type TextType = {
  value: string;
};

export const Text: React.FC<TextType> = ({ value }) => {
  const { t } = useTranslation();
  return t(value);
};
