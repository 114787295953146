import React, { useEffect, useMemo, useState, useRef } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  transformDate,
  useLanguageStore,
  useLocationStore,
  useNavigation,
} from 'mycheck-core';

import { Loader, Footer } from '@components';

import { CookiePolicy } from '../../components/CookiePolicy/CookiePolicy';
import { LocationContent } from '../../components/LocationContent/LocationContent';
import { TimeSelectionBox } from '../../components/TimeSelectionBox/TimeSelectionBox';

import styles from './LocationPageMobile.module.scss';
import MobileFixedHeaderWrapper from 'components/MobileFixedHeaderWrapper/MobileFixedHeaderWrapper';

export const LocationPageMobile: React.FC = observer(() => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const navigation = useNavigation();
  const location = useLocation();
  const locationState = useRef<{ from?: string }>(location.state);

  const [hasAvailableDays, setHasAvailableDays] = useState(false);

  const config = useMemo(() => {
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();
    const _config = MyCheckApp.instance.getGlobalConfig();
    const isCookiePolicyVisible = get(_config, 'settings.cookiePolicy', true);
    return {
      isCookiePolicyVisible,
      blockHomePage,
    };
  }, [location.search]);

  const handleBackClick = () => {
    if (LocationStore.experiencesTypes.length > 1) {
      LocationStore.setSelectedExperienceType('');
      LocationStore.setSelectedExperienceTypeTemp('');
    } else navigation.push(locationState.current?.from || '/');
  };

  const selectedHotel = LocationStore.hotelsList.find(
    (element) => element.id === LocationStore.selectedHotel,
  ) || { id: undefined, name: undefined };

  useEffect(() => {
    if (LocationStore.timeSettings && LocationStore.locationTimezoneName) {
      const days = transformDate(
        LocationStore.locationTimezoneName,
        LocationStore.localHotelDateFormat,
        LocationStore.timeSettings.available_days,
        false,
        LocationStore.timeSettings.open_hours,
      );
      setHasAvailableDays(days.length > 0);
    }
  }, [LocationStore.timeSettings, LocationStore.locationTimezoneName]);

  return (
    <div className={styles.locationPage}>
      {config.isCookiePolicyVisible && <CookiePolicy />}
      <MobileFixedHeaderWrapper
        handleBackClick={handleBackClick}
        headerTitle={LanguageStore.getBusinessTranslation(
          selectedHotel.id,
          'name',
          selectedHotel.name,
        )}
        hideBackButton={LocationStore.experiencesTypes.length === 1}
      >
        {hasAvailableDays && <TimeSelectionBox />}
      </MobileFixedHeaderWrapper>
      <LocationContent />
      <Footer />
      {(LocationStore.isFetching || !LocationStore.locationTimezoneName) && (
        <Loader />
      )}
    </div>
  );
});

LocationPageMobile.displayName = 'LocationPageMobile';
