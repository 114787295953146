import React, { type PropsWithChildren } from 'react';

import { observer } from 'mobx-react';

import { useBusinessFlow } from '../../hooks/useBusinessFlow/useBusinessFlow';

export const StoreLoaderService: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    useBusinessFlow();

    return <>{children}</>;
  },
);

StoreLoaderService.displayName = 'StoreLoaderService';
