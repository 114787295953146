import React from 'react';

import { FormikProps } from 'formik';

import { useTranslation } from 'mycheck-core';

import { CustomInput } from '@components';

import { NextButton } from '../../../../components/NextButton/NextButton';

import styles from './EmailScreen.module.scss';
import { RegisterFormikValues } from 'plugins/AuthPlugin/types/FormikValuesType';

interface Props {
  formikProps: FormikProps<RegisterFormikValues>;
}

export const EmailScreen: React.FC<Props> = ({ formikProps }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.emailScreen}>
      <CustomInput
        onChange={formikProps.handleChange}
        type="email"
        value={formikProps.values.email}
        name={'email'}
        placeholder={t('register.emailPlaceholder')}
        error={!!formikProps.errors.email}
        errorMessage={formikProps.errors.email}
      >
        <NextButton />
      </CustomInput>
    </div>
  );
};
