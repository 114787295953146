import React from 'react';

import {
  useAuthStore,
  useCheckoutStore,
  useLocation,
  useModalNavigator,
  useNavigation,
  useTranslation,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './LogOutModal.module.scss';

const LogOutModal: React.FC = () => {
  const { closeLogOutModal } = useModalNavigator();
  const CheckoutStore = useCheckoutStore();
  const AuthStore = useAuthStore();
  const location = useLocation();
  const navigation = useNavigation();
  const { t } = useTranslation();

  const handleLogOutConfirm = () => {
    closeLogOutModal();
    CheckoutStore.clearCheckout(true);
    CheckoutStore.clearRoomTableParams();
    AuthStore.logout();

    if (location.pathname === '/menu/checkout') {
      navigation.push('/menu');
    }
  };

  return (
    <PopUp
      onClick={handleLogOutConfirm}
      onClose={closeLogOutModal}
      onCancelClick={closeLogOutModal}
      buttonText={t('logOut.yes')}
      cancelButtonText={t('logOut.no')}
      withConfirmButton
      closeOnClickOutside={false}
      showCloseIcon={false}
      paperClassName={styles.logOutModalPaper}
      childWrapperClassName={styles.logOutModalChildWrapper}
      testId="log-out-popup"
    >
      {t('logOut.confirmationText')}
    </PopUp>
  );
};

export default LogOutModal;
