import React, { createContext, useContext, useState } from 'react';

import axios from 'axios';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation, ApiClient } from 'mycheck-core';

import { ClosePage, Overlay, PrimaryButton } from '@components';

import {
  useAuthStore,
  useLanguageStore,
} from '../../../core/stores/useStoreHooks';

import styles from './ErrorModalTracker.module.scss';
import { MyCheckApiError } from 'types/GenericTypes';
import { ErrorStatusCode } from './constants';

type ErrorTrackerContextType = {
  onError: (err: MyCheckApiError, callbackError?: () => void) => void;
};

const errorTrackerContext: ErrorTrackerContextType = {
  onError: (_err, _callbackError) => null,
};

const ErrorTrackerContext = createContext(errorTrackerContext);

export function useErrorTrackerHandler() {
  const context = useContext(ErrorTrackerContext);
  return { onError: context.onError };
}

export const ErrorModalTracker = ({ children }) => {
  const { t, i18n } = useTranslation();
  const AuthStore = useAuthStore();
  const LanguageStore = useLanguageStore();

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState(t('errors.defaultError') as string);
  const [callback, setCallback] = useState<() => void>();

  const closeModal = () => {
    setShowModal(false);
    callback();
  };

  const reportMissingKey = async (err: MyCheckApiError) => {
    try {
      if (!!err.message && !!err.code && !!err.info) {
        await ApiClient.instance.post<Array<Record<string, unknown>>>(
          `/restaurants/api/v3/translations/${AuthStore.businessID}/${LanguageStore.selectedValue}/missed`,
          [{ message: err.message, code: err.code, info: err.info }],
        );
      }
    } catch (_err) {}
  };

  const onErrorMessage = (err: MyCheckApiError) => {
    const errorKey = `errors.${err.message}`;

    if (err.code === ErrorStatusCode.CAPTCHA_VERIFICATION_FAILED) {
      return t(`errors.captchaVerification`);
    }

    if (!i18n.exists(errorKey)) {
      reportMissingKey(err);
      return t(`errors.defaultError`);
    }

    if (
      err.message === 'CART_CHECKOUT_ITEM_OUT_OF_STOCK' ||
      err.message === 'CART_ITEM_OUT_OF_STOCK' ||
      err.message === 'OUT_OF_STOCK'
    ) {
      return t(errorKey, {
        listOfItems: (
          err as unknown as {
            items_not_in_stock: Array<{ pui: string; name: string }>;
          }
        ).items_not_in_stock
          .map((e) => e.name)
          .join(', '),
        interpolation: { escapeValue: false },
      });
    }

    return t(errorKey);
  };

  const onError = (err: MyCheckApiError, callbackError = () => null) => {
    if (!axios.isCancel(err)) {
      setShowModal(true);
      setMessage(onErrorMessage(err));
      setCallback(() => () => callbackError());
    }
  };

  const location = useLocation();

  const config = React.useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const mainTextStyle = get(_config, 'general.popup.mainText', {});

    return {
      mainTextStyle,
    };
  }, [location.search]);

  return (
    <ErrorTrackerContext.Provider value={{ onError }}>
      {children}
      {showModal && (
        <Overlay testId="error-modal" className={styles.overlay} isPopup>
          <div className={styles.root}>
            <ClosePage
              testId="error-modal"
              onClose={closeModal}
              customStylePath="general.popup.icons.x"
            />
            <div className={styles.wrapper}>
              <div style={config.mainTextStyle}>{message}</div>
            </div>
            <PrimaryButton onClick={closeModal}>
              {t('general.ok')}
            </PrimaryButton>
          </div>
        </Overlay>
      )}
    </ErrorTrackerContext.Provider>
  );
};
