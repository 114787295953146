import qs from 'qs';

import { ApiCacheConstants, ApiClient } from 'mycheck-core';
import {
  ScheduleParameters,
  HttpLocationApiResponse,
  LocationApi,
} from './types/LocationTypes';

const LocationUrls = {
  hotelsData: (businessId) =>
    `/hotels/api/v1/hotels/properties?business_id=${businessId}`,
  locationsData: (businessId: number, date: string, locationGroupId?: number) =>
    `/restaurants/api/v3/locations?business_id=${businessId}${
      date ? `&experience%5Bslots%5D%5Bavailable_from%5D=${date}` : ''
    }&experience%5Bstatus%5D%5B=ACTIVE&contain_experience=1${
      locationGroupId ? `&location_group_id=${locationGroupId}` : ''
    }`,
  businessData: (query: string, businessId: number) =>
    `/api/v1/business/search?q=${query}&business_id=${businessId}`,
  experienceData: (selectedExperience) =>
    `/restaurants/api/v3/experiences/${selectedExperience}`,
  confirmExperienceSlot: (selectedExperienceId, value) =>
    `/restaurants/api/v3/experiences/${selectedExperienceId}/slots?from=${value}`,
  currenciesList:
    'https://general-settings.mycheckapp.com/wallet/v2/currency_settings.json',
  fetchTimeSettings: () => `/restaurants/api/v3/locations/schedule`,
};

export const HTTP_LOCATION_API = 'HTTP_LOCATION_API';

export class HttpLocationApi implements LocationApi {
  fetchHotels = async (businessId: number) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchHotels']
    >(LocationUrls.hotelsData(businessId), ApiCacheConstants.FETCH_HOTELS);
    return data;
  };

  fetchLocations = async ({
    businessId,
    date,
    locationGroupId,
  }: {
    businessId: number;
    date: string;
    locationGroupId?: number;
  }) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchLocations']
    >(
      LocationUrls.locationsData(
        businessId,
        encodeURIComponent(date),
        locationGroupId,
      ),
      ApiCacheConstants.FETCH_LOCATIONS,
    );
    return data;
  };

  fetchBusiness = async (query: string, businessId: number) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchLocations']
    >(LocationUrls.businessData(query, businessId));
    return data;
  };

  fetchExperience = async (selectedExperience: number) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchExperience']
    >(
      LocationUrls.experienceData(selectedExperience),
      ApiCacheConstants.FETCH_EXPERIENCE,
    );
    return data;
  };

  confirmExperienceSlot = async (
    selectedExperienceId: number,
    date: string,
  ) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['confirmExperienceSlot']
    >(
      LocationUrls.confirmExperienceSlot(
        selectedExperienceId,
        encodeURIComponent(date),
      ),
      ApiCacheConstants.CONFIRM_SLOT,
    );
    return data;
  };

  fetchAllergens = async (url: string) => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchAllergens']
    >(url, ApiCacheConstants.FETCH_ALLERGENS);
    return data;
  };

  fetchCurrenciesList = async () => {
    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchCurrencies']
    >(LocationUrls.currenciesList);
    return data;
  };

  fetchTimeSettings = async ({
    experience,
    businessId,
  }: ScheduleParameters) => {
    const mappedParams = {
      business_id: businessId,
      experience: {
        ...(experience.type && { type: experience.type }),
        status: experience.status || 'ACTIVE',
      },
    };

    const { data } = await ApiClient.instance.get<
      HttpLocationApiResponse['fetchTimeSettings']
    >(LocationUrls.fetchTimeSettings(), ApiCacheConstants.FETCH_TIME_SETTINGS, {
      params: mappedParams,
      paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
    });
    return data;
  };
}
