import React, { useCallback, useState, useMemo } from 'react';

import { css } from 'glamor';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, ParamErrorCodes } from 'mycheck-core';

import { Icon } from '@components';

import styles from './NotFoundPage.module.scss';

export const NotFoundPage: React.FC = observer(() => {
  const location = useLocation();
  const { t } = useTranslation();

  const [state] = useState(location.state);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const style = get(_config, 'general.error', {});

    return {
      style,
    };
  }, [location.search]);

  const getDescription = useCallback(() => {
    switch (state) {
      case ParamErrorCodes.HOTEL_ERROR:
        return t('general.hotelError');
      case ParamErrorCodes.RESTAURANT_ERROR:
        return t('general.restaurantError');
      case ParamErrorCodes.EXPERIENCE_ERROR:
        return t('general.experienceError');

      default:
        return t('general.error');
    }
  }, [location.state]);

  return (
    <div className={styles.root}>
      <Icon name={'error'} />
      <div className={css(config.style).toString()}>{getDescription()}</div>
    </div>
  );
});

NotFoundPage.displayName = 'NotFoundPage';
