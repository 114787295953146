import { useNavigate, useLocation } from 'react-router-dom';

export interface UseNavigationProps {
  push: (pathname: string, clearParams?: boolean, state?: unknown) => void;
  replace: (pathname: string, clearParams?: boolean) => void;
  replaceHash: (hash: string, useNative?: boolean) => void;
  setSearch: (search: string, setNew?: boolean) => void;
  setRawSearch: (search: string) => void;
  goBack: (pathname?: string) => void;
  hash: string;
}

class PageHistory {
  static instance: PageHistory = null;

  private _data: string[];

  constructor() {
    if (!PageHistory.instance) {
      this._data = [];
      PageHistory.instance = this;
    }

    return PageHistory.instance;
  }

  push(page: string) {
    this._data.push(page);
  }

  pop() {
    this._data.pop();
  }

  replace(value: string) {
    if (this._data.length > 0) {
      this._data[this._data.length - 1] = value;
    }
  }

  hasEntries = () => this._data.length > 0;
}

const pageHistoryInstance = new PageHistory();
Object.freeze(pageHistoryInstance);

export const handleSearch = (search: string, setNew = false) => {
  const params = new URLSearchParams(window.location.search);
  const pushedParam = new URLSearchParams(search);

  const newParams = new URLSearchParams();

  if (!setNew) {
    for (const key of params.keys()) {
      newParams.set(key, params.get(key));
    }
  }

  for (const key of pushedParam.keys()) {
    newParams.set(key, pushedParam.get(key));
  }

  return newParams.toString();
};

export const useNavigation = (): UseNavigationProps => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    push: (pathname: string, clearParams: boolean = false, state?: unknown) => {
      pageHistoryInstance.push(pathname);
      navigate(
        {
          pathname,
          search: !clearParams ? location.search : '',
          hash: !clearParams ? window.location.hash : '',
        },
        { state: state },
      );
    },

    replace: (pathname: string, clearParams: boolean = false) => {
      pageHistoryInstance.replace(pathname);
      navigate(
        {
          pathname,
          search: !clearParams ? location.search : '',
          hash: !clearParams ? window.location.hash : '',
        },
        { replace: true },
      );
    },
    replaceHash: (hash: string, useNative = true) => {
      if (useNative) {
        window.location.hash = hash;
      } else {
        navigate({ hash, search: location.search }, { replace: true });
      }
    },

    setSearch: (search: string, setNew = false) => {
      navigate(
        {
          search: handleSearch(search, setNew).toString(),
          hash: window.location.hash,
        },
        { replace: true },
      );
    },
    setRawSearch: (search: string) => {
      navigate(
        {
          search: search,
          hash: window.location.hash,
        },
        { replace: true },
      );
    },

    goBack: (pathname?: string) => {
      const hasEntries = pageHistoryInstance.hasEntries();
      pageHistoryInstance.pop();
      if (hasEntries && !pathname) {
        navigate(-1);
      } else {
        pageHistoryInstance.push(pathname);
        navigate(
          {
            pathname,
            search: location.search,
            hash: window.location.hash,
          },
          { replace: true },
        );
      }
    },

    hash: location.hash?.replace('#', ''),
  };
};
