import React from 'react';

import classnames from 'classnames';

import { CustomIcon, PriceFormatter } from '@components';

import styles from './RadioField.module.scss';
import { DynamicValue, DynamicValueArray } from 'types/GenericTypes';

type RadioFieldType = {
  setFieldValue: (property: string, value: DynamicValueArray) => void;
  modifierGroup: number;
  id: string;
  name: string;
  price: number;
  values: DynamicValueArray | DynamicValue;
};

export const RadioField: React.FC<RadioFieldType> = ({
  setFieldValue,
  values,
  modifierGroup,
  id,
  name,
  price,
}) => {
  const onClick = () => {
    setFieldValue('modifiers', {
      ...(values.modifiers as unknown as Record<string, unknown>),
      [modifierGroup]: [id],
    });
  };

  return (
    <div
      data-test-id="radio-field"
      className={classnames(styles.fieldItem, styles.fieldItemDisabled)}
      onClick={onClick}
    >
      <CustomIcon
        className={styles.fieldItemRadio}
        name={
          values.modifiers[modifierGroup]?.includes(id)
            ? 'radioFilled'
            : 'radioEmpty'
        }
      />
      <div>{name}</div>
      {!!price && (
        <div className={styles.fieldItemPrice}>
          +<PriceFormatter value={price} />
        </div>
      )}
    </div>
  );
};
