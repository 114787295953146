import React, { useEffect } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import { StorageService, useLocationStore, useTranslation } from 'mycheck-core';

import { CustomIcon, Overlay } from '@components';

import styles from '../Picker/Picker.module.scss';

interface ExperiencesPickerProps {
  onClose: () => void;
}

export const ExperiencesPicker: React.FC<ExperiencesPickerProps> = observer(
  ({ onClose }) => {
    const LocationStore = useLocationStore();
    const { t } = useTranslation();

    const handleClose = () => {
      StorageService.setValue(
        StorageService.STORAGE_KEYS.EXPERIENCE_PICKER,
        'true',
      );
      onClose();
    };

    const onClick = (id: string) => {
      LocationStore.setSelectedExperienceType(id);
      handleClose();
    };

    useEffect(() => {
      document.body.style.overflow = 'hidden';

      const element = document.getElementById(
        LocationStore.selectedExperienceType,
      );
      if (element) element.scrollIntoView({ block: 'center' });

      return () => {
        document.body.removeAttribute('style');
      };
    }, []);

    return (
      <>
        <Overlay
          withoutModal
          overlayStyle={{ top: 128, backdropFilter: 'blur(2px)' }}
          onClickOutside={handleClose}
          testId="experiences-picker"
        >
          <div className={styles.picker}>
            <div className={styles.pickerHeader}>
              <div onClick={handleClose}>
                <CustomIcon
                  name={'x'}
                  className={styles.pickerHeaderCloseIcon}
                ></CustomIcon>
              </div>
              <div className={styles.pickerHeaderTitle}>
                {' '}
                {t('experience.title')}
              </div>
            </div>
            <div
              className={classnames(
                styles.pickerList,
                LocationStore.experiencesTypes.length > 5
                  ? styles.pickerListLinear
                  : '',
              )}
              style={
                LocationStore.experiencesTypes.length > 5
                  ? { paddingBottom: 100 }
                  : {}
              }
            >
              {LocationStore.experiencesTypes.map((expType) => (
                <div
                  key={expType}
                  id={expType}
                  data-test-id={expType}
                  className={classnames(
                    styles.pickerListItem,
                    LocationStore.selectedExperienceType === expType
                      ? styles.pickerListItemActive
                      : '',
                  )}
                  onClick={() => {
                    onClick(expType);
                  }}
                >
                  {t(`experience.${expType}`)}
                </div>
              ))}
            </div>
          </div>
        </Overlay>
      </>
    );
  },
);

ExperiencesPicker.displayName = 'ExperiencesPicker';
