import React, { useEffect, useState } from 'react';

import { Form, useFormikContext } from 'formik';

import { useLocationStore, useTranslation } from 'mycheck-core';

import { Text, PrimaryButton, MobilePickerScroll } from '@components';

import styles from './ExperienceModal.module.scss';

export const ExperienceModalForm: React.FC = () => {
  const LocationStore = useLocationStore();
  const formik = useFormikContext<{ selectedExperience: string }>();
  const [experiencesList, setExperiencesList] = useState([]);
  const { t } = useTranslation();
  const { i18n } = useTranslation();

  const [valueGroups, setValueGroups] = useState({
    experience: t(`experience.${LocationStore.selectedExperienceType}`),
  });

  const [optionGroups, setOptionGroups] = useState({
    experience: LocationStore.experiencesTypes.map((expType) => {
      return t(`experience.${expType}`);
    }),
  });

  const handleChange = (name: string, value: any) => {
    if (value) {
      setValueGroups({
        experience: value,
      });

      formik.setFieldValue(
        'selectedExperience',
        experiencesList.filter((d) => {
          return d.label === value;
        })[0].type,
      );
    }
  };

  const setExperiences = async () => {
    const experiences = LocationStore.experiencesTypes.map((expType) => {
      return {
        type: expType,
        label: t(`experience.${expType}`),
      };
    });

    setExperiencesList(experiences);

    setOptionGroups({
      experience: LocationStore.experiencesTypes.map((expType) => {
        return t(`experience.${expType}`);
      }),
    });

    setValueGroups({
      experience: t(`experience.${LocationStore.selectedExperienceType}`),
    });
  };

  useEffect(() => {
    setExperiences();
  }, [LocationStore.selectedExperienceType, i18n.language]);

  return (
    <Form className={styles.experienceModalContainerForm}>
      <MobilePickerScroll
        optionGroups={optionGroups}
        valueGroups={valueGroups}
        onChange={handleChange}
        height={250}
        itemHeight={40}
        textAlign="center"
      />
      <PrimaryButton
        className={styles.primaryButton}
        testId="experiences-selection"
      >
        <Text value="general.save" />
      </PrimaryButton>
    </Form>
  );
};
