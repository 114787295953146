import { useCallback, useState } from 'react';

import { useCheckoutStore, useLocationStore, useMenuStore } from 'mycheck-core';

export const useCommonFlow = (trackValue: number) => {
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();
  const CheckoutStore = useCheckoutStore();
  const [value, setValue] = useState(trackValue);

  const resetInfo = useCallback(() => {
    if (value) {
      MenuStore.clearMenu();
      CheckoutStore.clearCheckout();
      LocationStore.setSelectedRestaurantId(null);
      LocationStore.setSelectedExperienceId(null);
      LocationStore.setSelectedExperienceType(null);
      LocationStore.setSelectedExperienceTypeTemp(null);
      LocationStore.clearRestaurant();
      LocationStore.clearRestaurantList();
      LocationStore.resetSelectedDate();
      LocationStore.resetTimeSettings();
    } else {
      setValue(trackValue);
    }
  }, [value, trackValue]);

  return { resetInfo };
};
