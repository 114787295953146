import React from 'react';

import { Background, Paper } from '@components';

import styles from './ConfirmPageElement.module.scss';

type ConfirmPageElementType = {
  title: string;
  value: string | JSX.Element;
};

export const ConfirmPageElement: React.FC<ConfirmPageElementType> = ({
  title,
  value,
}) => {
  return (
    <Paper className={styles.element} testId="confirm-page-element">
      <Background>
        <div className={styles.elementTitle}>{title}</div>
        <div className={styles.elementValue}>{value}</div>
      </Background>
    </Paper>
  );
};
