import React, { type CSSProperties } from 'react';

import { useLocationStore } from 'mycheck-core';

import { ExternalLinkIframe } from './ExternalLinkIframe';

type Props = {
  href: string;
  children: string;
  className?: string;
  style?: CSSProperties;
  testId?: string;
};

export const ExternalLink: React.FC<Props> = ({
  style = {},
  className,
  href,
  children,
  testId,
}) => {
  const LocationStore = useLocationStore();
  const isIframe = LocationStore.isIframe;

  if (!isIframe) {
    return (
      <a
        className={className}
        style={style}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <ExternalLinkIframe
      style={style}
      className={className}
      href={href}
      testId={testId}
    >
      {children}
    </ExternalLinkIframe>
  );
};
