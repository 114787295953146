import React, { useEffect, useMemo } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useLanguageStore, useLocationStore } from 'mycheck-core';

import { Background, HeaderNavigation, Loader } from '@components';

import { Footer } from '../../../../components/Footer/Footer';
import { CookiePolicy } from '../../components/CookiePolicy/CookiePolicy';
import { LocationContent } from '../../components/LocationContent/LocationContent';
import { LocationHeaderActions } from '../../components/LocationHeaderActions/LocationHeaderActions';

import styles from './LocationPageDesktop.module.scss';

export const LocationPageDesktop: React.FC = observer(() => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const location = useLocation();

  const config = useMemo(() => {
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();
    const _config = MyCheckApp.instance.getGlobalConfig();
    const isCookiePolicyVisible = get(_config, 'settings.cookiePolicy', true);
    return {
      isCookiePolicyVisible,
      blockHomePage,
    };
  }, [location.search]);

  useEffect(() => {
    if (
      !LocationStore.experiencesTypes.find(
        (e) => e === LocationStore.selectedExperienceType,
      )
    ) {
      LocationStore.selectedExperienceType = LocationStore.experiencesTypes[0];
      LocationStore.selectedExperienceTypeTemp =
        LocationStore.experiencesTypes[0];
    }
  }, [LocationStore.experiencesTypes]);

  return (
    <div className={styles.root}>
      <HeaderNavigation
        headerTitle={LanguageStore.getBusinessTranslation(
          LocationStore.selectedHotelObj.id,
          'name',
          LocationStore.selectedHotelObj.name,
        )}
      />
      {config.isCookiePolicyVisible && <CookiePolicy />}
      <LocationHeaderActions />
      <Background secondary className={styles.page}>
        <LocationContent />
      </Background>
      <Footer />
      {(LocationStore.isFetching || !LocationStore.locationTimezoneName) && (
        <Loader />
      )}
    </div>
  );
});

LocationPageDesktop.displayName = 'LocationPageDesktop';
