import {
  useLocationStore,
  useMenuStore,
  useNavigation,
  useWindowSize,
  removeSpecialCharacters,
} from 'mycheck-core';
import { useCallback, useContext, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { MenuPageContext } from '../pages/MenuPage/common/MenuPageContext';
import { useMenuElementVisibilityTracker } from './useMenuElementVisibilityTracker';
import { transformCategoryHashUrl } from 'core/core/helpers/transformCategoryHashUrl';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';
import { MOBILE_HEADER_OFFSET } from '../pages/MenuPage/common/constants';

export const useMenuTabs = () => {
  const { setSelectedTab, selectedTab } = useContext(MenuPageContext);
  const MenuStore = useMenuStore();
  const LocationStore = useLocationStore();
  const navigation = useNavigation();
  const { isLg } = useWindowSize();

  const setAndScrollToInitialTabFromUrlHash = useCallback(() => {
    if (!(MenuStore.isFetching || LocationStore.isFetching) && location.hash) {
      const selectedCategory = MenuStore.categoriesAndSubcategoriesFlat.find(
        (e) =>
          removeSpecialCharacters(e.name.toLocaleLowerCase()) ===
          transformCategoryHashUrl(
            location.hash?.toLocaleLowerCase(),
            'decode',
          ),
      );
      setSelectedTab(selectedCategory?.id);
      const element = document.getElementById(
        transformCategoryHashUrl(selectedCategory?.name, 'id'),
      );

      const isFirstCategory =
        selectedCategory?.id ===
        MenuStore.categoriesAndSubcategoriesFlat[0]?.id;

      if (isFirstCategory || !element) {
        window.scrollTo(0, 0);
      } else {
        isLg
          ? element?.scrollIntoView()
          : window.scrollTo(0, element?.offsetTop - MOBILE_HEADER_OFFSET);
      }
    }
  }, [
    LocationStore.isFetching,
    MenuStore.categoriesAndSubcategoriesFlat,
    MenuStore.isFetching,
    setSelectedTab,
    isLg,
  ]);

  useEffect(() => {
    setAndScrollToInitialTabFromUrlHash();
  }, [setAndScrollToInitialTabFromUrlHash]);

  const setUrlCategoryHash = (value: string) => {
    if (value) {
      navigation.replaceHash(transformCategoryHashUrl(value, 'encode'), false);
    }
  };

  const setUrlCategoryHashDebounce = useDebouncedCallback(
    setUrlCategoryHash,
    200,
  );

  const onScrollChangeEvent = (element: MenuCategoryType | MenuSubcategory) => {
    if (element.id !== selectedTab) {
      setSelectedTab(element.id);
      setUrlCategoryHashDebounce(element.name);
    }
  };

  const { onChangeVisibility } = useMenuElementVisibilityTracker({
    onScrollChangeEvent,
  });

  return {
    onChangeVisibility,
  };
};
