import { useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import {
  getSearchState,
  isPast,
  paramHandler,
  useCheckoutStore,
  useLocationStore,
  useNavigation,
} from 'mycheck-core';

const EXCLUDED_DEFAULT_PATHS = ['/', '/not-found'];

export const useStateHandler = (): void => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const location = useLocation();
  const navigation = useNavigation();
  const [paramsLoaded, setParamsLoaded] = useState(false);

  const setCurrentLocationId = useCallback(() => {
    const searchState = getSearchState();
    const { restaurant, hotel } = searchState;
    LocationStore.setCurrentLocationId(restaurant || hotel);
  }, [window.location.search]);

  const handleTokenParams = useCallback(() => {
    const values = getSearchState();
    paramHandler(values, 'isNative', (value) => {
      LocationStore.setIsNative(Boolean(value));
    });

    paramHandler(values, 'isIframe', (value) => {
      LocationStore.setIsIframe(Boolean(value));
    });

    paramHandler(values, 'hotel', (value) => {
      LocationStore.setSelectedHotel(Number(value));
    });

    paramHandler(values, 'location_group_id', (value) => {
      LocationStore.setLocationGroupId(Number(value));
    });

    paramHandler(values, 'restaurant', (value) => {
      LocationStore.setSelectedRestaurantId(Number(value), true);
    });

    paramHandler(values, 'date', (value) => {
      LocationStore.setSelectedDate(value);
      LocationStore.setSelectedTime(value);

      LocationStore.setSelectedDateTemp(value);
      LocationStore.setSelectedTimeTemp(value);

      if (isPast(LocationStore.selectedDate)) {
        LocationStore.setSelectedDate(dayjs.utc().format());
        LocationStore.setSelectedDateTemp(dayjs.utc().format());
        CheckoutStore.setSelectedCheckoutTime(dayjs.utc().format());
      }
    });

    paramHandler(values, 'checkoutTime', (value) => {
      CheckoutStore.setSelectedCheckoutTime(value);
    });

    paramHandler(values, 'room', (value) => CheckoutStore.setRoomNumber(value));
    paramHandler(values, 'table', (value) =>
      CheckoutStore.setTableNumber(value),
    );

    paramHandler(values, 'experience', (value) => {
      const params = new URLSearchParams(window.location.search);
      LocationStore.setSelectedExperienceType(
        value,
        !!params.get('experience'),
      );
      LocationStore.setSelectedExperienceTypeTemp(value);
    });

    paramHandler(values, 'experienceId', (value) => {
      LocationStore.setSelectedExperienceId(Number(value));
    });

    setParamsLoaded(true);
  }, [window.location.search]);

  const clearParam = (paramName: string, shouldDelete: boolean) => {
    if (shouldDelete) {
      const params = new URLSearchParams(window.location.search);
      params.delete(paramName);
      navigation.setRawSearch(params.toString());
    }
  };

  const setNewParams = useCallback(() => {
    if (paramsLoaded) {
      const statePathname = (paths = []) =>
        ![...EXCLUDED_DEFAULT_PATHS, ...paths].includes(location.pathname);
      const searchState = getSearchState();

      const stateToEncode = {
        ...(LocationStore.isNative || searchState.isNative
          ? { isNative: true }
          : {}),
        ...(LocationStore.isIframe || searchState.isIframe
          ? { isIframe: true }
          : {}),
        ...(LocationStore.selectedDate && statePathname()
          ? { date: LocationStore.selectedDate }
          : {}),
        ...(CheckoutStore.checkoutTime &&
        LocationStore.isAsap &&
        statePathname(['/location'])
          ? { checkoutTime: CheckoutStore.checkoutTime }
          : {}),
        ...(LocationStore.selectedHotel && statePathname()
          ? { hotel: LocationStore.selectedHotel }
          : {}),
        ...(LocationStore.locationGroupId && statePathname()
          ? { location_group_id: LocationStore.locationGroupId }
          : {}),
        ...(LocationStore.selectedRestaurantId && statePathname(['/location'])
          ? { restaurant: LocationStore.selectedRestaurantId }
          : {}),
        ...(LocationStore.selectedExperienceId && statePathname(['/location'])
          ? { experienceId: LocationStore.selectedExperienceId }
          : {}),
        ...(LocationStore.selectedExperienceType && statePathname()
          ? { experience: LocationStore.selectedExperienceType }
          : {}),
        ...(LocationStore.isSelectedRestaurantIdFromUrl ||
        searchState.urlrestaurant
          ? {
              urlrestaurant:
                searchState.urlrestaurant || LocationStore.selectedRestaurantId,
            }
          : {}),
        ...((CheckoutStore.roomNumber || searchState.room) &&
        statePathname(['/menu/confirm'])
          ? { room: CheckoutStore.roomNumber || searchState.room }
          : {}),
        ...((CheckoutStore.tableNumber || searchState.table) &&
        statePathname(['/menu/confirm'])
          ? { table: CheckoutStore.tableNumber || searchState.table }
          : {}),
      };

      if (Object.values(stateToEncode).filter((e) => e).length) {
        if (stateToEncode.isNative) {
          console.log('Native mode is ON');
        }
        navigation.setSearch(
          `state=${btoa(
            unescape(encodeURIComponent(JSON.stringify(stateToEncode))),
          )}`,
        );
      } else {
        navigation.setRawSearch('');
      }

      clearParam('hotel', !!LocationStore.selectedHotel);
      clearParam('location_group_id', !!LocationStore.locationGroupId);
      clearParam('restaurant', !!LocationStore.selectedRestaurantId);
      clearParam('experience', !!LocationStore.selectedExperienceId);
    }
  }, [
    LocationStore.selectedHotel,
    LocationStore.selectedRestaurantId,
    LocationStore.selectedExperienceType,
    LocationStore.selectedDate,
    LocationStore.selectedExperienceId,
    location.pathname,
    paramsLoaded,
  ]);

  useEffect(() => {
    setCurrentLocationId();
  }, [window.location.search]);

  useEffect(() => {
    handleTokenParams();
  }, []);

  useEffect(setNewParams, [
    LocationStore.selectedHotel,
    LocationStore.locationGroupId,
    LocationStore.selectedRestaurantId,
    LocationStore.selectedExperienceType,
    LocationStore.selectedDate,
    LocationStore.selectedExperienceId,
    location.pathname,
    paramsLoaded,
  ]);
};
