import { useNavigation, useTranslation, useWindowSize } from 'mycheck-core';
import React, { useCallback, useEffect } from 'react';
import styles from './OrderTimeoutPage.module.scss';
import { HeaderNavigation, HeaderNavigationProps } from '@components';
import MobileFixedHeaderWrapper from 'components/MobileFixedHeaderWrapper/MobileFixedHeaderWrapper';
import { ReactComponent as QrCodeIcon } from './qr_code.svg';

const OrderTimeoutPage = () => {
  const navigation = useNavigation();
  const { isLg } = useWindowSize();
  const { t } = useTranslation();

  const clearStateUrlParam = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const stateParamVal = params.get('state');

    if (stateParamVal) {
      params.delete('state');
      navigation.setRawSearch(params.toString());
    }
  }, [navigation]);

  useEffect(() => {
    clearStateUrlParam();
  }, [clearStateUrlParam]);

  const sharedHeaderProps: HeaderNavigationProps = {
    headerTitle: t('orderTimeout.headerTitle'),
    hideUserProfile: true,
  };

  return (
    <div>
      {isLg ? (
        <HeaderNavigation {...sharedHeaderProps} />
      ) : (
        <MobileFixedHeaderWrapper {...sharedHeaderProps} hideBackButton />
      )}
      <div className={styles.container}>
        <QrCodeIcon className={styles.qrCodeIcon} />
        <span className={styles.timeoutTitle}>{t('orderTimeout.title')}</span>
        <span className={styles.timeoutSubtitle}>
          {t('orderTimeout.subtitle')}
        </span>
      </div>
    </div>
  );
};

export default OrderTimeoutPage;
