import React from 'react';

import classnames from 'classnames';

import { PriceFormatter } from '@components';

import styles from './MyOrderSummary.module.scss';

interface MyOrderSummaryItemProps {
  label: string;
  value: string;
  currency: string;
  className?: string;
}

const MyOrderSummaryItem: React.FC<MyOrderSummaryItemProps> = ({
  label,
  value,
  currency,
  className,
}) => {
  return (
    <div className={classnames(styles.myOrderSummaryWrapperItem, className)}>
      <p>{label}</p>
      <p>
        <PriceFormatter value={Number(value)} currency={currency} />
      </p>
    </div>
  );
};

export default MyOrderSummaryItem;
