import i18n from 'i18next';
import { initReactI18next, Trans } from 'react-i18next';

import defaultTranslation from './defaultTranslation.json';

const resources = {
  en: {
    translation: defaultTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  interpolation: { escapeValue: false },
  parseMissingKeyHandler: () => '',
  lng: 'en',
  fallbackLng: 'en',
});

const t = i18n.t.bind(i18n);

export { defaultTranslation, Trans, t };

export default i18n;
