import { removeSpecialCharacters } from 'mycheck-core';

export const transformCategoryHashUrl = (
  categoryName: string,
  type: 'encode' | 'decode' | 'id',
) => {
  if (!categoryName) return;

  const result = {
    encode: `#${categoryName.replaceAll(' ', '-')}`,
    decode: removeSpecialCharacters(categoryName),
    id: categoryName.replaceAll(' ', '-'),
  }[type];

  return result.toLocaleLowerCase().trim();
};
