import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { capitalize } from 'lodash';

import styles from './Badge.module.scss';

const colors = ['success'] as const;

type BadgeColor = typeof colors;

interface BadgeProps extends PropsWithChildren {
  color?: BadgeColor;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  color = 'success',
}) => {
  return (
    <div
      className={classnames(
        styles.root,
        styles[`root${capitalize(color as string)}`],
      )}
    >
      {children}
    </div>
  );
};
