import React, {
  useEffect,
  useMemo,
  useRef,
  type PropsWithChildren,
} from 'react';

import { Field, useFormikContext } from 'formik';
import { css } from 'glamor';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { IFormikValues } from '../../types/IFormikValues';

import styles from './CustomCheckbox.module.scss';

interface CustomCheckboxProps extends PropsWithChildren {
  name: string;
  value: boolean;
  testId?: string;
}

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  name,
  value,
  children,
  testId,
}) => {
  const { errors, isSubmitting, handleChange } =
    useFormikContext<IFormikValues>();
  const inputRef = useRef<HTMLInputElement>();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const checkboxStyle = get(_config, 'general.checkbox', {});
    const checkboxVStyle = get(_config, 'general.checkboxV', {});
    const selectedCheckboxCss = css({
      border: `${checkboxVStyle.border}`,
      width: `${checkboxVStyle.width}`,
      height: `${checkboxVStyle.width}`,
      '&::after': {
        border: `solid ${checkboxVStyle.color}`,
      },
    });

    const unselectedCheckboxCss = css({
      ...checkboxStyle,
      border: errors.termsAndConditions
        ? '1px solid #bb0e2d'
        : checkboxStyle.border,
      height: checkboxStyle.width,
    });

    return {
      selectedCheckboxCss,
      unselectedCheckboxCss,
    };
  }, [location.search, errors]);

  useEffect(() => {
    if (!!Object.keys(errors).length) {
      const errKeys = Object.keys(errors);
      if (errKeys[0] === name && inputRef.current && isSubmitting) {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        inputRef.current.focus();
      }
    }
  }, [errors, isSubmitting]);

  return (
    <div className={styles.checkboxWrap}>
      <label className={styles.checkboxWrapCheckbox}>
        <Field
          innerRef={inputRef}
          type="checkbox"
          name={name}
          checked={value}
          onChange={handleChange}
          data-test-id={testId}
        />
        <span
          className={`${styles.checkboxWrapCheckboxCustom} ${
            value ? config.selectedCheckboxCss : config.unselectedCheckboxCss
          }`}
        />
      </label>
      <div className={styles.checkboxWrapLabel}>{children}</div>
    </div>
  );
};
