import React, { useMemo, FC, useContext } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useTranslation,
} from 'mycheck-core';

import { Overlay, PrimaryButton } from '@components';

import styles from './AlcoholModal.module.scss';
import { MenuPageContext } from 'plugins/MenuPlugin/pages/MenuPage/common/MenuPageContext';

export const AlcoholModal: FC = () => {
  const { handleSubmitAlcoholModal, handleCloseAlcoholModal } =
    useContext(MenuPageContext);
  const LanguageStore = useLanguageStore();
  const LocationStore = useLocationStore();

  const restaurantId = LocationStore.selectedRestaurantId;
  const location = useLocation();
  const menuStore = useMenuStore();
  const { t } = useTranslation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleStyle = get(_config, 'general.popup.title', {});
    const descriptionStyle = get(_config, 'general.popup.mainText', {});

    return {
      titleStyle,
      descriptionStyle,
    };
  }, [location.search]);

  const titleTranslation = LanguageStore.getProductTranslation(
    restaurantId,
    LocationStore.productId,
    'data.alcohol_restrictions.title',
    t('menu.alcoholModal.title'),
  );

  const bodyTranslation = LanguageStore.getProductTranslation(
    restaurantId,
    LocationStore.productId,
    'data.alcohol_restrictions.description',
    t('menu.alcoholModal.body'),
  );

  const Header = (
    <>
      <div
        className={styles.alcoholModalTitle}
        style={config.titleStyle}
        data-test-id="alcohol-modal-title"
      >
        {titleTranslation}
      </div>
    </>
  );

  const handleSubmitAndSave = () => {
    menuStore.setIsAdult();
    handleSubmitAlcoholModal();
  };

  return (
    <Overlay
      isPopup
      className={styles.alcoholModal}
      onClickOutside={handleCloseAlcoholModal}
      testId="alcohol-modal"
    >
      {Header}
      <div
        className={styles.alcoholModalDescription}
        style={config.descriptionStyle}
        data-test-id="alcohol-modal-description"
      >
        {bodyTranslation}
      </div>
      <div className={styles.alcoholModalButtons}>
        <PrimaryButton
          secondary
          className={styles.alcoholModalButtonsDismiss}
          onClick={handleCloseAlcoholModal}
          testId="alcohol-modal-no"
        >
          {t('menu.alcoholModal.no')}
        </PrimaryButton>
        <PrimaryButton
          className={styles.alcoholModalButtonsSubmit}
          onClick={handleSubmitAndSave}
          testId="alcohol-modal-yes"
        >
          {t('menu.alcoholModal.yes')}
        </PrimaryButton>
      </div>
    </Overlay>
  );
};
