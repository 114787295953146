export const AcceptedCardsName = {
  amazonpay: 'ac_amazonpay.png',
  'american-express': 'ac_amex.png',
  amex: 'ac_amex.png',
  applepay: 'ac_applepay.png',
  cirrus: 'ac_cirrus.png',
  'diners-club': 'ac_diners.png',
  diners: 'ac_diners.png',
  discover: 'ac_discover.png',
  elo: 'ac_elo.png',
  jcb: 'ac_jcb.png',
  maestro: 'ac_maestro.png',
  mastercard: 'ac_mastercard.png',
  paypal: 'ac_paypal.png',
  solo: 'ac_solo.png',
  switch: 'ac_switch.png',
  unionpay: 'ac_unionpay.png',
  unknown: 'ac_unknown.png',
  visa: 'ac_visa.png',
  tr: 'ac_tr.png',
};

export const CardTypes = {
  visa: 'ct_visa.png',
};

export enum MerchantsList {
  'SPS_ECOMMERCE' = 'SPS_ECOMMERCE',
  'MCPCI' = 'MCPCI',
}

export enum PaymentSource {
  APPLE_PAY = 'APPLE_PAY',
  VISA = 'visa',
  DISCOVER_CARD = 'discover card',
  PAYPAL = 'paypal',
  DEFAULT = 'default',
}

export enum CardProvider {
  SPS = 'sps',
}
