import React, { useMemo } from 'react';

import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { ExternalLink } from '@components';

type Props = {
  type: 'termsAndConditions' | 'privacyPolicy';
};

export const HomeAnchorLink: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const linkStyle = get(_config, 'home.style.link', {});
    const url = get(_config, `home.settings.${type}`, null);
    return {
      linkStyle,
      url,
    };
  }, [location.search]);

  return (
    <ExternalLink style={config.linkStyle} href={config.url}>
      {t(`home.${type}`)}
    </ExternalLink>
  );
};
