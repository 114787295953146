import React, { useEffect } from 'react';

import { useUserFlow, useWindowSize } from 'mycheck-core';

import { Footer, ProfileMenu, SideBar } from '@components';

import styles from './MenuSideBar.module.scss';

interface Props {
  isMenuOpen: boolean;
  handleMenuOpen: () => void;
  closeMenu: () => void;
  fullScreen?: boolean;
}

export const MenuSideBar: React.FC<Props> = ({
  isMenuOpen,
  handleMenuOpen,
  closeMenu,
  fullScreen = false,
}) => {
  const { supportMembers } = useUserFlow();
  const { isLg } = useWindowSize();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style['overscroll-behavior-y'] = 'none';
      document.body.style['touch-action'] = 'none';

      document.body.style.height = '100%';
      document.body.style.overflow = 'hidden';

      if (document.getElementById('locationContent')) {
        document.getElementById('locationContent').style.overflow = 'hidden';
      }
    }

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');

      if (document.getElementById('locationContent')) {
        document.getElementById('locationContent').style.overflow = 'auto';
      }
    };
  }, [isMenuOpen]);

  if (!supportMembers || isLg) {
    return null;
  }

  return (
    <SideBar
      isMenuOpen={isMenuOpen}
      handleMenuOpen={handleMenuOpen}
      closeMenu={closeMenu}
      fullScreen={fullScreen}
    >
      <ProfileMenu />
      <Footer className={styles.sideBarFooter} dataTestFooterVersion="" />
    </SideBar>
  );
};
