import React from 'react';

import classnames from 'classnames';

import { useLocationStore, useTranslation } from 'mycheck-core';

import { Background, Paper, PriceFormatter } from '@components';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';
import { AllergensGroup } from '../AllergensGroup/AllergensGroup';

import styles from './ProductInfo.module.scss';
import { ProductItem } from 'plugins/CheckoutPlugin/types/CheckoutTypes';

interface Props {
  itemData: ProductItem;
  handleAllergensClick: () => void;
}

export const ProductInfo: React.FC<Props> = ({
  itemData,
  handleAllergensClick,
}) => {
  const { t } = useTranslation();
  const locationStore = useLocationStore();
  const getTranslationForItem = useGetTranslationForMenuItem();

  return (
    <Paper
      className={classnames(styles.root, {
        [styles.rootIsNative]: locationStore.isNative && !itemData.image,
      })}
      testId="product-info"
    >
      <Background className={styles.rootProductInfo}>
        <div className={styles.rootProductInfoTitle}>
          {getTranslationForItem(itemData.pos_unique_id, itemData).name}
          {itemData.kcal > 0 && (
            <span className={styles.rootProductInfoCalories}>
              {itemData.kcal}
              {t('menu.calories.name')}
            </span>
          )}
        </div>
        <div className={styles.rootProductInfoDescription}>
          {getTranslationForItem(itemData.pos_unique_id, itemData).description}
        </div>
        <div className={styles.rootProductInfoFooter}>
          {itemData.allergens?.length > 0 && (
            <AllergensGroup
              allergens={itemData.allergens}
              handleAllergensClick={handleAllergensClick}
              numberOfVisibleItems={5}
            />
          )}
          <div
            className={styles.rootProductInfoFooterPrice}
            style={itemData.allergens?.length === 0 ? { width: '100%' } : {}}
          >
            <PriceFormatter value={itemData.price} />
          </div>
        </div>
      </Background>
    </Paper>
  );
};
