import React, { useCallback, useContext, useEffect, useState } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';

import {
  FLOW_TYPES,
  GaService,
  isWithinSixtyMinutes,
  transformDate,
  transformTime,
  transformTimeArrayToDateTimeArray,
  useCheckoutStore,
  useLocationStore,
  useNavigation,
  useTranslation,
} from 'mycheck-core';

import { Header } from '@components';

import { CustomButton } from '../../../../components/CustomButton/CustomButton';
import { TimeSelectionBox } from '../../../LocationPlugin/components/TimeSelectionBox/TimeSelectionBox';
import { MenuTab } from '../../components/MenuTab/MenuTab';
import { MobileMenuCategory } from '../../components/MobileMenuTab/MobileMenuCategory';

import styles from './MenuPage.module.scss';
import MenuPage from './common/MenuPage';
import { DaysSelectValueItem } from 'types/GenericTypes';
import { MenuPageContext } from './common/MenuPageContext';

export const MenuPageMobile: React.FC = observer(() => {
  const { headerTitle } = useContext(MenuPageContext);
  const CheckoutStore = useCheckoutStore();
  const LocationStore = useLocationStore();
  const navigation = useNavigation();
  const { t } = useTranslation();

  LocationStore.setNavigation(navigation);

  const [isTimeSelectionModalOpen, setIsTimeSelectionModalOpen] =
    useState(false);

  const isAsapOnly = LocationStore.selectedExperience?.settings.is_asap_only;
  const nextAvailable = LocationStore.restaurantList
    .filter((r) => r.id === LocationStore.selectedRestaurantId)[0]
    ?.experiences.filter(
      (ex) => ex.type === LocationStore.selectedExperienceType,
    )[0]?.settings.slots.next_available;
  const noNextAvailable = nextAvailable === null;
  const isWithinOneHourSelectedDate = isWithinSixtyMinutes(
    nextAvailable,
    LocationStore.selectedDate,
  );

  const onViewOrderClick = useCallback(() => {
    GaService.instance.sendEvent({
      category: 'Ordering - My Order',
      action: 'Impression',
      label: '',
    });
    navigation.push('/menu/order');
  }, [window.location.search]);

  const onHeaderClickBack = () => {
    navigation.push(
      LocationStore.flowType === FLOW_TYPES.HOTEL ? '/location' : '/',
    );
    navigation.replaceHash('');
  };

  const pickerAutoOpen = async () => {
    setIsTimeSelectionModalOpen(false);

    const dates = transformDate(
      LocationStore.locationTimezoneName,
      LocationStore.localDateFormat,
      LocationStore.selectedExperience?.settings?.available_days,
      false,
      LocationStore.timeSettings.open_hours,
    );

    const times: DaysSelectValueItem = dates.reduce((result, date) => {
      result[date.value] = transformTime(
        LocationStore.timeSettings,
        date.value,
        LocationStore.locationTimezoneName,
        LocationStore.localTimeFormat,
        false,
      );

      return result;
    }, {});

    const filterDates = dates
      .filter(
        (obj) =>
          !!times[
            moment(obj.value)
              .tz(LocationStore.locationTimezoneName)
              .utc()
              .format()
          ]?.length,
      )
      .map((e) => {
        const value = moment(e.value).tz(LocationStore.locationTimezoneName);
        return {
          ...e,
          value: value.date(),
          valueDate: value.clone().utc().format(),
          dayName: value.format('ddd').toLowerCase(),
        };
      });

    const locationHoursAvailable = Object.keys(
      LocationStore.selectedExperience.settings.open_hours,
    );

    const newDates = filterDates.filter((e) =>
      locationHoursAvailable.includes(e.dayName),
    );

    const selectedDateMoment = moment
      .utc(LocationStore.selectedDate)
      .tz(LocationStore.locationTimezoneName);

    const existOnDates =
      newDates.filter((d) => {
        const _date = moment
          .utc(d.valueDate)
          .tz(LocationStore.locationTimezoneName);
        return _date.isSame(selectedDateMoment, 'day');
      }).length > 0;

    if (!existOnDates) {
      setIsTimeSelectionModalOpen(true);
      return;
    }

    const date = selectedDateMoment.startOf('day').utc().format();
    const timeSlots = LocationStore.timeSlots;

    const locationTimeValues = transformTimeArrayToDateTimeArray(
      timeSlots[
        moment
          .utc(times[date][0].value)
          .tz(LocationStore.locationTimezoneName)
          .format('ddd')
          .toLowerCase()
      ] || [],
      newDates.find((e) => e.valueDate === date)?.valueDate,
      LocationStore.locationTimezoneName,
      LocationStore.localTimeFormat,
      LocationStore.selectedExperience.settings.frame_time,
    );

    const time = moment.utc(LocationStore.selectedDate).format();
    const existOnTimes =
      locationTimeValues.filter((locationTime) => {
        const _time = moment.utc(locationTime.value).format();
        return _time === time;
      }).length > 0;

    if (!existOnTimes && !isWithinOneHourSelectedDate) {
      setIsTimeSelectionModalOpen(true);
      return;
    }

    setIsTimeSelectionModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (
        LocationStore.timeSettings &&
        LocationStore.selectedExperience &&
        LocationStore.timeSlots
      ) {
        const timeout = setTimeout(async () => {
          await pickerAutoOpen();
        }, 1000);

        return () => clearTimeout(timeout);
      }
    })();
  }, [
    LocationStore.timeSettings,
    LocationStore.selectedExperience,
    LocationStore.timeSlots,
  ]);

  return (
    <>
      <div className={styles.mobileHeader}>
        <Header headerTitle={headerTitle} handleBackClick={onHeaderClickBack} />
        <MenuTab />
      </div>
      <MenuPage.Body
        className={classnames(styles.mobilePageWrapper, {
          [styles.hasIframe]: LocationStore.isIframe,
        })}
      >
        <>
          {!isAsapOnly && !noNextAvailable && (
            <div style={{ marginTop: 16 }}>
              <TimeSelectionBox
                locationHours
                initOpen={isTimeSelectionModalOpen}
                hideClose={isTimeSelectionModalOpen}
                handleModalOpen={(open) => {
                  if (!open) {
                    setIsTimeSelectionModalOpen(open);
                  }
                }}
              />
            </div>
          )}
          <MobileMenuCategory
            showFooter={!!CheckoutStore.numberOfCurrentOrderItems}
          />
          {!!CheckoutStore.numberOfCurrentOrderItems && (
            <CustomButton
              testId="view-order"
              text={`${t('menu.viewMyOrder')} (${
                CheckoutStore.currentOrderItemsTotalQuantity
              })`}
              backgroundColor="#1D1B20"
              handleButtonClick={onViewOrderClick}
            />
          )}
        </>
      </MenuPage.Body>
    </>
  );
});

MenuPageMobile.displayName = 'MenuPageMobile';
