import { MyCheckPlugin } from 'mycheck-core';

export class PluginInjector {
  private map: Map<string, MyCheckPlugin> = new Map();

  public get<T extends MyCheckPlugin>(key: string): T {
    return this.map.get(key) as T;
  }

  public set(key: string, value: MyCheckPlugin): void {
    this.map.set(key, value);
  }

  public getAllPlugins(): Array<MyCheckPlugin> {
    return Array.from(this.map.values());
  }
}
