import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';

import { useLocation } from 'react-router-dom';

import { API_CLIENT, ApiClient, injector, MyCheckApp } from 'mycheck-core';

import LogOutModal from '../../../plugins/AuthPlugin/components/LogoutModal/LogOutModal';
import { EditProfilePage } from '../../../plugins/AuthPlugin/pages/EditProfilePage/EditProfilePage';
import { HistoryPage } from '../../../plugins/AuthPlugin/pages/HistoryPage/HistoryPage';
import { MyOrderPage } from '../../../plugins/AuthPlugin/pages/MyOrderPage/MyOrderPage';
import PaymentMethodsPage from '../../../plugins/AuthPlugin/pages/PaymentMethodsPage/PaymentMethodsPage';
import { RegisterPage } from '../../../plugins/AuthPlugin/pages/RegisterPage/RegisterPage';
import { ConfirmPage } from '../../../plugins/CheckoutPlugin/pages/ConfirmPage/ConfirmPage';

export const ModalNavigatorContext = createContext({
  openHistoryPage: () => null,
  openMyOrderPage: () => null,
  openRegisterPage: () => null,
  openEditProfilePage: () => null,
  openPaymentMethodsPage: () => null,
  openConfirmPage: () => null,
  openLogOutModal: () => null,

  closeHistoryPage: () => null,
  closeMyOrderPage: () => null,
  closeRegisterPage: () => null,
  closeEditProfilePage: () => null,
  closePaymentMethodsPage: () => null,
  closeConfirmPage: () => null,
  closeLogOutModal: () => null,
  isAnyMemberPageOpen: false,
});

export const ModalNavigator: React.FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const [historyOpen, setHistoryOpen] = useState(false);
  const [myOrderOpen, setMyOrderOpen] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [logOutModalOpen, setLogOutModalOpen] = useState(false);

  const openHistoryPage = useCallback(() => setHistoryOpen(true), []);
  const openMyOrderPage = useCallback(() => setMyOrderOpen(true), []);
  const openRegisterPage = useCallback(() => setRegisterOpen(true), []);
  const openEditProfilePage = useCallback(() => setEditOpen(true), []);
  const openPaymentMethodsPage = useCallback(
    () => setPaymentMethodsOpen(true),
    [],
  );
  const openConfirmPage = useCallback(() => setConfirmOpen(true), []);
  const openLogOutModal = useCallback(() => setLogOutModalOpen(true), []);

  const closeHistoryPage = useCallback(() => setHistoryOpen(false), []);
  const closeMyOrderPage = useCallback(() => setMyOrderOpen(false), []);
  const closeRegisterPage = useCallback(() => setRegisterOpen(false), []);
  const closeEditProfilePage = useCallback(() => setEditOpen(false), []);
  const closePaymentMethodsPage = useCallback(
    () => setPaymentMethodsOpen(false),
    [],
  );
  const closeConfirmPage = useCallback(() => setConfirmOpen(false), []);
  const closeLogOutModal = useCallback(() => setLogOutModalOpen(false), []);
  const isAnyMemberPageOpen =
    historyOpen || editOpen || myOrderOpen || paymentMethodsOpen;

  useEffect(() => {
    closeRegisterPage();
    closeHistoryPage();
    closeMyOrderPage();
    closeEditProfilePage();
    closePaymentMethodsPage();
    closeLogOutModal();
  }, [location.pathname]);

  useEffect(() => {
    if (MyCheckApp.instance.getSupportMembersValue()) {
      const apiClient = injector.get<ApiClient>(API_CLIENT);
      apiClient.setFallbackFunction(openRegisterPage);
    }
  }, [location.search]);

  return (
    <ModalNavigatorContext.Provider
      value={{
        openHistoryPage,
        openRegisterPage,
        openMyOrderPage,
        openPaymentMethodsPage,
        openEditProfilePage,
        openConfirmPage,
        openLogOutModal,
        closeHistoryPage,
        closeRegisterPage,
        closeMyOrderPage,
        closePaymentMethodsPage,
        closeEditProfilePage,
        closeConfirmPage,
        closeLogOutModal,
        isAnyMemberPageOpen,
      }}
    >
      {children}
      {registerOpen && <RegisterPage />}
      {historyOpen && <HistoryPage />}
      {myOrderOpen && <MyOrderPage />}
      {editOpen && <EditProfilePage />}
      {paymentMethodsOpen && <PaymentMethodsPage />}
      {confirmOpen && <ConfirmPage />}
      {logOutModalOpen && <LogOutModal />}
    </ModalNavigatorContext.Provider>
  );
};
