import React, { useEffect, useMemo } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { useLocation } from 'react-router-dom';

import {
  attachAccessibility,
  attachFavicon,
  attachFonts,
  MyCheckApp,
  setHtmlPageTitle,
  transformDate,
  transformTime,
  useAuthStore,
  useCheckoutStore,
  useErrorTrackerHandler,
  useLanguageStore,
  useLocationStore,
  useNavigation,
  useNearestTime,
  useTimezoneTrackerContext,
  useWindowSize,
} from 'mycheck-core';

import { Loader } from '@components';

import { OptionsPageMobile } from '../../../OptionsPlugin/pages/OptionsPageMobile/OptionsPageMobile';
import { LocationPageDesktop } from '../LocationPageDesktop/LocationPageDesktop';
import { LocationPageMobile } from '../LocationPageMobile/LocationPageMobile';
import { DaysSelectValueItem } from 'types/GenericTypes';

export const LocationPage: React.FC = observer(() => {
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const navigation = useNavigation();
  const LanguageStore = useLanguageStore();
  const CheckoutStore = useCheckoutStore();
  const { checkTimezone } = useTimezoneTrackerContext();
  const { isLg } = useWindowSize();
  const { nearestTime } = useNearestTime();
  const location = useLocation();
  const errorHandler = useErrorTrackerHandler();

  LocationStore.setNavigation(navigation);

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();
    const supportMembers = MyCheckApp.instance.getSupportMembersValue();

    const fonts = get(_config, 'settings.fonts');
    const favicon = get(_config, 'settings.favicon');
    const accessibility = get(_config, 'settings.accessibility');
    const appSettingName = get(_config, 'settings.name');

    setHtmlPageTitle(appSettingName);
    attachFonts(fonts);
    attachFavicon(favicon);
    attachAccessibility(
      accessibility && process.env.NODE_ENV !== 'development',
    );

    const numberOfDays = get(_config, 'settings.datePickerDays', 7);

    return {
      numberOfDays,
      blockHomePage,
      supportMembers,
    };
  }, [location.search]);

  const loginGuest = async () => {
    try {
      await AuthStore.getGuestRefreshToken();
      await AuthStore.getGuestLogin();
      await AuthStore.setMetadata({ language: LanguageStore.selectedValue });
    } catch (err) {
      if (err instanceof Error) {
        errorHandler.onError(err);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await LocationStore.fetchAll('restaurant', config.blockHomePage);
    })();
  }, []);

  useEffect(() => {
    if (!AuthStore.isGuestLoggedIn) {
      loginGuest();
    }
  }, [AuthStore.isGuestLoggedIn]);

  useEffect(() => {
    if (nearestTime && !LocationStore.isFetching) {
      LocationStore.fetchRestaurants(LocationStore.selectedHotel, true);
    }
  }, [nearestTime]);

  useEffect(() => {
    if (
      LocationStore.selectedDate &&
      LocationStore.selectedDateTemp &&
      LocationStore.selectedDate !== LocationStore.selectedDateTemp
    ) {
      const newDates = transformDate(
        LocationStore.locationTimezoneName,
        LocationStore.localHotelDateFormat,
        config.numberOfDays,
        true,
        LocationStore.timeSettings.open_hours,
      );

      const newTimes: DaysSelectValueItem = newDates.reduce((result, date) => {
        result[date.value] = transformTime(
          LocationStore.timeSettings,
          date.value,
          LocationStore.locationTimezoneName,
          LocationStore.localHotelTimeFormat,
        );
        return result;
      }, {});

      const currentDay = newDates.find(
        (date) =>
          moment(date.value)
            .utcOffset(0, true)
            .tz(LocationStore.locationTimezoneName)
            .date() ===
          moment(LocationStore.selectedDate)
            .tz(LocationStore.locationTimezoneName)
            .utcOffset(0, true)
            .date(),
      );

      if (!currentDay) return;

      const currentDayProperty = currentDay.value;
      const timesArr = newTimes[currentDayProperty];
      const firstFitsRangeIndex = timesArr.findIndex(
        (time, index) =>
          moment(time.value).isAfter(moment.utc(LocationStore.selectedDate)) ||
          index === timesArr.length - 1,
      );

      if (firstFitsRangeIndex === -1) return;

      const dateIndex = !firstFitsRangeIndex
        ? firstFitsRangeIndex
        : firstFitsRangeIndex - 1;

      LocationStore.setSelectedDate(timesArr[dateIndex].value);
      LocationStore.setSelectedTime(timesArr[dateIndex].value);
      LocationStore.setSelectedDateTemp(timesArr[dateIndex].value);
    }
  }, []);

  useEffect(() => {
    if (!LocationStore.selectedExperienceType) return;

    checkTimezone();
    CheckoutStore.setIsGrace(false);
  }, [
    LocationStore.selectedHotelObj,
    LocationStore.locationTimezoneName,
    LocationStore.selectedExperienceType,
  ]);

  if (LocationStore.isFetching && !isLg) {
    return <Loader />;
  }

  if (!LocationStore.selectedExperienceType && !isLg)
    return <OptionsPageMobile />;

  if (isLg) {
    return <LocationPageDesktop />;
  }

  return <LocationPageMobile />;
});

LocationPage.displayName = 'LocationPage';
