import React, { memo, type MutableRefObject } from 'react';

import classnames from 'classnames';
import { FormikErrors } from 'formik';

import { CustomIcon, PriceFormatter } from '@components';

import styles from './CheckboxField.module.scss';
import { DynamicValue, DynamicValueArray } from 'types/GenericTypes';

type CheckboxFieldType = {
  setFieldValue: (property: string, value: DynamicValueArray) => void;
  modifierGroup: number;
  id: string;
  name: string;
  price: number;
  values: DynamicValueArray | DynamicValue;
  errors: FormikErrors<DynamicValueArray | DynamicValue>;
  maxSelect: number;
  labelRef: MutableRefObject<HTMLDivElement>;
  setFieldError: (field: string, message: string) => void;
};

export const CheckboxField: React.FC<CheckboxFieldType> = memo(
  ({
    setFieldValue,
    values,
    modifierGroup,
    id,
    name,
    price,
    maxSelect,
    labelRef,
    setFieldError,
  }) => {
    const isDisabled = () => {
      if (maxSelect === 0) {
        return false;
      }

      return (
        values.modifiers?.[modifierGroup]?.length === maxSelect &&
        !values.modifiers[modifierGroup].includes(id)
      );
    };

    const onClick = () => {
      if (!isDisabled()) {
        setFieldError(`${modifierGroup}`, undefined);
        if (!values.modifiers[modifierGroup]?.includes(id)) {
          setFieldValue('modifiers', {
            ...(values.modifiers as unknown as Record<string, unknown>),
            [modifierGroup]: [...(values.modifiers[modifierGroup] || []), id],
          });
        } else {
          setFieldValue('modifiers', {
            ...(values.modifiers as unknown as Record<string, unknown>),
            [modifierGroup]: (
              values.modifiers[modifierGroup] as unknown as string[]
            ).filter((el) => el !== id),
          });
        }
      } else {
        setFieldError(`${modifierGroup}`, 'Max number of items is selected');
        labelRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <div
        data-test-id="checkbox-field"
        className={classnames(styles.fieldItem, styles.fieldItemDisabled)}
        onClick={onClick}
      >
        <CustomIcon
          className={styles.fieldItemCheckbox}
          name={
            values.modifiers?.[modifierGroup]?.includes(id)
              ? 'checkboxV'
              : 'checkbox'
          }
        />
        <div>{name}</div>
        {!!price && (
          <div className={styles.fieldItemPrice}>
            +<PriceFormatter value={price} />
          </div>
        )}
      </div>
    );
  },
);

CheckboxField.displayName = 'CheckboxField';
