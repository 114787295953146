import { useEffect } from 'react';

function findAncestor(el) {
  while ((el = el.parentElement) && !el.getAttribute('data-overlay'))
    return el?.getAttribute('data-overlay') || null;
}

type Props = {
  overlayId?: string;
  withSibling?: boolean;
};

export function useClickOutside(
  ref,
  callback,
  { overlayId, withSibling }: Props = {},
) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref &&
        ref.current &&
        !(
          ref.current.contains(event.target) ||
          (withSibling &&
            ref.current.nextElementSibling?.contains(event.target))
        )
      ) {
        const targetAttribute =
          event.target.getAttribute('data-overlay') ||
          findAncestor(event.target);
        if (!overlayId) {
          callback(event);
        }

        if (targetAttribute === overlayId) {
          callback(event);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref]);
}
