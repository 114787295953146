import React, { PropsWithChildren } from 'react';

import styles from './DataGrid.module.scss';

export interface DataGridColumnProps extends PropsWithChildren {}

const DataGridColumn: React.FC<DataGridColumnProps> = ({ children }) => {
  return <div className={styles.dataGridHeaderColumn}>{children}</div>;
};

export default DataGridColumn;
