import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

export function useUserFlow() {
  const location = useLocation();
  const supportGuests = useMemo(
    () => MyCheckApp.instance.getSupportGuestsValue(),
    [location.search],
  );
  const supportMembers = useMemo(
    () => MyCheckApp.instance.getSupportMembersValue(),
    [location.search],
  );
  return { supportGuests, supportMembers };
}
