import React, { useEffect, useMemo, useState, type ChangeEvent } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  useFlowType,
  useWindowSize,
  useTranslation,
  MyCheckApp,
} from 'mycheck-core';

import { SelectBox } from '@components';

import styles from './SelectLocation.module.scss';
import { SelectLocationModal } from './SelectLocationModal';

type SelectLocationType = {
  data: any[];
  formikProps: any;
  limit: number;
};

const SelectElement = ({ label, description, value, onClick }) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const elementStyle = get(_config, 'search.element', {});
    const hotelNameStyle = get(_config, 'search.mainText', {});
    const dividerStyle = get(_config, 'general.divider', '');
    const hotelAddressStyle = get(_config, 'search.subText', {});

    return {
      elementStyle,
      hotelNameStyle,
      dividerStyle,
      hotelAddressStyle,
    };
  }, [location.search]);

  const onClickElement = () => onClick(value);

  return (
    <div
      data-test-id="select-element"
      className={styles.element}
      style={{ ...config.elementStyle, borderBottom: config.dividerStyle }}
      onClick={onClickElement}
    >
      <div style={config.hotelNameStyle}>{label}</div>
      <div style={{ marginTop: 5, ...config.hotelAddressStyle }}>
        {description}
      </div>
    </div>
  );
};

function onInputCallback() {
  return null;
}

export const SelectLocation: React.FC<SelectLocationType> = ({
  data = [],
  formikProps,
  limit,
}) => {
  const [value, setValue] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const { isRestaurantFlow } = useFlowType();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const listStyle = get(_config, 'search.list', {});

    return {
      listStyle,
    };
  }, [location.search]);

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.currentTarget.value);
  };
  const onClick = (val: string) => {
    formikProps.setFieldValue('selectedLocationID', val);
    closeModal();
  };

  const filterValue = () =>
    data.filter(
      (element) =>
        element.label?.toLowerCase()?.includes(value.toLowerCase()) ||
        element.description?.toLowerCase()?.includes(value.toLowerCase()) ||
        element.value?.toLowerCase()?.includes(value.toLowerCase()),
    );

  useEffect(() => {
    if (isLg) {
      closeModal();
    }
  }, [isLg]);

  if (isLg || isRestaurantFlow) {
    return (
      <SelectBox
        onHomePage
        isSearchable
        data={data}
        placeholder={t('home.selectLocationPlaceholder')}
        limit={limit}
        formikProps={formikProps}
        value={formikProps.values.selectedLocationID}
        property="selectedLocationID"
        onInputCallback={onInputCallback}
        keepCursorAtEnd
        testId="home-select-hotel"
      />
    );
  }

  return (
    <>
      <SelectBox
        onHomePage
        isSearchable
        data={data}
        placeholder={t('home.selectLocationPlaceholder')}
        limit={limit}
        formikProps={formikProps}
        value={formikProps.values.selectedLocationID}
        property="selectedLocationID"
        onClick={openModal}
        onInputCallback={onInputCallback}
        testId="home-select-hotel"
      />
      {isOpen && (
        <SelectLocationModal
          onChange={onChange}
          value={value}
          onClose={closeModal}
        >
          <div className={styles.list} style={config.listStyle}>
            {filterValue().map((element) => (
              <SelectElement
                key={element.label}
                label={element.label}
                description={element.description}
                value={element.value}
                onClick={onClick}
              />
            ))}
          </div>
        </SelectLocationModal>
      )}
    </>
  );
};
