import { useCallback, useEffect, useMemo, useRef } from 'react';

import axios from 'axios';
import { useLocation, useMatch } from 'react-router-dom';

import {
  MyCheckApp,
  useAuthStore,
  useCheckoutStore,
  useDateChange,
  useIsLocationOpen,
  useLanguageLoader,
  useLocationStore,
  useMenuStore,
  useNavigation,
  useShowNotAcceptingOrdersPopupDecider,
  useStateHandler,
} from 'mycheck-core';

import { CartStoreService } from '../../core/service/CartStoreService';

import { useHotelFlow } from './useHotelFlow';
import { useRestaurantFlow } from './useRestaurantFlow';

export const useBusinessFlow = () => {
  const navigation = useNavigation();
  const location = useLocation();
  const isLocationOpen = useIsLocationOpen();

  const showNotAcceptingOrdersPopupDecider =
    useShowNotAcceptingOrdersPopupDecider();
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const MenuStore = useMenuStore();
  const CheckoutStore = useCheckoutStore();
  const DateChange = useDateChange();

  const LanguageLoader = useLanguageLoader();

  const isMenuMatch = useMatch('/menu/*');

  const selectedExperienceRef = useRef(0);
  const selectedTimeRef = useRef('');
  const config = useMemo(() => {
    const blockHomePage = MyCheckApp.instance.getBlockHomePage();

    return {
      blockHomePage,
    };
  }, [location.search]);

  useRestaurantFlow();
  useHotelFlow();

  const onDateChange = useCallback(async () => {
    if (
      (selectedExperienceRef.current !== LocationStore.selectedExperienceId ||
        selectedTimeRef.current !== LocationStore.selectedDate) &&
      LocationStore.selectedExperienceId &&
      LocationStore.timeSettings &&
      isMenuMatch &&
      LocationStore.selectedRestaurant.id
    ) {
      try {
        selectedTimeRef.current = LocationStore.selectedDate;
        selectedExperienceRef.current = LocationStore.selectedExperienceId;
        await DateChange.fetchAfterChange();

        showNotAcceptingOrdersPopupDecider.showNotAcceptingOrdersPopupDecider();
      } catch (err) {
        if (axios.isCancel(err)) {
          return;
        }

        selectedExperienceRef.current = 0;
        MenuStore.stopFetching();
        MenuStore.clearMenu();

        navigation.replace('/location');
      }
    }
  }, [
    CheckoutStore.checkoutTime,
    LocationStore.selectedDate,
    LocationStore.timeSettings,
    LocationStore.locationTimezoneName,
    LocationStore.selectedExperience?.settings.is_asap_only,
    isMenuMatch,
    navigation.hash,
    isLocationOpen,
    LocationStore.selectedRestaurant.id,
  ]);

  useEffect(() => {
    try {
      LanguageLoader.loadTranslation();
      LocationStore.fetchCurrenciesList();

      if (!config.blockHomePage) {
        LocationStore.fetchHomeList(AuthStore.businessID);
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    CheckoutStore.restoreCurrentOrder(
      CartStoreService.setCurrentStore({
        hotelId: LocationStore.selectedHotel,
        restaurantId: LocationStore.selectedRestaurantId,
        experienceId: LocationStore.selectedExperienceId,
      }),
    );
  }, [LocationStore.selectedExperienceId]);

  useEffect(() => {
    switch (location.pathname) {
      case '/location':
        selectedExperienceRef.current = 0;
        break;
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMenuMatch) {
      onDateChange();
    }
  }, [isMenuMatch, onDateChange]);

  useStateHandler();
};
