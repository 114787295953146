import React, { useMemo, useState, type ChangeEvent } from 'react';

import { FormikProps } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { CustomInput, CustomPhoneInput, PopUp, Text } from '@components';

import { NextButton } from '../../../../components/NextButton/NextButton';

import styles from './VerificationScreen.module.scss';
import { RegisterFormikValues } from 'plugins/AuthPlugin/types/FormikValuesType';

interface Props {
  formikProps: FormikProps<RegisterFormikValues>;
  handleStepBack: () => void;
  handleResendCodeClick: () => void;
  handleReCaptchav3Verify: () => void;
}

export const VerificationScreen: React.FC<Props> = ({
  formikProps,
  handleStepBack,
  handleResendCodeClick,
  handleReCaptchav3Verify,
}) => {
  const location = useLocation();
  const [verificationSms, setVerificationSms] = useState(false);

  const { t } = useTranslation();
  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const handleResendClick = async () => {
    formikProps.setFieldValue('verificationCode', '');
    formikProps.setFieldError('verificationCode', '');
    setVerificationSms(true);
    await handleReCaptchav3Verify();
    handleResendCodeClick();
  };

  const handleHidePopUp = () => setVerificationSms(false);

  const customInputStyle = { marginTop: 24 };

  const handleChangeVerificationCode = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const re = /^[0-9\b]+$/;
    if (!event.currentTarget.value || re.test(event.currentTarget.value)) {
      formikProps.handleChange(event);
    }
  };

  return (
    <div className={styles.verificationScreen}>
      <div className={styles.verificationScreenDescription}>
        <Text value="register.verificationCodeLabel" />
      </div>
      <CustomPhoneInput
        testId="phone-verification"
        formikProps={formikProps}
        placeholder={t('profile.phonePlaceholder')}
        disabled
      >
        <NextButton onDisabledClick={handleStepBack} disabled />
      </CustomPhoneInput>
      <CustomInput
        onChange={handleChangeVerificationCode}
        value={formikProps.values.verificationCode}
        type="tel"
        name="verificationCode"
        placeholder={t('register.verificationCodePlaceholder')}
        error={!!formikProps.errors.verificationCode}
        errorMessage={t(`errors.${formikProps.errors.verificationCode}`)}
        customStyle={customInputStyle}
        inputMode="numeric"
      >
        <NextButton />
      </CustomInput>
      <div
        className={styles.verificationScreenResend}
        style={{ color: config.primary }}
        onClick={handleResendClick}
      >
        <Text value="register.verificationResendCodeButton" />
      </div>
      {verificationSms && (
        <PopUp onClose={handleHidePopUp}>
          <div className={styles.verificationScreenResendText}>
            {t('register.verificationPopUpTitle')}
          </div>
        </PopUp>
      )}
    </div>
  );
};
