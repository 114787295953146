import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  capitalizeFirstLetter,
  MyCheckApp,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { PaymentsMethod } from './PaymentsMethod';
import styles from './PaymentsMethodBox.module.scss';

interface Props {
  testId: string;
  text: string;
  onPaymentMethodChange: () => void;
}

export const PaymentsMethodBox: React.FC<Props> = ({
  testId,
  text,
  onPaymentMethodChange,
}) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const location = useLocation();
  const LocationStore = useLocationStore();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  const [paymentsMethodOpen, setPaymentsMethodOpen] = useState(false);
  const hasMultiPaymentsMethod = LocationStore.chargeOptions.length > 1;

  const handleChange = () => {
    if (hasMultiPaymentsMethod) {
      setPaymentsMethodOpen(true);
    }
  };

  useEffect(() => {
    if (isLg && LocationStore.chargeOptions.length > 1) {
      setPaymentsMethodOpen(true);
    }
  }, []);

  return (
    <div className={styles.paymentsMethodBoxContainer} data-test-id={testId}>
      <div className={styles.paymentsMethodBoxContainerTitle}>
        {t('checkout.paymentMethodLabel')}
      </div>
      <div
        className={classnames(
          styles.paymentsMethodBoxContainerBox,
          !hasMultiPaymentsMethod
            ? styles.paymentsMethodBoxContainerBoxDisable
            : '',
        )}
        onClick={handleChange}
      >
        {text}
        {hasMultiPaymentsMethod && (
          <div
            className={styles.paymentsMethodBoxContainerBoxChange}
            style={{ color: config.primary }}
          >
            {capitalizeFirstLetter(t('general.change').toLowerCase())}
          </div>
        )}
      </div>
      {paymentsMethodOpen && (
        <PaymentsMethod
          handleOpen={() => {
            setPaymentsMethodOpen(!paymentsMethodOpen);
          }}
          onChoose={() => {
            onPaymentMethodChange();
            setPaymentsMethodOpen(false);
          }}
        />
      )}
    </div>
  );
};
