import React, { useEffect, useMemo, useState, type CSSProperties } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, textEllipsis, useWindowSize } from 'mycheck-core';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './MenuTab.module.scss';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

interface Props {
  category: MenuCategoryType | MenuSubcategory;
  selectedId: number;
  onClick: (category: MenuCategoryType | MenuSubcategory) => void;
  tabLeft: number;
  tabWidth: number;
}

export const MenuTabDropDown: React.FC<Props> = observer(
  ({ category, selectedId, onClick, tabLeft, tabWidth }) => {
    const getTranslationForItem = useGetTranslationForMenuItem();
    const location = useLocation();
    const { isLg } = useWindowSize();

    const [fixedLeft, setFixedLeft] = useState(undefined);

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const primary = get(_config, 'palette.primary', '');
      let secondPrimary = get(_config, 'palette.primary', '');

      if (primary.indexOf('rgb') === 0) {
        secondPrimary = secondPrimary.replace('rgb', 'rgba');
        secondPrimary = secondPrimary.replace(')', ', 0.322');
      }

      if (primary.indexOf('#') === 0) {
        const opacity = '0.322';
        const values = primary.match(/\w\w/g);
        const [r, g, b] = values.map((k: string) => parseInt(k, 16));
        secondPrimary = ` rgba( ${r}, ${g}, ${b}, ${opacity} )`;
      }

      return {
        primary,
        secondPrimary,
      };
    }, [location.search]);

    useEffect(() => {
      const clientWidth =
        document.getElementById('MenuTabDropDownId')?.clientWidth;
      const screenWidth = window.screen.width;

      if (clientWidth > tabWidth && screenWidth - tabLeft < clientWidth) {
        setFixedLeft(screenWidth - clientWidth - 16);
      } else {
        setFixedLeft(tabLeft);
      }
    }, []);

    const renderItems = (sc: MenuSubcategory) => {
      const selectedStyle: CSSProperties =
        selectedId === sc.id && !isLg
          ? {
              border: `1px solid ${config.primary}`,
              backgroundColor: `${config.secondPrimary}`,
            }
          : selectedId === sc.id
            ? { color: config.primary }
            : {};

      return (
        <div key={sc.id} onClick={() => onClick(sc)}>
          <div
            className={classnames(
              styles.menuTabDropdownItem,
              selectedId === sc.id ? styles.menuTabDropdownItemSelected : '',
            )}
            style={selectedStyle}
          >
            {textEllipsis(getTranslationForItem(sc.pos_unique_id, sc).name, 22)}
          </div>
        </div>
      );
    };

    return (
      <div
        className={styles.menuTabDropdown}
        style={{ left: fixedLeft }}
        id="MenuTabDropDownId"
      >
        {category?.subcategories?.map(renderItems)}
      </div>
    );
  },
);

MenuTabDropDown.displayName = 'MenuTabDropDown';
