import { useLocationStore } from 'mycheck-core';
import { IPostMessage } from 'plugins/LocationPlugin/types/LocationTypes';

export const useNativeBridge = () => {
  const LocationStore = useLocationStore();

  const postMessage = (message: string, origin: string, parent = false) => {
    if (parent === true) {
      window.parent.postMessage(message, origin);
    } else {
      window.postMessage(message, origin);
    }
  };

  const postMessageClose = (message: IPostMessage) => {
    const isIframe = LocationStore.isIframe;
    const messageString = JSON.stringify(message);

    if (isIframe === true) {
      postMessage(messageString, '*', true);
    } else {
      postMessage('CLOSE', '*', false);
    }
  };

  return { postMessageClose };
};
