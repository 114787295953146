import React, { useMemo } from 'react';

import { useFormikContext } from 'formik';
import get from 'lodash/get';

import { MyCheckApp, Trans } from 'mycheck-core';

import { Checkbox, ExternalLink, SanitizedHtml } from '@components';

import styles from './AcceptPrivacy.module.scss';

interface IFormikProps {
  privacyCheck: boolean;
}

interface Props {
  privacyPolicyAndTerms: string;
}

export const AcceptPrivacy: React.FC<Props> = ({ privacyPolicyAndTerms }) => {
  const formikProps = useFormikContext<IFormikProps>();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const termsAndConditions = get(
      _config,
      'home.settings.termsAndConditions',
      null,
    );
    const privacyPolicy = get(_config, 'home.settings.privacyPolicy', null);
    const textStyle = get(_config, 'register.text1', {});

    return {
      termsAndConditions,
      privacyPolicy,
      textStyle,
    };
  }, []);

  return (
    <div className={styles.container}>
      <Checkbox
        name="privacyCheck"
        value={formikProps.values.privacyCheck}
        testId="accept-privacy"
        customStyles={{ margin: '16px 0 29px' }}
        customIconStyles={{ marginTop: '2px' }}
      >
        {privacyPolicyAndTerms ? (
          <SanitizedHtml html={privacyPolicyAndTerms} />
        ) : (
          <p className={styles.text}>
            <Trans
              i18nKey="auth.privacy"
              components={{
                termsLink: (
                  <ExternalLink href={config.termsAndConditions}>
                    text
                  </ExternalLink>
                ),
                privacyLink: (
                  <ExternalLink href={config.privacyPolicy}>text</ExternalLink>
                ),
              }}
            />
          </p>
        )}
      </Checkbox>
    </div>
  );
};
