import React, {
  useCallback,
  useMemo,
  useState,
  PropsWithChildren,
  type CSSProperties,
  type MouseEvent,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  getSearchState,
  MyCheckApp,
  useActiveScroll,
  useAuthStore,
  useLocationStore,
  useModalNavigator,
  useNativeBridge,
  useNavigation,
  useScrollPosition,
  useWindowSize,
} from 'mycheck-core';

import { LangPicker } from '../../plugins/LanguagePlugin/components/LanguagePicker';
import { Background } from '../Background/Background';
import { HeaderProfile } from '../HeaderProfile/HeaderProfile';
import { CustomIcon } from '../Icon/CustomIcon';
import { MenuSideBar } from '../MenuSideBar/MenuSideBar';

import styles from './Header.module.scss';

export interface HeaderProps extends PropsWithChildren {
  headerTitle?: string | JSX.Element;
  handleBackClick?: (event?: MouseEvent<HTMLDivElement>) => void;
  enforceSmall?: boolean;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  additionalTitle?: JSX.Element;
  enforceTitle?: boolean;
  hideBackButton?: boolean;
  color?: string;
  hideUserProfile?: boolean;
}

export const Header: React.FC<HeaderProps> = observer(
  ({
    headerTitle,
    handleBackClick,
    children,
    enforceSmall,
    style = {},
    rootStyle = {},
    additionalTitle,
    enforceTitle,
    hideBackButton = false,
    color,
    hideUserProfile,
  }) => {
    const AuthStore = useAuthStore();
    const locationStore = useLocationStore();
    const nativeBridge = useNativeBridge();
    const navigation = useNavigation();
    const { isLg } = useWindowSize();
    const location = useLocation();
    const activeScroll = useActiveScroll();
    const scrollPosition = useScrollPosition();
    const { openRegisterPage, isAnyMemberPageOpen } = useModalNavigator();

    const hideHeaderStyle: CSSProperties = useMemo(() => {
      const hasHide = activeScroll && scrollPosition > 0;

      return hasHide
        ? {
            position: 'absolute',
            top: -100,
          }
        : null;
    }, [activeScroll, scrollPosition]);

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();
      const blockHomePage = MyCheckApp.instance.getBlockHomePage();
      const supportMembers = MyCheckApp.instance.getSupportMembersValue();

      const primary = get(_config, 'palette.primary', {});
      const logo = get(_config, 'general.smallLogo', {});
      const headerStyle = get(_config, 'general.header', {});
      const titleStyle = get(_config, 'general.mobileTitle', {});
      const titleDesktop = get(_config, 'general.titleDesktop', {});

      return {
        primary,
        logo,
        headerStyle,
        titleStyle,
        titleDesktop,
        blockHomePage,
        supportMembers,
      };
    }, [window.location.search]);

    const showHeaderProfileMenu =
      config.supportMembers && !isAnyMemberPageOpen && !hideUserProfile;

    const onLogoClick = useCallback(
      () => (config.blockHomePage ? null : navigation.push('/')),
      [config.blockHomePage, window.location.search],
    );
    const goBack = (event?: MouseEvent<HTMLDivElement>) => {
      if (handleBackClick) {
        handleBackClick(event);
      } else {
        navigation.goBack();
      }
    };

    const onHandleClickBack = useCallback(
      (event?: MouseEvent<HTMLDivElement>) => {
        const values = getSearchState();

        if (locationStore.isNative) {
          if (
            location.pathname === '/location' ||
            (location.pathname === '/menu' && values.urlrestaurant)
          ) {
            nativeBridge.postMessageClose({ action: 'CLOSE', source: 'home' });
          } else {
            goBack(event);
          }
        } else {
          goBack(event);
        }
      },
      [window.location.search],
    );

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const closeMenu = () => setIsMenuOpen(false);
    const handleMenuOpen = () => {
      if (AuthStore.isGuest) {
        openRegisterPage();
      } else {
        setIsMenuOpen(!isMenuOpen);
      }
    };

    if (locationStore.isIframe) {
      return null;
    }

    if (isLg) {
      if (enforceSmall) {
        return (
          <Background
            className={classnames(styles.menuHeader, styles.desktopHeaderSmall)}
            style={{ ...rootStyle, backgroundColor: config.primary }}
          >
            <div
              data-test-id="header-back-button"
              className={styles.headerBackButton}
              onClick={onHandleClickBack}
            >
              {!hideBackButton && <CustomIcon name="backIconMobile" />}
            </div>
            <div className={styles.desktopHeaderSmallTitle} style={{ color }}>
              {headerTitle}
              {additionalTitle}
            </div>
            <div
              data-test-id="header-desktop-close"
              className={styles.desktopHeaderSmallIcon}
              onClick={onHandleClickBack}
            >
              <CustomIcon name="x" style={{ color: '#fff' }} />
            </div>
          </Background>
        );
      }

      return (
        <Background
          className={styles.desktopHeader}
          style={{ ...config.headerStyle, ...rootStyle }}
        >
          <div
            data-test-id="header-desktop-logo"
            className={styles.desktopHeaderLogo}
            onClick={onLogoClick}
            style={{ color: config.primary, ...config.titleDesktop, ...style }}
          >
            <img
              alt="logo"
              className={styles.desktopHeaderLogoImage}
              src={config.logo.url}
              width={config.logo.width}
            />
          </div>
          {enforceTitle && (
            <div className={styles.desktopHeaderTitle}>{headerTitle}</div>
          )}
          {children}
        </Background>
      );
    }

    return (
      <>
        <div
          className={classnames(
            styles.header,
            locationStore.isNative ? styles.headerIsNative : {},
          )}
          style={{
            backgroundColor: config.primary,
            ...rootStyle,
            ...hideHeaderStyle,
          }}
        >
          <div
            data-test-id="header-back-button"
            className={styles.headerBackButton}
            onClick={onHandleClickBack}
          >
            {!hideBackButton && <CustomIcon name="backIconMobile" />}
          </div>
          <div
            className={styles.headerTitle}
            style={showHeaderProfileMenu ? { paddingLeft: 44 } : {}}
          >
            {headerTitle}
          </div>
          <div className={styles.headerRightElement}>
            {showHeaderProfileMenu && (
              <HeaderProfile onPress={handleMenuOpen} />
            )}
            <LangPicker />
          </div>
        </div>
        {config.supportMembers && (
          <MenuSideBar
            isMenuOpen={isMenuOpen}
            handleMenuOpen={handleMenuOpen}
            closeMenu={closeMenu}
          />
        )}
      </>
    );
  },
);

Header.displayName = 'Header';
