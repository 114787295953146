import React, {
  Children,
  cloneElement,
  type PropsWithChildren,
  type ReactElement,
} from 'react';

import classnames from 'classnames';

import { useUserFlow } from 'mycheck-core';

import styles from './SideBar.module.scss';

interface SideBarProps extends PropsWithChildren {
  isMenuOpen: boolean;
  handleMenuOpen: () => void;
  closeMenu: () => void;
  fullScreen?: boolean;
}

export const SideBar: React.FC<SideBarProps> = ({
  isMenuOpen,
  handleMenuOpen,
  closeMenu,
  children,
  fullScreen = false,
}) => {
  const { supportMembers } = useUserFlow();

  if (!supportMembers) {
    return null;
  }

  return (
    <div className={styles.sidebar} data-test-id="sidebar">
      <div
        className={classnames(
          styles.sidebarContainer,
          fullScreen ? styles.sidebarContainerFullScreen : '',
        )}
        style={{ left: !isMenuOpen ? '100%' : window.screen.width - 319 }}
      >
        {Children.map(children, (child) =>
          cloneElement(child as ReactElement, { closeMenu }),
        )}
      </div>
      {isMenuOpen && (
        <div
          data-test-id="sidebar-overlay"
          onClick={handleMenuOpen}
          className={classnames(
            styles.sidebarOverlay,
            fullScreen ? styles.sidebarOverlayFullScreen : '',
          )}
        />
      )}
    </div>
  );
};
