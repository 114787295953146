import { observable, action, makeAutoObservable } from 'mobx';
import { persist } from 'mobx-persist';

import { MyCheckApp, toYearMonthDayFormat } from 'mycheck-core';

import { HttpAuthApi } from './AuthApi';
import { HttpAuthApiResponse } from './types/AuthTypes';
import {
  CardProvider,
  MerchantsList,
} from './pages/PaymentMethodsPage/constants';
import get from 'lodash/get';

export const AUTH_STORE = 'AUTH_STORE';

export class AuthStore {
  @observable @persist phone = '';
  @observable @persist email = '';
  @observable @persist userFirstName = '';
  @observable @persist userLastName = '';
  @observable @persist userId = 0;
  @observable @persist optinMarketing = '';
  @observable @persist refreshToken = '';
  @observable @persist accessToken = '';
  @observable @persist isLoggedIn = false;
  @observable @persist isGuest = true;
  @observable @persist isGuestLoggedIn = false;
  @observable @persist countryCodeFromLatLng = '';
  @observable dateOfBirth: Date | undefined = undefined;
  @observable verificationCode = '';
  @observable temporaryPhoneToken = '';
  @observable captchaToken = '';

  @observable publishableKey = '';
  @observable supportGuests = false;
  @observable isHistoryFetching = false;
  @observable history: Array<HttpAuthApiResponse['order']> = null;
  @observable selectedOrderId = 0;
  @observable selectedOrder: HttpAuthApiResponse['order'] = null;
  @observable businessID: number = undefined;
  @observable paymentMethods: HttpAuthApiResponse['paymentMethod'][] = [];
  @observable isPaymentMethodsFetching = false;
  private authApi: HttpAuthApi;

  constructor() {
    makeAutoObservable(this);
  }

  initStore = (
    authApi: HttpAuthApi,
    { publishableKey, supportGuests, businessID },
  ) => {
    this.authApi = authApi;
    this.publishableKey = publishableKey;
    this.supportGuests = supportGuests;
    this.businessID = businessID;

    return this;
  };

  afterHydrate = () => {
    if (this.isLoggedIn) {
      if (!this.supportGuests && this.isGuest) {
        this.logout();
      }

      if (this.supportGuests && !this.isGuest) {
        this.logout();
      }
    }
  };

  @action
  setCaptchaToken = (captchaToken: string) => {
    this.captchaToken = captchaToken;
  };

  @action
  setPhone = (phone: string) => {
    this.phone = phone;
  };

  @action
  setEmail = (email: string) => {
    this.email = email;
  };

  @action
  setUserFirstName = (firstName: string) => {
    this.userFirstName = firstName;
  };

  @action
  setUserLastName = (lastName: string) => {
    this.userLastName = lastName;
  };

  @action
  setOptinMarketing = (optinMarketing: string) => {
    this.optinMarketing = optinMarketing;
  };

  @action
  setDateOfBirth = (dateOfBirth: Date) => {
    this.dateOfBirth = dateOfBirth;
  };

  @action
  setAccessToken = (accessToken: string) => {
    this.accessToken = accessToken;
  };

  @action
  setRefreshToken = (refreshToken: string) => {
    this.refreshToken = refreshToken;
  };

  @action
  setIsLoggedIn = () => {
    this.isLoggedIn = true;
  };

  @action
  sendPhoneNumber = async (phoneNumber: string) => {
    const response = await this.authApi.sendPhoneNumber(
      this.publishableKey,
      phoneNumber,
      this.captchaToken,
    );
    const {
      tokens: { phone },
    } = response;
    this.temporaryPhoneToken = phone;
  };

  @action
  sendVerificationCode = async (code: number) => {
    const response = await this.authApi.sendVerificationCode(
      this.temporaryPhoneToken,
      code,
    );
    this.temporaryPhoneToken = response.token;
  };

  @action
  sendVerifyUser = async (
    withoutRegistration: boolean,
    data: { firstName: string; lastName: string; language: string },
  ) => {
    const response = await this.authApi.sendVerifyUser(
      this.publishableKey,
      this.temporaryPhoneToken,
      withoutRegistration,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        email: this.email,
        language: data.language,
      },
    );
    this.accessToken = response.accessToken;
    this.refreshToken = response.refreshToken;
    this.isGuest = response.userInfo.isGuest;
    this.isLoggedIn = true;
    return response.status;
  };

  @action
  sendVerifyEmail = async (email: string) =>
    await this.authApi.sendVerifyEmail(email, this.publishableKey);
  setMetadata = async ({
    firstName,
    lastName,
    email,
    language,
    optinMarketing,
    dateOfBirth,
  }: {
    firstName?: string;
    lastName?: string;
    email?: string;
    language: string;
    optinMarketing?: string;
    dateOfBirth?: Date;
  }) => {
    await this.authApi.setMetadata(
      {
        firstName: firstName ?? this.userFirstName,
        lastName: lastName ?? this.userLastName,
        email: email ?? this.email,
        optin: 1,
        optin_marketing: optinMarketing ?? this.optinMarketing,
        birth_date: dateOfBirth ? toYearMonthDayFormat(dateOfBirth) : '',
        language,
      },
      this.accessToken,
    );
  };

  @action
  getMetadata = async () => {
    const response = await this.authApi.getMetadata(this.accessToken);
    const birthDate = response.metadata['birth_date'];
    this.setUserFirstName(response.metadata.firstName);
    this.setUserLastName(response.metadata.lastName);
    this.setEmail(response.metadata.email);
    this.setPhone(response.metadata.phone);
    this.setDateOfBirth(birthDate ? new Date(birthDate) : undefined);
    this.isGuest = !!response.is_guest;
    this.userId = response.id;
    this.optinMarketing = response.metadata['optin_marketing'];
  };

  @action
  refreshUserToken = async () => {
    try {
      const response = await this.authApi.refreshUser(
        this.refreshToken,
        this.publishableKey,
      );
      if (response) {
        const { accessToken, refreshToken } = response;
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;

        await this.logInByAccessToken(this.accessToken);
      }
    } catch {
      this.logout();
    }
  };

  @action
  getGuestRefreshToken = async () => {
    const response = await this.authApi.fetchGuestRefreshToken(
      this.publishableKey,
    );
    this.refreshToken = response.refreshToken;
  };

  @action
  getGuestLogin = async () => {
    const response = await this.authApi.fetchGuestLogin(
      this.publishableKey,
      this.refreshToken,
    );
    this.isGuestLoggedIn = true;
    this.isGuest = response.userInfo.isGuest;
    this.accessToken = response.accessToken;
    this.refreshToken = response.refreshToken;
  };

  @action
  logout = () => {
    this.phone = '';
    this.email = '';
    this.userFirstName = '';
    this.userLastName = '';
    this.verificationCode = '';
    this.temporaryPhoneToken = '';
    this.refreshToken = '';
    this.accessToken = '';
    this.isLoggedIn = false;
    this.isGuest = true;
    this.isGuestLoggedIn = false;
    this.clearHistory();
  };

  @action
  getCountryCodeFromIp = (defaultCountryCode: string) => {
    if (!navigator.geolocation) {
      this.countryCodeFromLatLng = defaultCountryCode;
    }

    const success = async (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      this.countryCodeFromLatLng = await this.authApi.getCountryCodeFromIp(
        latitude,
        longitude,
      );
    };

    const error = () => {
      this.countryCodeFromLatLng = defaultCountryCode;
    };

    navigator.geolocation.getCurrentPosition(success, error);
  };

  @action
  fetchHistory = async (restaurantId: number, page = 1) => {
    this.isHistoryFetching = true;

    const response = await this.authApi.fetchHistory(
      this.businessID,
      this.userId,
      page,
      this.accessToken,
      restaurantId,
    );
    this.history =
      this.history !== null ? [...this.history, ...response] : response;

    this.isHistoryFetching = false;
  };

  @action
  clearHistory = () => {
    this.history = null;
  };

  @action
  setSelectedOrderId = (orderId: number) => {
    if (!orderId) {
      this.selectedOrder = null;
    }

    this.selectedOrderId = orderId;
  };

  @action
  fetchSelectedOrder = async () => {
    this.selectedOrder = await this.authApi.fetchOrderById(
      this.selectedOrderId,
      this.accessToken,
    );
  };

  emailReceipt = async () => {
    await this.authApi.emailReceipt(this.selectedOrderId, this.accessToken);
  };

  @action
  logInByAccessToken = async (accessToken: string) => {
    try {
      this.setAccessToken(accessToken);
      await this.getMetadata();
      this.setIsLoggedIn();
    } catch {
      this.logout();
    }
  };

  @action
  logInGuest = async (
    language: string,
    errorHandler: (err: Error, callback?: () => void | Promise<void>) => any,
  ) => {
    try {
      await this.getGuestRefreshToken();
      await this.getGuestLogin();
      await this.setMetadata({ language: language });
    } catch (err) {
      if (err instanceof Error) {
        errorHandler(err);
      }
    }
  };

  @action
  fetchPaymentMethods = async () => {
    const _config_v3 = MyCheckApp.instance.getWalletV3Config();
    const cardProvider = get(_config_v3, 'general.creditcard_provider.name');
    const provider =
      cardProvider === CardProvider.SPS
        ? MerchantsList.SPS_ECOMMERCE
        : MerchantsList.MCPCI;
    this.isPaymentMethodsFetching = true;

    try {
      const response = await this.authApi.fetchPaymentMethods(
        this.accessToken,
        provider,
      );
      this.paymentMethods = response;
    } catch (_err) {
      this.paymentMethods = [];
    }

    this.isPaymentMethodsFetching = false;
  };

  @action
  fetchApiVersions = async (businessId: string) =>
    await this.authApi.fetchApiVersions(this.accessToken, businessId);
}

const _store = new AuthStore();

export { _store as AuthStoreInstance };
