import { useRef } from 'react';

import {
  isPast,
  isSameDay,
  useCheckoutStore,
  useIsLocationOpen,
  useLocationStore,
  useNearestTime,
  useNotAcceptingOrdersHandler,
} from 'mycheck-core';

export const useShowNotAcceptingOrdersPopupDecider = () => {
  const LocationStore = useLocationStore();
  const { getNearestTime } = useNearestTime();
  const isLocationOpen = useIsLocationOpen();
  const NotAcceptingOrdersHandler = useNotAcceptingOrdersHandler();
  const notAcceptedShownRef = useRef(false);
  const CheckoutStore = useCheckoutStore();

  const showNotAcceptingOrders = () => {
    NotAcceptingOrdersHandler.open();
    notAcceptedShownRef.current = true;
  };

  const showNotAcceptingOrdersPopupDecider = () => {
    const nearestTime = getNearestTime();
    const selectedAsap =
      isSameDay(
        LocationStore.selectedDate,
        LocationStore.locationTimezoneName,
      ) &&
      (LocationStore.selectedDate === nearestTime ||
        isPast(LocationStore.selectedDate, nearestTime) ||
        isPast(LocationStore.selectedDate));

    // checkout menu
    if (LocationStore.selectedExperience.settings.checkout.is_active) {
      if (LocationStore.isAsap) {
        if (
          isLocationOpen.isOpen(LocationStore.selectedExperience) &&
          !selectedAsap
        ) {
          showNotAcceptingOrders();
        }

        if (
          !CheckoutStore.checkoutTime ||
          (!isLocationOpen.isOpen(LocationStore.selectedExperience) &&
            (!CheckoutStore.isGrace ||
              (CheckoutStore.isGrace && !CheckoutStore.isOnGraceTime)))
        ) {
          showNotAcceptingOrders();
        }
      } else {
        const selectedExperience =
          LocationStore.selectedRestaurant.experiences.find(
            (experience) =>
              experience.type === LocationStore.selectedExperienceType,
          );

        if (selectedExperience.settings.slots.next_available === null) {
          showNotAcceptingOrders();
        }
      }
    }
  };

  return { showNotAcceptingOrdersPopupDecider };
};
