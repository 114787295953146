import React, { useEffect, useMemo, useState, type CSSProperties } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import {
  useActiveScroll,
  useDisplayDateTime,
  useScrollPosition,
  useTranslation,
} from 'mycheck-core';

import { CustomIcon, OptionSelect } from '@components';

import { TimeSelectionModal } from '../OrderDetails/TimeSelectionModal/TimeSelectionModal';

import styles from './TimeSelectionBox.module.scss';

interface Props {
  handleModalOpen?: (open: boolean) => void;
  className?: string;
  locationHours?: boolean;
  initOpen?: boolean;
  hideClose?: boolean;
}

export const TimeSelectionBox: React.FC<Props> = observer(
  ({
    className,
    locationHours = false,
    handleModalOpen = null,
    initOpen = false,
    hideClose = false,
  }) => {
    const { t } = useTranslation();
    const displayValue = useDisplayDateTime({
      useLocation: locationHours,
      isHotelDateTimeFormat: !locationHours,
      toTimeRange: false,
    });
    const [isTimeModalOpen, setIsTimeModalOpen] = useState(initOpen);
    const activeScroll = useActiveScroll();
    const scrollPosition = useScrollPosition();

    const { hide: hideStyles, position: positionStyles } = useMemo(() => {
      const hide: CSSProperties =
        activeScroll && scrollPosition > 40
          ? {
              position: 'absolute',
              top: -100,
            }
          : null;

      const position: CSSProperties = activeScroll
        ? { position: 'fixed' }
        : null;
      return {
        hide,
        position,
      };
    }, [activeScroll, scrollPosition]);

    useEffect(() => {
      setIsTimeModalOpen(initOpen);
    }, [initOpen]);

    const handleModal = () => {
      setIsTimeModalOpen(!isTimeModalOpen);
      if (handleModalOpen) {
        handleModalOpen(!isTimeModalOpen);
      }
    };

    if (locationHours) {
      return (
        <>
          <OptionSelect
            className={styles.locationHoursRoot}
            onClick={handleModal}
          >
            {displayValue}
          </OptionSelect>
          {isTimeModalOpen && (
            <TimeSelectionModal
              handleModalOpen={handleModal}
              hotelDateTimeFormat={false}
              locationHours
              hideClose={hideClose}
            />
          )}
        </>
      );
    }

    return (
      <div className={classnames(styles.root, className)}>
        <div
          className={styles.content}
          onClick={handleModal}
          style={{ ...positionStyles, ...hideStyles }}
        >
          <div className={styles.contentBox}>
            <CustomIcon
              name="time"
              customStylePath="general.arrowDown"
              className={styles.contentBoxIconCustom}
            />
            <span className={styles.contentBoxTime}>{displayValue}</span>
            <span className={styles.contentBoxChange}>
              {t('timeSelection.change')}
            </span>
          </div>
        </div>
        {isTimeModalOpen && (
          <TimeSelectionModal
            handleModalOpen={handleModal}
            hotelDateTimeFormat
          />
        )}
      </div>
    );
  },
);

TimeSelectionBox.displayName = 'TimeSelectionBox';
