import { LanguageOption } from '../../types/LanguageTypes';
import React from 'react';

import { LangListElement } from './LangListElement';

interface Props {
  languages: LanguageOption[];
  selectedLanguage: LanguageOption;
  handleSelectedLanguage: (lang: LanguageOption) => void;
}

export const LangList: React.FC<Props> = ({
  languages,
  handleSelectedLanguage,
  selectedLanguage,
}) => {
  return (
    <>
      {languages.map((lang) => (
        <LangListElement
          key={lang.value}
          lang={lang}
          selected={selectedLanguage}
          handleSelectedLanguage={handleSelectedLanguage}
        />
      ))}
    </>
  );
};
