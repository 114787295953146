import React from 'react';

import classnames from 'classnames';
import { Field } from 'formik';
import range from 'lodash/range';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { getMonth, getMonths, getYear } from 'mycheck-core';

import { CustomIcon, CustomInput, SelectBox } from '@components';

import styles from './DatePicker.module.scss';

const control = (baseStyles) => ({
  ...baseStyles,
  background: 'unset',
  border: 'none',
  boxShadow: 'none',
  minHeight: 'unset',
  height: 'auto',
  fontSize: '12px',
  fontWeight: 'bold',
  cursor: 'pointer',
});

const dropdownIndicatorStyles = { margin: '0 8px' };

interface ArrowComponentProps {
  onClick: () => void;
  direction: 'arrowLeft' | 'arrowRight';
  disabled: boolean;
}

const ArrowComponent: React.FC<ArrowComponentProps> = ({
  onClick,
  direction,
  disabled,
}) => (
  <div
    onClick={onClick}
    className={classnames(styles.arrowComponentContainer, {
      [styles.arrowComponentContainerDisabled]: disabled,
    })}
  >
    <CustomIcon
      name={direction}
      style={{
        ...{ color: '#777777', width: '6px' },
        ...(direction === 'arrowLeft'
          ? { marginRight: '5px' }
          : { marginLeft: '5px' }),
      }}
    />
  </div>
);

interface Props {
  fieldName: string;
  placeholder: string;
  maxDate: Date;
  minDate: Date;
  maxYear: number;
  minYear: number;
}

export const DatePickerComponent: React.FC<Props> = ({
  fieldName,
  placeholder,
  maxDate,
  minDate,
  maxYear,
  minYear,
}) => {
  const years = range(minYear, maxYear, 1);
  const months = getMonths();

  return (
    <Field name={fieldName}>
      {({ field, _meta, form: { setFieldValue, errors } }) => {
        return (
          <DatePicker
            {...field}
            selected={field.value}
            dateFormat="dd/MM/yyyy"
            onChange={(date: string) => setFieldValue(field.name, date)}
            placeholderText={placeholder}
            maxDate={maxDate}
            minDate={minDate}
            customInput={
              <CustomInput
                value={''}
                type={'text'}
                name={field.name}
                error={!!errors[field.name]}
                errorMessage={errors[field.name]}
                customStyle={{ marginTop: 32 }}
                datePickerInput
              />
            }
            renderCustomHeader={({
              date,
              changeMonth,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={styles.customHeaderContainer}>
                <ArrowComponent
                  onClick={decreaseMonth}
                  direction={'arrowLeft'}
                  disabled={prevMonthButtonDisabled}
                />
                <SelectBox
                  value={months[getMonth(date)]}
                  data={months.map((month) => ({ label: month, value: month }))}
                  onChange={(value) =>
                    changeMonth(months.indexOf(value as string))
                  }
                  customDropdownIndicatorStyles={dropdownIndicatorStyles}
                  customSelectStyles={{ control }}
                  withoutFormikProps
                />
                <SelectBox
                  value={getYear(date)}
                  data={years.map((option) => ({
                    label: String(option),
                    value: option,
                  }))}
                  onChange={(value) => changeYear(value)}
                  customDropdownIndicatorStyles={dropdownIndicatorStyles}
                  customSelectStyles={{ control }}
                  withoutFormikProps
                />
                <ArrowComponent
                  onClick={increaseMonth}
                  direction={'arrowRight'}
                  disabled={nextMonthButtonDisabled}
                />
              </div>
            )}
          />
        );
      }}
    </Field>
  );
};
