import React, { useEffect, useState, type ChangeEvent } from 'react';

import { useTranslation } from 'mycheck-core';

import { PopUp, CustomInput } from '@components';

import { TipItem } from '../TipItem/TipItem';

import styles from './CustomTip.module.scss';

type CustomTipType = {
  id: string;
  onClick: (id: string) => void;
  currentValue: string | number;
  formikProps: any;
};

export const CustomTip: React.FC<CustomTipType> = ({
  id,
  onClick,
  currentValue,
  formikProps,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentTip, setCurrentTip] = useState(formikProps.values.customTip);
  const onChange = (evt: ChangeEvent<HTMLInputElement>) =>
    setCurrentTip(evt.currentTarget.value);
  const onTrigger = () => setIsOpen(!isOpen);
  const onSubmit = () => {
    const value = parseFloat(currentTip) || 0;
    formikProps.setFieldValue('customTip', value);
    onClick(id);
    setIsOpen(false);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setCurrentTip(formikProps.values.customTip);
  }, [formikProps.values.customTip]);

  const tipValue = Number(currentTip) ? currentTip : '';

  return (
    <>
      <TipItem
        id={id}
        testId="custom-tip"
        label={t('checkout.customTipLabel')}
        currentValue={currentValue}
        onClick={onTrigger}
      />
      {isOpen && (
        <PopUp
          onClose={handleCloseModal}
          onClick={onSubmit}
          buttonText={t('checkout.customTipButton')}
          testId="custom-tip"
          triggerToRerender={currentTip}
        >
          <div className={styles.modal}>
            <div className={styles.modalTitle}>
              {t('checkout.customTipTitle')}
            </div>
            <div className={styles.modalContainer}>
              <CustomInput
                onChange={onChange}
                value={tipValue}
                type={'decimal'}
                name={'customTip'}
                placeholder={t('checkout.customTipPlaceholder')}
                testId="custom-tip"
              />
            </div>
          </div>
        </PopUp>
      )}
    </>
  );
};
