import React, { useEffect, useState, type CSSProperties } from 'react';

import {
  useAuthStore,
  useErrorTrackerHandler,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { PopUp } from '@components';

import styles from './VersionsModal.module.scss';
import { ApiVersions } from 'plugins/AuthPlugin/types/AuthTypes';

interface Props {
  bodyStyle: CSSProperties;
  toggleVersionModal: () => void;
}

export const VersionsModal: React.FC<Props> = ({
  bodyStyle,
  toggleVersionModal,
}) => {
  const AuthStore = useAuthStore();
  const { currentLocationID } = useLocationStore();
  const { t } = useTranslation();
  const errorHandler = useErrorTrackerHandler();
  const [versions, setVersions] = useState<ApiVersions | null>(null);

  useEffect(() => {
    const fetchApiVersions = async () => {
      try {
        const apiVersions = await AuthStore.fetchApiVersions(
          String(currentLocationID),
        );
        setVersions(apiVersions);
      } catch (err) {
        if (err instanceof Error) {
          errorHandler.onError(err);
        }
      }
    };

    fetchApiVersions();
  }, []);

  return versions ? (
    <PopUp testId="version-page" onClose={toggleVersionModal}>
      <div style={bodyStyle}>
        {Object.entries(versions).map(([key]) => (
          <div className={styles.versionRow} key={key}>
            <p data-test-id={`version-${key}-name`}>{t(`versions.${key}`)}</p>
            <p data-test-id={`version-${key}-number`}>{versions[key]}</p>
          </div>
        ))}
      </div>
    </PopUp>
  ) : null;
};
