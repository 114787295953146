import React, { useEffect, useMemo, useRef, type CSSProperties } from 'react';

import { FormikProps, useFormikContext } from 'formik';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import styled from 'styled-components';

import {
  capitalizeFirstLetter,
  escapeRegExp,
  MyCheckApp,
  useLanguageLoader,
  useTranslation,
} from 'mycheck-core';

import { CustomIcon } from '../Icon/CustomIcon';
import { Icon } from '../Icon/Icon';

import styles from './SelectBox.module.scss';

interface IOption {
  label: string;
  value: string | number;
  data?: {
    description: string;
  };
}

interface Props {
  onClick?: () => void;
  onInputCallback?: (value: string) => void;
  data: Array<IOption>;
  placeholder?: string;
  disabled?: boolean;
  formikProps?: FormikProps<any>;
  value?: string | number;
  limit?: number;
  property?: string;
  isSearchable?: boolean;
  error?: boolean;
  keepCursorAtEnd?: boolean;
  onHomePage?: boolean;
  customStyle?: CSSProperties;
  withLabel?: boolean;
  showOptional?: boolean;
  onChange?: (value: string | number) => void;
  testId?: string;
  withoutFormikProps?: boolean;
  customSelectStyles?: {
    control: (baseStyles: CSSProperties) => CSSProperties;
  };
  customDropdownIndicatorStyles?: CSSProperties;
}

const Option = (props) => {
  const { data } = props;
  const location = useLocation();

  const menuConfig = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const description = get(_config, 'general.select.description', {});

    return {
      description,
    };
  }, [location.search]);

  return (
    <components.Option {...props}>
      <div className={styles.name}>{data.label}</div>
      <div className={styles.description} style={menuConfig.description}>
        {data.description}
      </div>
    </components.Option>
  );
};

const OptionLoader = (props) => {
  return (
    <components.Option {...props}>
      <CustomIcon name="loader" className={styles.optionLoader} />
    </components.Option>
  );
};

const SelectBoxInput: React.FC<Props> = ({
  onClick,
  data = [],
  placeholder,
  formikProps,
  withoutFormikProps,
  value,
  customSelectStyles,
  customDropdownIndicatorStyles,
  property,
  error,
  isSearchable = false,
  limit = 6,
  onInputCallback,
  keepCursorAtEnd,
  onHomePage,
  disabled,
  testId = '',
  onChange,
}) => {
  const location = useLocation();
  const { errors, isSubmitting } = useFormikContext();
  const { isRtl } = useLanguageLoader();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const inputStyle = get(_config, 'general.select.value', {});
    const inputBorderStyle = get(_config, 'general.input.border');
    const inputErrorStyle = get(_config, 'general.inputError', {});
    const disabledInputStyle = get(_config, 'general.disableInput', {});
    const primaryColor = get(_config, 'palette.primary', '');
    const dividerStyle = get(_config, 'general.divider', '');
    const titleStyle = get(_config, 'general.select.title', {});

    const homePageControlStyle = get(_config, 'home.style.searchField', {});
    const homePagePlaceholderStyle = get(_config, 'home.style.placeHolder', {});
    const homePageSearchTextStyle = get(_config, 'home.style.searchText', {});

    const timeSelectionResult = get(
      _config,
      'restaurants.timeSelection.result',
      {},
    );

    const customStyles = {
      container: () => ({
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
      }),
      control: () => ({
        alignItems: 'center',
        border: inputBorderStyle,
        display: 'flex',
        ...(onHomePage ? homePageControlStyle : {}),
        ...(error ? inputErrorStyle : {}),
        ...(disabled ? disabledInputStyle : {}),
      }),
      dropdownIndicator: () => ({
        color: primaryColor,
      }),
      option: () => ({
        borderBottom: dividerStyle,
        ...((property === 'date' || property === 'time') && isRtl()
          ? {
              direction: 'ltr',
              display: 'flex',
              justifyContent: 'flex-end',
            }
          : {}),
        ...(property === 'date' || property === 'time'
          ? timeSelectionResult
          : titleStyle),
        ...(property === 'paymentMethod' ? { textTransform: 'none' } : {}),
      }),
      singleValue: () => ({
        ...(onHomePage ? inputStyle : {}),
        position: 'absolute',
        ...(property === 'date' || property === 'time'
          ? {
              direction: 'ltr',
              display: 'flex',
              justifyContent: 'flex-end',
            }
          : {}),
        ...(onHomePage ? homePageSearchTextStyle : {}),
      }),
      placeholder: () => ({
        ...(onHomePage ? homePagePlaceholderStyle : {}),
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: null,
        boxShadow: `0 0 20px 6px rgba(0, 0, 0, 0.05)`,
      }),
      menuList: (provided) => ({
        ...provided,
        overflowY: 'auto',
        border: 'none',
        maxHeight: 42 * limit + 10, // Minimal possible height for ${limit} options without scroll
      }),
    };
    return {
      customStyles,
      homePageControlStyle,
      homePageSearchTextStyle,
      homePagePlaceholderStyle,
    };
  }, [location.search, errors]);

  const selectedValue = data.find((item) => item.value === value) || null;
  const inputRef = useRef<Select>();

  const handleChange = (selectedOption: IOption) => {
    if (formikProps) {
      onChange && onChange(selectedOption?.value || '');
      formikProps.setFieldValue(property, selectedOption?.value || '');
    }

    if (withoutFormikProps) {
      onChange && onChange(selectedOption?.value || '');
    }
  };

  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      {isSearchable ? (
        <Icon name="search" className={styles.icon} />
      ) : (
        <CustomIcon
          name="arrowDown"
          customStylePath="general.arrowDown"
          className={`${styles.icon}`}
          style={customDropdownIndicatorStyles}
        />
      )}
    </components.DropdownIndicator>
  );

  const Placeholder = (props) =>
    !props.isFocused && (
      <components.Placeholder {...props}>
        <div className={styles.selectBoxPlaceholder}>{placeholder}</div>
      </components.Placeholder>
    );

  const MenuList = (props) => (
    <components.MenuList {...props}>
      {Array.isArray(props.children) && props.children}
    </components.MenuList>
  );

  const onInputChange = (e) => {
    if (e) {
      formikProps.setFieldValue(property, '');
    }

    if (onInputCallback) {
      onInputCallback(e);
    }
  };

  useEffect(() => {
    if (!!Object.keys(errors).length) {
      const errKeys = Object.keys(errors);
      if (errKeys[0] === property && inputRef.current && isSubmitting) {
        inputRef.current.select.inputRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        inputRef.current.onMenuOpen();
      }
    }
  }, [errors, isSubmitting]);

  const filterOption = (option: IOption, inputValue: string): boolean =>
    (
      option.label
        ?.toString()
        .toLowerCase()
        .match(escapeRegExp(inputValue)?.toLowerCase()) ||
      option.data?.description
        ?.toLowerCase()
        .match(escapeRegExp(inputValue)?.toLowerCase()) ||
      []
    ).length > 0;

  type MobileInputProps = {
    backgroundColor: string;
    border: string;
    height: number;
    color: string;
    fontFamily: string;
    fontSize: string;
    placeholderColor: string;
    placeholderFontSize: number;
    placeholderFontFamily: string;
  };

  const MobileInput = styled.input<MobileInputProps>`
    background-color: ${(props) => props.backgroundColor} !important;
    border: ${(props) => props.border} !important;
    height: ${(props) => props.height} !important;
    color: ${(props) => props.color} !important;
    font-family: ${(props) => props.fontFamily} !important;
    font-size: ${(props) => props.fontSize} !important;

    ::placeholder {
      color: ${(props) => props.placeholderColor} !important;
      font-size: ${(props) => props.placeholderFontSize} !important;
      font-family: ${(props) => props.placeholderFontFamily} !important;
    }
  `;

  if (onClick) {
    return (
      <div onClick={onClick} className={styles.row}>
        {onHomePage ? (
          <MobileInput
            value={selectedValue?.label}
            placeholder={placeholder as string}
            className={styles.inputOnclick}
            backgroundColor={config.homePageControlStyle.backgroundColor}
            border={config.homePageControlStyle.border}
            height={config.homePageControlStyle.height}
            color={config.homePageSearchTextStyle.color}
            fontFamily={config.homePageSearchTextStyle.fontFamily}
            fontSize={config.homePageSearchTextStyle.fontSize}
            placeholderColor={config.homePagePlaceholderStyle.color}
            placeholderFontSize={config.homePagePlaceholderStyle.fontSize}
            placeholderFontFamily={config.homePagePlaceholderStyle.fontFamily}
            data-test-id={testId}
          />
        ) : (
          <input
            className={styles.inputOnclick}
            value={selectedValue?.label}
            placeholder={placeholder as string}
            data-test-id={testId}
          />
        )}
        <Icon name="search" className={styles.searchIcon} />
      </div>
    );
  }

  return (
    <Select
      ref={inputRef}
      inputId={testId}
      data-test-id={testId}
      classNamePrefix="select-box"
      value={selectedValue}
      onChange={handleChange}
      options={
        !!data.length
          ? data
          : [{ label: 'loader', value: 'loader', isDisabled: true }]
      }
      components={{
        Placeholder,
        DropdownIndicator,
        Option: !!data.length ? Option : OptionLoader,
        MenuList,
        IndicatorSeparator: null,
        ClearIndicator: null,
      }}
      styles={{ ...config.customStyles, ...customSelectStyles }}
      placeholder={placeholder}
      isSearchable={isSearchable}
      filterOption={filterOption}
      onInputChange={onInputChange}
      isClearable
      keepCursorAtEnd={keepCursorAtEnd}
      isDisabled={disabled}
    />
  );
};

export const SelectBox: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.selectBoxContainer} style={props.customStyle}>
      {props.withLabel && (
        <label className={`${styles.formLabel}`}>
          {capitalizeFirstLetter(props.placeholder)}
        </label>
      )}
      {props.showOptional && (
        <label className={styles.optionalLabel}>
          {t('checkout.optionalLabel')}
        </label>
      )}
      <SelectBoxInput {...props} />
    </div>
  );
};
