import React, { PropsWithChildren } from 'react';

import styles from './MobilePickerScroll.module.scss';
import { PickerColumn } from './PickerColumn';

interface MobilePickerScrollProps {
  optionGroups: any;
  valueGroups: any;
  onChange: (name: string, value: any) => void;
  itemHeight?: number;
  height?: number;
  naturalScroll?: boolean;
  textAlign?: 'center' | 'right' | 'left';
}

const handleMouseOver = () => {
  document.body.style.overflow = 'hidden';
};

const handleMouseOut = () => {
  document.body.style.overflow = 'visible';
};

export const MobilePickerScroll = ({
  optionGroups,
  valueGroups,
  onChange,
  itemHeight = 36,
  height = 216,
  naturalScroll = true,
  textAlign,
}: PropsWithChildren<MobilePickerScrollProps>) => {
  const highlightStyle = {
    height: itemHeight,
    marginTop: -(itemHeight / 2),
  };

  return (
    <div
      className={styles.pickerContainer}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{ height }}
    >
      <div className={styles.pickerInner}>
        {Object.keys(optionGroups).map((name, index) => (
          <PickerColumn
            tabIndex={1000 + index}
            key={name}
            name={name}
            options={optionGroups[name]}
            value={valueGroups[name]}
            itemHeight={itemHeight}
            naturalScroll={naturalScroll}
            columnHeight={height}
            onChange={onChange}
            textAlign={textAlign ? textAlign : null}
          />
        ))}
        <div className={styles.pickerHighlight} style={highlightStyle}></div>
      </div>
    </div>
  );
};
