import React, { PropsWithChildren } from 'react';

import styles from './DataGrid.module.scss';
import DataGridColumn, { DataGridColumnProps } from './DataGridColumn';
import DataGridContainer, { DataGridContainerProps } from './DataGridContainer';
import DataGridHeader, { DataGridHeaderProps } from './DataGridHeader';
import DataGridLoader, { DataGridLoaderProps } from './DataGridLoader';
import DataGridRow, { DataGridRowProps } from './DataGridRow';

interface DataGridProps {
  Column: React.FC<DataGridColumnProps>;
  Container: React.FC<DataGridContainerProps>;
  Header: React.FC<DataGridHeaderProps>;
  Loader: React.FC<DataGridLoaderProps>;
  Row: React.FC<DataGridRowProps>;
}

const DataGrid: React.FC<PropsWithChildren> & DataGridProps = ({
  children,
}) => {
  return <div className={styles.dataGrid}>{children}</div>;
};

DataGrid.Column = DataGridColumn;
DataGrid.Container = DataGridContainer;
DataGrid.Header = DataGridHeader;
DataGrid.Loader = DataGridLoader;
DataGrid.Row = DataGridRow;

export default DataGrid;
