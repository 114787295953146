import React, { useEffect } from 'react';

import { CustomIcon } from '../Icon/CustomIcon';

import styles from './ClosePage.module.scss';

type ClosePageType = {
  onClose: () => void;
  customStylePath?: string;
  testId?: string;
};

export const ClosePage: React.FC<ClosePageType> = ({
  onClose,
  customStylePath = 'general.x',
  testId = '',
}) => {
  useEffect(() => {
    const onKeyUp = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keyup', onKeyUp);
    return () => window.removeEventListener('keyup', onKeyUp);
  }, []);

  return (
    <div
      className={styles.icon}
      onClick={onClose}
      data-test-id={`${testId}-close-page`}
    >
      <CustomIcon name="x" customStylePath={customStylePath} />
    </div>
  );
};
