import React, {
  useMemo,
  type CSSProperties,
  type PropsWithChildren,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import styles from './PrimaryButton.module.scss';

interface PrimaryButtonProps extends PropsWithChildren {
  testId?: string;
  onClick?: () => void;
  secondary?: boolean;
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  onClick,
  secondary,
  className,
  disabled,
  style = {},
  testId = '',
}) => {
  const location = useLocation();
  const configStyles = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const backgroundColor = get(_config, 'palette.primary', '');
    const backgroundColorSecondary = get(_config, 'palette.secondary', '');
    const primaryButtonStyle = get(_config, 'general.primaryButton', {});
    const configSecondary = get(_config, 'general.secondaryButton', {});
    const disabledStyle = get(_config, 'general.disableButton', {});

    return {
      primary: { backgroundColor, ...primaryButtonStyle },
      secondary: {
        backgroundColor: backgroundColorSecondary,
        ...configSecondary,
      },
      disabled: { ...primaryButtonStyle, ...disabledStyle },
    };
  }, [location.search]);

  if (onClick) {
    return (
      <button
        onClick={onClick}
        className={classnames(styles.button, className && className)}
        style={{
          ...(secondary ? configStyles.secondary : configStyles.primary),
          ...style,
        }}
        disabled={disabled}
        data-test-id={`button-${testId}`}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      type="submit"
      className={classnames(styles.button, className && className)}
      style={{
        ...(disabled
          ? configStyles.disabled
          : secondary
            ? configStyles.secondary
            : configStyles.primary),
        ...style,
      }}
      disabled={disabled}
      data-test-id={`button-${testId}`}
    >
      {children}
    </button>
  );
};
