import React from 'react';

import {
  displayDateTime,
  displayTimeFromHourMinute,
  getDateDays,
  isBefore,
  isBetween,
  useLocationStore,
  useTranslation,
} from 'mycheck-core';

import { TranslateText } from '@components';
import { ILocationItem } from 'plugins/LocationPlugin/types/LocationTypes';

type Props = {
  restaurant: ILocationItem;
};

export const LocationContentOpenHours: React.FC<Props> = ({ restaurant }) => {
  const LocationStore = useLocationStore();
  const { t } = useTranslation();

  const experience = restaurant.experiences.find(
    (r) => r.type === LocationStore.selectedExperienceType,
  );
  const openHours = experience.settings.open_hours;
  const openDays =
    typeof LocationStore.locationTimezoneName !== 'undefined'
      ? getDateDays(Object.keys(openHours), LocationStore.locationTimezoneName)
      : null;

  if (!openDays || !openDays.days.length) {
    return t('location.closedAllTime');
  }

  let currentString: JSX.Element = null;

  openHours[openDays.today.format('ddd').toLowerCase()]?.some((element) => {
    if (
      isBetween(element.from, element.to, LocationStore.locationTimezoneName)
    ) {
      currentString = t('location.openAllTime');
    }

    if (
      !currentString &&
      isBefore(element.from, LocationStore.locationTimezoneName)
    ) {
      currentString = (
        <TranslateText
          tKey="location.currentlyClosed"
          values={{
            at: displayTimeFromHourMinute(
              element.from,
              LocationStore.localHotelTimeFormat,
            ),
          }}
        />
      );
      return true;
    }
  });

  if (currentString) {
    return <>{currentString}</>;
  }

  openDays.days.some((day) => {
    const currentDay = day.format('ddd').toLowerCase();
    openHours[currentDay]?.some((element) => {
      const [hours, minutes] = element.from.split(':');
      const dateFrom = day
        .clone()
        .set('hours', hours)
        .set('minutes', minutes)
        .startOf('minute')
        .format();

      currentString = (
        <TranslateText
          tKey="location.currentlyClosed"
          values={{
            at: displayDateTime(
              dateFrom,
              LocationStore.locationTimezoneName,
              LocationStore.localHotelDateFormat,
              LocationStore.localHotelTimeFormat,
            ),
          }}
        />
      );

      return true;
    });

    return <>{currentString}</>;
  });

  return <>{currentString}</>;
};
