import React, { type MouseEvent } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import {
  useLocationStore,
  useCheckoutStore,
  useMenuStore,
  useNavigation,
  GaService,
  useTranslation,
  useLanguageStore,
  isPast,
  useNearestTime,
  isWithinSixtyMinutes,
  getDateDays,
  isBetween,
  useWindowSize,
  injector,
  attachFonts,
  MyCheckApp,
  attachFavicon,
} from 'mycheck-core';

import { HTTP_AUTH_API, HttpAuthApi } from '../../../AuthPlugin/AuthApi';

import styles from './LocationContentExperience.module.scss';
import { LocationContentExperienceTime } from './LocationContentExperienceTime';
import { LocationContentOpenHours } from './LocationContentOpenHours';
import { ILocationItem } from 'plugins/LocationPlugin/types/LocationTypes';

type Props = {
  restaurant: ILocationItem;
};

export const LocationContentExperience: React.FC<Props> = observer(
  ({ restaurant }) => {
    const LocationStore = useLocationStore();
    const MenuStore = useMenuStore();
    const CheckoutStore = useCheckoutStore();
    const LanguageStore = useLanguageStore();
    const { nearestTime } = useNearestTime();

    const { t } = useTranslation();
    const { isLg } = useWindowSize();
    const navigation = useNavigation();

    const experience = restaurant.experiences?.find(
      (ex) => ex.type === LocationStore.selectedExperienceType,
    );
    const isMoreInfoAvailable = true; //get(config, 'settings.moreInfo', false)
    const isViewMenuOnly = !experience.settings.checkout?.is_active ?? true;
    const isAsapOnly = experience.settings.is_asap_only;
    const nextAvailable = experience.settings.slots.next_available;
    const noNextAvailable = nextAvailable === null;
    const isWithinOneHourSelectedDate = isWithinSixtyMinutes(
      nextAvailable,
      LocationStore.selectedDate,
    );
    const openHours = experience.settings.open_hours;
    const openDays =
      typeof LocationStore.locationTimezoneName !== 'undefined'
        ? getDateDays(
            Object.keys(openHours),
            LocationStore.locationTimezoneName,
          )
        : null;
    let isOpen = false;
    openHours[openDays?.today.format('ddd').toLowerCase()]?.some((element) => {
      if (
        isBetween(element.from, element.to, LocationStore.locationTimezoneName)
      ) {
        isOpen = true;
      }
    });
    const onClick = async () => {
      MenuStore.clearMenu();
      if (LocationStore.selectedExperience) {
        if (
          LocationStore.selectedExperience.id !== experience.id ||
          LocationStore.selectedRestaurantId !== restaurant.id
        ) {
          CheckoutStore.clearCheckoutWithTime();
        }
      }

      LocationStore.setSelectedRestaurantId(restaurant.id);
      LocationStore.setSelectedExperienceId(experience.id);
      CheckoutStore.setSelectedCheckoutTime(
        experience.settings.slots.next_available,
      );
      MenuStore.setViewOnlyShowed(false);
      GaService.instance.sendEvent({
        category: 'Ordering - Locations',
        action: 'Location',
        label: restaurant.name,
      });
      GaService.instance.sendEvent({
        category: 'Ordering - Locations',
        action: 'Time',
        label: experience.settings.slots.next_available,
      });
      GaService.instance.sendEvent({
        category: 'Ordering - Locations',
        action: 'Experience',
        label: t(`experience.${LocationStore.selectedExperienceFromList.type}`),
      });

      const authApi = injector.get<HttpAuthApi>(HTTP_AUTH_API);
      const uiConfig = await authApi.fetchUIConfigByBusinessId(
        restaurant.id.toString(),
      );

      const parsedConfig = JSON.parse(uiConfig.data.config as string);
      parsedConfig.settings.name = uiConfig.data.name;
      parsedConfig.settings.cookiesPolicyUrl = uiConfig.data?.policy_url;
      attachFonts(parsedConfig.settings.fonts);
      MyCheckApp.instance.setRestaurantConfig(parsedConfig);

      if (parsedConfig.settings.favicon) {
        attachFavicon(parsedConfig.settings.favicon);
      }
      navigation.push('/menu');
    };

    const handleMoreInfoModal =
      (id: number) => (event?: MouseEvent<HTMLDivElement>) => {
        if (event) {
          event.stopPropagation();
        }

        if (!isLg) {
          navigation.push(`/location/${id}/info`);
        }
        LocationStore.setMoreInfo(id);
      };

    const checkIsNotAvailable = () => {
      if (isViewMenuOnly) {
        return false;
      }

      if (isAsapOnly) {
        if (
          LocationStore.selectedDate === nearestTime ||
          isPast(LocationStore.selectedDate)
        ) {
          if (isOpen && isWithinOneHourSelectedDate) {
            return false;
          }
        }

        return true;
      }

      if (!openHours || (Array.isArray(openHours) && !openHours.length)) {
        return true;
      }

      return noNextAvailable;
    };

    const isNotAvailable = checkIsNotAvailable();

    const imageClassnames = classnames(styles.image, {
      [styles.imageGray]: isNotAvailable,
    });

    const restaurantName = LanguageStore.getBusinessTranslation(
      restaurant.id,
      'name',
      restaurant.name,
    );

    return (
      <div
        data-test-id="location-content-experience"
        className={styles.restaurant}
        onClick={onClick}
      >
        {!!restaurant.image && (
          <div className={styles.imageContainer}>
            <img
              className={imageClassnames}
              src={restaurant.image}
              alt={restaurantName}
            />
          </div>
        )}

        <LocationContentExperienceTime restaurant={restaurant} />

        <div className={styles.container}>
          <div
            className={styles.info}
            style={!restaurant.image ? { paddingTop: 35 } : {}}
          >
            <div className={styles.infoContainer}>
              <div
                className={classnames(styles.title, {
                  [styles.titleNotAvailable]: isNotAvailable,
                })}
              >
                {restaurantName}
              </div>
              <div className={styles.description}>
                {LanguageStore.getProductTranslation(
                  restaurant.id,
                  LocationStore.productId,
                  'data.short_description',
                  restaurant.short_description,
                )}
              </div>
            </div>
            <div className={styles.bottom}>
              <div>
                <LocationContentOpenHours restaurant={restaurant} />
              </div>
              {isMoreInfoAvailable && (
                <div
                  className={styles.moreInfo}
                  onClick={handleMoreInfoModal(restaurant.id)}
                >
                  {t('location.moreInfo')}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

LocationContentExperience.displayName = 'LocationContentExperience';
