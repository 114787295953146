import { ApiCacheConstants, ApiClient } from 'mycheck-core';
import {
  HttpAuthApiParams,
  HttpAuthApiRequest,
  HttpAuthApiResponse,
  IHttpAuthApi,
} from './types/AuthTypes';

const AuthUrls = {
  configUrl: (publishableKey: string) =>
    `/users/api/v2/business/configurations?public_only=1&keys=pickupv2&publishableKey=${publishableKey}`,
  configUrl_v3: (publishableKey: string) =>
    `/users/api/v2/business/configurations?public_only=1&keys=wallet_v3,dine_captcha_config,order_expiration_minutes&publishableKey=${publishableKey}`,
  uiConfigUrlById: (id: string) =>
    `/restaurants/api/v3/ui-configurations?id=${id}`,
  uiConfigUrlByBusinessId: (businessId: string) =>
    `/restaurants/api/v3/ui-configurations?business_id=${businessId}`,
  countryCodeFromIpUrl: (lat: number, lng: number) =>
    `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${process.env.REACT_APP_OPENCAGEDATA_API_KEY}`,
  phoneNumberUrl: '/users/api/v1/user/metadata/guest',
  verificationCodeUrl: '/users/api/v1/user/metadata/verify/guest',
  verifiedTokenUrl: '/users/api/v1/login/verified-token',
  verifyEmailUrl: `/users/api/v1/state/email`,
  refreshUserUrl: (refreshToken: string, publishableKey: string) =>
    `/users/api/v1/login?refreshToken=${refreshToken}&publishableKey=${publishableKey}`,
  setMetadata: '/users/api/v1/user/metadata',
  getMetadata: '/users/api/v1/user',
  guestRefreshTokenUrl: '/users/api/v1/refresh_token',
  guestLoginUrl: '/users/api/v1/login',
  fetchHistoryUrl: `/restaurants/api/v3/orders`,
  fetchOrderByIdUrl: (orderId: number) =>
    `/restaurants/api/v3/order/${orderId}/details`,
  emailReceipt: (orderId: number) =>
    `/restaurants/api/v3/order/${orderId}/send-invoice`,
  getPaymentMethods: (provider: string) =>
    `/payment-methods/api/v1/payment-methods?isWithGiftCards=1&isDeleted=false&isExpired=false&source=${provider}`,
  getVersions: (businessId: string) =>
    `restaurants/api/v3/business/${businessId}/versions`,
};

export const HTTP_AUTH_API = 'HTTP_AUTH_API';

export class HttpAuthApi implements IHttpAuthApi {
  fetchConfig = async (publishableKey: string) => {
    const { data } = await ApiClient.instance.get<
      Record<string, { id: string }>
    >(AuthUrls.configUrl(publishableKey));
    return data;
  };

  fetchConfig_v3 = async (publishableKey: string) => {
    const { data } = await ApiClient.instance.get<
      HttpAuthApiResponse['configV3']
    >(AuthUrls.configUrl_v3(publishableKey));
    return data;
  };

  fetchUIConfigById = async (id: string) => {
    const { data } = await ApiClient.instance.get(AuthUrls.uiConfigUrlById(id));
    return data;
  };

  fetchUIConfigByBusinessId = async (businessId: string) => {
    const { data } = await ApiClient.instance.get<
      Record<string, { config: unknown; name: unknown; policy_url?: unknown }>
    >(AuthUrls.uiConfigUrlByBusinessId(businessId));
    return data;
  };

  sendPhoneNumber = async (
    publishableKey: string,
    phone: string,
    captchaToken: string | null,
  ) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iSendPhoneNumber'],
      HttpAuthApiResponse['iSendPhoneNumber']
    >(AuthUrls.phoneNumberUrl, {
      phone,
      publishableKey,
      ...(captchaToken && { captchaToken, actionCaptcha: 'login' }),
    });
    return data;
  };

  sendVerificationCode = async (token: string, code: number) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iSendVerificationCode'],
      HttpAuthApiResponse['iSendVerificationCode']
    >(AuthUrls.verificationCodeUrl, {
      code,
      token,
    });
    return data;
  };

  sendVerifyUser = async (
    publishableKey: string,
    temporaryToken: string,
    withoutRegistration: boolean,
    metadata: HttpAuthApiParams['metadataParams'],
  ) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iSendVerifyUser'],
      HttpAuthApiResponse['iSendVerifyUser']
    >(AuthUrls.verifiedTokenUrl, {
      publishableKey,
      verified_token: temporaryToken,
      without_registration: withoutRegistration,
      metadata: {
        ...metadata,
        ...(typeof metadata.optin === undefined
          ? {}
          : { optin: metadata.optin }),
      },
    });
    return data;
  };

  sendVerifyEmail = async (email: string, publishableKey: string) => {
    const { data } = await ApiClient.instance.get<
      HttpAuthApiResponse['iSendVerifyEmail']
    >(AuthUrls.verifyEmailUrl, null, {
      params: { email, publishableKey },
    });
    return data;
  };

  getMetadata = async (accessToken: string) => {
    const { data } = await ApiClient.instance.get<
      HttpAuthApiResponse['iGetMetadata']
    >(AuthUrls.getMetadata, null, { headers: { Authorization: accessToken } });
    return data;
  };

  setMetadata = async (
    params: HttpAuthApiParams['metadataParams'],
    accessToken: string,
  ) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iSetMetadata'],
      HttpAuthApiResponse['iSetMetadata']
    >(
      AuthUrls.setMetadata,
      {
        metadata: JSON.stringify({
          ...params,
          ...(typeof params.optin === undefined ? {} : { optin: params.optin }),
        }),
      },
      { headers: { Authorization: accessToken } },
    );
    return data;
  };

  refreshUser = async (refreshToken: string, publishableKey: string) => {
    const response = await ApiClient.instance.post<
      HttpAuthApiRequest['iRefreshUser'],
      HttpAuthApiResponse['iRefreshUser']
    >(AuthUrls.refreshUserUrl(refreshToken, publishableKey));
    return response?.data || null;
  };

  fetchGuestRefreshToken = async (publishableKey: string) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iGuestRefreshToken'],
      HttpAuthApiResponse['iGuestRefreshToken']
    >(AuthUrls.guestRefreshTokenUrl, {
      publishableKey,
    });
    return data;
  };

  fetchGuestLogin = async (publishableKey: string, refreshToken: string) => {
    const { data } = await ApiClient.instance.post<
      HttpAuthApiRequest['iFetchGuestLogin'],
      HttpAuthApiResponse['iFetchGuestLogin']
    >(AuthUrls.guestLoginUrl, {
      publishableKey,
      refreshToken,
    });

    return data;
  };

  getCountryCodeFromIp = async (latitude: number, longitude: number) => {
    try {
      const { data } = await ApiClient.instance.get<
        HttpAuthApiResponse['getCountryCodeFromIp']
      >(AuthUrls.countryCodeFromIpUrl(latitude, longitude));
      return data.results[0].components.country_code;
    } catch (error) {
      return 'us';
    }
  };

  fetchHistory = async (
    businessId: number,
    userId: number,
    page: number,
    accessToken: string,
    restaurantId: number,
  ) => {
    const { data } = await ApiClient.instance.get<{
      data: Array<HttpAuthApiResponse['order']>;
    }>(AuthUrls.fetchHistoryUrl, null, {
      headers: { Authorization: accessToken },
      params: {
        page,
        business_id: businessId,
        user_id: userId,
        per_page: 30,
        status: 'CLOSED',
        restaurant_id: restaurantId,
      },
    });
    return data.data;
  };

  fetchOrderById = async (id: number, accessToken: string) => {
    const { data } = await ApiClient.instance.get<{
      data: HttpAuthApiResponse['order'];
    }>(AuthUrls.fetchOrderByIdUrl(id), ApiCacheConstants.USER_ORDER_BY_ID, {
      headers: { Authorization: accessToken },
    });
    return data.data;
  };

  emailReceipt = async (id: number, accessToken: string) => {
    await ApiClient.instance.post(AuthUrls.emailReceipt(id), null, {
      headers: { Authorization: accessToken },
    });
  };

  fetchPaymentMethods = async (accessToken: string, provider: string) => {
    const { data } = await ApiClient.instance.get<{
      paymentMethods: HttpAuthApiResponse['paymentMethod'][];
    }>(AuthUrls.getPaymentMethods(provider), null, {
      headers: { Authorization: accessToken },
    });
    return data.paymentMethods;
  };

  fetchApiVersions = async (accessToken: string, businessId: string) => {
    const { data } = await ApiClient.instance.get<{
      data: HttpAuthApiResponse['apiVersions'];
    }>(AuthUrls.getVersions(businessId), null, {
      headers: { Authorization: accessToken },
    });
    return data.data;
  };
}
