import { useEffect, useRef } from 'react';

const INACTIVE_USER_TIME_THRESHOLD = 3600000;

export const useInactiveUserTracker = (callback = () => null) => {
  const userActivityTimeout = useRef(null);

  function inactiveUserAction() {
    callback();
  }

  function resetUserActivityTimeout() {
    clearTimeout(userActivityTimeout.current);
    userActivityTimeout.current = setTimeout(() => {
      inactiveUserAction();
    }, INACTIVE_USER_TIME_THRESHOLD);
  }

  function activateActivityTracker() {
    window.addEventListener('mousemove', resetUserActivityTimeout);
    window.addEventListener('scroll', resetUserActivityTimeout);
    window.addEventListener('keydown', resetUserActivityTimeout);
    window.addEventListener('resize', resetUserActivityTimeout);
  }

  function deactivateActivityTracker() {
    window.removeEventListener('mousemove', resetUserActivityTimeout);
    window.removeEventListener('scroll', resetUserActivityTimeout);
    window.removeEventListener('keydown', resetUserActivityTimeout);
    window.removeEventListener('resize', resetUserActivityTimeout);
  }

  useEffect(() => {
    activateActivityTracker();
    return deactivateActivityTracker;
  }, []);
};
