import React, { useMemo, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, textEllipsis } from 'mycheck-core';

import { CustomIcon } from '@components';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './MenuTab.module.scss';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

interface Props {
  categories: MenuCategoryType[];
  selectedId: number;
  tabLeft: number;
  onClick: (category: MenuCategoryType | MenuSubcategory) => void;
}

export const MenuTabMoreDropDown: React.FC<Props> = observer(
  ({ categories, selectedId, tabLeft, onClick }) => {
    const getTranslationForItem = useGetTranslationForMenuItem();
    const location = useLocation();
    const [subCategoryOpen, setSubCategoryOpen] = useState(false);
    const [subCategoryMainSelected, setSubCategoryMainSelected] =
      useState(undefined);

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const primary = get(_config, 'palette.primary', '');

      return {
        primary,
      };
    }, [location.search]);

    return (
      <div
        className={styles.menuTabDropdown}
        style={{ left: tabLeft, display: 'flex' }}
        id="MenuTabDropDownId"
      >
        <div style={{ overflowY: 'scroll' }}>
          {categories?.map((category) => (
            <div
              key={category.id}
              onClick={() => {
                if (
                  category.subcategories &&
                  category.subcategories.length > 0
                ) {
                  setSubCategoryMainSelected(category);
                  setSubCategoryOpen(true);
                  if (
                    !category.subcategories.some((s) => s.id === selectedId)
                  ) {
                    onClick(category);
                  }
                } else {
                  onClick(category);
                  setSubCategoryOpen(false);
                }
              }}
            >
              <div
                className={classnames(
                  styles.menuTabDropdownItem,
                  selectedId === category.id ||
                    category.subcategories.some((sc) => sc.id === selectedId)
                    ? styles.menuTabDropdownItemSelected
                    : '',
                  category.subcategories && category.subcategories.length > 0
                    ? styles.menuTabDropdownItemSubcategories
                    : '',
                )}
                style={
                  selectedId === category.id ||
                  category.subcategories.some((sc) => sc.id === selectedId)
                    ? { color: config.primary }
                    : {}
                }
              >
                <span className={styles.menuTabDropdownItemSpan}>
                  {textEllipsis(
                    getTranslationForItem(category.pos_unique_id, category)
                      .name,
                    22,
                  )}
                </span>
                {category.subcategories &&
                  category.subcategories.length > 0 && (
                    <CustomIcon
                      name={'arrowRight'}
                      className={classnames(styles.menuTabLableArrowRight)}
                      style={{ color: '#777777' }}
                    />
                  )}
              </div>
            </div>
          ))}
        </div>
        {subCategoryOpen && (
          <div className={styles.menuTabDropdownItemMoreSubcategories}>
            {subCategoryMainSelected.subcategories.map(
              (category: MenuSubcategory) => (
                <div
                  key={category.id}
                  onClick={() => {
                    onClick(category);
                    setSubCategoryOpen(false);
                  }}
                  className={classnames(
                    styles.menuTabDropdownItem,
                    styles.menuTabDropdownItemSubcategories,
                    styles.menuTabDropdownItemMoreSubcategoriesItem,
                    selectedId === category.id
                      ? styles.menuTabDropdownItemSelected
                      : '',
                  )}
                  style={
                    selectedId === category.id ? { color: config.primary } : {}
                  }
                >
                  <span className={styles.menuTabDropdownItemSpan}>
                    {textEllipsis(
                      getTranslationForItem(category.pos_unique_id, category)
                        .name,
                      22,
                    )}
                  </span>
                </div>
              ),
            )}
          </div>
        )}
      </div>
    );
  },
);

MenuTabMoreDropDown.displayName = 'MenuTabMoreDropDown';
