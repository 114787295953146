import {
  attachAccessibility,
  attachFavicon,
  attachFonts,
  getSearchState,
  injector,
  MyCheckApp,
  MyCheckPlugin,
  StoresConstants,
} from 'mycheck-core';

import { HTTP_AUTH_API, HttpAuthApi } from './AuthApi';
import { AuthStore, AuthStoreInstance } from './AuthStore';
import { RecaptchaProvider } from 'core/core/constants/RecaptchaProviderConstants';
import { checkIsCaptchaEnabled } from 'core/core/helpers/checkIsCaptchaEnabled';

export const DINE_AUTH_PLUGIN_KEY = 'DINE_AUTH_PLUGIN_KEY';

export { AuthStore, AuthStoreInstance };

export class AuthPlugin implements MyCheckPlugin {
  readonly name = DINE_AUTH_PLUGIN_KEY;
  private publishableKey: string;

  async init(): Promise<void> {
    injector.set(HTTP_AUTH_API, new HttpAuthApi());
    this.publishableKey = MyCheckApp.instance.getPublishableKey();
    const params = new URLSearchParams(window.location.search);
    const searchState = getSearchState();
    const hotel = searchState?.hotel || params.get('hotel');
    const restaurant = searchState?.restaurant || params.get('restaurant');

    const authApi = injector.get<HttpAuthApi>(HTTP_AUTH_API);
    const { business_details } = await authApi.fetchConfig(this.publishableKey);
    const { config } = await authApi.fetchConfig_v3(this.publishableKey);
    let uiConfig = await authApi.fetchUIConfigByBusinessId(business_details.id);
    let parsedConfig = JSON.parse(uiConfig.data.config as string);
    parsedConfig.settings.name = uiConfig.data.name;
    parsedConfig.settings.cookiesPolicyUrl = uiConfig.data?.policy_url;

    attachFonts(parsedConfig.settings.fonts);

    MyCheckApp.instance.setConfig({
      business_details,
      config: parsedConfig,
      wallet_v3: config.wallet_v3,
      isCaptchaV3EnterpriseEnabled: checkIsCaptchaEnabled(
        config.dine_captcha_config,
        RecaptchaProvider.V3_ENTERPRISE,
      ),
      order_expiration_minutes: config.order_expiration_minutes,
    });

    let favicon = '';
    let accessibility = false;
    if (parsedConfig.settings.favicon) {
      favicon = parsedConfig.settings.favicon;
    }

    // Adding AccessiBe script
    accessibility =
      parsedConfig.settings.accessibility &&
      process.env.NODE_ENV !== 'development';

    if (hotel) {
      uiConfig = await authApi.fetchUIConfigByBusinessId(hotel);
      parsedConfig = JSON.parse(uiConfig.data.config as string);
      parsedConfig.settings.name = uiConfig.data.name;
      parsedConfig.settings.cookiesPolicyUrl = uiConfig.data?.policy_url;
      attachFonts(parsedConfig.settings.fonts);
      MyCheckApp.instance.setHotelConfig(parsedConfig);
      if (parsedConfig.settings.favicon) {
        favicon = parsedConfig.settings.favicon;
      }
      accessibility =
        parsedConfig.settings.accessibility &&
        process.env.NODE_ENV !== 'development';
    }

    if (restaurant) {
      uiConfig = await authApi.fetchUIConfigByBusinessId(restaurant);
      parsedConfig = JSON.parse(uiConfig.data.config as string);
      parsedConfig.settings.name = uiConfig.data.name;
      parsedConfig.settings.cookiesPolicyUrl = uiConfig.data?.policy_url;
      attachFonts(parsedConfig.settings.fonts);
      MyCheckApp.instance.setRestaurantConfig(parsedConfig);
      if (parsedConfig.settings.favicon) {
        favicon = parsedConfig.settings.favicon;
      }
      accessibility =
        parsedConfig.settings.accessibility &&
        process.env.NODE_ENV !== 'development';
    }

    if (favicon) {
      attachFavicon(favicon);
    }

    attachAccessibility(accessibility);
  }

  getStores = () => {
    const supportGuests = MyCheckApp.instance.getSupportGuestsValue();
    const businessID = MyCheckApp.instance.getBusinessID();

    const authStore = AuthStoreInstance.initStore(new HttpAuthApi(), {
      publishableKey: this.publishableKey,
      supportGuests,
      businessID,
    });
    return [{ [StoresConstants.AuthStore]: authStore }];
  };

  afterHydrate = () => {
    AuthStoreInstance.afterHydrate();
  };
}
