import React, { useEffect } from 'react';

import {
  getSearchState,
  useLocationStore,
  useNativeBridge,
  useNavigation,
} from 'mycheck-core';

export const BackHandler: React.FC = () => {
  const values = getSearchState();
  const navigate = useNavigation();
  const nativeBridge = useNativeBridge();
  const LocationStore = useLocationStore();

  useEffect(() => {
    const onBackEvent = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.action !== undefined) {
          if (data.action === 'BACK') {
            const path = window.location.pathname;
            if (
              path === '/' ||
              path === '/location' ||
              (path === '/menu' && values.urlrestaurant)
            ) {
              nativeBridge.postMessageClose({
                action: 'CLOSE',
                source: 'home',
              });
            } else {
              navigate.goBack();
            }
          }
        }
      } catch (err) {}
    };

    document.addEventListener('message', onBackEvent);
    window.addEventListener('message', onBackEvent);

    return () => {
      document.removeEventListener('message', onBackEvent);
      window.removeEventListener('message', onBackEvent);
    };
  }, []);

  useEffect(() => {
    const handleBackDeviceButton = () => {
      const path = window.location.pathname;
      const state = getSearchState();

      if (path.indexOf('/location') > -1 && !state.experience) {
        LocationStore.setSelectedExperienceType('');
      }
    };

    window.addEventListener('popstate', handleBackDeviceButton);

    return () => {
      window.removeEventListener('popstate', handleBackDeviceButton);
    };
  }, []);

  return null;
};
