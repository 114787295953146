import { Background } from '@components';
import React, { PropsWithChildren, useContext } from 'react';
import { MenuPageContext } from './MenuPageContext';

export interface MenuPageBodyProps extends PropsWithChildren {
  className?: string;
}

export const MenuPageBody: React.FC<MenuPageBodyProps> = ({
  className,
  children,
}) => {
  const { isLoading } = useContext(MenuPageContext);

  return (
    <Background secondary className={className}>
      {!isLoading && children}
    </Background>
  );
};

MenuPageBody.displayName = 'MenuPageBody';
