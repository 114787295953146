import React, {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  PropsWithChildren,
  type CSSProperties,
} from 'react';

import classnames from 'classnames';
import { FormikProps, useFormikContext } from 'formik';
import { css } from 'glamor';
import get from 'lodash/get';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { CustomIcon } from '../Icon/CustomIcon';

import styles from './CustomPhoneInput.module.scss';

import 'react-phone-input-2/lib/material.css';

interface CustomPhoneInputProps<T> extends PropsWithChildren {
  formikProps: FormikProps<T>;
  disabled?: boolean;
  coloredDropdown?: boolean;
  placeholder?: string;
  customStyle?: CSSProperties;
  showOptional?: boolean;
  testId?: string;
}

export const CustomPhoneInput = <
  T extends { phone: string; country?: string },
>({
  formikProps,
  disabled,
  children,
  coloredDropdown,
  placeholder,
  customStyle,
  showOptional = false,
  testId = '',
}: CustomPhoneInputProps<T>) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { errors, isSubmitting } = useFormikContext();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const labelStyle = get(_config, 'general.label', {});
    const dropdownStyle = { boxShadow: '0 0 20px 6px rgba(0, 0, 0, 0.05)' };

    const containerCss = css({
      '& > .special-label': {
        ...labelStyle,
        fontSize: `${labelStyle.fontSize} !important`,
        left: '7px !important',
        padding: '0 2px !important',
      },
    });

    const inputCss = css({
      '&:disabled': {
        background: `#E3E3E3 !important`,
      },
    });

    return {
      dropdownStyle,
      containerCss,
      inputCss,
    };
  }, [location.search, errors]);

  const handleChange = (
    phone: string,
    country: { countryCode: string; dialCode: string },
  ) => {
    formikProps.setValues({
      ...formikProps.values,
      phone: phone,
      country: country.countryCode.toUpperCase(),
      dialCode: country.dialCode,
    });
  };

  useEffect(() => {
    const selectedFlagContainer = document
      .getElementsByClassName('flag-dropdown')
      .item(0);
    const selectedFlag = document
      .getElementsByClassName('selected-flag')
      .item(0);
    document.getElementsByClassName('arrow').item(0)?.remove();

    if (selectedFlagContainer && selectedFlag) {
      const customWrap = document.createElement('div');
      customWrap.setAttribute('class', styles.customWrap);
      selectedFlagContainer.appendChild(customWrap);
      customWrap.appendChild(selectedFlag);
    }
  }, []);

  const formRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<
    HTMLInputElement &
      HTMLTextAreaElement & {
        state: { formattedNumber: number };
        numberInputRef: HTMLInputElement;
      }
  >(null);

  const handleFocus = useCallback(() => {
    formRef.current.classList.add(styles.focused);
  }, []);

  const handleBlur = useCallback(() => {
    if (!inputRef.current?.state.formattedNumber) {
      formRef.current.classList.remove(styles.focused);
    }
  }, []);

  useEffect(() => {
    if (inputRef.current?.state.formattedNumber) {
      formRef.current.classList.add(styles.focused);
    }
  }, []);

  useEffect(() => {
    if (!!Object.keys(errors).length) {
      const errKeys = Object.keys(errors);
      if (errKeys[0] === 'phone' && inputRef.current && isSubmitting) {
        inputRef.current.numberInputRef.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        inputRef.current.numberInputRef.focus();
      }
    }
  }, [errors, isSubmitting]);

  return (
    <div className={`${styles.form}`} style={customStyle} ref={formRef}>
      <label className={styles.formLabel}>{placeholder}</label>
      {showOptional && (
        <label className={styles.optionalLabel}>
          {t('checkout.optionalLabel')}
        </label>
      )}
      <div
        className={classnames(
          styles.phoneInputContainer,
          disabled && styles.phoneInputContainerDisabled,
        )}
      >
        <div
          className={classnames(
            styles.arrowIcon,
            disabled && styles.arrowIconDisabled,
          )}
        >
          <CustomIcon name="arrowDown" customStylePath="general.arrowDown" />
        </div>
        {children}
        <PhoneInput
          inputStyle={
            formikProps.errors.phone
              ? { border: '1px solid #bb0e2d' }
              : { border: '1px solid #777777' }
          }
          country={formikProps.values.country}
          containerClass={`${config.containerCss}`}
          buttonClass={classnames(
            styles.dropdownButton,
            coloredDropdown && styles.dropdownButtonColored,
            disabled && styles.dropdownButtonDisabled,
          )}
          inputClass={`${styles.phoneInputContainerInput} ${config.inputCss}`}
          dropdownClass={styles.phoneInputContainerDropdown}
          dropdownStyle={config.dropdownStyle}
          value={formikProps.values.phone}
          placeholder={null}
          specialLabel={null}
          onChange={handleChange}
          onKeyDown={(evt) => {
            if (
              (evt.which >= 48 && evt.which <= 57) ||
              (evt.which >= 96 && evt.which <= 105) ||
              evt.which === 8 ||
              evt.which === 9 ||
              evt.which === 37 ||
              evt.which === 39 ||
              evt.which === 46
            ) {
            } else {
              evt.preventDefault();
            }
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onEnterKeyPress={formikProps.submitForm}
          disabled={disabled}
          // @ts-expect-error library type error
          ref={inputRef}
          inputProps={{ 'data-test-id': `${testId}-input` }}
          isValid
        />
      </div>
    </div>
  );
};
