import React, { useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './Quantity.module.scss';

interface Props {
  quantity: number;
  decreaseQuantity: () => void;
  increaseQuantity: () => void;
}

export const Quantity: React.FC<Props> = ({
  quantity,
  decreaseQuantity,
  increaseQuantity,
}) => {
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primary = get(_config, 'palette.primary', {});

    return {
      primary,
    };
  }, [location.search]);

  return (
    /*<div className={styles.quantity} style={!isItemAdded ? {paddingBottom: 10} : {}}>*/
    <div className={styles.quantity}>
      <div className={styles.quantityButtonWrap}>
        <button
          type="button"
          className={styles.quantityButtonWrapButton}
          onClick={decreaseQuantity}
          disabled={quantity === 1}
          style={{ background: quantity === 1 ? '#C6C6C6' : config.primary }}
          data-test-id="decrease-item-count"
        >
          <CustomIcon
            name="minus"
            customStylePath="editItem.quantity.minus"
            style={quantity === 1 ? { color: '#777777' } : {}}
          />
        </button>
        <div
          className={styles.quantityButtonWrapNumber}
          style={{ border: `2px solid ${config.primary}` }}
        >
          {quantity}
        </div>
        <button
          type="button"
          className={styles.quantityButtonWrapButton}
          onClick={increaseQuantity}
          style={{ background: config.primary }}
          data-test-id="increase-item-count"
        >
          <CustomIcon name="plus" customStylePath="editItem.quantity.plus" />
        </button>
      </div>
    </div>
  );
};
