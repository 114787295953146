import { useMemo } from 'react';

import get from 'lodash/get';

import { MyCheckApp } from 'mycheck-core';

import { FLOW_TYPES } from '../core/constants/FlowTypeConstants';

export function useFlowType() {
  const flowType = useMemo(
    () =>
      get(
        MyCheckApp.instance.getGlobalConfig(),
        'settings.flow',
        FLOW_TYPES.RESTAURANT,
      ),
    [],
  );
  const isRestaurantFlow = flowType === FLOW_TYPES.RESTAURANT;
  const isHotelFlow = flowType === FLOW_TYPES.HOTEL;
  return { isRestaurantFlow, isHotelFlow };
}
