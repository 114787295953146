import React, { useMemo } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import styles from '../OrderSummary/OrderSummary.module.scss';

type TipItemType = {
  id: string;
  testId: string;
  label: string;
  currentValue: string | number;
  onClick: (value: string) => void;
  className?: string;
};

export const TipItem: React.FC<TipItemType> = ({
  id,
  testId,
  onClick,
  label,
  currentValue,
  className,
}) => {
  const onClickHandler = () => onClick(id);
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();
    const primaryColor = get(_config, 'palette.primary', {});

    const componentStyle = {
      notSelectedTip: {
        borderColor: `${primaryColor}`,
        color: primaryColor,
        display: label.length < 10 ? 'flex' : '-webkit-box',
      },
      selectedTip: {
        backgroundColor: primaryColor,
        borderColor: `${primaryColor}`,
        fontWeight: 700,
        display: label.length < 10 ? 'flex' : '-webkit-box',
      },
    };

    return {
      componentStyle,
    };
  }, [location.search, label]);

  return (
    <div
      onClick={onClickHandler}
      className={classnames(
        styles.orderSummaryButtonGroupButton,
        className ?? '',
      )}
      style={
        currentValue === id
          ? config.componentStyle.selectedTip
          : config.componentStyle.notSelectedTip
      }
      data-test-id={testId}
    >
      {label}
    </div>
  );
};
