import React, { type CSSProperties } from 'react';

import { useFormikContext } from 'formik';

import { useTranslation } from 'mycheck-core';

import styles from './FieldLimitLabel.module.scss';

interface Props {
  fieldName?: string;
  fieldValue?: string;
  maxLength: number;
  style?: CSSProperties;
}

export const FieldLimitLabel: React.FC<Props> = ({
  fieldName,
  fieldValue,
  maxLength,
  style,
}) => {
  const { values } = useFormikContext();
  const { t } = useTranslation();

  if (maxLength === undefined || (!fieldName && fieldValue === undefined)) {
    return null;
  }

  const remainingCount = fieldName
    ? maxLength - values[fieldName].length
    : maxLength - fieldValue.length;

  return (
    <div style={{ ...style }} className={styles.root}>
      {t('general.commentLimitCount', { maxLength, remainingCount })}
    </div>
  );
};
