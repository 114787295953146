import React, { type MouseEvent } from 'react';

import { observer } from 'mobx-react';

import {
  useWindowSize,
  useAuthStore,
  GaService,
  useModalNavigator,
} from 'mycheck-core';

import { ProfileMenu } from '@components';

import { DropDown } from '../DropDown/DropDown';
import { CustomIcon } from '../Icon/CustomIcon';

import styles from './HeaderProfile.module.scss';
import { ProfileLabel } from './ProfileLabel';

type HeaderProfileType = {
  children?: ({ onClose }) => JSX.Element;
  onPress?: () => void;
  label?: JSX.Element;
  onHomePage?: boolean;
  iconColor?: string;
};

export const HeaderProfile: React.FC<HeaderProfileType> = observer(
  ({ onPress, onHomePage, iconColor }) => {
    const AuthStore = useAuthStore();
    const { openRegisterPage } = useModalNavigator();

    const onClickHandler = (e: MouseEvent<HTMLDivElement>) => {
      GaService.instance.sendEvent({
        category: 'Ordering - Home',
        action: 'Menu',
        label: 'Click',
      });

      if (AuthStore.isLoggedIn) {
        onPress();
      } else {
        openRegisterPage();
        e.preventDefault();
        e.stopPropagation();
      }
    };

    const { isLg } = useWindowSize();
    if (isLg) {
      return (
        <DropDown
          label={<ProfileLabel onHomePage={onHomePage} AuthStore={AuthStore} />}
          showArrow={false}
          onHomePage={onHomePage}
          disableDropdown={!AuthStore.isLoggedIn}
          componentStyles={{ wrapperStyle: { top: 35, width: 260 } }}
          testId="header-profile"
        >
          {() => <ProfileMenu />}
        </DropDown>
      );
    }

    return (
      <div
        className={styles.icon}
        onClick={onClickHandler}
        data-test-id="header-profile-menu"
      >
        <CustomIcon
          name="userProfile"
          style={iconColor ? { color: iconColor, width: 16 } : {}}
        />
      </div>
    );
  },
);

HeaderProfile.displayName = 'HeaderProfile';
