import React, { type CSSProperties } from 'react';

import { observer } from 'mobx-react';

import {
  useLanguageStore,
  useLocation,
  useLocationStore,
  useNavigation,
  useTranslation,
} from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './OptionsContent.module.scss';
import { IHotelItem } from 'plugins/LocationPlugin/types/LocationTypes';

interface Props {
  style?: CSSProperties;
  hotel: IHotelItem;
}
export const OptionsContent: React.FC<Props> = observer(({ hotel }) => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  const navigation = useNavigation();
  const location = useLocation();
  const { t } = useTranslation();

  const hotelName = LocationStore.selectedHotel
    ? LanguageStore.getBusinessTranslation(hotel.id, 'name', hotel.name)
    : '';
  const handleClick = (experience: string) => {
    LocationStore.setSelectedExperienceType(experience);
    navigation.push(location.pathname);
  };

  return (
    <div className={styles.optionsContent}>
      <div className={styles.optionsContentHeader}>
        <div className={styles.optionsContentWelcome}>
          {t('experience.welcome', { hotelName: hotelName })}
        </div>

        <div className={styles.optionsContentTitle}>
          {t('experience.title')}
        </div>
      </div>

      <ul className={styles.optionsContentList}>
        {LocationStore.experiencesTypes.map((experience) => (
          <li
            className={styles.optionsContentListItem}
            key={experience}
            onClick={() => handleClick(experience)}
            data-test-id={experience}
          >
            <div>{t(`experience.${experience}`)}</div>

            <CustomIcon
              name="arrowDown"
              customStylePath="general.arrowDown"
              className={styles.optionsContentIcon}
            />
          </li>
        ))}
      </ul>
    </div>
  );
});

OptionsContent.displayName = 'OptionsContent';
