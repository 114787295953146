import React, { useCallback, useState, type CSSProperties } from 'react';
import { ClosePage } from '../ClosePage/ClosePage';
import { Overlay } from '../Overlay/Overlay';

type Props = {
  href: string;
  children: string;
  className?: string;
  style?: CSSProperties;
  testId?: string;
};

export const ExternalLinkIframe: React.FC<Props> = ({
  href,
  children,
  style,
  className,
  testId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <a
        style={style}
        className={className}
        onClick={onOpen}
        data-test-id={testId}
      >
        {children}
      </a>
      {isOpen && (
        <Overlay
          onClickOutside={onClose}
          testId="iframe-popup"
          height={'90vh'}
          width={'90%'}
        >
          <ClosePage onClose={onClose} testId="iframe" />
          <iframe
            title={children}
            src={href}
            style={{
              width: '100%',
              height: '100%',
              paddingTop: 36,
              border: 'none',
            }}
          />
        </Overlay>
      )}
    </>
  );
};
