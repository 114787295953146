const WalletV2SdkPath = process.env.REACT_APP_WALLET_2_SDK_JS;
const WalletV3SdkPath = process.env.REACT_APP_WALLET_3_SDK_JS;
const WalletV3SdkCssPath = process.env.REACT_APP_WALLET_3_SDK_CSS;
const FirebaseSdk = process.env.REACT_APP_FIREBASE_JS;
const FirebaseDatabase = process.env.REACT_APP_FIREBASE_DATABASE;

const _PromiseCache = {};

const createTagElement = (type: 'js' | 'css', url: string) => {
  let element = null;
  switch (type) {
    case 'css':
      element = document.createElement('link');
      element.type = 'text/css';
      element.rel = 'stylesheet';
      element.href = url;
      break;

    case 'js':
      element = document.createElement('script');
      element.async = true;
      element.src = url;
      break;
  }

  return element;
};

const _load = (type: 'js' | 'css') => (url) => {
  if (_PromiseCache[url]) {
    return _PromiseCache[url];
  }

  const newPromise = new Promise(function (resolve, reject) {
    const element = createTagElement(type, url);
    element.onload = function () {
      resolve(url);
    };
    element.onerror = function () {
      reject(url);
    };

    if (type === 'css') {
      document.head.append(element);
    } else {
      document.body.append(element);
    }
  });

  _PromiseCache[url] = newPromise;

  return newPromise;
};

const Loader = { js: _load('js'), css: _load('css') };

export const attachWalletV2 = async (): Promise<void> =>
  await Loader.js(WalletV2SdkPath);
export const attachWalletV3 = async (): Promise<void[]> =>
  await Promise.all([
    Loader.js(WalletV3SdkPath),
    Loader.css(WalletV3SdkCssPath),
  ]);
export const attachFirebase = async (): Promise<void> => {
  await Loader.js(FirebaseSdk);
  return await Loader.js(FirebaseDatabase);
};

const FONTS_ID = 'mycheck-wallet-font';

export const attachFonts = (
  fonts: string & { all: unknown },
  currentLanguage?: string,
) => {
  const { head } = document;
  const style = document.createElement('style');
  style.type = 'text/css';
  style.id = currentLanguage ? `${FONTS_ID}-${currentLanguage}` : 'fontId';

  const fontsToAdd = [];

  const fontFormat = (name, urls) => `@font-face {
    font-family: "${name}";
    src: url("${urls.woff2}") format("woff2"),
         url("${urls.woff}") format("woff"),
         url("${urls.ttf}") format("truetype"),
         url("${urls.svg}") format("svg");
  }`;

  const object =
    currentLanguage && fonts[currentLanguage]
      ? fonts[currentLanguage]
      : fonts.all;

  Object.keys(object).map((key) => {
    fontsToAdd.push(fontFormat(key, object[key]));
  });

  Array.from(document.querySelectorAll(`[id^="${FONTS_ID}"]`)).forEach(
    (element) => element.remove(),
  );
  style.appendChild(document.createTextNode(fontsToAdd.join('\n')));
  const elems = head.getElementsByTagName('style');
  for (let i = 0; i < elems.length; i++) {
    if (elems[i].id === 'fontId') {
      elems[i].parentNode.removeChild(elems[i]);
    }
  }
  head.appendChild(style);
};

export const attachFavicon = (favicon: string) => {
  let link: HTMLLinkElement = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = favicon;
};

export const attachAccessibility = (value: boolean) => {
  const exist: HTMLScriptElement = document.querySelector(
    "script[id~='accessibility']",
  );
  const innerHtmlScript =
    "(function(){var s = document.createElement('script'),e = ! document.body ? document.querySelector('head') : document.body;s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';s.async = true;s.onload = function(){acsbJS.init({statementLink : '',footerHtml : '',hideMobile : false,hideTrigger : false,language : 'en',position : 'right',leadColor : '#146FF8',triggerColor : '#146FF8',triggerRadius : '50%',triggerPositionX : 'right',triggerPositionY : 'bottom',triggerIcon : 'people',triggerSize : 'medium',triggerOffsetX : 20,triggerOffsetY : 20,mobile : {triggerSize : 'small',triggerPositionX : 'right',triggerPositionY : 'center',triggerOffsetX : 0,triggerOffsetY : 0,triggerRadius : '50%'}});};e.appendChild(s);}());";

  if (value) {
    if (!exist) {
      const script = document.createElement('script');
      script.id = 'accessibility';
      script.innerHTML = innerHtmlScript;
      document.body.appendChild(script);
    } else {
      exist.innerHTML = innerHtmlScript;
    }
  } else if (!value && exist && exist.innerHTML !== '') {
    exist.innerHTML = '';
  }
};

export const setHtmlPageTitle = (title: string) => {
  window.document.title = title;
};
