import { AuthStoreInstance } from 'auth-plugin';
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios';

import { ApiCacheConstants } from './constants/ApiCacheConstants';
import { injector } from './tools/injector';

export const API_CLIENT = 'API_CLIENT';

const TOKEN_EXPIRED_CODE = 121;

type CancelTokenStorage = {
  [key: string]: CancelTokenSource;
};

export class ApiClient {
  public static get instance() {
    return injector.get<ApiClient>(API_CLIENT);
  }

  private readonly client: AxiosInstance;
  private cancelTokenStorage: CancelTokenStorage = {};
  private callbackRefresh = () => null;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
    });

    this.client.interceptors.response.use(
      (response) => response,
      this.interceptorResponseRefreshToken,
    );
  }

  private interceptorResponseRefreshToken = async (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    const errorData = error.response.data;

    if (errorData.code === TOKEN_EXPIRED_CODE && !originalRequest._retry) {
      originalRequest._retry = true;
      await AuthStoreInstance.refreshUserToken();
      originalRequest.headers.Authorization = AuthStoreInstance.accessToken;
      return axios(originalRequest).catch((err) =>
        Promise.reject(err.response.data),
      );
    }

    if (errorData.code === 1015) {
      return this.callbackRefresh();
    }

    return Promise.reject(error.response.data);
  };

  private cancelCurrentCall = (cacheQuery: string) => {
    const cacheRequest = this.cancelTokenStorage[cacheQuery];
    if (cacheRequest) {
      cacheRequest.cancel();
    }
  };

  private setCancelTokenStorage = (cacheQuery: string) => {
    if (cacheQuery) {
      const cancelToken = axios.CancelToken.source();
      this.cancelTokenStorage = {
        ...this.cancelTokenStorage,
        [cacheQuery]: cancelToken,
      };

      return cancelToken.token;
    }
  };

  public addInterceptors = (headers: Record<string, string>): void => {
    this.client.interceptors.request.use((config) => {
      config.headers = {
        ...config.headers,
        ...headers,
      };

      return config;
    });
  };

  public setFallbackFunction = (callback: () => void) => {
    this.callbackRefresh = callback;
  };

  public async get<T = Record<string, unknown>, _R = AxiosResponse<T>>(
    url: string,
    cacheQuery: string = null,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<T>> {
    await this.cancelCurrentCall(cacheQuery);
    return this.client.get(url, {
      ...config,
      cancelToken: this.setCancelTokenStorage(cacheQuery),
    });
  }

  public async delete<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<R>> {
    await this.cancelCurrentCall(url);
    return this.client.delete(url, config);
  }

  public async post<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<R>> {
    await this.cancelCurrentCall(url);
    return this.client.post(url, data, {
      ...config,
      cancelToken: this.setCancelTokenStorage(url),
    });
  }

  public async put<T = Record<string, unknown>, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config: AxiosRequestConfig = {},
  ): Promise<AxiosResponse<R>> {
    await this.cancelCurrentCall(url);
    return this.client.put(url, data, {
      ...config,
      cancelToken: this.setCancelTokenStorage(url),
    });
  }

  public cancelRequest = (query: ApiCacheConstants): void =>
    this.cancelCurrentCall(query);
}
