import { useFormikContext } from 'formik';

import { useLanguageStore, useLocationStore } from 'mycheck-core';

import { IFormikValues } from '../../types/FormikValues';

export const useGetHomeList = () => {
  const LocationStore = useLocationStore();
  const LanguageStore = useLanguageStore();
  return LocationStore.homeList.map((hotel) => ({
    label: LanguageStore.getBusinessTranslation(hotel.id, 'name', hotel.name),
    description: hotel.place.data?.formatted_address,
    value: hotel.id.toString(),
  }));
};

export const useGetExperiences = () => {
  const LocationStore = useLocationStore();
  const formikProps = useFormikContext<IFormikValues>();
  const currentRestaurant = LocationStore.homeList.find(
    (e) => e.id == formikProps.values.selectedLocationID,
  );
  if (!currentRestaurant) {
    return [];
  }

  return currentRestaurant.experiences.map((experience) => ({
    label: experience.type,
    value: experience.type,
  }));
};
