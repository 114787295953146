import React, {
  forwardRef,
  useMemo,
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type SyntheticEvent,
} from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp } from 'mycheck-core';

import styles from './Paper.module.scss';

type Props = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  style?: CSSProperties;
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void;
  testId?: string;
  id?: string;
};

export const Paper = forwardRef<HTMLDivElement, Props>(
  ({ children, className, style, onClick, testId, id }, ref) => {
    const location = useLocation();

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const background = get(_config, 'palette.primaryBackground', '');
      const rootStyle = { background, ...style };

      return {
        rootStyle,
      };
    }, [location.search]);

    return (
      <div
        id={id}
        data-test-id={testId}
        ref={ref}
        style={config.rootStyle}
        className={classnames(styles.root, { [className]: !!className })}
        onClick={onClick}
      >
        {children}
      </div>
    );
  },
);

Paper.displayName = 'Paper';
