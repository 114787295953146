import React from 'react';

import { useTranslation } from 'mycheck-core';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';

import styles from './AddonsGroup.module.scss';
import { MenuModifierGroup } from 'types/GenericTypes';

type Props = {
  group: MenuModifierGroup;
};

export const AddonsGroupLabel: React.FC<Props> = ({ group }) => {
  const { t } = useTranslation();
  const getTranslationForItem = useGetTranslationForMenuItem();

  const renderValue = () => {
    if (!group.minSelect && !group.maxSelect) {
      return null;
    }

    if (group.minSelect === 1 && group.maxSelect === 1) {
      return t('menu.modifierSelectOne');
    }

    if (group.minSelect > 0 && group.minSelect === group.maxSelect) {
      return t(
        'menu.modifierSelect',
        //TODO try to bump library to get proper typing
        { value: group.minSelect } as unknown as string,
        { interpolation: { escapeValue: false } },
      );
    }

    if (group.minSelect === 0 && group.maxSelect > 10) {
      return t('menu.modifierSelectAllApply');
    }

    if (
      group.minSelect === 0 &&
      group.maxSelect >= 1 &&
      group.maxSelect <= 10
    ) {
      return t(
        'menu.modifierSelectUpTo10',
        //TODO try to bump library to get proper typing
        { value: group.maxSelect } as unknown as string,
        { interpolation: { escapeValue: false } },
      );
    }

    if (group.minSelect > 0 && group.maxSelect > 1) {
      return t(
        'menu.modifierSelectUpToInf',
        //TODO try to bump library to get proper typing
        { value: group.maxSelect } as unknown as string,
        { interpolation: { escapeValue: false } },
      );
    }

    return '';
  };

  return (
    <>
      <div>{getTranslationForItem(group.pos_unique_id, group).name}</div>
      <div className={styles.selectLabel}>{renderValue()}</div>
    </>
  );
};
