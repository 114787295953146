import React from 'react';

import { PriceFormatter } from '@components';

import styles from './MyOrderListItem.module.scss';
import { HttpAuthApiResponse } from 'plugins/AuthPlugin/types/AuthTypes';

interface MyOrderListItemProps {
  item: HttpAuthApiResponse['orderItem'];
  selectedOrder: HttpAuthApiResponse['order'];
}

const MyOrderListItem: React.FC<MyOrderListItemProps> = ({
  item,
  selectedOrder,
}) => {
  return (
    <div className={styles.myOrderListItem}>
      <div className={styles.myOrderListItemMain}>
        <p>
          {item.quantity > 1 ? `${item.quantity}x` : ''} {item.name}
        </p>
        <p>
          <PriceFormatter
            value={Number(item.total_price)}
            currency={selectedOrder.currency}
          />
        </p>
      </div>
      {item.modifiers.map((mod) => (
        <div key={mod.id} className={styles.myOrderListItemModifier}>
          <p>+{mod.name}</p>
          <p>
            +
            <PriceFormatter
              value={Number(mod.price * item.quantity)}
              currency={selectedOrder.currency}
            />
          </p>
        </div>
      ))}
    </div>
  );
};

export default MyOrderListItem;
