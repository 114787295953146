import React from 'react';

import {
  useCheckoutStore,
  useLocationStore,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './Choose.module.scss';

interface Props {
  onClose?: () => void;
  onChoose: () => void;
}
export const Choose: React.FC<Props> = ({ onChoose }) => {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const { isLg } = useWindowSize();
  const { t } = useTranslation();

  const handleChoose = (value: string) => {
    CheckoutStore.setPaymentMethod(value);
    onChoose();
  };

  return (
    <div className={styles.paymentMethodChoose}>
      <ul className={styles.paymentMethodChooseList}>
        {LocationStore.chargeOptions.map((pm) => (
          <li
            className={styles.paymentMethodChooseListItem}
            key={pm.value}
            onClick={() => handleChoose(pm.value)}
          >
            <div>{t(`checkout.paymentMethods.${pm.value}`) || pm.label}</div>

            {!isLg && (
              <CustomIcon
                name="arrowDown"
                customStylePath="general.arrowDown"
                className={styles.paymentMethodChooseListItemIcon}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
