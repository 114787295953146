import { useEffect } from 'react';

import {
  getSearchState,
  paramHandler,
  useFlowType,
  useLocationStore,
} from 'mycheck-core';

import { useCommonFlow } from './useCommonFlow';

export const useHotelFlow = (): void => {
  const LocationStore = useLocationStore();
  const SharedLogic = useCommonFlow(LocationStore.selectedHotel);
  const { isHotelFlow } = useFlowType();

  useEffect(() => {
    const values = getSearchState();
    paramHandler(values, 'date', (value) => {
      LocationStore.setSelectedDate(value);
      LocationStore.setSelectedDateTemp(value);
    });
  }, []);

  useEffect(() => {
    if (isHotelFlow && LocationStore.selectedHotel) {
      SharedLogic.resetInfo();
    }
  }, [LocationStore.selectedHotel, LocationStore.locationGroupId]);
};
