import React, { forwardRef } from 'react';

import { observer } from 'mobx-react';

import { useGetTranslationForMenuItem } from '../../hooks/useGetTranslationForMenuItem';
import { MenuCategoryHeader } from '../MenuCategory/MenuCategoryHeader';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

interface DesktopMenuCategoryHeaderProps {
  category: MenuCategoryType | MenuSubcategory;
  subcategory?: boolean;
}

export const DesktopMenuCategoryHeader = observer(
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLDivElement, DesktopMenuCategoryHeaderProps>(
    ({ category, subcategory }, ref) => {
      const getTranslationForItem = useGetTranslationForMenuItem();

      return (
        <MenuCategoryHeader
          name={getTranslationForItem(category.pos_unique_id, category).name}
          subcategory={subcategory}
          id={category.name}
          ref={ref}
        />
      );
    },
  ),
);

DesktopMenuCategoryHeader.displayName = 'DesktopMenuCategoryHeader';
