import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import moment from 'moment-timezone';

import {
  isWithinSixtyMinutes,
  transformDate,
  transformTime,
  transformTimeArrayToDateTimeArray,
  useDisplayDateTime,
  useLocationStore,
} from 'mycheck-core';

import { HeaderActions, TimePicker } from '@components';

import { Action } from '../../../../components/Action/Action';
import { MenuTab } from '../MenuTab/MenuTab';

import styles from './MenuHeaderActions.module.scss';
import { DaysSelectValueItem } from 'types/GenericTypes';

export const MenuHeaderActions: React.FC = observer(() => {
  const [hasAutoOpen, setHasAutoOpen] = useState(false);
  const [timePickerOpen, setTimePickerOpen] = useState(false);
  const [hasAvailableDays, setHastAvailableDays] = useState(false);
  const LocationStore = useLocationStore();
  const displayValue = useDisplayDateTime({
    isHotelDateTimeFormat: true,
    toTimeRange: false,
  });
  const nextAvailable = LocationStore.restaurantList
    .filter((r) => r.id === LocationStore.selectedRestaurantId)[0]
    ?.experiences.filter(
      (ex) => ex.type === LocationStore.selectedExperienceType,
    )[0]?.settings.slots.next_available;
  const isWithinOneHourSelectedDate = isWithinSixtyMinutes(
    nextAvailable,
    LocationStore.selectedDate,
  );

  const TimePickerHandle = () => {
    if (!hasAutoOpen) {
      setTimePickerOpen(!timePickerOpen);
    }
  };

  const TimePickerCloseHandle = () => {
    setHasAutoOpen(false);
    setTimePickerOpen(false);
  };

  const pickerAutoOpen = async () => {
    setHasAutoOpen(false);
    setTimePickerOpen(false);

    const dates = transformDate(
      LocationStore.locationTimezoneName,
      LocationStore.localDateFormat,
      LocationStore.selectedExperience?.settings?.available_days,
      false,
      LocationStore.timeSettings.open_hours,
    );

    const times: DaysSelectValueItem = dates.reduce((result, date) => {
      result[date.value] = transformTime(
        LocationStore.timeSettings,
        date.value,
        LocationStore.locationTimezoneName,
        LocationStore.localTimeFormat,
        false,
      );

      return result;
    }, {});

    const filterDates = dates
      .filter(
        (obj) =>
          !!times[
            moment(obj.value)
              .tz(LocationStore.locationTimezoneName)
              .utc()
              .format()
          ]?.length,
      )
      .map((e) => {
        const value = moment(e.value).tz(LocationStore.locationTimezoneName);
        return {
          ...e,
          value: value.date(),
          valueDate: value.clone().utc().format(),
          dayName: value.format('ddd').toLowerCase(),
        };
      });

    const locationHoursAvailable = Object.keys(
      LocationStore.selectedExperience.settings.open_hours,
    );

    const newDates = filterDates.filter((e) =>
      locationHoursAvailable.includes(e.dayName),
    );

    const selectedDateMoment = moment
      .utc(LocationStore.selectedDate)
      .tz(LocationStore.locationTimezoneName);

    const existOnDates =
      newDates.filter((d) => {
        const _date = moment
          .utc(d.valueDate)
          .tz(LocationStore.locationTimezoneName);
        return _date.isSame(selectedDateMoment, 'day');
      }).length > 0;

    if (!existOnDates) {
      setHasAutoOpen(true);
      setTimePickerOpen(true);
      return;
    }

    const date = selectedDateMoment.startOf('day').utc().format();
    const timeSlots = LocationStore.timeSlots;

    const locationTimeValues = transformTimeArrayToDateTimeArray(
      timeSlots[
        moment
          .utc(times[date][0].value)
          .tz(LocationStore.locationTimezoneName)
          .format('ddd')
          .toLowerCase()
      ] || [],
      newDates.find((e) => e.valueDate === date)?.valueDate,
      LocationStore.locationTimezoneName,
      LocationStore.localTimeFormat,
      LocationStore.selectedExperience.settings.frame_time,
    );

    const time = moment.utc(LocationStore.selectedDate).format();
    const existOnTimes =
      locationTimeValues.filter((t) => {
        const _time = moment.utc(t.value).format();
        return _time === time;
      }).length > 0;

    if (!existOnTimes && !isWithinOneHourSelectedDate) {
      setHasAutoOpen(true);
      setTimePickerOpen(true);
      return;
    }
    setHasAutoOpen(false);
    setTimePickerOpen(false);
  };

  useEffect(() => {
    if (
      LocationStore.timeSettings &&
      LocationStore.locationTimezoneName &&
      LocationStore.localHotelDateFormat
    ) {
      const days = transformDate(
        LocationStore.locationTimezoneName,
        LocationStore.localHotelDateFormat,
        LocationStore.timeSettings.available_days,
        false,
        LocationStore.timeSettings.open_hours,
      );
      setHastAvailableDays(days.length > 0);
    }
  }, [
    LocationStore.timeSettings,
    LocationStore.locationTimezoneName,
    LocationStore.localHotelDateFormat,
  ]);
  useEffect(() => {
    (async () => {
      if (
        LocationStore.timeSettings &&
        LocationStore.selectedExperience &&
        LocationStore.timeSlots
      ) {
        const timeout = setTimeout(async () => {
          await pickerAutoOpen();
        }, 1000);

        return () => clearTimeout(timeout);
      }
    })();
  }, [
    LocationStore.timeSettings,
    LocationStore.selectedExperience,
    LocationStore.timeSlots,
  ]);

  return (
    <HeaderActions>
      <div className={styles.wrapper}>
        <MenuTab />
        {!LocationStore.isAsap && hasAvailableDays && (
          <Action
            id="time-action"
            label={displayValue}
            height={40}
            width="100%"
            leftIconName="time"
            onClick={TimePickerHandle}
          />
        )}
        {timePickerOpen && (
          <TimePicker
            onClose={TimePickerCloseHandle}
            style={{ right: 24, left: 'auto' }}
            locationHours
            hideClose={hasAutoOpen}
          />
        )}
      </div>
    </HeaderActions>
  );
});

MenuHeaderActions.displayName = 'MenuHeaderActions';
