import React, { useEffect } from 'react';

import { Formik } from 'formik';

import { useLocationStore, useTranslation } from 'mycheck-core';

import { Icon, Overlay } from '@components';

import styles from './ExperienceModal.module.scss';
import { ExperienceModalForm } from './ExperienceModalForm';

interface Props {
  handleModalOpen: () => void;
}

export const ExperienceModal: React.FC<Props> = ({ handleModalOpen }) => {
  const LocationStore = useLocationStore();
  const { t } = useTranslation();

  const onSubmit = async ({ selectedExperience }) => {
    LocationStore.setSelectedExperienceType(selectedExperience);
    handleModalOpen();
  };

  const onClose = () => {
    document.body.style.removeProperty('overscroll-behavior-y');
    document.body.style.removeProperty('touch-action');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');
    document.getElementById('locationContent').style.overflow = 'auto';
    handleModalOpen();
  };

  const onClickOutside = () => {
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const initValues = {
    selectedExperience: LocationStore.selectedExperienceType,
  };

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';
    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';

    document.getElementById('root').style.height = '100%';
    document.getElementById('root').style.overflow = 'hidden';

    document.getElementById('locationContent').style.overflow = 'hidden';

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
      document.getElementById('locationContent').style.overflow = 'auto';
    };
  }, []);

  return (
    <Overlay
      width={'100%'}
      className={styles.experienceModalContainer}
      onClickOutside={onClickOutside}
    >
      <div className={styles.experienceModalContainerTitle}>
        {t('experience.title')}
      </div>
      <div className={styles.experienceModalContainerExit} onClick={onClose}>
        <Icon name="x" />
      </div>
      <Formik initialValues={initValues} onSubmit={onSubmit}>
        <ExperienceModalForm />
      </Formik>
    </Overlay>
  );
};
