import { DineCaptchaConfig } from 'plugins/AuthPlugin/types/AuthTypes';
import { RecaptchaProvider } from '../constants/RecaptchaProviderConstants';
import { isNumber } from 'lodash';

export const checkIsCaptchaEnabled = (
  captchaConfig: DineCaptchaConfig,
  provider: RecaptchaProvider,
) => {
  return (
    !!captchaConfig &&
    captchaConfig.provider === provider &&
    isNumber(captchaConfig.threshold)
  );
};
