import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import {
  PaymentConstants,
  useAuthStore,
  useErrorTrackerHandler,
  useModalNavigator,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Header, Overlay, PopUp, PrimaryButton } from '@components';

import DataGrid from '../../../../components/DataGrid/DataGrid';

import MyOrderListItem from './MyOrderListItem/MyOrderListItem';
import styles from './MyOrderPage.module.scss';
import MyOrderSummary from './MyOrderSummary/MyOrderSummary';

export const MyOrderPage: React.FC = observer(() => {
  const [isEmailConfirmationPopupOpen, setIsEmailConfirmationPopupOpen] =
    useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const errorHandler = useErrorTrackerHandler();
  const { closeMyOrderPage, openHistoryPage } = useModalNavigator();
  const authStore = useAuthStore();

  const isButtonAvailable =
    authStore.selectedOrder &&
    authStore.selectedOrder.status === 'CLOSED' &&
    authStore.selectedOrder.payments.some(
      (payment) =>
        payment.type === PaymentConstants.PaymentMethodsValue.WALLET &&
        payment.status === 'OK',
    );

  const toggleEmailConfirmationOpen = () =>
    setIsEmailConfirmationPopupOpen((isOpen) => !isOpen);

  const onButtonClick = async () => {
    setIsEmailSending(true);
    try {
      await authStore.emailReceipt();
      toggleEmailConfirmationOpen();
      setIsEmailSending(false);
    } catch (err) {
      setIsEmailSending(false);
      errorHandler.onError(err as Error);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.removeAttribute('style');
    };
  }, []);

  useEffect(() => {
    if (authStore.selectedOrderId) {
      authStore.fetchSelectedOrder();
    }
  }, [authStore.selectedOrderId]);

  const handleBackClick = () => {
    authStore.setSelectedOrderId(0);
    closeMyOrderPage();
    openHistoryPage();
  };

  const renderBody = () => (
    <div className={styles.myOrderPage}>
      <Header
        headerTitle={t('history.myOrder')}
        handleBackClick={handleBackClick}
        enforceSmall={isLg}
        rootStyle={{ position: 'absolute', width: '100%', top: 0 }}
      />
      <DataGrid>
        <DataGrid.Header>
          <DataGrid.Column>{t('history.name')}</DataGrid.Column>
          <DataGrid.Column>{t('history.price')}</DataGrid.Column>
        </DataGrid.Header>
        <DataGrid.Container>
          {authStore.selectedOrder?.items.map((item) => (
            <MyOrderListItem
              key={item.id}
              item={item}
              selectedOrder={authStore.selectedOrder}
            />
          ))}
        </DataGrid.Container>
        <DataGrid.Loader isLoading={!authStore.selectedOrder} />
      </DataGrid>
      {authStore.selectedOrder && (
        <MyOrderSummary selectedOrder={authStore.selectedOrder}>
          {isButtonAvailable && (
            <PrimaryButton
              onClick={onButtonClick}
              testId="send-receipt"
              disabled={isEmailSending}
            >
              {t('history.sendReceipt')}
            </PrimaryButton>
          )}
        </MyOrderSummary>
      )}
      {isEmailConfirmationPopupOpen && (
        <PopUp onClose={toggleEmailConfirmationOpen} buttonText={'OK'}>
          {t('history.receiptEmailSended')}
        </PopUp>
      )}
    </div>
  );

  if (isLg) {
    return <Overlay testId="my-order-page">{renderBody()}</Overlay>;
  }

  return renderBody();
});

MyOrderPage.displayName = 'MyOrderPage';
