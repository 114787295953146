import React from 'react';

import { observer } from 'mobx-react';

import { useMenuStore } from 'mycheck-core';

import { Footer } from '../../../../components/Footer/Footer';
import { KcalLAdultLabel } from '../KcalLAdultLabel/KcalLAdultLabel';

import { MenuItemContent } from './MenuItemContent';
import styles from './MobileMenuCategory.module.scss';
import { useMenuTabs } from 'plugins/MenuPlugin/hooks/useMenuTabs';
import InView from 'react-intersection-observer';

interface MobileMenuCategoryProps {
  showFooter: boolean;
}

export const MobileMenuCategory: React.FC<MobileMenuCategoryProps> = observer(
  ({ showFooter }) => {
    const MenuStore = useMenuStore();
    const { onChangeVisibility } = useMenuTabs();

    return (
      <div className={styles.root}>
        {MenuStore.categoriesAndSubcategoriesFlat.map((category, index) => (
          <InView
            onChange={(inView) => onChangeVisibility(category, inView)}
            threshold={0.1}
            key={category.id}
          >
            {({ ref }) => (
              <MenuItemContent ref={ref} key={index} category={category} />
            )}
          </InView>
        ))}
        <KcalLAdultLabel />
        <Footer style={{ marginTop: 40 }} />
        <div style={{ height: showFooter ? 24 : 0 }} />
      </div>
    );
  },
);

MobileMenuCategory.displayName = 'MobileMenuCategory';
