import React, { useMemo, useEffect, type ReactNode } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useAuthStore, useModalNavigator } from 'mycheck-core';

import { CustomIcon } from '@components';

import { Text } from '../Text/Text';

import styles from './ProfileMenu.module.scss';

export type AccountData = {
  id: string;
  title: JSX.Element;
  icon: ReactNode;
  onClick: () => void;
  isVisible: boolean;
};

interface Props {
  closeMenu?: () => void;
}

export const ProfileMenu: React.FC<Props> = observer(
  ({ closeMenu = () => null }) => {
    const {
      openEditProfilePage,
      openHistoryPage,
      openPaymentMethodsPage,
      openLogOutModal,
    } = useModalNavigator();
    const location = useLocation();
    const { isLoggedIn } = useAuthStore();

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const termsAndConditions = get(
        _config,
        'home.settings.termsAndConditions',
        null,
      );
      const termsAndConditionsDisplay = get(
        _config,
        'settings.termsDisplay.profile',
        true,
      );
      const privacyPolicy = get(_config, 'home.settings.privacyPolicy', null);
      const privacyPolicyDisplay = get(
        _config,
        'settings.privacyDisplay.profile',
        true,
      );
      const isHistoryVisible = get(_config, 'settings.history', false);

      return {
        termsAndConditions,
        termsAndConditionsDisplay,
        privacyPolicy,
        privacyPolicyDisplay,
        isHistoryVisible,
      };
    }, [location.search]);

    useEffect(() => {
      if (!isLoggedIn) {
        closeMenu();
      }
    }, [isLoggedIn]);

    const renderAction = (item) => {
      if (item.redirect) {
        return (
          <a
            key={item.id}
            data-test-id={item.id}
            href={item.redirect}
            target="_blank"
            className={styles.profileMenuItem}
            rel="noopener noreferrer"
          >
            <div className={styles.profileMenuItemIcon}>{item.icon}</div>
            <div className={styles.profileMenuItemLabel}>{item.title}</div>
            <div className={styles.profileMenuItemArrowIcon}>
              <CustomIcon name={'arrowRight'} />
            </div>
          </a>
        );
      }

      return (
        <div
          key={item.id}
          data-test-id={item.id}
          className={styles.profileMenuItem}
          onClick={() => {
            if (item.onClick) {
              item.onClick();
            }
          }}
        >
          <div className={styles.profileMenuItemIcon}>{item.icon}</div>
          <div className={styles.profileMenuItemLabel}>{item.title}</div>
          <div className={styles.profileMenuItemArrowIcon}>
            <CustomIcon name={'arrowRight'} />
          </div>
        </div>
      );
    };

    const accountData: AccountData[] = [
      {
        id: 'profile-menu-edit-profile',
        title: <Text value="sidebar.editProfile" />,
        icon: <CustomIcon name="editProfile" />,
        onClick: openEditProfilePage,
        isVisible: true,
      },
      {
        id: 'payment-methods',
        title: <Text value="sidebar.paymentMethods" />,
        icon: <CustomIcon name="wallet" />,
        onClick: openPaymentMethodsPage,
        isVisible: true,
      },
      {
        id: 'profile-menu-history',
        title: <Text value="sidebar.history" />,
        icon: <CustomIcon name="history" />,
        onClick: openHistoryPage,
        isVisible: config.isHistoryVisible,
      },
      {
        id: 'profile-menu-logout',
        title: <Text value="sidebar.logOut" />,
        icon: <CustomIcon name="logout" />,
        onClick: openLogOutModal,
        isVisible: true,
      },
    ].filter((el) => el.isVisible);

    return (
      <div className={styles.profileMenu}>{accountData.map(renderAction)}</div>
    );
  },
);

ProfileMenu.displayName = 'ProfileMenu';
