import { injector } from '../tools/injector';

export const GA_SERVICE = 'GA_SERVICE';

interface EventParamsArgs {
  category: string;
  action: string;
  label: string;
  value?: number;
}

export class GaService {
  constructor(gaId: string) {
    this.init(gaId);
  }

  private init(_gaId: string) {
    // ReactGA.initialize(gaId)
  }

  public static get instance() {
    return injector.get<GaService>(GA_SERVICE);
  }

  public sendEvent(_params: EventParamsArgs) {
    //ReactGA.event(params)
  }
}
