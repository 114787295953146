import React, { useCallback, useMemo } from 'react';

import { Form, useFormikContext } from 'formik';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useFlowType,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { CustomIcon, PopUp } from '@components';

import { IFormikValues } from '../../types/FormikValues';

import { HomeAnchorLink } from './HomeAnchorLink';
import styles from './HomePageForm.module.scss';
import { HotelFlow } from './HotelFlow';
import { RestaurantFlow } from './RestaurantFlow';

export const HomePageForm: React.FC = observer(() => {
  const formikProps = useFormikContext<IFormikValues>();
  const { isRestaurantFlow } = useFlowType();
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const titleConfig = get(_config, 'home.style.title', {});
    const logoConfig = get(_config, 'home.style.logo', null);
    const linkStyle = get(_config, 'home.style.link', {});
    const termsIsDisplayed = get(_config, 'settings.termsDisplay.home', true);
    const privacyPolicyIsDisplayed = get(
      _config,
      'settings.privacyDisplay.home',
      true,
    );
    const separator = termsIsDisplayed && privacyPolicyIsDisplayed && '|';
    const bodyStyle = get(_config, 'general.popup.mainText', {});

    return {
      titleConfig,
      logoConfig,
      linkStyle,
      termsIsDisplayed,
      privacyPolicyIsDisplayed,
      separator,
      bodyStyle,
    };
  }, [location.search]);

  const hidePopUp = useCallback(() => formikProps.setErrors({}), []);

  return (
    <Form className={styles.homePageForm}>
      <div className={styles.logoContainer}>
        {config.logoConfig && (
          <img className={styles.logo} src={config.logoConfig} alt={'No img'} />
        )}
        {isLg && (
          <span className={styles.title} style={config.titleConfig}>
            {t('home.title')}
          </span>
        )}
      </div>
      <div className={styles.titleContainer}>
        {!isLg && (
          <span className={styles.title} style={config.titleConfig}>
            {t('home.title')}
          </span>
        )}
        {isRestaurantFlow ? <RestaurantFlow /> : <HotelFlow />}
        <div className={styles.homePageFormPoweredBy}>
          <CustomIcon name="poweredBy" />
        </div>
        <div className={styles.homePageFormLinks} style={config.linkStyle}>
          {config.termsIsDisplayed && (
            <HomeAnchorLink type="termsAndConditions" />
          )}
          {config.separator}
          {config.privacyPolicyIsDisplayed && (
            <HomeAnchorLink type="privacyPolicy" />
          )}
        </div>
        {!!Object.keys(formikProps.errors).length && (
          <PopUp onClose={hidePopUp} testId="home-restaurant-error">
            {t('home.popUpRestaurant')}
          </PopUp>
        )}
      </div>
    </Form>
  );
});

HomePageForm.displayName = 'HomePageForm';
