import React, { useContext } from 'react';

import { observer } from 'mobx-react';
import { Outlet } from 'react-router-dom';

import { useLocationStore, useNavigation, useTranslation } from 'mycheck-core';

import { HeaderNavigation } from '@components';

import { Footer } from '../../../../components/Footer/Footer';
import { MenuDesktop } from '../../components/Menu/MenuDesktop';
import { MenuHeaderActions } from '../../components/MenuHeaderActions/MenuHeaderActions';
import { OrderPage } from '../OrderPage/OrderPage';

import styles from './MenuPage.module.scss';
import MenuPage from './common/MenuPage';
import { MenuPageContext } from './common/MenuPageContext';

export const MenuPageDesktop: React.FC = observer(() => {
  const { headerTitle } = useContext(MenuPageContext);
  const navigation = useNavigation();
  const LocationStore = useLocationStore();
  const { t } = useTranslation();

  const onLogoClick = () => {
    navigation.replaceHash('');
    navigation.push('/location');
  };

  return (
    <>
      <HeaderNavigation headerTitle={headerTitle} onLogoClick={onLogoClick} />
      <MenuHeaderActions />
      <MenuPage.Body className={styles.pageWrapper}>
        <MenuDesktop />
        <div className={styles.checkoutWrapper}>
          <OrderPage />
          {LocationStore.viewMode && (
            <div className={styles.viewModeBox}>{t('menu.viewModeLabel')}</div>
          )}
        </div>
        <Outlet />
      </MenuPage.Body>
      <Footer className={styles.footer} />
    </>
  );
});

MenuPageDesktop.displayName = 'MenuPageDesktop';
