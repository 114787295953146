import './index.css';
import { AuthPlugin } from 'auth-plugin';
import { CheckoutPlugin } from 'checkout-plugin';
import { LanguagePlugin } from 'language-plugin';
import { LocationPlugin } from 'location-plugin';
import { MenuPlugin } from 'menu-plugin';

import { MyCheckApp, MYCHECK_APP, injector } from 'mycheck-core';

import 'react-virtualized/styles.css';

export const BASE_URL = process.env.REACT_APP_API_URI;

const initApplication = async (publishableKey: string) => {
  const app = new MyCheckApp({
    plugins: [
      new AuthPlugin(),
      new LanguagePlugin(),
      new LocationPlugin(),
      new MenuPlugin(),
      new CheckoutPlugin(),
    ],
  });

  injector.set(MYCHECK_APP, app);

  await app.init(BASE_URL, publishableKey);

  app.render();
};

window.initPickupV3 = initApplication;
