import { useRef } from 'react';

import {
  useCheckoutStore,
  useDateTimeTrackerHandler,
  useGoBackToMenu,
  useIsLocationOpen,
  useLanguageStore,
  useLocationStore,
  useMenuStore,
  useMenuTrackerHandler,
  isDayToday,
} from 'mycheck-core';

export function useDateChange() {
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();
  const LanguageStore = useLanguageStore();
  const menuTracker = useMenuTrackerHandler();
  const MenuStore = useMenuStore();
  const DateTimeTracker = useDateTimeTrackerHandler();
  const isLocationOpen = useIsLocationOpen();
  const { goBackToMenu } = useGoBackToMenu();
  const currentExperience = useRef<number>(null);

  const fetchAfterChange = async () => {
    MenuStore.startFetching();
    if (
      LocationStore.selectedExperience?.id !== currentExperience.current ||
      LocationStore.selectedExperienceId !== currentExperience.current
    ) {
      currentExperience.current = LocationStore.selectedExperienceId;
      await LocationStore.fetchExperience(LocationStore.selectedExperienceId);
      await LocationStore.fetchAllergens();
    }

    if (
      isDayToday(LocationStore.selectedDate) &&
      isLocationOpen.isOpen(LocationStore.selectedExperience)
    ) {
      CheckoutStore.setIsGrace(true);
    } else if (
      LocationStore.selectedExperienceId !== currentExperience.current
    ) {
      CheckoutStore.setIsGrace(false);
    }

    await MenuStore.selectCurrentMenu({
      bid: LocationStore.selectedRestaurant.id,
      menus: LocationStore.menuFiles,
      translations: LocationStore.menuTranslations,
      time: CheckoutStore.checkoutTime,
      timezone: LocationStore.locationTimezoneName,
      language: LanguageStore.selectedValue,
    });

    await DateTimeTracker.confirmSlot({
      isUpdateModalAvailable: true,
      isFutureModalAvailable: true,
      isClosedModalAvailable: false,
    });
    await menuTracker.checkIsSameMenu({ errCallback: goBackToMenu });

    MenuStore.stopFetching();
  };

  return { fetchAfterChange };
}
