import React, { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';
import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation } from 'mycheck-core';

import { CustomIcon } from '@components';

import styles from './MenuTab.module.scss';
import { MenuTabMoreDropDown } from './MenuTabMoreDropDown';
import { MenuCategoryType, MenuSubcategory } from 'types/GenericTypes';

type Props = {
  categories: Array<MenuCategoryType>;
  selectedTabId: number;
  onCategoryClick: (param: MenuCategoryType | MenuSubcategory) => void;
  onMoreClick: () => void;
  forceClose: boolean;
};

export const MenuTabMore: React.FC<Props> = observer(
  ({ categories, selectedTabId, onCategoryClick, onMoreClick, forceClose }) => {
    const { t } = useTranslation();
    const location = useLocation();

    const [dropdownOpen, setDropdownOpen] = useState(forceClose || false);
    const [dropdownLeft, setDropdownLeft] = useState(0);

    const config = useMemo(() => {
      const _config = MyCheckApp.instance.getGlobalConfig();

      const primary = get(_config, 'palette.primary', '');

      return {
        primary,
      };
    }, [location.search]);

    useEffect(() => {
      const tabLeft = document
        .getElementById('menu-tab-more')
        .getBoundingClientRect().x;
      setDropdownLeft(tabLeft + 24);
    }, []);

    const isSelected = categories.some(
      (c) =>
        c.id === selectedTabId ||
        c.subcategories.some((sc) => sc.id === selectedTabId),
    );

    useEffect(() => {
      if (
        forceClose ||
        !categories.some(
          (c) =>
            c.id === selectedTabId ||
            c.subcategories.some((sc) => sc.id === selectedTabId),
        )
      ) {
        setDropdownOpen(false);
      }
    }, [forceClose, selectedTabId]);

    return (
      <>
        <div
          id="menu-tab-more"
          className={classnames(
            styles.menuTabLable,
            isSelected ? styles.menuTabSelected : '',
          )}
          style={isSelected ? { backgroundColor: config.primary } : {}}
          onClick={() => {
            onMoreClick();
            if (
              !categories.some(
                (c) =>
                  c.id === selectedTabId ||
                  c.subcategories.some((sc) => sc.id === selectedTabId),
              )
            ) {
              onCategoryClick(categories[0]);
              setTimeout(() => {
                setDropdownOpen(true);
              }, 100);
            } else {
              setDropdownOpen(!dropdownOpen);
            }
          }}
        >
          {t('menu.more')}
          <CustomIcon
            name={'arrowDown'}
            className={classnames(
              styles.menuTabLableArrow,
              styles.menuTabLableArrowDown,
              dropdownOpen ? styles.menuTabLableArrowDownOpen : '',
            )}
            style={!isSelected ? { color: '#1D1B20' } : {}}
          />
        </div>
        {dropdownOpen && (
          <MenuTabMoreDropDown
            selectedId={selectedTabId}
            categories={categories}
            tabLeft={dropdownLeft}
            onClick={(category: MenuCategoryType | MenuSubcategory) => {
              if (
                !category.subcategories ||
                (category.subcategories && category.subcategories.length === 0)
              ) {
                setDropdownOpen(false);
              }
              onCategoryClick(category);
            }}
          />
        )}
      </>
    );
  },
);

MenuTabMore.displayName = 'MenuTabMore';
