import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  PropsWithChildren,
  type CSSProperties,
  type ChangeEvent,
} from 'react';

import classnames from 'classnames';
import { useFormikContext } from 'formik';
import { css } from 'glamor';

import { capitalizeFirstLetter, useTranslation } from 'mycheck-core';

import styles from './CustomInput.module.scss';

interface CustomInputProps extends PropsWithChildren {
  id?: string;
  testId?: string;
  type?: string;
  name?: string;
  placeholder?: string;
  value: string;
  textarea?: boolean;
  className?: string;
  customStyle?: CSSProperties;
  placeHolderStyle?: CSSProperties;
  error?: boolean;
  onChange?: (e?: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => void;
  errorMessage?: string;
  disabled?: boolean;
  inputMode?:
    | 'email'
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'numeric'
    | 'decimal'
    | 'search';
  showOptional?: boolean;
  maxLength?: number;
  datePickerInput?: boolean;
  withAnimation?: boolean;
}

export const CustomInput: React.FC<CustomInputProps> = ({
  id,
  error,
  customStyle,
  errorMessage,
  children,
  showOptional = false,
  testId = '',
  placeHolderStyle,
  datePickerInput = false,
  withAnimation = false,
  ...props
}) => {
  const { errors, isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  const inputCss = useMemo(() => {
    return css({
      '&:disabled': {
        background: '#E3E3E3 !important',
      },
      '&:-webkit-autofill': {
        WebkitBoxShadow: '0 0 0px 1000px transparent inset',
      },
    });
  }, [error]);

  const onChangeEvt = (evt: ChangeEvent<any>) => {
    if (props.maxLength) {
      const value = evt.currentTarget.value;
      evt.currentTarget.value = value.substr(0, props.maxLength);
    }

    if (props.type === 'decimal') {
      let value = evt.currentTarget.value
        .replace(/[^\d\.]/g, '')
        .replace(/(\..*)\./g, '$1')
        .substring(0, 10);
      value = value.startsWith('.') ? value.replace(/./g, '') : value;

      const afterDot = value.toString().split('.')[1]?.length || 0;
      if (afterDot === 1) {
        value = parseFloat(value).toFixed(1);
      }

      if (afterDot >= 2) {
        value = parseFloat(value).toFixed(2);
      }

      evt.currentTarget.value = value;
    }

    if (props.onChange) {
      props.onChange(evt);
    }
  };

  const formRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);

  useEffect(() => {
    if (props.value) {
      formRef.current.classList.add(styles.focused);
    }
  }, [props.value]);

  const onFocusForm = useCallback(() => {
    formRef.current.classList.add(styles.focused);
  }, []);

  const onBlurForm = useCallback(() => {
    if (!inputRef.current.value) {
      formRef.current.classList.remove(styles.focused);
    }
  }, []);

  useEffect(() => {
    if (!!Object.keys(errors).length) {
      const errKeys = Object.keys(errors);
      if (errKeys[0] === props.name && inputRef.current && isSubmitting) {
        inputRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
        inputRef.current.focus();
      }
    }
  }, [errors, isSubmitting]);

  const formClassnames = classnames(
    styles.form,
    props.className,
    datePickerInput && props.value ? styles.focused : '',
    withAnimation ? styles.animation : '',
  );

  const Component = props.textarea ? 'textarea' : 'input';

  const { textarea, ...componentProps } = props;

  return (
    <div
      data-test-id={`custom-input-form-${testId || props.name}`}
      id={id}
      className={`${formClassnames}`}
      ref={formRef}
      onFocus={onFocusForm}
      onBlur={onBlurForm}
      style={{
        ...(withAnimation ? { marginBottom: error ? '40px' : '24px' } : {}),
        ...customStyle,
      }}
    >
      <label
        className={classnames(
          styles.formLabel,
          children ? styles.withIcon : '',
        )}
        style={placeHolderStyle ? placeHolderStyle : {}}
      >
        {props.placeholder}
      </label>
      {showOptional && (
        <label className={styles.formOptionalLabel}>
          {t('checkout.optionalLabel')}
        </label>
      )}
      <Component
        {...componentProps}
        {...(textarea ? { rows: 5 } : {})}
        onChange={onChangeEvt}
        className={classnames(`${styles.formInput} ${inputCss}`, {
          [styles.animation]: withAnimation,
        })}
        ref={inputRef}
        disabled={props.disabled}
        inputMode={props.inputMode}
        data-test-id={`custom-input-${testId || props.name}`}
        style={error ? { border: '1px solid #BB0E2D' } : {}}
      />
      {!!error && !!errorMessage && (
        <div className={styles.formErrorText}>
          {capitalizeFirstLetter(errorMessage)}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
