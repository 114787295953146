import React, {
  useCallback,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';

import { observer } from 'mobx-react';

import {
  useCheckoutStore,
  useLocationStore,
  useAuthStore,
  useNavigation,
  paramHandler,
  getSearchState,
} from 'mycheck-core';

export const ParamsService: React.FC<PropsWithChildren> = observer(
  ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const LocationStore = useLocationStore();
    const CheckoutStore = useCheckoutStore();
    const AuthStore = useAuthStore();
    const navigation = useNavigation();

    const handleRedirect = () => {
      const params = new URLSearchParams(window.location.search);
      const searchState = getSearchState();

      const hotelParam = params.get('hotel') || searchState.hotel;
      const restaurantParam =
        params.get('restaurant') || searchState.restaurant;
      const experienceParam =
        params.get('experience') || searchState.experience;

      const [page = ''] = window.location.pathname.split('/');

      if (experienceParam && restaurantParam && hotelParam) {
        navigation.replace('/menu');
        return true;
      }

      if (hotelParam && !page.includes('location')) {
        navigation.replace('/location');
        return true;
      }

      if (!hotelParam && page === 'location') {
        return navigation.replace('/');
      }

      if (page === 'menu') {
        if (!restaurantParam || !experienceParam) {
          return navigation.replace('/');
        }
      }

      return false;
    };

    const handleLocationParams = useCallback(() => {
      const params = new URLSearchParams(window.location.search);
      paramHandler(params, 'isNative', (value) => {
        LocationStore.setIsNative(Boolean(value === 'true'));
      });
      paramHandler(params, 'isIframe', (value) => {
        LocationStore.setIsIframe(Boolean(value === 'true'));
      });
      paramHandler(params, 'hotel', (value) => {
        LocationStore.setSelectedExperienceId(null);
        LocationStore.setSelectedHotel(Number(value));
      });
      paramHandler(params, 'location_group_id', (value) => {
        LocationStore.setLocationGroupId(Number(value));
      });
      paramHandler(params, 'restaurant', (value) => {
        LocationStore.setSelectedExperienceId(null);
        LocationStore.setSelectedRestaurantId(Number(value), true);
      });
      paramHandler(params, 'experience', (value) => {
        LocationStore.setSelectedExperienceType(value, true);
        LocationStore.setSelectedExperienceTypeTemp(value);
        LocationStore.isSelectedRestaurantIdFromUrl = false;
      });
      paramHandler(params, 'date', (value) => {
        LocationStore.setSelectedDate(value);
        LocationStore.setSelectedDateTemp(value);
        CheckoutStore.setSelectedCheckoutTime(value);
      });
      paramHandler(params, 'time', (value) => {
        LocationStore.setSelectedTime(value);
        CheckoutStore.setSelectedCheckoutTime(value);
      });
    }, [window.location.search]);

    const handleAuthParams = useCallback(() => {
      const params = new URLSearchParams(window.location.search);

      paramHandler(params, 'accessToken', (value) =>
        AuthStore.logInByAccessToken(value),
      );
      paramHandler(params, 'refreshToken', (value) => {
        AuthStore.setRefreshToken(value);
        AuthStore.refreshUserToken();
      });
    }, [window.location.search]);

    const handleCheckoutParams = useCallback(() => {
      const params = new URLSearchParams(window.location.search);
      paramHandler(params, 'room', (value) =>
        CheckoutStore.setRoomNumber(value),
      );
      paramHandler(params, 'table', (value) =>
        CheckoutStore.setTableNumber(value),
      );
      paramHandler(params, 'grace', (value) =>
        CheckoutStore.setIsGrace(value === 'true'),
      );
    }, [window.location.search]);

    useEffect(() => {
      handleLocationParams();
      handleCheckoutParams();
      handleAuthParams();

      handleRedirect();

      setIsLoaded(true);
    }, []);

    if (!isLoaded) {
      return null;
    }

    return <>{children}</>;
  },
);

ParamsService.displayName = 'ParamsService';
