import {
  getDateDays,
  isBetween,
  millisecondsDifferenceTime,
  useLocationStore,
} from 'mycheck-core';
import { IExperienceItem } from 'plugins/LocationPlugin/types/LocationTypes';

export const useIsLocationOpen = () => {
  const locationStore = useLocationStore();
  const isOpen = (currentExperience: IExperienceItem) => {
    if (!currentExperience) {
      return false;
    }

    const openHours = currentExperience.settings.open_hours;
    const openDays = getDateDays(
      Object.keys(openHours),
      locationStore.locationTimezoneName,
    );

    let isOpenVal = false;
    openHours[openDays?.today.format('ddd').toLowerCase()]?.some((element) => {
      if (
        isBetween(element.from, element.to, currentExperience.timezone.name)
      ) {
        isOpenVal = true;
      }

      return isOpenVal;
    });

    return isOpenVal;
  };

  const isClosingIn = (currentExperience: IExperienceItem) => {
    if (!currentExperience) {
      return 0;
    }

    const openHours = currentExperience.settings.open_hours;
    const openDays = getDateDays(
      Object.keys(openHours),
      locationStore.locationTimezoneName,
    );

    let isOpenVal = false;
    let closesIn = 0;
    openHours[openDays?.today.format('ddd').toLowerCase()]?.some((element) => {
      if (
        isBetween(element.from, element.to, currentExperience.timezone.name)
      ) {
        isOpenVal = true;
        closesIn = millisecondsDifferenceTime(
          element.to,
          currentExperience.timezone.name,
        );
      }

      return isOpenVal;
    });

    return closesIn;
  };

  return { isOpen, isClosingIn };
};
