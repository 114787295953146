import React, { useEffect, useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useTranslation, useWindowSize } from 'mycheck-core';

import { Icon, Overlay, TimePicker } from '@components';

import styles from './TimeSelectionModal.module.scss';

interface Props {
  handleModalOpen: () => void;
  locationHours?: boolean;
  hotelDateTimeFormat: boolean;
  hideClose?: boolean;
}

export const TimeSelectionModal: React.FC<Props> = ({
  handleModalOpen,
  locationHours,
  hideClose = false,
}) => {
  const { t } = useTranslation();
  const { isLg } = useWindowSize();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const styleComponent = () => {
      const style = get(_config, 'restaurants.timeSelection', {});
      return {
        closeIcon: style.icons.x,
        title: style.Title,
        value: style.Value,
      };
    };

    return {
      styleComponent: styleComponent(),
    };
  }, [location.search]);

  const overlayStyle = isLg
    ? { marginTop: 150, width: 320, left: 'unset', zIndex: 2 }
    : {};

  const onClose = () => {
    document.body.style.removeProperty('overscroll-behavior-y');
    document.body.style.removeProperty('touch-action');
    document.body.removeAttribute('style');
    document.documentElement.removeAttribute('style');
    document.getElementById('root').removeAttribute('style');

    if (!locationHours) {
      document.getElementById('locationContent').style.overflow = 'auto';
    }
    handleModalOpen();
  };

  const onClickOutside = () => {
    if (!hideClose) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  useEffect(() => {
    document.body.style['overscroll-behavior-y'] = 'none';
    document.body.style['touch-action'] = 'none';

    document.body.style.height = '100%';
    document.body.style.overflow = 'hidden';

    document.documentElement.style.height = '100%';
    document.documentElement.style.overflow = 'hidden';

    document.getElementById('root').style.height = '100%';
    document.getElementById('root').style.overflow = 'hidden';

    if (!locationHours) {
      document.getElementById('locationContent').style.overflow = 'hidden';
    }

    return () => {
      document.body.style.removeProperty('overscroll-behavior-y');
      document.body.style.removeProperty('touch-action');
      document.body.removeAttribute('style');
      document.documentElement.removeAttribute('style');
      document.getElementById('root').removeAttribute('style');
      const locationContentElement = document.getElementById('locationContent');

      if (!locationHours && locationContentElement) {
        locationContentElement.style.overflow = 'auto';
      }
    };
  }, []);

  return (
    <Overlay
      width={'100%'}
      className={styles.timeModalContainer}
      overlayStyle={overlayStyle}
      onClickOutside={onClickOutside}
    >
      <div
        className={styles.timeModalContainerTitle}
        style={config.styleComponent.title}
      >
        {isLg
          ? t('timeSelection.selectTimeTitle')
          : t('timeSelection.selectDayAndTimeTitle')}
      </div>
      {!hideClose && (
        <div className={styles.timeModalContainerExit} onClick={onClose}>
          <Icon name="x" style={config.styleComponent.closeIcon} />
        </div>
      )}
      <TimePicker locationHours={locationHours} onClose={handleModalOpen} />
    </Overlay>
  );
};
