import React, { useMemo, type MouseEvent } from 'react';

import get from 'lodash/get';
import { observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';

import { MyCheckApp, useModalNavigator, useWindowSize } from 'mycheck-core';

import { CustomIcon, DropDownLabel } from '@components';

import styles from './HeaderProfile.module.scss';

export const ProfileLabel = observer(({ AuthStore, onHomePage }) => {
  const { openRegisterPage } = useModalNavigator();
  const { isLg } = useWindowSize();
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const style = get(
      _config,
      onHomePage ? 'home.style.headerText' : 'general.headerText',
      {},
    );
    const primary = get(_config, 'palette.primary', {});

    return {
      style,
      primary,
    };
  }, [location.search]);

  const handleMenuOpen = (e: MouseEvent<HTMLDivElement>) => {
    openRegisterPage();
    e.preventDefault();
    e.stopPropagation();
  };

  if (AuthStore.isLoggedIn) {
    return (
      <DropDownLabel
        name={
          <div className={styles.row}>
            <CustomIcon
              name="userProfile"
              style={isLg && !onHomePage ? { color: config.primary } : {}}
            />
          </div>
        }
      />
    );
  }

  return (
    <div data-test-id="header-profile-login" onClick={handleMenuOpen}>
      <DropDownLabel
        name={
          <div className={styles.row}>
            <CustomIcon
              name="userProfile"
              style={isLg && !onHomePage ? { color: config.primary } : {}}
            />
          </div>
        }
      />
    </div>
  );
});
