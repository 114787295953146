import { useEffect, useState } from 'react';

import moment from 'moment-timezone';

import { useInterval, useLocationStore } from 'mycheck-core';

import { getNearestDateTime } from '../core';

export const useNearestTime = () => {
  const LocationStore = useLocationStore();
  const [nearestTime, setNearestTime] = useState('');
  const [interval, setInterval] = useState(5000);

  const updateTimesAvailable = () => {
    if (
      LocationStore.timeSettings &&
      typeof LocationStore.locationTimezoneName !== 'undefined'
    ) {
      const value = getNearestDateTime(
        LocationStore.timeSettings,
        LocationStore.locationTimezoneName,
      );
      const valueTime = moment(value);

      const nextValue = valueTime
        .clone()
        .add('minute', 1)
        .startOf('minute')
        .set('millisecond', 100);
      const seconds = nextValue.diff(valueTime);
      setNearestTime(value);

      setInterval(seconds);
    } else {
      setNearestTime('');
    }
  };

  const getNearestTime = () => {
    if (
      LocationStore.timeSettings &&
      typeof LocationStore.locationTimezoneName !== 'undefined'
    ) {
      return getNearestDateTime(
        LocationStore.timeSettings,
        LocationStore.locationTimezoneName,
      );
    } else {
      return '';
    }
  };

  useEffect(() => {
    updateTimesAvailable();
  }, [LocationStore.timeSettings, LocationStore.locationTimezoneName]);

  useInterval(updateTimesAvailable, interval);

  return { nearestTime, getNearestTime };
};
