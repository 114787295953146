import React from 'react';

import { useTranslation } from 'mycheck-core';

import { AcceptedCardsName, CardTypes, PaymentSource } from '../constants';

import styles from './PaymentMethodItem.module.scss';
import { PaymentMethodItem as PaymentMethodItemType } from 'plugins/AuthPlugin/types/AuthTypes';

interface PaymentMethodItemProps {
  card: PaymentMethodItemType;
}

const PaymentMethodItem: React.FC<PaymentMethodItemProps> = ({ card }) => {
  const { t } = useTranslation();
  const { issuerFull, source, value, last4Digits } = card;
  const issuer = issuerFull.toLowerCase() as keyof typeof AcceptedCardsName;

  const endingText = () => {
    if (source === PaymentSource.APPLE_PAY) {
      return 'Apple pay';
    }

    const text = {
      [PaymentSource.PAYPAL]: '',
      [PaymentSource.DEFAULT]: t('paymentMethods.endingIn'),
    };

    return text[issuer] || text['default'];
  };

  const cardImageSrc = () => {
    if (source === PaymentSource.APPLE_PAY) {
      return `${process.env.REACT_APP_WALLET_3_SDK_ASSETS}/${AcceptedCardsName.applepay}`;
    }

    const src = {
      [PaymentSource.VISA]: `${process.env.REACT_APP_WALLET_3_SDK_ASSETS}/${CardTypes.visa}`,
      [PaymentSource.DISCOVER_CARD]: `${process.env.REACT_APP_WALLET_3_SDK_ASSETS}/${AcceptedCardsName.discover}`,
      [PaymentSource.DEFAULT]: `${process.env.REACT_APP_WALLET_3_SDK_ASSETS}/${AcceptedCardsName[issuer]}`,
    };

    return src[issuer] || src['default'];
  };

  return (
    <div className={styles.paymentMethodItem}>
      <div className={styles.paymentMethodItemWrapper}>
        <img src={cardImageSrc()} alt={value} />
        <span>
          {endingText()} {last4Digits}
        </span>
      </div>
    </div>
  );
};

export default PaymentMethodItem;
