import { useContext } from 'react';

import { AuthStore } from 'auth-plugin';
import { CheckoutStore } from 'checkout-plugin';
import { LanguageStore } from 'language-plugin';
import { LocationStore } from 'location-plugin';
import { MenuStore } from 'menu-plugin';
import { MobXProviderContext } from 'mobx-react';

import { StoresConstants } from '..';

type Keys =
  | StoresConstants.AuthStore
  | StoresConstants.MenuStore
  | StoresConstants.LocationStore
  | StoresConstants.CheckoutStore
  | StoresConstants.LanguageStore;

function useStores() {
  return useContext<Record<Keys, any>>(MobXProviderContext);
}

export function useAuthStore(): AuthStore {
  return useStores()[StoresConstants.AuthStore];
}

export function useLocationStore(): LocationStore {
  return useStores()[StoresConstants.LocationStore];
}

export function useMenuStore(): MenuStore {
  return useStores()[StoresConstants.MenuStore];
}

export function useCheckoutStore(): CheckoutStore {
  return useStores()[StoresConstants.CheckoutStore];
}

export function useLanguageStore(): LanguageStore {
  return useStores()[StoresConstants.LanguageStore];
}
