import { useMemo } from 'react';

import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import {
  MyCheckApp,
  useAuthStore,
  useCheckoutStore,
  useLocationStore,
} from 'mycheck-core';
import { MenuItem as MenuItemGenericType } from 'types/GenericTypes';

export function useProductClick() {
  const AuthStore = useAuthStore();
  const LocationStore = useLocationStore();
  const CheckoutStore = useCheckoutStore();

  const { accessToken } = AuthStore;
  const experienceId = LocationStore.selectedExperienceId;
  const businessId = LocationStore.selectedRestaurantId;
  const location = useLocation();

  const config = useMemo(() => {
    const _config = MyCheckApp.instance.getGlobalConfig();

    const showEditItemConfiguration:
      | 'ALWAYS'
      | 'MODIFIERS'
      | 'REQUIRED_MODIFIERS' = get(_config, 'settings.showEditItem', 'ALWAYS');

    return {
      showEditItemConfiguration,
    };
  }, [location.search]);

  const onClickItem = async (
    item: MenuItemGenericType,
    callback: (id: number) => void,
  ) => {
    if (config.showEditItemConfiguration === 'ALWAYS') {
      return callback(item.id);
    }

    if (
      config.showEditItemConfiguration === 'MODIFIERS' &&
      item.modifierGroups.length > 0
    ) {
      return callback(item.id);
    }

    if (
      config.showEditItemConfiguration === 'REQUIRED_MODIFIERS' &&
      item.modifierGroups.filter((e) => e.minSelect > 0).length > 0
    ) {
      return callback(item.id);
    }

    CheckoutStore.setOrderItem({ ...item, quantity: 1, comment: '' });
    await CheckoutStore.updateOrCreateCheckout({
      accessToken,
      experienceId,
      businessId,
    });
  };

  return { onClickItem };
}
