import React, { useCallback, useEffect } from 'react';

import classnames from 'classnames';
import { observer } from 'mobx-react';

import {
  useAuthStore,
  useModalNavigator,
  useTranslation,
  useWindowSize,
} from 'mycheck-core';

import { Background, CustomIcon, Header, Overlay } from '@components';

import PaymentMethodItem from './PaymentMethodItem/PaymentMethodItem';
import styles from './PaymentMethodsPage.module.scss';

const PaymentMethodsPage: React.FC = observer(() => {
  const { isLg } = useWindowSize();
  const { t } = useTranslation();
  const { closePaymentMethodsPage } = useModalNavigator();
  const AuthStore = useAuthStore();
  const handleBackClick = useCallback(() => closePaymentMethodsPage(), []);
  const hasSavedCards = !!AuthStore.paymentMethods.length;

  useEffect(() => {
    AuthStore.fetchPaymentMethods();
  }, []);

  const renderBody = () => (
    <div
      className={classnames(styles.paymentMethodsPage, styles.paymentMethods)}
    >
      <Header
        headerTitle={t('paymentMethods.paymentTitle')}
        enforceSmall={isLg}
        handleBackClick={handleBackClick}
      />
      <Background className={styles.paymentMethods}>
        {!AuthStore.isPaymentMethodsFetching && !hasSavedCards ? (
          <p className={styles.noCardsTitle}>
            {t('paymentMethods.noCardsSaved')}
          </p>
        ) : (
          <>
            <p className={styles.cardsTitle}>
              {t('paymentMethods.yourSavedCards')}
            </p>
            {AuthStore.paymentMethods.map((card) => (
              <PaymentMethodItem card={card} key={card.id} />
            ))}
          </>
        )}
        {AuthStore.isPaymentMethodsFetching && !hasSavedCards && (
          <CustomIcon name="loader" />
        )}
        {!AuthStore.isPaymentMethodsFetching && (
          <p className={styles.cardManagementInfo}>
            {t('paymentMethods.cardManagementInfo')}
          </p>
        )}
      </Background>
    </div>
  );

  if (isLg) {
    return <Overlay>{renderBody()}</Overlay>;
  }

  return renderBody();
});

PaymentMethodsPage.displayName = 'PaymentMethodsPage';

export default PaymentMethodsPage;
